import Checkbox from "@mui/material/Checkbox";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React, { useState } from 'react';
import { FormControlLabel, Grid, TextField as MuiTextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { admissionsAdvisorUtils } from '../../../admissions-advisor/utils/admission-advisor-utils';
import { TakePaymentFields } from './take-payment-fields';
import { RootState } from '../../state/root-reducer';
import { CrossoverSelectionEnum } from "@finpay/estimation-types";

interface TakePaymentViewProps {
  formik: any;
  handleStripeStatus: (status: boolean) => void;
}

export function TakePaymentView(props: TakePaymentViewProps) {
  const {
    formik,
    handleStripeStatus
  } = props;

  const [displayPFRTotalError, setDisplayPFRTotalError] = useState(false);

  const estimate = useSelector((state: RootState) =>
    state.admissionsAdvisorContext.estimatorContext.estimate
  )

  function totalEstimatedPFR() {
      const pfrSummary: any = estimate?.isPlanYearCrossover
          ? estimate.crossOverSummary
          : estimate?.summary;
      const pfrAmt = estimate?.isPlanYearCrossover
          ? estimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE
              ? estimate?.crossOverSummary?.summaryBeforeCrossover?.totalPFR
              : estimate?.crossOverSummary?.summaryWithCrossover?.totalPFR
          : pfrSummary?.totalPFR;
      return pfrAmt ?? 0;
  }
  const handleAmountChange = (e: any, fieldName: string) => {
    const { value } = e.target;
    
    const maxAmount: number = totalEstimatedPFR();
    const sanitizedValue: number = Math.min(parseFloat(value), maxAmount);
    const pfrAmt = totalEstimatedPFR();
    
    if (fieldName === 'payNowAmt') {
      formik.setFieldValue('payNowAmt', sanitizedValue);
    } else if (fieldName === 'payAtFacilityAmt') {
      formik.setFieldValue('payAtFacilityAmt', sanitizedValue);
    }

    if((pfrAmt - Number(formik.values.payAtFacilityAmt) - Number(formik.values.payNowAmt)) < 0){
      setDisplayPFRTotalError(true);
    } else {
      setDisplayPFRTotalError(false);
    }
  };

  return (
    <>
      <Typography variant="subtitle2" className="my-3" style={{marginTop: '22px'}}>
        Financial Clearance Details
      </Typography>
      <Grid container>
        <Grid item direction="column" md={4} sm={4} >
          <FormControlLabel control={
            <Checkbox
              checked={formik.values.isPayNow}
              name="isPayNow"
              onChange={(e)=>{
                formik.handleChange(e)
                formik.setFieldValue("payNowAmt", "");
              }}/>}
                label="Take Payment Now ?"
                className="label-chbx"
                labelPlacement="start"
                style={{marginLeft:0, display: 'flex'}} 
                test-id="complete-patient-record-take-payment-now-checkbox"
          />
        </Grid>
        <Grid item direction="column" md={8} sm={8} >
          <FormControlLabel
            control={
              <MuiTextField
                type="number"
                name="payNowAmt"
                value={formik.values.payNowAmt}
                onChange={(e) => {
                  handleAmountChange(e, 'payNowAmt');
                }}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: <AttachMoneyIcon style={{color: '#afbecd'}}/>
                }}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={!formik.values.isPayNow}
                test-id="complete-patient-record-take-payment-modal-amount-input"
              />
            } label={
              <Typography sx={{width: 7/10, paddingLeft: 2, textAlign: 'right', paddingRight: '30px' }}>
                Amount
              </Typography>
            } labelPlacement="start"  style={{marginLeft:20, display: 'flex', marginRight: '5px'}}
          />
        </Grid>
      </Grid>
      <Grid container style={{marginTop: '6px'}}>
        <Grid item direction="column" md={4} sm={4} >
          <FormControlLabel control={
            <Checkbox
              checked={formik.values.isPayAtFacility}
              name="isPayAtFacility"
              onChange={(e)=>{
                formik.handleChange(e);
                formik.setFieldValue("payAtFacilityAmt", "");
              }}/>}
              label="Take Payment at Facility ?"
              className="label-chbx"
              labelPlacement="start"
              style={{marginLeft:0, display: 'flex'}} 
            />
        </Grid>
        <Grid item direction="column" md={8} sm={8} >
          <FormControlLabel control={
            <MuiTextField
                name="payAtFacilityAmt"
                type="number"
                value={formik.values.payAtFacilityAmt}
                onChange={(e) => {
                  handleAmountChange(e, 'payAtFacilityAmt')
                }}
                onBlur={formik.handleBlur}
                InputProps={{
                  startAdornment: <AttachMoneyIcon style={{color: '#afbecd'}}/>
                }}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={!formik.values.isPayAtFacility}
            />} label={
            <Typography sx={{width: 7/10, paddingLeft: 2, textAlign: 'right', paddingRight: '30px' }}>
              Amount
            </Typography>
          } labelPlacement="start"  style={{marginLeft:20, display: 'flex', marginRight: '5px'}}/>
        </Grid>
      </Grid>
      <Typography sx={{width: '100%', paddingTop: '8px', textAlign: 'right', fontSize: '13px', fontStyle: 'italic', color: displayPFRTotalError ? 'red' : 'black'}}>
        Total payment amount cannot exceed pfr amount
      </Typography>
      { formik.values.isPayNow && 
        <Grid container style={{marginTop: '6px'}} >
          <Typography variant="subtitle2" className="my-3" style={{marginTop: '22px'}}>
            Payment Details
          </Typography>
          <TakePaymentFields 
            paymentMethodsFailed={false}
            requiresExistingSource={false}
            requiresRecurringPayment={false}
            formik={formik}
            stripeStatus={handleStripeStatus}
          />
        </Grid>
      }
    </>
  );
}
