import {Grid, Typography} from '@mui/material';
import {Button} from '@finpay-development/shared-components';
import React, {useState} from 'react';

export const EnrollmentThankYouView = (props: {
  clientDetails: {
    surveyUrl: string,
    email: string,
    phoneNumber: string
  }
}) => {

  const {clientDetails: {surveyUrl, email, phoneNumber}} = props;

  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

  const contactText = getClientSupportText(email, phoneNumber);

  return (
      <>
        <Grid item xs={12} sx={{marginTop: '3rem'}}>
          <Typography test-id="enrollment-page-thank-you">
            Thank you for enrolling!
          </Typography>
        </Grid>
        {
            surveyUrl && (
                <div test-id="enrollment-page-survey">
                  <Grid item xs={12}>
                    <Typography sx={{marginTop: '1rem'}}>
                      We genuinely appreciate your opinion.
                    </Typography>
                    <Typography sx={{marginTop: '1rem'}}>
                      Your responses to our short 5 question survey are invaluable
                      in shaping a better experience for everyone. Thank you for
                      helping
                      us make a difference.
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="center" sx={{marginTop: '3rem'}}>
                    <Button
                        test-id="enrollment-page-survey-btn"
                        spinnerLeftPosition={9}
                        loading={isFormProcessing}
                        disabled={isFormProcessing}
                        onClick={() => {
                          setIsFormProcessing(true);
                          window.location.href = `https://www.surveymonkey.com/r/${surveyUrl}`;
                        }}
                    >
                      Complete My Survey
                    </Button>
                  </Grid>
                </div>
            )
        }
        {
            contactText && (
                <Grid item xs={12} sx={{marginTop: '3rem'}}>
                  <Typography>
                    {contactText}
                  </Typography>
                </Grid>
            )
        }

      </>
  );

};

export const getClientSupportText = (...contactInfo: Array<string | null>) => {

  if (contactInfo.every(
      contact => [null, undefined, ''].includes(contact))) return;

  let contactInfoString = '';

  contactInfo.forEach((contactInfo) => {
    if (contactInfo) contactInfoString += contactInfoString.length === 0 ?
        contactInfo :
        ` or ${contactInfo}`;
  });

  return `For help or questions, please contact ${contactInfoString}.`;
};