import { ApiBatchLimits } from '../../enums';
import store from '../../state/store';
import { showErrorStatus } from '../../../security/state/user-slice';
import { userService } from 'src/admin/services/admin-user-service';
import { UserInfoClient } from 'src/admin/models/user-info-client';
import { paynowService } from 'src/guest/services/paynow-service';
import { User } from 'src/security/model/user';

/**
 * Creates a user record if one doesn't exist
 * @param params Object containing user creation parameters
 * @returns The created user record or null if creation fails
 */
export const checkAndCreateUser = async (params: {
  userEmail: string;
  firstName: string;
  lastName: string;
  clientId: number;
  patientEncounterId?: number;
  dispatch?: any; // Optional dispatch function
}): Promise<any> => {
  const { userEmail, firstName, lastName, clientId, patientEncounterId, dispatch } = params;
  
  try {
    // Create user scope object
    const userClientScopeToSave = new UserInfoClient();
    userClientScopeToSave.clientId = clientId;
    userClientScopeToSave.isFacilityLevel = true;
    userClientScopeToSave.allowedFacilities = [];
    
    // Create user object
    const userToCreate = {
      userName: userEmail,
      firstName: firstName,
      lastName: lastName,
      userScope: "FINPAY",
      clientId: clientId,
      entityId: 0,
      isActive: true,
      isPatient: true,
      convRunNum: 1,
      userRole: {
        userRoleId: 4,
        roleName: "Account Holder",
        roleDesc: "User Role for Patients or Patient Guarators",
        roleType: "E",
        isActive: true
      },
      allowedClients: [userClientScopeToSave],
      patientEncounterId: patientEncounterId || 0
    };
    
    // Call user creation service
    const response = await userService.createOrUpdateUser(userToCreate);
    
    if (!response || response.hasErrors) {
      throw new Error(response?.errorMessage || 'Failed to create user');
    }
    
    // Return the newly created user
    return response.entity;
  } catch (error) {
    console.error("Error creating user:", error);
    
    // Use provided dispatch or fall back to store.dispatch
    if (dispatch) {
      dispatch(showErrorStatus(`Error creating user: ${error}`));
    } else {
      store.dispatch(showErrorStatus(`Error creating user: ${error}`));
    }
    
    return null;
  }
};

/**
 * Process a user record based on email and return userId/ownerId through callback
 */
export const processUserRecord = async (
  email: string, 
  isPatientPortal: boolean = false,
  onCancel: () => void = () => {},
  checkAndCreateUserParams?: Parameters<typeof checkAndCreateUser>[0],
  onUserRecordProcessed: (userId: string | null) => void = () => {}
): Promise<any> => {
  if (!email || isPatientPortal) {
    onUserRecordProcessed(null);
    return null;
  }
  
  try {
    // Look up user by email
    const userRecord = (await userService.getAllUsers(
      0, 
      ApiBatchLimits.users, 
      { userName: email }
    ))?.entity?.[0];
    
    // Case 1: User exists but is not a patient
    if (userRecord && !userRecord.isPatient) {
      const dispatchFn = checkAndCreateUserParams?.dispatch || store.dispatch;
      dispatchFn(showErrorStatus(
        "Finpay Admin user already exists with this username. Please try another email"
      ));
      onCancel();
      onUserRecordProcessed(null);
      return null;
    } 
    // Case 2: User doesn't exist, create them
    else if (!userRecord) {
      if (checkAndCreateUserParams) {
        const createdUser = await checkAndCreateUser(checkAndCreateUserParams);
        if (createdUser && createdUser.userId) {
          onUserRecordProcessed(createdUser.userId);
        } else {
          onUserRecordProcessed(null);
        }
        return createdUser;
      } else {
        onUserRecordProcessed(null);
        return null;
      }
    }
    
    // Case 3: User exists and is a patient, return them
    if (userRecord && userRecord.userId) {
      onUserRecordProcessed(userRecord.userId);
    } else {
      onUserRecordProcessed(null);
    }
    return userRecord;
  } catch (error) {
    console.error("Error processing user record:", error);
    const dispatchFn = checkAndCreateUserParams?.dispatch || store.dispatch;
    dispatchFn(showErrorStatus(`Error processing user: ${error}`));
    onUserRecordProcessed(null);
    return null;
  }
};

export const processGuestUserRecord = async (
  email: string, 
  authToken: string,
  isPatientPortal: boolean = false,
  onCancel: () => void = () => {},
  checkAndCreateUserParams?: Parameters<typeof checkAndCreateUser>[0],
  onUserRecordProcessed: (userId: string | null) => void = () => {},
): Promise<any> => {
  if (!email || isPatientPortal) {
    onUserRecordProcessed(null);
    return null;
  }
  
  try {
    // Look up user by email
    const userRecordResponse = await paynowService.getUserRecord(
      email,
      authToken
    );

    const userRecord = Array.isArray(userRecordResponse) ? userRecordResponse[0] : null;

    // Case 1: User exists but is not a patient
    if (userRecord && !userRecord.isPatient) {
      const dispatchFn = checkAndCreateUserParams?.dispatch || store.dispatch;
      dispatchFn(showErrorStatus(
        "Finpay Admin user already exists with this username. Please try another email"
      ));
      onCancel();
      onUserRecordProcessed(null);
      return null;
    } 
    // Case 2: User doesn't exist, create them
    else if (!userRecord) {
      if (checkAndCreateUserParams) {
        const createdUser = await createGuestUser(authToken, checkAndCreateUserParams);

        if (createdUser && createdUser.userId) {
          onUserRecordProcessed(createdUser.userId);
        } else {
          onUserRecordProcessed(null);
        }
        return createdUser;
      } else {
        onUserRecordProcessed(null);
        return null;
      }
    }
    
    // Case 3: User exists and is a patient, return them
    if (userRecord && userRecord.userId) {
      onUserRecordProcessed(userRecord.userId);
    } else {
      onUserRecordProcessed(null);
    }
    return userRecord;
  } catch (error) {
    console.error("Error processing user record:", error);
    const dispatchFn = checkAndCreateUserParams?.dispatch || store.dispatch;
    dispatchFn(showErrorStatus(`Error processing user: ${error}`));
    onUserRecordProcessed(null);
    return null;
  }
};

export const createGuestUser = async (authToken: string, params: {
  userEmail: string;
  firstName: string;
  lastName: string;
  clientId: number;
  patientEncounterId?: number;
  dispatch?: any; // Optional dispatch function
}): Promise<any> => {
  const { userEmail, firstName, lastName, clientId, patientEncounterId, dispatch } = params;
  
  try {
    // Create user scope object
    const userClientScopeToSave = new UserInfoClient();
    userClientScopeToSave.clientId = clientId;
    userClientScopeToSave.isFacilityLevel = true;
    userClientScopeToSave.allowedFacilities = [];
    
    // Create user object
    const userToCreate = {
      userName: userEmail,
      firstName: firstName,
      lastName: lastName,
      userScope: "FINPAY",
      clientId: clientId,
      entityId: 0,
      isActive: true,
      isPatient: true,
      convRunNum: 1,
      userRole: {
        userRoleId: 4,
        roleName: "Account Holder",
        roleDesc: "User Role for Patients or Patient Guarators",
        roleType: "E",
        isActive: true
      },
      allowedClients: [userClientScopeToSave],
      patientEncounterId: patientEncounterId || 0
    };
    
    // Call user creation service
    const response = await paynowService.createGuestUser(userToCreate as unknown as User, authToken);
    
    if (!response || response.hasErrors) {
      throw new Error(response?.errorMessage || 'Failed to create user');
    }
    // Return the newly created user
    return response;
  } catch (error) {
    console.error("Error creating user:", error);
    
    // Use provided dispatch or fall back to store.dispatch
    if (dispatch) {
      dispatch(showErrorStatus(`Error creating user: ${error}`));
    } else {
      store.dispatch(showErrorStatus(`Error creating user: ${error}`));
    }
    
    return null;
  }
};