import { FacilityPayerPlans, Vob, VobPostBody } from "./vob";
import {
  TimingRisk,
  PayorRisk,
} from "../../shared/model/timing-and-payor-risk";

import {
  emptyPatientEncounter,
  PatientEncounter,
} from "../../patient/components/models/patient-encounter";
import { TypeOfCare } from "../../shared/model/type-of-care";
import { PatientPaymentProgram
} from "../../patient/components/models/patient-payment-program";
import { client } from "../../shared/model/client";
import { ImplementationFacility } from "../../implementation-specialist/components/implementation-clients/details/models/implementation-facility";
import { emptyPrimaryContact, PrimaryContact } from "src/shared/model/primary-contact";

export interface AdvisorPatientBody {
  email: string;
  primaryAddress: {
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: {
      stateCode: string | undefined;
      stateId?: number | null | undefined;
      stateName: string | undefined;
    };
    zipCode?: string;
  };
  phoneNumber: string;
}

export interface vobPatient {
  firstName: string;
  lastName: string;
  birthDate?: string | null;
  advisorPatientId?: number;
  isLoading: boolean;
  ormVersion?: number;
  lastUpdateDt?: string;
  lastUpdateUserId?: string;
  lastFunctionName?: string;
  createDt?: string;
  createUserId?: string;
  middleName?: string;
  fpPatientId?: number;
  advisorPatientBody?: AdvisorPatientBody;
  primaryPhoneNum?: number;
  clientId: null;
}

export interface vobPatientPostBody {
  firstName: string;
  lastName: string;
  birthDate: string;
  advisorPatientId?: number;
  fpPatientId?: number | undefined;
  patientId?: number;
  advisorPatientBody?: AdvisorPatientBody;
  middleName?: string;
  clientId?: number;
}

export const emptyVobPatient = {
  firstName: "",
  lastName: "",
  birthDate: null,
  advisorPatientId: undefined,
  isLoading: false,
  fpPatientId: undefined,
  patientId: undefined,
  clientId: null,
};

export interface PovertyGuidelineData {
  year: string;
  state: string;
  household_size: string;
  poverty_threshold: number;
}

export interface patientSearchSource {
  advisorPatientId: number;
  patientId: number;
  firstName: string;
  middleName?: string;
  email?: string;
  phoneNumber?: string;
  secPhoneNum?: string;
  secPhoneType?: string;
  lastName: string;
  birthDate: string;
  clientId?: number;
  clientsPatientAccountId?: number;
  primaryPhoneNum?: string;
  ormVersion?: number;
  createUserId?: string;
  createDt?: string;
  lastUpdateUserId?: number;
  lastUpdateDt?: string;
  vobs?: VobPostBody[];
  iocs?: patientIocResult[];
  associatedClients?: AssociatedClient[];
  associatedFacilities?: AssociatedFacility[];
  fpPatientId: number;
  address?: {
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: {
      stateId?: number | null | undefined;
      stateCode: string;
      stateName: string;
    };
    zipCode: string;
    zipCode4?: string | null | undefined;
    addressType?: string;
  };
  advisorPatientBody?: AdvisorPatientBody;
}

export interface AssociatedClient {
  clientId: string;
  clientName: string;
}

export interface AssociatedFacility {
  facilityId: string;
  facilityName: string;
}

export interface patientSearchResult {
  _index: string;
  _type: string;
  _id: string;
  _score: string;
  _source: patientSearchSource;
}

export interface patientIocResult {
  instanceCreateDt: string;
  isLocked: boolean;
  createUserId: string;
  createDt: string;
  isConverted: boolean;
  paidToPatient: boolean;
  isRiskClassOverriden: boolean;
  mceSent: boolean;
  patientEncounterId: number;
  patientId: number;
  clientId: number;
  clientName?: string;
  facilityId: number;
  facilityName?: string;
  workflowId: number;
  workflow?: any;
  workflowStatusId: number;
  workflowSubStatusId: number;
  lastUpdateUserId: string;
  lastUpdateDt: string;
  convertUserId?: number;
  linkedUserId?: number;
  clientsPatientAccountId?: number;
  clientsPatientIOCId?: number;
  salesforceId?: number;
  levelOfCare?: number;
  pfrAmt?: number;
  timingRiskId?: number;
  payorRiskId?: number;
  admissionDt?: string;
  dischargeDt?: string;
  pfrDiscount?: number;
  preDiscountPfrAmt?: number;
  hasChampions?: boolean;
  iocStatus?: string;
  iocStatusDt?: string;
  capacityToPayId?: string;
  finClearanceStatus?: string;
  finClearanceStatusDt?: string;
  workflowStatusDt?: string;
  lockedByUserId?: string;
  sectionStatus?: string;
  lastFunctionName?: string;
  patientStatus?: string;
  patientStatusDt?: string;
  lockRequestedById?: string;
  lockStatus?: string;
  convertDt?: string;
  riskClassId?: number;
  riskClassName?: string;
  sourcePatientId?: number;
  convRunNum?: number;
  ormVersion?: number;
  vobDate?: string;
  typeOfCareId?: number;
  timingRisk?: TimingRisk;
  payorRisk?: PayorRisk;
  pfrEstimateId?: number;
  patientPaymentProgram?: PatientPaymentProgram[];
}

export interface FinPassPatientModel {
  advisorPatientId: number;
  contactId: number;
  contact: PrimaryContact;
  convRunNum?: number | undefined;
  createDt: string;
  createUserId: string;
  clientId: number;
  lastFunctionName: string;
  lastUpdateDt: string;
  lastUpdateUserId: string;
  patientId: number;
  patientMasterId: number;
  patientStatus: string;
  patientStatusDt: string;
  advisorPatientBody?: AdvisorPatientBody;
}

export const emptyFinPassPatientModel: FinPassPatientModel = {
  advisorPatientId: 0,
  contactId: 0,
  convRunNum: 0,
  createDt: "",
  createUserId: "",
  clientId: 0,
  lastFunctionName: "",
  lastUpdateDt: "",
  lastUpdateUserId: "",
  patientId: 0,
  patientMasterId: 0,
  patientStatus: "",
  patientStatusDt: "",
  advisorPatientBody: {
    email: "",
    primaryAddress: {
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: {
        stateCode: "",
        stateId: null,
        stateName: "",
      },
      zipCode: "",
    },
    phoneNumber: ""
  },
  contact: emptyPrimaryContact,
};

export interface IocPatientInsurance {
  coinsuranceAmt: number;
  coinsuranceType: string;
  copayAmt: number;
  deductibleAmt: number;
  deductibleIncInOOPMaxFl: boolean;
  familyDeductibleAmt: number;
  familyOOPMaxAmt: number;
  familyRemainingOOPMaxAmt: number;
  familyRemDeductibleAmt: number;
  groupNumber: string;
  insuranceCompanyName: string;
  insurancePlanName: string;
  isInNetwork: boolean;
  isSubjectToCopay: boolean;
  maxDays: number;
  oopmaxAmt: number;
  policyNumber: string;
  remainingOOPMaxAmt: number;
  remDeductibleAmt: number;
  sortOrder?: string | undefined | null;
}

export interface IocConfig {
  paramId?: number;
  patientId: number;
  clientId: number;
  facilityId: number;
  pfrAmt: number;
  pfrEstimateId?: number;
  patientInsurance?: IocPatientInsurance[];
  timingRisk?: TimingRisk;
  payorRisk?: PayorRisk;
  clientsPatientIOCId?: string;
  levelOfCare?: string;
  workflow?: any;
  clientsPatientAccountId?: string;
  typeOfCare?: TypeOfCare;
  totalSurchargeRate?: number;
  finClearanceStatus?: string;
  admissionDt?: string;
  dischargeDt?: string;
}


