import {createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {Utils} from '../../shared/utils';
import {
  resetAdminConfigurationContext,
} from '../../admin-configuration/state/admin-configuration-slice';
import {resetAdminRoleContext} from '../../admin/state/admin-roles-slice';
import {
  resetAdminUserContext,
  setIsPublicMode,
} from '../../admin/state/users/admin-slice';
import {
  resetImplementationClientContext,
} from '../../implementation-specialist/state/clients/implementation-clients-slice';
import {
  resetSpecialistFacilityContext,
} from '../../implementation-specialist/state/facility/implementation-facility-slice';
import {
  resetRulesEngineContext,
} from '../../implementation-specialist/state/rules-engine/rules-engine-slice';
import {resetPatientContext} from '../../patient/state/patient-slice';
import {clearPatient} from '../../admissions-advisor/state/vob-patient-slice';
import {clearVOB} from '../../admissions-advisor/state/vob-slice';
import {clearEstimate} from '../../admissions-advisor/state/estimator-slice';
import {
  clearRiskAssessment,
} from '../../admissions-advisor/state/risk-assessment-slice';
import {CookieExpiration} from '../../shared/enums';
import {RootState} from '../../shared/state/root-reducer';
import {GetError} from '../model/application-status';
import {ChangePasswordState} from '../model/change-password-state';
import {Credentials} from '../model/credentials';
import {sampleIdDB, Tokens} from '../services/SSO';
import {getDefaultUserContextState} from '../model/user-context-state';
import {
  authService,
  changeAuthState,
  GENERIC_AUTHENICATION_ERROR, INVALID_COGNITO_STATE,
  INVALID_DOMAIN_ERROR,
  VERIFICATIONCODE_AUTHENICATION_ERROR,
} from '../services/authorization-service';
import {userService} from '../services/user-service';
import {userService as adminUserService} from '../../admin/services/admin-user-service'
import {resetUserContext, showErrorStatus, showStatus} from './user-slice';
import HttpInterceptor from '../../shared/Http/http-interceptor';
import {Auth} from 'aws-amplify';
import {
  getClients,
} from '../../implementation-specialist/state/clients/implementation-clients-thunk';
import {patientHelper} from '../../patient/services/patient-service';
import {paynowService} from '../../guest/services/paynow-service';
import {
  specialistClientService
} from '../../implementation-specialist/services/implementation-client-service';

export const logUserIn = createAsyncThunk(
  'logUserIn',
  async (credentials: Credentials, thunkAPI) => {

    thunkAPI.dispatch(setIsPublicMode(false))
      clearAllState(); // ensures you can switch from logging in as a patient or as other/adminif (typeof credentials.password !== "undefined") {
      if (typeof credentials.password !== "undefined") {
          const isUserAuthenticated = await authService.login(credentials.email, credentials.password);

          const loginResult = getDefaultUserContextState();

          // When the user has been authenticated trigger call to get user profile
          if (isUserAuthenticated) {
              const userProfileStatus = await userService.getUserProfileWrapper({thunkAPI});
              // When Profile is missing log user out and show error
              if (userProfileStatus.userProfileNotFound) {
                  loginResult.applicationStatus = GetError(GENERIC_AUTHENICATION_ERROR);
                  thunkAPI.dispatch(logUserOut());
              } else {
                  loginResult.userProfile = userProfileStatus.userProfile;
                  loginResult.userIsAuthenticated = true;
                  loginResult.onLoginScreen = false;
                  loginResult.homeRoute = userProfileStatus.userProfile.defaultRoute;
                  loginResult.clearForm = true;
                  Cookies.set("userStatus", {isActive: true}, {expires: CookieExpiration.days});
              }
          } else {
            const authenicatedUser: any = await Auth.signIn(credentials.email, credentials.password);
            const checkForChallenge: boolean = authenicatedUser.hasOwnProperty("challengeName");
            if (!checkForChallenge) {
              // don't set application status to error if we're currently redirecting for a challenge
              loginResult.applicationStatus = GetError(GENERIC_AUTHENICATION_ERROR);
            }
          }
          return loginResult;
      }
      // Allow for custom error
      else if (credentials.error){
          const loginResult = getDefaultUserContextState();
          loginResult.applicationStatus = GetError(credentials.error);

          return loginResult;
      }
      else {
          const loginResult = getDefaultUserContextState();
          loginResult.applicationStatus = GetError("Passwords are required");

          return loginResult;
      }

  }

);

export const getProfileState = createAsyncThunk(
    'getProfileState',
    async(tokens: Tokens, thunkAPI) =>{
        clearAllState(); // ensures you can switch from logging in as a patient or as other/admin
        const loginResult = getDefaultUserContextState();

            const userProfileStatus = await userService.getUserProfileWrapper({isSSO: true, tokens, thunkAPI});
            // When Profile is missing log user out and show error
            if (userProfileStatus.userProfileNotFound) {
                thunkAPI.dispatch(clearAllState());
                // Redirects to home with error as query
                window.location.replace("/?error=404");
            } else {
                loginResult.userProfile = userProfileStatus.userProfile;
                loginResult.userIsAuthenticated = true;
                loginResult.onLoginScreen = false;
                loginResult.homeRoute = userProfileStatus.userProfile.defaultRoute;
                loginResult.userEmail = userProfileStatus.userProfile.userName;
                loginResult.clearForm = true;
                Cookies.set("userStatus", {isActive: true}, {expires: CookieExpiration.days});
            }

        return loginResult;
    }

);

export const refreshToken = createAsyncThunk(
  'refreshToken', async () => {
    return await authService.fetchSecurityToken();
  }
)
export const generateToken = createAsyncThunk(
    'generateToken',
    async(code : string, thunkAPI) =>{
        const tokens: Tokens = await userService.getTokensFromCode(code);
        HttpInterceptor(true, tokens);
        return thunkAPI.dispatch(getProfileState(tokens));
    }
)

export const ssoRedirect = createAsyncThunk(
    'ssoRedirect',
    async(credentials : Credentials, thunkAPI) =>{
        const email_domain : string = credentials.email.split('@')[1];
        if(!credentials.password) {
            if (Utils.includesIDP(email_domain, sampleIdDB)) {
                const url : string = Utils.generateSamlUrl({IDP: {value: email_domain}});
                window.location.replace(url);
                // to run with a custom redirect link
                // ({IDP: {value: email_domain}, redirectURI: "http://localhost:3000"});
            } else {
                const cred = new Credentials();
                cred.error = INVALID_DOMAIN_ERROR;
                thunkAPI.dispatch(logUserIn(cred));
            }
        }

    }
)

export const checkForCode = createAsyncThunk(
    'checkCode',
    async(param : void, thunkAPI) => {
        HttpInterceptor(false);
        const url :URL = new URL(window.location.href);
        const params : URLSearchParams = new URLSearchParams(url.search);
        if(params.has('code')){
            const code : any = params.get('code');
            return thunkAPI.dispatch(generateToken(code));
        }
        if(params.has('error')){
            const error_string : any = params.get('error');
            const error_code : number = parseInt(error_string);
            alert(`Error ${error_code}: ${Utils.getErrorByCode(error_code)} \n\n${GENERIC_AUTHENICATION_ERROR}`);
            window.location.replace("/");
        }
    }
)

export const logUserOut = createAsyncThunk(
  'logUserOut',
  async (param: void, thunkAPI) => {
    thunkAPI.dispatch(clearAllState());
    authService.clearCognitoCookies()
    const response = await authService.logout();
    return response;
  }
)


export const clearAllState = createAsyncThunk(
  'clearAllState',
  async (param: void, thunkAPI) => {
    thunkAPI.dispatch(resetUserContext());
    thunkAPI.dispatch(resetPatientContext());
    thunkAPI.dispatch(resetSpecialistFacilityContext());
    thunkAPI.dispatch(resetRulesEngineContext());
    thunkAPI.dispatch(resetAdminRoleContext());
    thunkAPI.dispatch(resetAdminConfigurationContext());
    thunkAPI.dispatch(resetAdminUserContext());
    thunkAPI.dispatch(resetImplementationClientContext());
    thunkAPI.dispatch(clearPatient());
    thunkAPI.dispatch(clearVOB());
    thunkAPI.dispatch(clearRiskAssessment());
    thunkAPI.dispatch(clearEstimate());
  }
)

export const checkIfUserIsAuthenticated = createAsyncThunk(
  'checkIfUserIsAuthenticated',
  async (param: void, thunkAPI) => {
    const checkUserSession = await authService.checkForUsersession();
    const getProfileResult =  getDefaultUserContextState();

    if (checkUserSession?.isAuthenticated) {
      const userProfileStatus = await userService.getUserProfileWrapper({thunkAPI});

      // When Profile is missing log user out 
      if (userProfileStatus.userProfileNotFound) {
        thunkAPI.dispatch(logUserOut());
    } else {
        getProfileResult.userProfile = userProfileStatus.userProfile;
        getProfileResult.userIsAuthenticated = true;
        getProfileResult.onLoginScreen = false;
        getProfileResult.homeRoute = userProfileStatus.userProfile.defaultRoute;
        getProfileResult.clearForm = true;
      }

      if(userProfileStatus.userProfile.userRole.userRoleId !== 4) {
        thunkAPI.dispatch(getClients({
          includeFacility: true,
        }))
      }
    }
    return getProfileResult;
  }
)


export const updatePassword = createAsyncThunk(
  'updatePassword',
  async (newPassword: string, thunkAPI) => {

    const response = await authService.SetPassword(newPassword);

      // After Password is updated always ensure user is logged out.
      thunkAPI.dispatch(logUserOut());

    return response;
  }
)

//Used by admin/patient portal login forgot password flow
export const sendForgetPasswordNotification = createAsyncThunk(
  'sendForgetPasswordNotification',
  async (params: {username: string, userRoleId?: number}, thunkAPI) => {

    const {username} = params;
    const response = await authService.sendAccHolderNotification(params);

    if(!response.passwordUpdated){

      if (response.statusMessage === INVALID_COGNITO_STATE){

        /**
         * If user is in a force change password state,
         * we need to resend the welcome email.
         * */

        try{

          const tokenResponse = await paynowService.getAnonymousRequestToken();

          if (tokenResponse.hasErrors) {
            throw new Error("Guest token request failed");
          }

          HttpInterceptor(false, tokenResponse!, true);

          const userResponse = await adminUserService.getAllUsers(0, 1000, {
            userName: username,
          })
          if(userResponse.hasErrors){
            throw new Error("Error while fetching user")
          }

          if(userResponse.entity.length ===0){
            throw new Error("No user record exists")
          }

          const userId = userResponse.entity?.[0].userId;

          const response = await specialistClientService.resendInvitation(userId);

          if(response.hasErrors){
            throw new Error("Error resending welcome email")
          }

          thunkAPI.dispatch(showStatus("Invitation sent successfully "));

          return {
            username,
            showPasswordChangeView: false
          };

        }catch(e: any){
          changeAuthState("signin");
          thunkAPI.dispatch(showErrorStatus(GENERIC_AUTHENICATION_ERROR))
          throw Error(e)
        }

      }else {
        // Return User to the Login Screen if error occurred sending notification
        changeAuthState("signin");
        thunkAPI.dispatch(showErrorStatus(response.statusMessage))
        throw new Error(response.statusMessage)
      }

    }

    return {
      username,
      showPasswordChangeView: true
    };
  }
)

//Used by admin portal user password resend flow
//For case where user has already updated password after receiving welcome email
export const sendForgetPasswordNotificationV2 = createAsyncThunk(
    'sendForgetPasswordNotificationV2',
    async (params: {username: string, userRoleId: number}, thunkAPI) => {

      const {username} = params;

      const response = await authService.sendAccHolderNotification(params)

      if(!response.passwordUpdated){
        patientHelper.robustErrorHandler({
          entity: { code: "", message: "" },
          errorMessage: "Error while sending password change notification",
          hasErrors: true
        }, thunkAPI)
      } else {
        thunkAPI.dispatch(showStatus("Invitation sent successfully "));
      }

      return username;
    }
)

export const updatePasswordWithVerificationCode = createAsyncThunk(
  'updatePasswordWithVerificationCode',
  async (changePasswordState: ChangePasswordState, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const userName = rootState.userContext.userEmail;
    const response = await authService.SetPasswordWithVerificationCode(userName, changePasswordState);

    // Return User to the Login Screen after updating password
    changeAuthState("signin");
    return response;
  }
)

export const updatePasswordWithVerificationCodev2 = createAsyncThunk(
    'updatePasswordWithVerificationCodev2',
    async (changePasswordState: ChangePasswordState) => {
      const userName = changePasswordState.email
      return await authService.SetPasswordWithVerificationCode(userName!,
          changePasswordState);
    }
)

export const verifySigninCode = createAsyncThunk(
  'verifySigninCode',
  async (code: string, thunkAPI) => {
    const verificationCodeStatus = await authService.verifySigninCode(code);
    const loginResult =  getDefaultUserContextState();

    // When the user has been authenticated trigger call to get user profile
    if (verificationCodeStatus) {
      const userProfileStatus = await userService.getUserProfileWrapper({thunkAPI});
      // When Profile is missing log user out and show error
      if (userProfileStatus.userProfileNotFound) {
        thunkAPI.dispatch(logUserOut());
        loginResult.applicationStatus = GetError(GENERIC_AUTHENICATION_ERROR);
      } else {
        loginResult.userProfile = userProfileStatus.userProfile;
        loginResult.userIsAuthenticated = true;
        loginResult.onLoginScreen = false;
        loginResult.homeRoute = userProfileStatus.userProfile.defaultRoute;
        loginResult.clearForm = true;
      }
    } else {
      // Return User to the Login Screen if error occurred sending notification
      loginResult.clearForm = true;
      loginResult.applicationStatus = GetError(VERIFICATIONCODE_AUTHENICATION_ERROR);
      thunkAPI.dispatch(logUserOut());
    }

    return loginResult;
  }
)