import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { saveCallbackStatus } from "@finpay-development/shared-components";
import {
  convertedPatientFilter,
  emptyConvertedPatientFilter,
  emptyPreConvertedPatientFilter,
  PatientClientFilter,
  preConvertedPatientFilter,
} from "../components/models/patient-filter";
import {
  addPatientPaymentMethod,
  convertPatientEncounter,
  createPatientNote,
  deletePatientNote,
  deletePatientPaymentMethod,
  deletePatientPaymentProgram,
  dismissReminder,
  getConvertedPatients,
  getDocuments,
  getEvaluateRule,
  getInstanceOfCare,
  getPatientDemographics,
  getPatientNotes,
  getPaymentMethods,
  getRiskClass,
  getPreconvertedPatients,
  getTransactions,
  updateEncountersAfterMakePayment,
  releaseCharge,
  saveInstanceOfCare,
  saveInsurance,
  savePatient,
  savePatientChampion,
  savePatientPaymentProgram,
  setRecentlyViewedPatient,
  getEncounterSignUrl,
  setUpEncountersAccountHolder,
  verifyBankAccount,
  updateContactInfo,
  savePfrAdjustment,
  createS3File,
  getS3Document,
  deleteS3Document,
  getForwardedSignUrl,
  lockIOC,
  createSPAHelloSignRequest,
  createPFRHelloSignRequest,
  createPhiHelloSignRequest,
  updatePFRDocument,
  updateWarmTransfer,
  checkClientPatientIOCId,
  checkClientPatientAccountId,
  getPtRecord,
  updateIOC,
  getVOBAndEstimateForFinpass,
  getVOBAndNewEstimateForFinpass,
  saveVOBForFinpass,
  putProgram,
  getVOB,
  postVob,
} from './patient-thunk';
import { emptyLoadingState, loadingState } from '../components/models/loading-state';
import { PatientEncounterCard } from '../components/models/patient-encounter-card';
import { emptyPatientViewModel, PatientViewModel } from '../components/models/patient-view-model';
import { emptyPatientEncounter, PatientEncounter,patientEncounterSignUrl } from '../components/models/patient-encounter';
import { UNKNOWN_SERVER_ERROR } from '../../shared/state/saving-status';
import { Rule } from '../../admin-configuration/models/rule';
import { PatientNotes } from '../components/models/patient-note';
import { CreateTokenCardData } from '@stripe/stripe-js';
import { TokenState } from "../../stripe/models/stripe-token-state";
import { emptyStripeBankData, StripeBankData } from "../../stripe/models/stripe-bank-data";
import { emptyErrorState, ErrorState } from "../components/models/error-state";
import { RecentlyViewedTypes } from "../../shared/enums";
import { recentlyViewedPatientsObj } from "../../shared/model/recentlyViewed";
import { PrimaryContact } from '../../shared/model/primary-contact';
import { TimingRisk } from "src/shared/model/timing-and-payor-risk";
import { Workflow } from "../../shared/model/client";
import { patientHelper } from "../services/patient-service";
import { blankState, emptyBlankState } from "../components/models/blank-state";
import { ReleasePaymentErrorContext, emptyReleasePaymentErrorContext } from "../components/models/release-payment-model";
import { PatientS3document } from "../components/models/patient-s3document";
import { emptyRiskClass, RiskClass } from "../../shared/model/risk-class";
import { emptyAccountHolderContext, AccountHolderContext } from "../components/models/account-holder-model";
import {Vob} from 'src/admissions-advisor/models/vob';
import {emptyVob} from 'src/admissions-advisor/models/vob';
import {
  estimatorSliceShape
} from '../../admissions-advisor/state/estimator-slice';
import {
  getClientLevelsOfCare
} from './patient-thunk';
import {
  configGetLOC
} from '../../admin-configuration/state/admin-configuration-thunk';
import {
  apiStatus,
  emptyEstimate,
} from '../../admissions-advisor/models/estimator';
import { createPatientIoc } from "src/admissions-advisor/state/vob-patient-thunk";


export interface PatientState {
  preconvertedPatients: PatientEncounterCard[]; // all preconverted patients
  preconvertedPatientsFiltered: {[key: string]: PatientEncounterCard[]};
  convertedPatients: PatientEncounterCard[]; // all converted patients
  convertedPatientsFiltered: {[key: string]: PatientEncounterCard[]};
  selectedPatient: PatientViewModel; // patient object containing (possibly) multiple encounters
  selectedEncounter: PatientEncounter; // selected encounter via dropdown
  selectedVOB: Vob;
  selectedEstimate: estimatorSliceShape;
  patientIocStatus: saveCallbackStatus,
  calculatedRiskClass: RiskClass;

  preConvertedFilterFormValues: preConvertedPatientFilter; // preconverted filter values
  convertedFilterFormValues: convertedPatientFilter; // converted filter values
  preConvertedClientAndFacilityData: PatientClientFilter[];
  convertedClientAndFacilityData: PatientClientFilter[];

  preConvertedClientCheckboxesChecked: Number[];
  convertedClientCheckboxesChecked: Number[];
  preConvertedFacilityCheckboxesChecked: Number[];
  convertedFacilityCheckboxesChecked: Number[];

  savePatientModalSaveStatus: saveCallbackStatus,
  downPaymentModalSaveStatus: saveCallbackStatus,
  instanceOfCareModalSaveStatus: saveCallbackStatus,
  completePatientModalIOCSaveStatus: saveCallbackStatus,
  downPaymentTokens: TokenState, // stripe token for down payment modal,
  recurringPaymentTokens: TokenState,
  patientTakePaymentTokens: TokenState,
  recurringPatientTakePaymentTokens: TokenState,
  patientMakePaymentTokens: TokenState,
  isPreConverted: boolean,
  recentlyViewedPreConvertedPatients: Array<recentlyViewedPatientsObj>, // preconverted recently viewed patients from cookies
  recentlyViewedConvertedPatients: Array<recentlyViewedPatientsObj> ,// converted recently viewed patients from cookies
  redirectToId: number,
  matchingRule: Rule,
  comments: PatientNotes[],
  reminders: PatientNotes[],
  stripeCardData: CreateTokenCardData,
  stripeBankData: StripeBankData,
  isError: ErrorState,
  isSaving: boolean,
  // common state for most/all slices
  errorMessage: string,
  isLoading: loadingState, // object containing loading state for multiple pages
  isConverting: boolean, // is in the process of converting an IOC
  isBlank: blankState, // object telling us whether a state field is actually empty (after api was called), or if was just initialized to empty. Not needed for all fields.
  convertedActiveEncounters: PatientEncounter[],
  convertedCompletedEncounters: PatientEncounter[],
  convertedCancelledEncounters: PatientEncounter[],
  selectedConvertedEncounters: PatientEncounter[], // list of encounters that are selected via Account Holder View. (one of active, converted, or completed encounters lists)
  currentConvertedEncounter: PatientEncounter, // focused encounter that is currently being interacted with by the user
  isDownPaymentFullyPaid: boolean,
  patientEncounterSignUrl: patientEncounterSignUrl, // patientEncounterUrl to sign
  transactionsCSVData: [string[]],
  refreshAccountHolderDashboard: boolean,
  patientS3Documents: Array<PatientS3document>,
  isUploadingS3: boolean,
  scriptWindowObjectReference: Array<any>
  clientsPatientIOCIdFilter?: Array<number>;
  inboundPtRecord?: PatientRecordRes;
  clientsPatientAccountIdFilter?: Array<number>;
  accountHolderContext: AccountHolderContext;
  releasePaymentStatusMsg: ReleasePaymentErrorContext;
}

export interface PatientRecordRes {
  admissionDt: string;
  clientId: number;
  clientsPatientAccountId: string;
  clientsPatientIOCId: string;
  facilityId: number;
  patient: {
      contact: PrimaryContact;
      clientId: number;
      patientStatus: string;
  };
  patientId: number | null;
  patientInsurance: any;
  patientNotes: PatientNotes;
  timingRisk: TimingRisk;
  workflow: Workflow;
}

const initialState = {
    preconvertedPatients: [],
    preconvertedPatientsFiltered: {},
    convertedPatients: [],
    convertedPatientsFiltered: {},
    selectedPatient: emptyPatientViewModel,
    selectedEncounter: emptyPatientEncounter,
    selectedVOB: emptyVob,
    selectedEstimate: {
      estimate: null,
      facilityLevelsOfCareByPlan: [],
      facilityAllLevelsOfCare: [],
      clientLevelsOfCare: [],
      priorLevelsOfCare: [],
      savedEstimations: [],
      fetchedEstimate: {
        ormVersion: undefined,
        createUserId: "",
        createDt: "",
        pfrEstimateId: undefined,
        vobId: undefined,
        advisorPatientId: undefined,
        estimateBody: undefined,
        lastUpdateUserId: "",
        lastUpdateDt: ""
      },
      clientDetails: undefined,
      isLoading: {
        isLoadingFacilityLocByPlan: false,
        facilityLocByPlanStatus: apiStatus.none,
        isLoadingMasterLoc: false,
        isLoadingClientLoc: false,
        configGetLOCStatus: apiStatus.none,
        getClientLOCStatus: apiStatus.none,
        isLoadingEstimates: false,
        isLoadingGetEstimate: false,
        isLoadingSaveEstimates: false,
        getEstimateStatus: apiStatus.none,
        saveEstimateStatus: apiStatus.none,
        isLoadingFacilityLoc: false,
        isLoadingClientDetails: false,
        clientDetailsStatus: apiStatus.none,
      },
      facilityLevelsOfCareList: null,
    },
    patientIocStatus: saveCallbackStatus.none,
    calculatedRiskClass: emptyRiskClass,
    preConvertedFilterFormValues: emptyPreConvertedPatientFilter,
    convertedFilterFormValues: emptyConvertedPatientFilter,
    preConvertedClientAndFacilityData: [],
    convertedClientAndFacilityData: [],

    preConvertedClientCheckboxesChecked: [],
    convertedClientCheckboxesChecked: [],
    preConvertedFacilityCheckboxesChecked: [],
    convertedFacilityCheckboxesChecked: [],

  savePatientModalSaveStatus: saveCallbackStatus.none,
  downPaymentModalSaveStatus: saveCallbackStatus.none,
  instanceOfCareModalSaveStatus: saveCallbackStatus.none,
  completePatientModalIOCSaveStatus: saveCallbackStatus.none,
  downPaymentTokens: {},
  recurringPaymentTokens: {},
  patientTakePaymentTokens: {},
  recurringPatientTakePaymentTokens: {},
  patientMakePaymentTokens: {},
  isPreConverted: true,
  recentlyViewedPreConvertedPatients: [],
  recentlyViewedConvertedPatients: [],
  redirectToId: 0,
  matchingRule: { ruleId: -1 } as Rule,
  comments: [],
  reminders: [],
  stripeCardData: {},
  stripeBankData: emptyStripeBankData,
  isError: emptyErrorState,
  isSaving: false,
  errorMessage: "",
  isLoading: emptyLoadingState,
  isConverting: false,
  isBlank: emptyBlankState,
  convertedActiveEncounters: [],
  convertedCompletedEncounters: [],
  convertedCancelledEncounters: [],
  selectedConvertedEncounters: [],
  currentConvertedEncounter: {} as PatientEncounter,
  isDownPaymentFullyPaid: false,
  patientEncounterSignUrl:{} as patientEncounterSignUrl,
  transactionsCSVData: [[]],
  refreshAccountHolderDashboard: false,
  patientS3Documents: [],
  isUploadingS3: false,
  scriptWindowObjectReference: [],
  clientsPatientIOCIdFilter: undefined,
  clientsPatientAccountIdFilter: undefined,
  inboundPtRecord: {} as PatientRecordRes,
  accountHolderContext: emptyAccountHolderContext,
  releasePaymentStatusMsg:emptyReleasePaymentErrorContext,
} as PatientState;

// Slice
const patientContextSlice = createSlice({
    name: 'patientContext',
    initialState,
    reducers: {
        setSelectedVOB: (state, action) => {
            state.selectedVOB = {
                ...state.selectedVOB,
                ...action.payload,
            };
        },
        clearStatus: state => {
            state.savePatientModalSaveStatus = saveCallbackStatus.none;
            state.downPaymentModalSaveStatus = saveCallbackStatus.none;
            state.instanceOfCareModalSaveStatus = saveCallbackStatus.none;
            state.errorMessage = '';
        },
        
        clearEncounter: (state) => {
            state.selectedEncounter = emptyPatientEncounter;
        },
        preConvertedClientFacilityCheckboxData: (state, action) => {
            state.preConvertedClientAndFacilityData = action.payload;
        },
        clearPatientIocStatus: (state) => {
			state.patientIocStatus = initialState.patientIocStatus;
            state.completePatientModalIOCSaveStatus = initialState.completePatientModalIOCSaveStatus;
            state.isLoading.createPatientIoc = initialState.isLoading.createPatientIoc;
		},

        convertedClientFacilityCheckboxData: (state, action) => {
            state.convertedClientAndFacilityData = action.payload;
        },

        setPreConvertedClientCheckboxChecked: (
            state,
            action: PayloadAction<{clientId: number; isChecked: boolean}>
        ) => {
            let preConvertedClientCheckboxesChecked = [
                ...current(state.preConvertedClientCheckboxesChecked),
            ];
            let preConvertedFacilityCheckboxesChecked = [
                ...current(state.preConvertedFacilityCheckboxesChecked),
            ];
            let foundIndex = preConvertedClientCheckboxesChecked.indexOf(
                action.payload.clientId
            );

            if (foundIndex === -1) {
                preConvertedClientCheckboxesChecked.push(
                    action.payload.clientId
                );
            } else {
                preConvertedClientCheckboxesChecked.splice(foundIndex, 1);
            }
            state.preConvertedClientCheckboxesChecked =
                preConvertedClientCheckboxesChecked;

            const preConvertedClientAndFacilityData = [
                ...current(state.preConvertedClientAndFacilityData),
            ];
            // This will check all facilities under the checked client
            if (action.payload.isChecked) {
                preConvertedClientCheckboxesChecked.forEach(clientId => {
                    const found = preConvertedClientAndFacilityData.find(
                        item => {
                            return item.clientId === clientId;
                        }
                    );
                    if (found) {
                        let facilityIds = found.facilities?.map((facility: { clientFacilityId: any; }) => {
                            return facility.clientFacilityId;
                        });
                        if (facilityIds) {
                            const distinctFacilityIds = new Set([
                                ...preConvertedFacilityCheckboxesChecked,
                                ...facilityIds,
                            ]);
                            state.preConvertedFacilityCheckboxesChecked =
                                Array.from(distinctFacilityIds);
                        }
                    }
                });
            } else {
                // get all facilityIds from the checked clientId. Effectively this will uncheck all facilities from the unchecked client
                const client = preConvertedClientAndFacilityData.find(
                    client => {
                        return client.clientId === action.payload.clientId;
                    }
                );
                const facilities = client?.facilities?.map(
                    (facility: { clientFacilityId: any; }) => facility.clientFacilityId
                );
                if (facilities) {
                    facilities.forEach((facilityId: any) => {
                        foundIndex =
                            preConvertedFacilityCheckboxesChecked.indexOf(
                                facilityId
                            );
                        if (foundIndex !== -1) {
                            preConvertedFacilityCheckboxesChecked.splice(
                                foundIndex,
                                1
                            );
                        }
                    });
                    state.preConvertedFacilityCheckboxesChecked =
                        preConvertedFacilityCheckboxesChecked;
                }
            }
        },
        setConvertedClientCheckboxChecked: (state, action) => {
            let convertedClientCheckboxesChecked = [
                ...current(state.convertedClientCheckboxesChecked),
            ];
            let convertedFacilityCheckboxesChecked = [
                ...current(state.convertedFacilityCheckboxesChecked),
            ];
            let foundIndex = convertedClientCheckboxesChecked.indexOf(
                action.payload.clientId
            );

            if (foundIndex === -1) {
                convertedClientCheckboxesChecked.push(action.payload.clientId);
            } else {
                convertedClientCheckboxesChecked.splice(foundIndex, 1);
            }
            state.convertedClientCheckboxesChecked =
                convertedClientCheckboxesChecked;

            const convertedClientAndFacilityData = [
                ...current(state.convertedClientAndFacilityData),
            ];
            // This will check all facilities under the checked client
            if (action.payload.isChecked) {
                convertedClientCheckboxesChecked.forEach(clientId => {
                    const found = convertedClientAndFacilityData.find(item => {
                        return item.clientId === clientId;
                    });
                    if (found) {
                        let facilityIds = found.facilities?.map((facility: { clientFacilityId: any; }) => {
                            return facility.clientFacilityId;
                        });
                        if (facilityIds) {
                            const distinctFacilityIds = new Set([
                                ...convertedFacilityCheckboxesChecked,
                                ...facilityIds,
                            ]);
                            state.convertedFacilityCheckboxesChecked =
                                Array.from(distinctFacilityIds);
                        }
                    }
                });
            } else {
                // get all facilityIds from the checked clientId. Effectively this will uncheck all facilities from the unchecked client
                const client = convertedClientAndFacilityData.find(client => {
                    return client.clientId === action.payload.clientId;
                });
                const facilities = client?.facilities?.map(
                    (facility: { clientFacilityId: any; }) => facility.clientFacilityId
                );
                if (facilities) {
                    facilities.forEach((facilityId: any) => {
                        foundIndex =
                            convertedFacilityCheckboxesChecked.indexOf(
                                facilityId
                            );
                        if (foundIndex !== -1) {
                            convertedFacilityCheckboxesChecked.splice(
                                foundIndex,
                                1
                            );
                        }
                    });
                    state.convertedFacilityCheckboxesChecked =
                        convertedFacilityCheckboxesChecked;
                }
            }
        },
        setPreConvertedFacilityCheckboxChecked: (
            state,
            action: PayloadAction<number>
        ) => {
            let preConvertedFacilityCheckboxesChecked = [
                ...current(state.preConvertedFacilityCheckboxesChecked),
            ];
            const foundIndex = preConvertedFacilityCheckboxesChecked.indexOf(
                action.payload
            );

            if (foundIndex === -1) {
                preConvertedFacilityCheckboxesChecked.push(action.payload);
            } else {
                preConvertedFacilityCheckboxesChecked.splice(foundIndex, 1);
            }
            state.preConvertedFacilityCheckboxesChecked =
                preConvertedFacilityCheckboxesChecked;

            // filter all patient records to show just the ones from the array of checked facility ids
            const allPreConvertedPatientRecords = [
                ...current(state.preconvertedPatients),
            ];
            const filteredByFacility =
                preConvertedFacilityCheckboxesChecked.flatMap(facilityId => {
                    return allPreConvertedPatientRecords.filter(
                        patientRecord =>
                            patientRecord.clientFacilityId === facilityId &&
                            facilityId > 0
                    );
                });
            state.preconvertedPatientsFiltered =
                patientHelper.filterPreconvertedPatients(filteredByFacility);
            preConvertedFacilityCheckboxesChecked =
                preConvertedFacilityCheckboxesChecked.filter(
                    facilityId => facilityId !== -1
                );
            if (preConvertedFacilityCheckboxesChecked.length === 0) {
                state.preconvertedPatientsFiltered =
                    patientHelper.filterPreconvertedPatients(
                        allPreConvertedPatientRecords
                    );
            }
        },
        setConvertedFacilityCheckboxChecked: (
            state,
            action: PayloadAction<number>
        ) => {
            let convertedFacilityCheckboxesChecked = [
                ...current(state.convertedFacilityCheckboxesChecked),
            ];
            const foundIndex = convertedFacilityCheckboxesChecked.indexOf(
                action.payload
            );

            if (foundIndex === -1) {
                convertedFacilityCheckboxesChecked.push(action.payload);
            } else {
                convertedFacilityCheckboxesChecked.splice(foundIndex, 1);
            }
            state.convertedFacilityCheckboxesChecked =
                convertedFacilityCheckboxesChecked;

            // filter all patient records to show just the ones from the array of checked facility ids
            const allConvertedPatientRecords = [
                ...current(state.convertedPatients),
            ];
            const filteredByFacility =
                convertedFacilityCheckboxesChecked.flatMap(facilityId => {
                    return allConvertedPatientRecords.filter(
                        patientRecord =>
                            patientRecord.clientFacilityId === facilityId &&
                            facilityId > 0
                    );
                });
            state.convertedPatientsFiltered =
                patientHelper.filterConvertedPatients(filteredByFacility);
            convertedFacilityCheckboxesChecked =
                convertedFacilityCheckboxesChecked.filter(
                    facilityId => facilityId !== -1
                );
            if (convertedFacilityCheckboxesChecked.length === 0) {
                state.convertedPatientsFiltered =
                    patientHelper.filterConvertedPatients(
                        allConvertedPatientRecords
                    );
            }
        },
        setPreconvertedFilterFormValues: (
            state,
            action: PayloadAction<Partial<preConvertedPatientFilter>>
        ) => {
            state.preConvertedFilterFormValues = {
                ...state.preConvertedFilterFormValues,
                ...action.payload,
            };
        },
        resetPreConvertedFilterFormValues: state => {
            state.preConvertedFilterFormValues = emptyPreConvertedPatientFilter;
        },
        resetConvertedFilterFormValues: state => {
            state.convertedFilterFormValues = emptyConvertedPatientFilter;
        },
        setConvertedFilterFormValues: (
            state,
            action: PayloadAction<Partial<convertedPatientFilter>>
        ) => {
            state.convertedFilterFormValues = {
                ...state.convertedFilterFormValues,
                ...action.payload,
            };
        },
        setDownPaymentToken(state, action: PayloadAction<TokenState>) {
            state.downPaymentTokens = action.payload;
        },
        setRecurringPaymentToken(state, action: PayloadAction<TokenState>) {
            state.recurringPaymentTokens = action.payload;
        },
        setPatientTakePaymentToken(state, action: PayloadAction<TokenState>) {
            state.patientTakePaymentTokens = action.payload;
        },
        setRecurringPatientTakePaymentToken(
            state,
            action: PayloadAction<TokenState>
        ) {
            state.recurringPatientTakePaymentTokens = action.payload;
        },
        setPatientMakePaymentToken(state, action: PayloadAction<TokenState>) {
            state.patientMakePaymentTokens = action.payload;
        },
        setDownPaymentFullyPaid(state, action: PayloadAction<boolean>) {
            state.isDownPaymentFullyPaid = action.payload;
        },
        setTransactionsCSVData: (state, action) => {
            state.transactionsCSVData = action.payload;
        },
        setIsPreConverted(state, action: PayloadAction<any>) {
            state.isPreConverted = action.payload;
        },
        setCalculatedRemainingBalance(state, action: PayloadAction<any>) {
            const isAccountHolder = action.payload.isAccountHolder;
            if (isAccountHolder) {
                state.currentConvertedEncounter.calculatedRemainingBalance =
                    action.payload.calculatedRemainingBalance;
            } else {
                state.selectedEncounter.calculatedRemainingBalance =
                    action.payload.calculatedRemainingBalance;
            }
        },
        resetCalculatedRemainingBalance: state => {
            state.selectedEncounter.calculatedRemainingBalance = undefined;
            state.currentConvertedEncounter.calculatedRemainingBalance =
                undefined;
        },
        setSelectedPatient(state, action) {
            state.selectedPatient.patientId = action.payload?.patientId;
            state.selectedPatient.contact = action.payload?.contact;
        },
        setSelectedPatientV2(state, action) {
          state.selectedPatient = {...emptyPatientViewModel, ...action.payload};
        },
        setMatchingRule(state, action) {
            state.matchingRule = action.payload;
        },
        setRecentlyViewedPatients: (state, action) => {
            if (
                action.payload.type === RecentlyViewedTypes.preConvertedPatients
            ) {
                state.recentlyViewedPreConvertedPatients =
                    action.payload.recentlyViewed;
            } else {
                state.recentlyViewedConvertedPatients =
                    action.payload.recentlyViewed;
            }
        },
        clearComments: state => {
            state.comments = [];
            state.reminders = [];
        },
        clearSelectedEncounter: state => {
            state.selectedEncounter = emptyPatientEncounter;
            state.selectedPatient = emptyPatientViewModel;
            state.isBlank.isCommentsEmpty = false;
        },
        clearSignUrl: state => {
            state.patientEncounterSignUrl = {} as patientEncounterSignUrl;
            state.isError.hellosign = false;
            state.isLoading.hellosign = false;
        },
        clearReleaseChargeError: state => {
            state.isError.releaseCharge = false;
        },
        resetPreconvertedPatients: state => {
            state.preconvertedPatients = [];
        },
        setRedirectToId: (state, action) => {
            state.redirectToId = action.payload;
        },
        setSelectedEncounter: (state, action) => {
            state.selectedEncounter = action.payload;
        },
        setPatientPaymentMethods: (state, action) => {
            state.selectedEncounter.patientPaymentMethods = action.payload;
        },
        getInitialEncounter: state => {
            // sort encounters by patientEncounterId and then assign selected encounter to the first in the list
            const sortedEncounters =
                state.selectedPatient?.patientEncounters &&
                state.selectedPatient.patientEncounters.sort(
                    (a: PatientEncounter, b: PatientEncounter) =>
                        a.patientEncounterId - b.patientEncounterId
                );
            state.selectedPatient.patientEncounters = sortedEncounters;
            state.selectedEncounter =
                sortedEncounters && sortedEncounters[0]
                    ? sortedEncounters[0]
                    : emptyPatientEncounter;
        },
        setPatientEncounters(state, action) {
            state.selectedPatient.patientEncounters = action.payload;
        },
        setStripeCardData: (state, action) => {
            state.stripeCardData = action.payload;
        },
        setStripeBankData: (state, action) => {
            state.stripeBankData = action.payload;
        },
        setConvertedActiveEncounters: (state, action) => {
            state.convertedActiveEncounters = action.payload;
        },
        setConvertedCompletedEncounters: (state, action) => {
            state.convertedCompletedEncounters = action.payload;
        },
        setConvertedCancelledEncounters: (state, action) => {
            state.convertedCancelledEncounters = action.payload;
        },
        setSelectedConvertedEncounters: (state, action) => {
            state.selectedConvertedEncounters = action.payload;
        },
        setCurrentConvertedEncounter: (state, action) => {
            state.currentConvertedEncounter = action.payload;
        },
        resetPatientContext() {
            return initialState;
        },
        resetIsConverting: state => {
            state.isConverting = false;
        },
        resetDataAccountHolder(state) {
            state.selectedConvertedEncounters = [];
            state.convertedActiveEncounters = [];
            state.convertedCompletedEncounters = [];
            state.convertedCancelledEncounters = [];
            state.selectedPatient = {} as PatientViewModel;
            state.selectedPatient.patientEncounters = [];
            state.currentConvertedEncounter = {} as PatientEncounter;
            state.isLoading.accountHolderDashboard = false;
            state.isError.accountHolderDashboard = false;
            state.refreshAccountHolderDashboard = true;
        },
        resetRefreshAccountHolderDashboard: state => {
            state.refreshAccountHolderDashboard = false;
        },
        addPatientS3Document: (state, action) => {
            state.patientS3Documents = action.payload;
        },
        setEmptyS3Document(state) {
            //state.patientS3Documents = emptyPatientS3Document;
        },
        addScriptWindowObjectReference(state, action: PayloadAction<any>) {
            state.scriptWindowObjectReference = [action.payload];
        },

        resetScriptWindowObjectReference(state) {
            state.scriptWindowObjectReference =
                initialState.scriptWindowObjectReference;
        },

        setAccountHolderContext(state, action) {
            if (action.payload.type === 'reset') {
                state.accountHolderContext = {
                    userName: null,
                    isPatient: null,
                    isExistingEmail: null,
                    firstName: null,
                    lastName: null,
                };
            } else {
                state.accountHolderContext = {
                    ...action.payload,
                };
            }
        },

        initializePatientVOBState(state){
            state.selectedVOB = emptyVob
        },
      setVobAndEstimate: (state, action) => {
        state.selectedVOB = action.payload.vob;
      }
    },
    extraReducers: builder => {
        // preConverted patients
        builder.addCase(updateIOC.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.selectedEncounter;
        });
        builder.addCase(getPreconvertedPatients.pending, (state, action) => {
            state.isLoading.preconvertedPatients = true;
        });
        builder.addCase(getPreconvertedPatients.fulfilled, (state, action) => {
            state.isLoading.preconvertedPatients = false;
            const preConvertedPatients = action.payload.sort(
                (first: PatientEncounterCard, second: PatientEncounterCard) =>
                    //sort client name
                    first.clientName > second.clientName
                        ? 1
                        : // then by first and last name
                        first.clientName === second.clientName
                        ? first.contactCard.firstName +
                              ' ' +
                              first.contactCard.lastName >
                          second.contactCard.firstName +
                              ' ' +
                              second.contactCard.lastName
                            ? 1
                            : -1
                        : -1
            );
            state.preconvertedPatients = preConvertedPatients;
            state.preconvertedPatientsFiltered =
                patientHelper.filterPreconvertedPatients(preConvertedPatients);
        });
        builder.addCase(getPreconvertedPatients.rejected, (state, action) => {
            state.isLoading.preconvertedPatients = false;
        });
        // converted patients
        builder.addCase(getConvertedPatients.pending, (state, action) => {
            state.isLoading.convertedPatients = true;
            state.isError.convertedPatients = false;
        });
        builder.addCase(getConvertedPatients.fulfilled, (state, action) => {
            state.isLoading.convertedPatients = false;
            const convertedPatients = action.payload.sort(
                (first: PatientEncounterCard, second: PatientEncounterCard) =>
                    //sort client name
                    first.clientName > second.clientName
                        ? 1
                        : // then by first and last name
                        first.clientName === second.clientName
                        ? first.contactCard.firstName +
                              ' ' +
                              first.contactCard.lastName >
                          second.contactCard.firstName +
                              ' ' +
                              second.contactCard.lastName
                            ? 1
                            : -1
                        : -1
            );
            state.convertedPatients = convertedPatients;
            state.convertedPatientsFiltered =
                patientHelper.filterConvertedPatients(convertedPatients);
            state.isError.convertedPatients = false;
        });
        builder.addCase(getConvertedPatients.rejected, (state, action) => {
            state.isLoading.convertedPatients = false;
            state.isError.convertedPatients = true;
        });

        // add patient demographics
        builder.addCase(savePatient.pending, (state, action) => {
            state.savePatientModalSaveStatus = saveCallbackStatus.none;
            state.isError.missingAddress = false;
        });
        builder.addCase(savePatient.fulfilled, (state, action) => {
            state.savePatientModalSaveStatus = saveCallbackStatus.success;
            state.selectedPatient = action.payload?.patientDetails;
            state.isError.missingAddress = action.payload?.isMissingAddress;
        });
        builder.addCase(savePatient.rejected, (state, action) => {
            state.savePatientModalSaveStatus = saveCallbackStatus.error;
            state.isError.missingAddress = false;
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        // patient demographics
        builder.addCase(getPatientDemographics.pending, (state, action) => {
            state.isLoading.patientDemographics = true;
            state.isError.missingAddress = false;
        });
        builder.addCase(getPatientDemographics.fulfilled, (state, action) => {
            state.isLoading.patientDemographics = false;
            state.selectedPatient = action.payload.patientDetails;
            state.isError.missingAddress = action.payload?.isMissingAddress;
        });
        builder.addCase(getPatientDemographics.rejected, (state, action) => {
            state.isLoading.patientDemographics = false;
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
            state.isError.missingAddress = false;
        });
        // patient instance of care
        builder.addCase(saveInstanceOfCare.pending, (state, action) => {
            state.instanceOfCareModalSaveStatus = saveCallbackStatus.none;
        });
        builder.addCase(saveInstanceOfCare.fulfilled, (state, action) => {
            state.instanceOfCareModalSaveStatus = saveCallbackStatus.success;
            state.selectedPatient.patientEncounters =
                action.payload.allPatientEncounters;
            state.selectedEncounter = action.payload.selectedPatientEncounter;
            if (action.payload.setPaymentProgramError === true) {
                state.isError.paymentProgramNeedsUpdate =
                    action.payload.setPaymentProgramError;
            }
        });
        builder.addCase(saveInstanceOfCare.rejected, (state, action) => {
            state.instanceOfCareModalSaveStatus = saveCallbackStatus.error;
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        // calculated risk class
        builder.addCase(getRiskClass.pending, (state, action) => {
            state.isLoading.calculatedRiskClass = false;
        });
        builder.addCase(getRiskClass.fulfilled, (state, action) => {
            state.isLoading.calculatedRiskClass = true;
            state.calculatedRiskClass = action.payload;
        });
        builder.addCase(getRiskClass.rejected, (state, action) => {
            state.isLoading.calculatedRiskClass = false;
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        builder.addCase(lockIOC.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.selectedEncounter;
        });
        builder.addCase(lockIOC.rejected, (state, action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        // patient insurance
        builder.addCase(saveInsurance.pending, (state, action) => {});
        builder.addCase(saveInsurance.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.newSelectedEncounter;
            state.selectedPatient.patientEncounters =
                action.payload.newEncounters;
        });
        builder.addCase(saveInsurance.rejected, (state, action) => {});

        // patient vob
        builder.addCase(getVOBAndEstimateForFinpass.fulfilled, (state, action) => {
            state.instanceOfCareModalSaveStatus = saveCallbackStatus.success;
            state.selectedVOB = action.payload.vob.vobBody;
            state.selectedVOB.vobId = action.payload.vob.vobId;
        });
        builder.addCase(getVOBAndNewEstimateForFinpass.fulfilled, (state, action) => {
            state.instanceOfCareModalSaveStatus = saveCallbackStatus.success;
            state.selectedVOB = action.payload.vob.vobBody;
            state.selectedVOB.vobId = action.payload.vob.vobId;
        });
        builder.addCase(saveVOBForFinpass.fulfilled, (state, action) => {
          state.selectedVOB = action.payload.vobBody;
          state.selectedVOB.vobId = action.payload.vobId;
        })
        builder.addCase(getVOB.fulfilled, (state, action) => {
            state.selectedVOB = action.payload.vobBody;
            state.selectedVOB.vobId = action.payload.vobId; 
        })
        builder.addCase(postVob.fulfilled, (state, action) => {
            state.selectedVOB = action.payload.vobBody;
            state.selectedVOB.vobId = action.payload.vobId; 
        })

        // patient champions
        builder.addCase(savePatientChampion.pending, (state, action) => {});
        builder.addCase(savePatientChampion.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.newSelectedEncounter;
            state.selectedPatient.patientEncounters =
                action.payload.newEncounters;
        });
        builder.addCase(savePatientChampion.rejected, (state, action) => {});
        builder.addCase(createPhiHelloSignRequest.pending, (state, action) => {
            state.isLoading.phiDocument = true;
        });
        builder.addCase(
            createPhiHelloSignRequest.fulfilled,
            (state, action) => {
                state.isLoading.phiDocument = false;
                state.selectedEncounter.authorizationDocumentStatus.overallDocumentStatus =
                    'Pending Signature';
                state.selectedEncounter.authorizationDocumentStatus.doPhiConsentDocumentsExist =
                    true;
                state.selectedEncounter.authorizationDocumentStatus.phiConsentDocuments =
                    [action.payload];
            }
        );
        builder.addCase(createPhiHelloSignRequest.rejected, (state, action) => {
            state.isLoading.phiDocument = false;
        });

        // patient documents
        builder.addCase(getDocuments.pending, (state, action) => {
            state.isLoading.patientDocuments = true;
        });
        builder.addCase(getDocuments.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.selectedEncounter;
            state.selectedPatient.patientEncounters =
                action.payload.patientEncounters;
            state.isLoading.patientDocuments = false;
        });
        builder.addCase(getDocuments.rejected, (state, action) => {
            state.isLoading.patientDocuments = false;
        });
        // patient payment methods

        builder.addCase(getPaymentMethods.pending, (state, action) => {
            state.isError.paymentMethods = false;
            state.isLoading.paymentMethods = true;
        });
        builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
            const isAccountHolder = action.payload?.isAccountHolder; // if we are fetching payment methods in the account holder view (not patient records)
            if (isAccountHolder) {
                state.currentConvertedEncounter =
                    action.payload.selectedEncounter;
                state.selectedConvertedEncounters =
                    action.payload.patientEncounters;
            } else {
                state.selectedEncounter = action.payload.selectedEncounter;
                state.selectedPatient.patientEncounters =
                    action.payload.patientEncounters;
            }
            state.isError.paymentMethods = false;
            state.isLoading.paymentMethods = false;
        });
        builder.addCase(getPaymentMethods.rejected, (state, action) => {
            state.isError.paymentMethods = true;
            state.isLoading.paymentMethods = false;
        });

        builder.addCase(addPatientPaymentMethod.pending, (state, action) => {});
        builder.addCase(addPatientPaymentMethod.fulfilled, (state, action) => {
            const isAccountHolder = action.payload?.isAccountHolder;
            if (isAccountHolder) {
                state.currentConvertedEncounter =
                    action.payload.newSelectedEncounter;
                state.selectedConvertedEncounters =
                    action.payload.newEncounters;
            } else {
                state.selectedEncounter = action.payload.newSelectedEncounter;
                state.selectedPatient.patientEncounters =
                    action.payload.newEncounters;
            }
        });
        builder.addCase(
            addPatientPaymentMethod.rejected,
            (state, action) => {}
        );

        builder.addCase(
            deletePatientPaymentMethod.pending,
            (state, action) => {}
        );
        builder.addCase(
            deletePatientPaymentMethod.fulfilled,
            (state, action) => {
                const isAccountHolder = action.payload?.isAccountHolder;
                if (isAccountHolder) {
                    state.currentConvertedEncounter =
                        action.payload.newSelectedEncounter;
                    state.selectedConvertedEncounters =
                        action.payload.newEncounters;
                } else {
                    state.selectedEncounter =
                        action.payload.newSelectedEncounter;
                    state.selectedPatient.patientEncounters =
                        action.payload.newEncounters;
                }
            }
        );
        builder.addCase(
            deletePatientPaymentMethod.rejected,
            (state, action) => {}
        );
        // patient transactions
        builder.addCase(getTransactions.pending, (state, action) => {
            state.isError.patientTransactions = false;
            state.isLoading.patientTransactions = true;
        });
        builder.addCase(getTransactions.fulfilled, (state, action) => {
            const isAccountHolder = action.payload?.isAccountHolder; // if we are fetching transactions in the account holder view (not patient records)
            if (isAccountHolder) {
                state.currentConvertedEncounter =
                    action.payload.selectedEncounter;
                state.selectedConvertedEncounters =
                    action.payload.patientEncounters;
            } else {
                state.selectedEncounter = action.payload.selectedEncounter;
                state.selectedPatient.patientEncounters =
                    action.payload.patientEncounters;
            }
            state.isError.patientTransactions = false;
            state.isLoading.patientTransactions = false;
        });
        builder.addCase(getTransactions.rejected, (state, action) => {
            state.isLoading.patientTransactions = false;
            state.isError.patientTransactions = true;
        });

        // patient payment program
        builder.addCase(savePatientPaymentProgram.pending, (state, action) => {
            state.isLoading.paymentProgram = true;
        });
        builder.addCase(
            savePatientPaymentProgram.fulfilled,
            (state, action) => {
                state.isLoading.paymentProgram = false;
                state.selectedEncounter =
                    action.payload.selectedPatientEncounter;
                state.selectedPatient.patientEncounters =
                    action.payload.allPatientEncounters;
                state.isError.paymentProgramNeedsUpdate = false;
            }
        );
        builder.addCase(savePatientPaymentProgram.rejected, (state, action) => {
            state.isLoading.paymentProgram = false;
        });

        // put payment program
        builder.addCase(putProgram.pending, (state, action) => {
            state.isLoading.paymentProgram = true;
        });
        builder.addCase(
            putProgram.fulfilled,
            (state, action) => {
                if (action.payload !== false) {
                    state.isLoading.paymentProgram = false;
                    state.selectedEncounter =
                        action.payload.selectedPatientEncounter;
                    state.selectedPatient.patientEncounters =
                        action.payload.allPatientEncounters;
                    state.isError.paymentProgramNeedsUpdate = false;
                }
            }
        );
        builder.addCase(putProgram.rejected, (state, action) => {
            state.isLoading.paymentProgram = false;
        });

        builder.addCase(
            deletePatientPaymentProgram.pending,
            (state, action) => {}
        );
        builder.addCase(
            deletePatientPaymentProgram.fulfilled,
            (state, action) => {
                state.selectedEncounter =
                    action.payload.selectedPatientEncounter;
                state.selectedPatient.patientEncounters =
                    action.payload.allPatientEncounters;
            }
        );
        builder.addCase(
            deletePatientPaymentProgram.rejected,
            (state, action) => {}
        );
        // patient payment program
        builder.addCase(getEvaluateRule.pending, (state, action) => {
            state.isLoading.evaluateRule = true;
        });
        builder.addCase(getEvaluateRule.fulfilled, (state, action) => {
            state.isLoading.evaluateRule = false;
            state.matchingRule = action.payload;
        });
        builder.addCase(getEvaluateRule.rejected, (state, action) => {
            state.isLoading.evaluateRule = false;
        });

        // TODO: Test this out once POST api for PFR Adjustment fully works (patientPFRId is a real value and not null. This should happen once an IOC is "converted")
        builder.addCase(
            savePfrAdjustment.pending,
            (state, action: PayloadAction<any>) => {}
        );
        builder.addCase(
            savePfrAdjustment.fulfilled,
            (state, action: PayloadAction<any>) => {
                state.selectedEncounter = action.payload.encounter;
                state.selectedPatient.patientEncounters =
                    action.payload.allEncounters;
            }
        );
        builder.addCase(savePfrAdjustment.rejected, (state, action) => {});

        builder.addCase(getInstanceOfCare.pending, (state, action) => {
            state.isLoading.patientIOC = true;
            state.isError.patientEncounter = false;
        });
        builder.addCase(getInstanceOfCare.fulfilled, (state, action) => {
            state.isLoading.patientIOC = false;
            state.isError.patientEncounter = false;
            state.selectedEncounter = action.payload.selectedPatientEncounter;
            state.selectedPatient.patientEncounters =
                action.payload.allPatientEncounters;
        });
        builder.addCase(getInstanceOfCare.rejected, (state, action) => {
            state.isLoading.patientIOC = false;
            state.isError.patientEncounter = true;
        });
        builder.addCase(getPatientNotes.pending, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.none;
            state.isLoading.comments = true;
        });
        builder.addCase(getPatientNotes.fulfilled, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.success;
            state.comments = action.payload.comments;
            state.reminders = action.payload.reminders;
            state.isLoading.comments = false;
            state.isBlank.isCommentsEmpty =
                action.payload.comments?.length > 0 ? false : true;
        });
        builder.addCase(getPatientNotes.rejected, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.error;
            state.isLoading.comments = false;
        });

        builder.addCase(createPatientNote.pending, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.none;
        });
        builder.addCase(createPatientNote.fulfilled, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.success;
            if (action.payload.isReminder) {
                state.reminders.unshift(action.payload);
            } else {
                state.comments.unshift(action.payload);
            }
        });
        builder.addCase(createPatientNote.rejected, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.error;
        });

        builder.addCase(deletePatientNote.pending, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.none;
        });
        builder.addCase(deletePatientNote.fulfilled, (state, action) => {
            const patientNotesId = action.payload.patientNotesId;
            const isReminder = action.payload.isReminder;
            state.downPaymentModalSaveStatus = saveCallbackStatus.success;

            if (isReminder) {
                state.reminders = state.reminders.filter(function (reminders: any) {
                    return reminders.patientNotesId !== patientNotesId;
                });
            } else {
                state.comments = state.comments.filter(function (comments: any) {
                    return comments.patientNotesId !== patientNotesId;
                });
            }
        });
        builder.addCase(deletePatientNote.rejected, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.error;
        });
        builder.addCase(dismissReminder.pending, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.none;
        });
        builder.addCase(dismissReminder.fulfilled, (state, action) => {
            const patientNotesId = action.payload.patientNotesId;
            state.downPaymentModalSaveStatus = saveCallbackStatus.success;
            state.reminders = state.reminders.filter(function (reminders: any) {
                return reminders.patientNotesId !== patientNotesId;
            });
        });
        builder.addCase(dismissReminder.rejected, (state, action) => {
            state.downPaymentModalSaveStatus = saveCallbackStatus.error;
        });
        // 
        builder.addCase(updateEncountersAfterMakePayment.fulfilled, (state, action) => {
            const isAccountHolder = action.payload?.isAccountHolder; // if we are fetching transactions in the account holder view (not patient records)
            if (isAccountHolder) {
                state.selectedConvertedEncounters =
                    action.payload.newEncounters;
                state.currentConvertedEncounter =
                    action.payload.newSelectedEncounter;
            } else {
                state.selectedEncounter = action.payload.newSelectedEncounter;
                state.selectedPatient.patientEncounters =
                    action.payload.newEncounters;
            }
        });
        builder.addCase(releaseCharge.pending, (state, action) => {
            state.isError.releaseCharge = false;
        });
        builder.addCase(releaseCharge.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.newSelectedEncounter;
            state.selectedPatient.patientEncounters =
                action.payload.newEncounters;
            state.isError.releaseCharge = false;
        });
        builder.addCase(releaseCharge.rejected, (state, action) => {
            state.releasePaymentStatusMsg = {
                errorMsg: action.error.message!,
                paymentId: action.meta.arg.payment.paymentId!,
                encounterId: action.meta.arg.encounterId!,
                patientId: action.meta.arg.patientId!,
            };
            state.isError.releaseCharge = true;
        });
        builder.addCase(convertPatientEncounter.pending, (state, action) => {
            state.isError.convert = false;
            state.isConverting = true;
        });
        builder.addCase(convertPatientEncounter.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.newSelectedEncounter;
            state.selectedPatient.patientEncounters =
                action.payload.newEncounters;
            state.isError.convert = false;
            state.isConverting = true;
            state.isPreConverted = false;
            state.convertedFilterFormValues = {
                ...state.convertedFilterFormValues,
                goodStanding: true,
                isPreConverted: false,
            };
        });
        builder.addCase(convertPatientEncounter.rejected, (state, action) => {
            state.isError.convert = true;
            state.isConverting = true;
        });
        builder.addCase(setRecentlyViewedPatient.fulfilled, (state, action) => {
            if (
                action.payload.type === RecentlyViewedTypes.preConvertedPatients
            ) {
                state.recentlyViewedPreConvertedPatients =
                    action.payload.recentlyViewedPatients;
            } else {
                state.recentlyViewedConvertedPatients =
                    action.payload.recentlyViewedPatients;
            }
        });
        builder.addCase(verifyBankAccount.pending, (state, action) => {});
        builder.addCase(verifyBankAccount.fulfilled, (state, action) => {
            // todo; need to see a successful response first
        });
        builder.addCase(verifyBankAccount.rejected, (state, action) => {});

        builder.addCase(getEncounterSignUrl.fulfilled, (state, action) => {
            state.isLoading.patientIOC = false;
            state.patientEncounterSignUrl = action.payload;
            state.isLoading.hellosign = false;
            state.isError.hellosign = false;
        });
        builder.addCase(getEncounterSignUrl.pending, (state, action) => {
            state.isError.hellosign = false;
            state.isLoading.hellosign = true;
        });
        builder.addCase(getEncounterSignUrl.rejected, (state, action) => {
            state.isError.hellosign = true;
            state.isLoading.hellosign = false;
        });

        builder.addCase(getForwardedSignUrl.fulfilled, (state, action) => {
            state.patientEncounterSignUrl = action.payload;
        });
        // Account Holder Dashboard Initialization
        builder.addCase(
            setUpEncountersAccountHolder.pending,
            (state, action) => {
                state.isLoading.accountHolderDashboard = true;
                state.isError.accountHolderDashboard = false;
            }
        );
        builder.addCase(
            setUpEncountersAccountHolder.fulfilled,
            (state, action) => {
                state.selectedConvertedEncounters =
                    action.payload.currentSelectedEncounters;
                state.convertedActiveEncounters =
                    action.payload.activeEncounters;
                state.convertedCompletedEncounters =
                    action.payload.completedEncounters;
                state.convertedCancelledEncounters =
                    action.payload.cancelledEncounters;
                state.selectedPatient = action.payload.selectedPatient;
                state.selectedPatient.patientEncounters =
                    action.payload.encountersList;

                state.isLoading.accountHolderDashboard = false;
                state.isError.accountHolderDashboard = false;
            }
        );
        builder.addCase(
            setUpEncountersAccountHolder.rejected,
            (state, action) => {
                state.isLoading.accountHolderDashboard = false;
                state.isError.accountHolderDashboard = true;
            }
        );
        builder.addCase(updateContactInfo.fulfilled, (state, action) => {
            state.selectedConvertedEncounters =
                action.payload.selectedConvertedEncounters;
            state.isSaving = false;
        });
        builder.addCase(updateContactInfo.pending, (state, action) => {
            state.isSaving = true;
        });
        builder.addCase(createS3File.pending, (state, action) => {
            state.isUploadingS3 = true;
        });
        builder.addCase(createS3File.fulfilled, (state, action) => {
            state.isUploadingS3 = false;
        });
        builder.addCase(createS3File.rejected, (state, action) => {
            state.isUploadingS3 = false;
        });
        builder.addCase(getS3Document.pending, (state, action) => {
            state.isUploadingS3 = true;
        });
        builder.addCase(getS3Document.fulfilled, (state, action) => {
            state.patientS3Documents = action.payload;
            state.isUploadingS3 = false;
        });
        builder.addCase(getS3Document.rejected, (state, action) => {
            state.isUploadingS3 = false;
        });
        builder.addCase(deleteS3Document.pending, (state, action) => {
            state.isUploadingS3 = true;
        });
        builder.addCase(deleteS3Document.fulfilled, (state, action) => {
            state.isUploadingS3 = false;
        });
        builder.addCase(createSPAHelloSignRequest.pending, (state, action) => {
            state.isLoading.spaDocument = true;
        });
        builder.addCase(
            createSPAHelloSignRequest.fulfilled,
            (state, action) => {
                state.isLoading.spaDocument = false;
                state.selectedEncounter.authorizationDocumentStatus.overallDocumentStatus =
                    'Pending Signature';
                state.selectedEncounter.authorizationDocumentStatus.doFullPayDocumentsExist =
                    true;
                state.selectedEncounter.authorizationDocumentStatus.spaDocuments =
                    [action.payload];
            }
        );
        builder.addCase(createSPAHelloSignRequest.rejected, (state, action) => {
            state.isLoading.spaDocument = false;
        });
        builder.addCase(createPFRHelloSignRequest.pending, (state, action) => {
            state.isLoading.pfrDocument = true;
        });
        builder.addCase(
            createPFRHelloSignRequest.fulfilled,
            (state, action) => {
                state.isLoading.pfrDocument = false;
                state.selectedEncounter.authorizationDocumentStatus.overallDocumentStatus =
                    'Pending Signature';
                state.selectedEncounter.authorizationDocumentStatus.doPfrAdjustmentDocumentsExist =
                    true;
                state.selectedEncounter.authorizationDocumentStatus.pfrAdjustmentDocuments =
                    [action.payload];
            }
        );
        builder.addCase(createPFRHelloSignRequest.rejected, (state, action) => {
            state.isLoading.pfrDocument = false;
        });
        builder.addCase(updatePFRDocument.fulfilled, (state, action) => {
            state.selectedEncounter.authorizationDocumentStatus.pfrAdjustmentDocuments =
                [action.payload];
        });
        builder.addCase(updateWarmTransfer.fulfilled, (state, action) => {
            state.selectedEncounter = action.payload.selectedEncounter;
        });
        builder.addCase(updateWarmTransfer.rejected, (state, action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        builder.addCase(checkClientPatientIOCId.fulfilled, (state, action) => {
            state.clientsPatientIOCIdFilter = action.payload;
        });
        builder.addCase(checkClientPatientIOCId.rejected, (state, action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        builder.addCase(checkClientPatientAccountId.fulfilled, (state, action) => {
            state.clientsPatientAccountIdFilter = action.payload;
        });
        builder.addCase(checkClientPatientAccountId.rejected, (state, action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
        builder.addCase(getPtRecord.fulfilled, (state, action) => {
            state.inboundPtRecord = action.payload;
        });
        builder.addCase(getPtRecord.rejected, (state, action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        });
      builder.addCase(getClientLevelsOfCare.fulfilled, (state, action: PayloadAction<any>) => {
        state.selectedEstimate.isLoading.getClientLOCStatus = apiStatus.success;
        state.selectedEstimate.isLoading.isLoadingClientLoc = false;
        state.selectedEstimate.clientLevelsOfCare = action.payload;
      });
      builder.addCase(getClientLevelsOfCare.rejected, (state, action) => {
        state.selectedEstimate.isLoading.getClientLOCStatus = apiStatus.error;
        state.selectedEstimate.isLoading.isLoadingClientLoc = false;
      });
      builder.addCase(getClientLevelsOfCare.pending, (state, action) => {
        state.selectedEstimate.isLoading.getClientLOCStatus = apiStatus.none;
        state.selectedEstimate.isLoading.isLoadingClientLoc = true;
      });
      builder.addCase(configGetLOC.pending, (state, action) => {
        state.selectedEstimate.isLoading.configGetLOCStatus = apiStatus.none;
        state.selectedEstimate.isLoading.isLoadingMasterLoc = true;
      });
      builder.addCase(configGetLOC.fulfilled, (state, action: PayloadAction<any>) => {
        state.selectedEstimate.isLoading.configGetLOCStatus = apiStatus.success;
        state.selectedEstimate.isLoading.isLoadingMasterLoc = false;
        state.selectedEstimate.priorLevelsOfCare = action.payload;
      });
      builder.addCase(configGetLOC.rejected, (state, action) => {
        state.selectedEstimate.isLoading.configGetLOCStatus = apiStatus.error;
        state.selectedEstimate.isLoading.isLoadingMasterLoc = false;
      });
      builder.addCase(createPatientIoc.pending, (state, action) => {
        state.patientIocStatus = saveCallbackStatus.none;
        state.isLoading.createPatientIoc = true;
      });
      builder.addCase(createPatientIoc.fulfilled, (state, action) => {
        state.isLoading.createPatientIoc = false;
        state.patientIocStatus = saveCallbackStatus.success;
        state.selectedEncounter = action.payload;
        state.completePatientModalIOCSaveStatus = saveCallbackStatus.success;
      });
      builder.addCase(createPatientIoc.rejected, (state, action) => {
        state.isLoading.createPatientIoc = false;
        state.patientIocStatus = saveCallbackStatus.error;
        state.completePatientModalIOCSaveStatus = saveCallbackStatus.error;
      });
    },
});

export const {
    setSelectedVOB,
    clearStatus,
    resetIsConverting,
    setPreconvertedFilterFormValues,
    resetPreConvertedFilterFormValues,
    setDownPaymentToken,
    setRecurringPaymentToken,
    setPatientTakePaymentToken,
    setRecurringPatientTakePaymentToken,
    setPatientMakePaymentToken,
    setDownPaymentFullyPaid,
    setIsPreConverted,
    setConvertedFilterFormValues,
    resetConvertedFilterFormValues,
    setRedirectToId,
    setSelectedEncounter,
    setPatientPaymentMethods,
    getInitialEncounter,
    setRecentlyViewedPatients,
    clearComments,
    clearEncounter,
    clearPatientIocStatus,
    setTransactionsCSVData,
    clearSelectedEncounter,
    resetDataAccountHolder,
    resetRefreshAccountHolderDashboard,
    setStripeCardData,
    setStripeBankData,
    setCurrentConvertedEncounter,
    setCalculatedRemainingBalance,
    resetPatientContext,
    setSelectedPatient,
    setSelectedPatientV2,
    setMatchingRule,
    preConvertedClientFacilityCheckboxData,
    convertedClientFacilityCheckboxData,
    setPreConvertedClientCheckboxChecked,
    setConvertedClientCheckboxChecked,
    setPreConvertedFacilityCheckboxChecked,
    setConvertedFacilityCheckboxChecked,
    clearSignUrl,
    clearReleaseChargeError,
    addScriptWindowObjectReference,
    resetScriptWindowObjectReference,
    setAccountHolderContext,
    initializePatientVOBState,
    setVobAndEstimate,
    setPatientEncounters
} = patientContextSlice.actions;
export default patientContextSlice.reducer;
