import {Button, TextField} from '@finpay-development/shared-components';
import {Grid, MenuItem, Tooltip, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';

import {
  getClientFacilities,
} from '../../../implementation-specialist/state/facility/implementation-facility-thunk';
import {RootState} from '../../../shared/state/root-reducer';
import {AppDispatch} from '../../../shared/state/store';
import {patientService} from '../../../patient/services/patient-service';
import {
  apiStatus,
  EstimateRequestConfig,
  PfrEstimator,
} from '../../models/estimator';
import {
  clearEstimate,
  clearGetEstimateStatus,
  clearSaveEstimatorStatus,
  setEstimate,
} from '../../state/estimator-slice';
import {setPatient} from '../../state/vob-patient-slice';
import {
  callNewEstimateGet,
  callNewEstimatePost,
  callNewEstimatePut,
  getSavedEstimates,
} from '../../state/estimator-thunk';
import {createPatientIoc, getVobPatient} from '../../state/vob-patient-thunk';
import {getVob} from '../../state/vob-thunk';
import {
  EstCompletePatientRecordModal,
} from './estimator-complete-patient-record-modal';
import {Utils} from '../../../shared/utils';
import {checkPermissions} from '../../../security/components/access-control';
import {RolePageNames} from '../../../security/model/role-page-names';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import {IocConfig, patientIocResult} from '../../models/patient';
import {setSelectedEncounter, clearPatientIocStatus} from '../../../patient/state/patient-slice';
import {Estimate} from '@finpay/estimation-types';
import useIsIOCConverted from 'src/shared/Hooks/use-is-ioc-converted';
export interface estimateConfig {
  paramId: number,
  vobId: number
}

export function EstimatorFooter() {
  const navigate = useNavigate();
  const [completePatientRecordModalOpen, setCompletePatientRecordModalOpen] = useState(false);
  const [isEstimateSelected, setIsEstimateSelected] = useState(false);
  const [isCreateNewEstimate, setIsCreateNewEstimate] = useState(false);
  const [savedEstimate, setSavedEstimate] = useState<PfrEstimator>();
  const [filteredEstimates, setFilteredEstimates] = useState<PfrEstimator[]>([]);
  const [savedEncounter, setSavedEncounter] = useState<patientIocResult>();
  const dispatch = useDispatch<AppDispatch>();
  const paramId: number = -2;

  const state = {
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    fetchedEstimateState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.fetchedEstimate
    ),
    fetchedEstimateLoadingState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.isLoadingGetEstimate
    ),
    fetchedEstimateStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.getEstimateStatus
    ),
    savedEstimatesLoadingState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.isLoadingSaveEstimates
    ),
    savedEstimatesStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.saveEstimateStatus
    ),
    savedEstimationsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.savedEstimations
    ),
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
    userProfile: useSelector(
      (state: RootState) =>
      state.userContext.userProfile
    ),
    allClients: useSelector(
      (state: RootState) =>
          state.implementationContext?.implementationSpecialistClient
              .allClientsWithFacillities
    ),
    selectedFinPassPatient: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.selectedFinPassPatient
    ),
    estimate: useSelector(
      (state: RootState) => {
        return state?.admissionsAdvisorContext.estimatorContext.estimate
      }
    )
  };
  const {
    vobPatientState,
    vobState,
    fetchedEstimateState,
    fetchedEstimateLoadingState,
    fetchedEstimateStatusState,
    savedEstimatesLoadingState,
    savedEstimatesStatusState,
    savedEstimationsState,
    selectedEncounter,
    userProfile,
    allClients,
    selectedFinPassPatient,
    estimate
  } = state;

  const isIOCConverted = useIsIOCConverted();

  useEffect(() => {
    const config: estimateConfig = getEstimateConfig();
    dispatch(getSavedEstimates(config));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimate]);

  useEffect(() => {
    const getSavedIOCEstimateName = async (estimates: PfrEstimator[]) => {
      if(estimate?.patientEncounterId!) {
        const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId: vobPatientState.patient.fpPatientId!, encounterId: estimate?.patientEncounterId!});
        const iocEstimateId = instanceOfCare.entity.pfrEstimateId!;
        const matchingEstimate = estimates.find((estimate) => estimate.pfrEstimateId === iocEstimateId);
        setSavedEncounter(instanceOfCare.entity);
        setSavedEstimate(matchingEstimate);
      }
    };

    // TODO: Discuss Saved Estimate Variables for New Estimate Shape
    if(savedEstimationsState.length > 0) {
        setFilteredEstimates(savedEstimationsState);
        getSavedIOCEstimateName(savedEstimationsState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedEstimationsState]);

  useEffect(() => {
    const fetchData = async () => {
      // TODO: Discuss loading States for New Estimate Shape
      if (!savedEstimatesLoadingState && savedEstimatesStatusState === apiStatus.success) {
        // estimate has been saved - reload saved estimates
        const config: estimateConfig = getEstimateConfig();
        dispatch(clearSaveEstimatorStatus());
        await dispatch(getSavedEstimates(config));
      }
      if (isEstimateSelected && !fetchedEstimateLoadingState && fetchedEstimateStatusState === apiStatus.success) {
        // load new estimate from dropdown
        const patientConfig: any = {
          paramId: paramId,
          patientId: fetchedEstimateState.advisorPatientId
        }
        await dispatch(getVobPatient(patientConfig));
        const vobConfig: any = {
          paramId: paramId,
          vobId: fetchedEstimateState.vobId
        }
        await dispatch(getVob(vobConfig));
        const estimatorStateCopy: any = {...fetchedEstimateState.estimateBody};
        if (estimatorStateCopy) {
          estimatorStateCopy.estimateId = fetchedEstimateState?.pfrEstimateId;
          dispatch(clearEstimate());
          const currentClientId = estimatorStateCopy.client?.clientId || estimatorStateCopy.clientId;
          if (currentClientId) {
            await dispatch(getClientFacilities(currentClientId));
          }
          dispatch(setEstimate(estimatorStateCopy));
        }
        dispatch(clearGetEstimateStatus());
      }
    };
  
    fetchData();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedEstimatesLoadingState, fetchedEstimateLoadingState]);
  

  const handlePatientModalSubmit = async (estimation:  Estimate) => {
    // Convert EstimatorBody to Estimate if necessary
    await saveCurrentEstimate(estimation, false, true);
    setCompletePatientRecordModalOpen(false);
  };

  const handlePatientModalCancel = () => {
    setCompletePatientRecordModalOpen(false);
  };

  const getEstimateConfig = (): estimateConfig => {
    return {
      paramId: paramId,
      vobId: vobState.vobId!
    };
  };

  const handleSavedEstimationChange = async (e: Event) => {
    const estimationId = +(e.target as HTMLInputElement).value;
    setIsEstimateSelected(true);
    const config: EstimateRequestConfig = {
      paramId: paramId,
      estimationId: estimationId
    }
    const estimateResponse = await dispatch(callNewEstimateGet(config));
    if(estimateResponse?.payload?.patientEncounterId) {
      let vobPatientStateCopy = Utils.deepClone(vobPatientState);
      const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId: vobPatientState.patient.fpPatientId!, encounterId: estimateResponse?.payload?.estimateBody?.finPay?.patientEncounterId!});
      vobPatientStateCopy.patient.selectedIOC = instanceOfCare.entity;
      dispatch(setPatient(vobPatientStateCopy.patient));
    }
  };

  const saveCurrentEstimate = async (estimatorStateCopy?: Estimate | null, createNew?: boolean, ignoreIOCSave?: boolean) => {
    let copyOfEstimateState: any = estimatorStateCopy;
    if (!estimatorStateCopy) {
      copyOfEstimateState = estimate;
    }
    const originalEstimate = filteredEstimates.find(estimation => estimation.pfrEstimateId === copyOfEstimateState?.estimateId);
    const createdDate = originalEstimate?.createDt ? admissionsAdvisorUtils.formatDateTime(new Date(originalEstimate?.createDt!)) : admissionsAdvisorUtils.formatDateTime(new Date());
    const levelsOfCare = estimate?.facilityLevelOfCare?.map(loc => loc.facilityLevelOfCareName).join(", ");
    const estimateDescription = `${levelsOfCare} - ${createdDate}`;
    copyOfEstimateState = {...copyOfEstimateState, description: estimateDescription}

    if (isCreateNewEstimate || createNew) {
      delete copyOfEstimateState?.estimateId;
    }

    if (
      copyOfEstimateState?.estimateId === undefined ||
        isNaN(copyOfEstimateState.estimateId as number)
    ) {
        const postEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
          vobState.vobId,
          vobState.client?.clientId!,
          vobState.facility?.facilityId!,
          copyOfEstimateState,
          vobPatientState.patient.advisorPatientId!,
          copyOfEstimateState?.patientEncounterId
      );
      await dispatch(callNewEstimatePost(postEstimateBody));
    } else {
      const putEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
        vobState.vobId,
        vobState.client?.clientId!,
        vobState.facility?.facilityId!,
        copyOfEstimateState,
        vobPatientState.patient.advisorPatientId!,
        copyOfEstimateState.patientEncounterId ?? copyOfEstimateState.finPay?.patientEncounterId
      );
      await dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: copyOfEstimateState?.estimateId}));
    }

    setIsEstimateSelected(false)
    setIsCreateNewEstimate(false);
    
    if (copyOfEstimateState?.estimateId === savedEstimate?.pfrEstimateId && !ignoreIOCSave && savedEncounter !== undefined) {
      await updateSkeletonIOC()
    }
  }

  const updateSkeletonIOC = async () => {
      const iocConfig = mapToIOCModel();
      const iocResponse = await dispatch(createPatientIoc(iocConfig));
      dispatch(setSelectedEncounter(iocResponse?.payload));
      dispatch(clearPatientIocStatus());
  };

  const handleSendToCRM = async () => {
      await saveCurrentEstimate(undefined, false, true); // Save Estimate
      await updateSkeletonIOC(); // Save Skeleton IOC
      const clientCrm = admissionsAdvisorUtils.getClientCrmDetails(
          Number(estimate?.clientId!),
          allClients!
      );
      const isIntegrationEnabled =
          admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
      if (isIntegrationEnabled) {
          await patientService.integrationUpdate({
              patientEncounterId: Number(estimate?.patientEncounterId!),
              patientId: vobPatientState.patient.fpPatientId!,
              crmTypeSlug: clientCrm[0]?.crmType?.crmTypeSlug || undefined,
          });
      }
  };

  const handleSendToFinPass = async () => {
    if(isIOCConverted) {
      return navigate(`/specialist/dashboard/${vobPatientState.patient.fpPatientId}`, {
        state: {
            tabIndex: 1
        }
      });
    }
    dispatch(setPatient(vobPatientState.patient));
    setCompletePatientRecordModalOpen(true);
  }

  function isWorkFlowStatusManaged() {
    return (
      selectedEncounter &&
      selectedEncounter.workflow &&
      selectedEncounter.workflow?.workflowStatus?.workflowStatusId > 0 &&
      !(
        selectedEncounter.workflow?.workflowStatus?.workflowStatusId === 20 &&
        selectedEncounter.workflow?.workflowSubStatus?.workflowSubStatusId === 7
      )
    );
  }

  function mapToIOCModel(): IocConfig {
      const pfrSummary: any = estimate?.summary;
      const pfrAmt = pfrSummary.totalPFR;

      const levelsOfCare = estimate?.facilityLevelOfCare!
          .map(loc => loc.facilityLevelOfCareName)
          .join(', ');
      const truncLevelsOfCare =
          levelsOfCare?.length! > 128 ? levelsOfCare?.slice(0, 128) : levelsOfCare;
      const surchargeRateTotal =
          admissionsAdvisorUtils.newEstimateCalculateTotalSurchargeRate(
            estimate?.facilityLevelOfCare!
          );
      const iocConfig: IocConfig = {
          paramId: 0,
          patientId: selectedFinPassPatient?.patientId,
          clientId: Number(estimate?.clientId!),
          facilityId: Number(estimate?.facilityId!),
          pfrAmt: pfrAmt,
          pfrEstimateId: Number(estimate?.estimateId),
          levelOfCare: truncLevelsOfCare,
          totalSurchargeRate: surchargeRateTotal,
          timingRisk: savedEncounter?.timingRisk,
          payorRisk: savedEncounter?.payorRisk,
      };
      return iocConfig;
  }

  function checkEstimateToIoc() {
    let estimatorStatus: boolean = false
    if(estimate?.estimateId !== savedEstimate?.pfrEstimateId && iocIsManagedStatus) {
      estimatorStatus = true;
    }
    return estimatorStatus;
  }

  function canSendToFinPass() {
    return checkPermissions(
      userProfile.userRole.userRolePages,
      RolePageNames.AdmissionsAdvisor,
      "Send to FinPass",
      false
    );
  }

  const disableSaveAndSendToFinpassButton = !canSendToFinPass();
  const iocIsManagedStatus = isWorkFlowStatusManaged();
  const disabledEstimate = checkEstimateToIoc();

  return (
    <>
      {estimate?.facilityLevelOfCare?.length! > 0 && (
        <Grid
          container
          direction="row"
          spacing={2}
          className="mt-1"
          style={{ borderTop: "1px solid #999999" }}
        >
          <Grid item direction="column" xs={10}>
            {estimate?.estimateId ? (
              <>
                <Grid container direction="row" spacing={1}>
                  <Grid item direction="column" md={4}>
                  <TextField
                      disabled={iocIsManagedStatus || isIOCConverted}
                      select
                      label="Saved Estimates"
                      name="savedEstimations"
                      loading={fetchedEstimateLoadingState}
                      value={estimate?.estimateId}
                      onChange={(e: Event) => {
                        handleSavedEstimationChange(e);
                      }}
                      className="state-field"
                      onBlur={() => {}}
                    >
                      {filteredEstimates.length > 0 &&
                        [...filteredEstimates]
                          ?.sort((a, b) =>
                            a.estimateBody?.description! >
                            b.estimateBody?.description!
                              ? 1
                              : -1
                          )
                          .map((estimation: PfrEstimator) => (
                            <MenuItem
                              key={estimation.pfrEstimateId}
                              value={estimation.pfrEstimateId}
                              style={{ fontWeight: estimation.pfrEstimateId === savedEstimate?.estimateBody?.pfrEstimateId ? 'bold' : 'normal' }}
                            >
                              {estimation.estimateBody?.description}
                            </MenuItem>
                          ))
                      }

                    </TextField>
                  </Grid>
                  <Grid item direction="row" md={8} style={{display: "flex"}}>
                    <Button
                      type="secondary"
                      loading={savedEstimatesLoadingState}
                      disabled={isIOCConverted || disabledEstimate}
                      onClick={() => {
                        saveCurrentEstimate();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      disabled={iocIsManagedStatus || isIOCConverted}
                      type="secondary"
                      onClick={() => {                      
                        saveCurrentEstimate(undefined, true);
                      }}
                      style={{ marginLeft: "1em" }}
                    >
                      Save As New Estimate
                    </Button>
                    <Grid item direction="column" style={{ textAlign: "left", paddingLeft: "12px" }} xs={4}>
                    {savedEstimate?.estimateBody?.description && (
                        <>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{fontSize: '12px'}}
                            >
                                IoC Estimate:
                            </Typography>
                            <Tooltip
                                title={
                                    savedEstimate
                                        ?.estimateBody
                                        .description!
                                }
                            >
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                >
                                    {savedEstimate
                                        ?.estimateBody
                                        ?.description &&
                                    savedEstimate
                                        .estimateBody
                                        .description
                                        .length > 40
                                        ? `${savedEstimate.estimateBody.description.slice(
                                              0,
                                              34
                                          )}...`
                                        : savedEstimate
                                              ?.estimateBody
                                              ?.description}
                                </Typography>
                            </Tooltip>
                        </>
                    )}
                  </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Button
                  type="secondary"
                  onClick={() => {
                    saveCurrentEstimate(undefined, true);
                  }}
                  test-id="save-estimate-btn"
                >
                  Save
                </Button>
              </>
            )}
            
          </Grid>
          <Grid item direction="column" style={{ textAlign: "right", whiteSpace: "nowrap", paddingLeft: "0px" }} xs={2}>
          <Button
              disabled={
                  disableSaveAndSendToFinpassButton
                      ? false
                      : disabledEstimate
              }
              onClick={
                  disableSaveAndSendToFinpassButton
                      ? handleSendToCRM
                      : handleSendToFinPass
              }
              test-id="send-to-finpass-btn"
          >
              {disableSaveAndSendToFinpassButton
                  ? 'Send to CRM'
                  : isIOCConverted
                  ? 'View Instance of Care'
                  : estimate?.patientEncounterId
                  ? 'Update Instance of Care'
                  : 'Create Instance of Care'
              }
          </Button>
          </Grid>
          
        </Grid>
      )}

      {completePatientRecordModalOpen && (
        <EstCompletePatientRecordModal
          open={completePatientRecordModalOpen}
          handlePatientRecordModalCancel={handlePatientModalCancel}
          handlePatientRecordModalSubmit={handlePatientModalSubmit}
        />
      )}
    </>
  );
}
