import axios from 'axios';
import { DialogActionButton, LoadingOverlay, Button } from '@finpay-development/shared-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { TakePaymentView } from './take-payment-view';
import TakePaymentModalContent, { paymentToggleTypes } from './take-payment-modal-content';
import { RootState } from '../../state/root-reducer';
import { vobPatient } from '../../../admissions-advisor/models/patient';
import { PaymentType } from 'src/patient/components/models/payment-type';
import { patientService } from 'src/patient/services/patient-service';
import { AppDispatch } from "../../state/store";
import {
  addPatientPaymentMethod,
  createSPAHelloSignRequest,
  getPaymentMethods,
  putProgram
} from 'src/patient/state/patient-thunk';
import { showErrorStatus, showStatus } from 'src/security/state/user-slice';
import { Utils } from '../../utils';
import { PAYMENT_METHOD_TYPES } from 'src/patient/components/models/payment-method';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import { PatientPaymentProgram } from 'src/patient/components/models/patient-payment-program';
import { Payment } from 'src/patient/components/models/payment';
import { PaymentMethod } from 'src/patient/components/models/payment-method';
import { PaymentDetail } from 'src/patient/components/models/payment-detail';
import { PatientPaymentSchedule } from 'src/patient/components/models/patient-payment.schedule';
import MobilePaymentsLoader from '../mobile-payments-loader';
import { ApiBatchLimits, stripePaymentErrors } from 'src/shared/enums';
import { setCurrentClient } from 'src/implementation-specialist/state/clients/implementation-clients-slice';
import yupValidationSchema from './validation-schema';
import { saveUser } from 'src/admin/state/users/admin-thunk';
import { UserInfo } from 'src/admin/models/user-info';
import { adminUserHelper, userService } from 'src/admin/services/admin-user-service';
import { UserInfoClient } from 'src/admin/models/user-info-client';
import { axiosSaveHelper } from 'src/shared/service/axios-save-helper';
import { ClientCrm, ClientStatusCardViewModel } from 'src/shared/model/client-status-card';
import { OnlinePaymentRef } from "./online-payment";
import { paynowService as paymentService } from "src/guest/services/paynow-service";
import { setSelectedEncounter } from 'src/patient/state/patient-slice';

interface TakePaymentProps {
  open: boolean;
  handleModalCancel: () => void;
  vobPatientState?: vobPatient;
  paymentData?: {
    paymentType?: PaymentType,
    patientId: number;
    patientEncounterId: number;
    defaultPaymentAmt?: number;
    paymentChannelId: number;
    disallowedPaymentTypes?: string[];
    isOnlyAccountHolder?: boolean;
    disallowedPatientAPI?: boolean;
    isAuthOnly?: boolean;
  }
  handleSubmitCallBack?: (payment: any, response: any) => void;
  hasPaymentReason?: boolean;
  isPaymentProgramV5?: boolean;
  maxPaymentAmt?: string | number;
  showUseForRecurringButton?: boolean;
}

const createSetupIntent = async (paymentMethodData: {
  customerId: string;
  customerName: string;
  accountNumber: string;
  routingNumber: string;
}, metaData: {
  encounterId: string,
  nameOnCard: string,
  paymentMethodType: string,
  payorId: string
}) => {
  try {
    const testApiKey = 'rk_test_51BGXuLGsLKhlK6LiisygepxMuRSKLyYI2lOV5vWXZJPBm0GZWmdShQ00A48bPY1rZNqGZWBEx0YU7EkYy1fC65Ty00JXYNFlri';

    const stripeAxios = axios.create({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${testApiKey}`,
      },
    });

    const response = await stripeAxios.post(
      'https://api.stripe.com/v1/setup_intents',
      new URLSearchParams({
        confirm: 'true',
        customer: paymentMethodData.customerId,
        'mandate_data[customer_acceptance][type]': 'offline',
        'payment_method_data[billing_details][name]': paymentMethodData.customerName,
        'payment_method_data[type]': 'us_bank_account',
        'payment_method_data[us_bank_account][account_holder_type]': 'individual',
        'payment_method_data[us_bank_account][account_number]': paymentMethodData.accountNumber,
        'payment_method_data[us_bank_account][routing_number]': paymentMethodData.routingNumber,
        'payment_method_options[us_bank_account][verification_method]': 'skip',
        'payment_method_types[]': 'us_bank_account',
        'metadata[metaData.encounterId]': metaData.encounterId,
        'metadata[metaData.nameOnCard]': metaData.nameOnCard,
        'metadata[metaData.paymentMethodType]': metaData.paymentMethodType,
        'metadata[metaData.payorId]': metaData.payorId,
      })
    );

    return response.data;
  } catch (error: any) {
    console.error('Error creating Setup Intent:', error.response?.data || error.message);
    throw error;
  }
};

export function TakePaymentModal(props: TakePaymentProps) {
  const { open, handleModalCancel, vobPatientState, paymentData, handleSubmitCallBack, hasPaymentReason, isPaymentProgramV5, maxPaymentAmt, showUseForRecurringButton } = props;
  const patientId = paymentData?.patientId || 0;
  const encounterId = paymentData?.patientEncounterId || 0;
  const defaultPaymentAmt = maxPaymentAmt || paymentData?.defaultPaymentAmt || '';
  const paymentChannelId = paymentData?.paymentChannelId;
  const disallowedPaymentTypes = paymentData?.disallowedPaymentTypes || [];
  const isOnlyAccountHolder = !!paymentData?.isOnlyAccountHolder;
  const disallowedPatientAPI = !!paymentData?.disallowedPatientAPI;
  const paymentType = paymentData?.paymentType;
  const isAuthOnly = paymentData?.isAuthOnly || false;

  const [isIOCLoading, setIsIOCLoading] = useState(false);
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(false);
  const [isPayProcessing, setIsPayProcessing] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const [patientDetails, setPatientDetails] = useState<any>(null);
  const onlinePaymentRef = useRef<OnlinePaymentRef>(null);

  const stateFields = {
    stripeCardToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.token
    ),
    stripeRecurringCardToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.token
    ),
    stripeBankToken: useSelector(
      (state: RootState) => state.patientContext.downPaymentTokens.bankToken
    ),
    stripeRecurringBankToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.bankToken
    ),
    allClients: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.allClients),
    currentClient: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.currentClient),
    isPatientPortal: useSelector((state: RootState) => state.userContext.userProfile?.isPatient),
    userProfile: useSelector((state: RootState) => state.userContext.userProfile),
    selectedEncounter: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter
    }),
  };

  const {
    allClients,
    currentClient,
    stripeCardToken,
    stripeRecurringCardToken,
    stripeBankToken,
    stripeRecurringBankToken,
    isPatientPortal,
    selectedEncounter,
    userProfile
  } = stateFields;

  const requiredKeys = ['patientPaymentScheduleId', 'pfrAmt', 'paymentDueAmt', 'downPmtAmt', 'paymentFreq', 'remainingTerms', 'scheduleStatus', 'scheduleStartDt', 'nextPaymentDueDt', 'paymentStartDay', 'terms'];

  const lastPaymentProgram = selectedEncounter?.patientPaymentProgram?.length > 0
    ? selectedEncounter?.patientPaymentProgram[selectedEncounter?.patientPaymentProgram?.length - 1]
    : null;

  const isAchVerified = lastPaymentProgram?.isACHVerified;
  const isAch = lastPaymentProgram?.isACH;
  const workflowStatusId = lastPaymentProgram?.workFlow?.workflowStatus?.workflowStatusId;
  const workflowSubStatusId = lastPaymentProgram?.workFlow?.workflowSubStatus?.workflowSubStatusId;
  const patientPaymentProgram = lastPaymentProgram
    ? {
        ..._.omit(lastPaymentProgram, ['isACHVerified', 'isACH', 'patientPaymentMethod', 'workFlow', 'workflowDueDt', 'workflowStatusDt']),
        ...(lastPaymentProgram.highRiskApprovalDt == null && { highRiskApprovalDt: new Date().toISOString() }), // Add highRiskApprovalDt only if it's null
        isACH: isAch,
        isACHVerified: isAchVerified,
        workflowStatusId: workflowStatusId,
        workflowSubStatusId: workflowSubStatusId,
        patientPaymentSchedule: {
          // Pick only the required keys from patientPaymentSchedule
          ..._.pick(lastPaymentProgram.patientPaymentSchedule, requiredKeys),
          ...(lastPaymentProgram.patientPaymentSchedule?.nextPaymentDueDt == null && { nextPaymentDueDt: new Date().toISOString() }), // Add nextPaymentDueDt only if it's null
          ...(lastPaymentProgram.patientPaymentSchedule?.downPmtAmt == null && { downPmtAmt: 0 }) // Add downPmtAmt only if it's null
        }
      }
    : {} as PatientPaymentProgram;


  const isDisabledACH = !!selectedEncounter?.isConverted;

  const title = 'Take a Payment';

  const client: ClientStatusCardViewModel | undefined = allClients.find(
    (c: ClientStatusCardViewModel) =>
        c.clientId === selectedEncounter?.clientId
  );

  const isPaymentProgramVersionGreaterOrEqualTo4 = typeof client?.paymentProgramVersion === 'number' && client?.paymentProgramVersion >= 4;
  const isPaymentProgramVersionEqualTo5 = typeof client?.paymentProgramVersion === 'number' && client?.paymentProgramVersion === 5;
  const isIntegrationEnabled = client?.clientCrm?.some((crm: ClientCrm) => crm.isIntEnabled);

  useEffect(() => {
    (async () => {
      // Only fetch if we have an encounterId and the selectedEncounter in Redux is empty
      if (encounterId && (!selectedEncounter || selectedEncounter.patientEncounterId !== encounterId)) {
        setIsIOCLoading(true);
        const res: any = await patientService.getPatientInstanceOfCare({ patientId, encounterId });
        dispatch(setSelectedEncounter(res.entity));
        
        setIsIOCLoading(false);
      }
    })();
  }, [encounterId, patientId, dispatch]);

  useEffect(() => {
    if (patientId && !disallowedPatientAPI) {
      (async () => {
        setIsPatientLoading(true);
        const res: any = await patientService.getPatient(patientId);
        setPatientDetails(res.entity);
        if (allClients?.length > 0) {
          const filteredClient = allClients.find((v) => v.clientId === res.entity.clientId);
          dispatch(setCurrentClient(filteredClient));
        }
        setIsPatientLoading(false);
      })();
    }
  }, [patientId]);

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const processError = (response: any) => {
    if (response.entity?.code) {
      if (response.entity?.message === stripePaymentErrors.generic_decline) {
        dispatch(showErrorStatus(`${response.entity?.message} - ${response.entity?.code}:${response.entity?.declineCode}`));
      } else {
        dispatch(showErrorStatus(response.entity?.message || response.entity));
      }
    } else {
      dispatch(showErrorStatus(response.entity || response.errorMessage));
    }
  }

  const formik = useFormik(
    vobPatientState
      ? {
        initialValues: {
          patientTitle: '',
          patientFirstName: vobPatientState.firstName,
          patientMI: vobPatientState.middleName
            ? vobPatientState.middleName
            : '',
          patientLastName: vobPatientState.lastName,
          email: vobPatientState?.advisorPatientBody?.email
            ? vobPatientState.advisorPatientBody.email
            : '',
          phone1: vobPatientState?.advisorPatientBody?.phoneNumber
            ? vobPatientState.advisorPatientBody.phoneNumber
            : '',
          phone1Home: false,
          phone2: '',
          phone2Home: false,
          retypeEmail: vobPatientState?.advisorPatientBody?.email
            ? vobPatientState.advisorPatientBody.email
            : '',
          streetAddress1: vobPatientState?.advisorPatientBody?.primaryAddress
            ?.streetAddress1
            ? vobPatientState.advisorPatientBody.primaryAddress
              .streetAddress1
            : '',
          streetAddress2: vobPatientState?.advisorPatientBody?.primaryAddress
            ?.streetAddress2
            ? vobPatientState.advisorPatientBody.primaryAddress
              .streetAddress2
            : '',
          city: vobPatientState?.advisorPatientBody?.primaryAddress?.city
            ? vobPatientState.advisorPatientBody.primaryAddress.city
            : '',
          stateCode: vobPatientState?.advisorPatientBody?.primaryAddress
            ?.state?.stateCode
            ? vobPatientState.advisorPatientBody.primaryAddress.state
              .stateCode
            : '',
          zip: vobPatientState?.advisorPatientBody?.primaryAddress?.zipCode
            ? vobPatientState.advisorPatientBody.primaryAddress.zipCode
            : '',
          timingRisk: selectedEncounter?.timingRisk?.timingRiskId
            ? selectedEncounter.timingRisk.timingRiskId
            : null,
          payorRisk: selectedEncounter?.payorRisk?.payorRiskId
            ? selectedEncounter.payorRisk.payorRiskId
            : null,
          isWarmTransfer: '',
          noteText: '',
          isPayNow: false,
          payNowAmt: '',
          isPayAtFacility: false,
          payAtFacilityAmt: '',
          useExistingSource: false,
          savePaymentMethod: false,
          nameOnCard: '',
          leaveVMFl: false,
          smsConsentFl: true,
          paymentReason: '',
          isCaptured: false,
        },
        onSubmit: () => { }
      }
      : {
        initialValues: {
          useExistingSource: false,
          amount: defaultPaymentAmt,
          payorName: '-1',
          paymentMethodType: paymentToggleTypes.CREDIT_CARD,
          nameOnCard: "",
          bankName: "",
          nameOnAccount: "",
          routingNumber: "",
          accountNumber: "",
          retypeAccountNumber: "",
          paymentDay: "",
          recurringPayment: false,
          receiptEmail: '',
          paymentSources: '',
          useAsRecurringSource: false,
          paymentReason: '',
          firstName: "",
          lastName: "",
          isCaptured: false
        },
        validationSchema: yupValidationSchema,
        onSubmit: async (values) => {
          setIsPayProcessing(true);
          const successMsg = isAuthOnly ? 'Payment Authorization Successful' : 'Payment Successful';
          try {
            const receiptEmail = values.receiptEmail;
            let userRecord;
            if (!isPatientPortal) {
              userRecord = (await userService.getAllUsers(0, ApiBatchLimits.users, { userName: receiptEmail || '' }))?.entity?.[0]
              if (userRecord && !userRecord.isPatient) { // Case: User exists but is admin
                dispatch(showErrorStatus("Finpay Admin user already exists with this username. Please try another email"));
                handleModalCancel();
                return;
              } else if (!userRecord) { // Case: User does not exist
                userRecord = await checkAndCreateUser(userRecord);
              } 
              } else if (isPatientPortal) { // Case: User is patient in patient portal
                userRecord = userProfile;
              } else {
                throw new Error("No owner Id for payment");
            }

            let newPayorName = '';
            
            let customerId = patientDetails && patientDetails.contact && patientDetails.contact.externalId;

            // create patient champion
            if (values.payorName === 'add-new-payor') {
              const fullName = getFullName(values);
              const contact = {
                firstName: (fullName || '').split(' ')[0] || '',
                lastName: (fullName || '').split(' ').slice(1).join(' '),
                email: receiptEmail,
              };
              const customer = await patientService.createCustomer(contact);
              const newChampion = {
                patientEncounterId: encounterId,
                contact: {
                  ...contact,
                  leaveVMFl: false,
                  smsConsentFl: false,
                  externalId: customer?.entity?.id,
                }
              }
              const newPatientChampion = await patientService.createPatientChampion(newChampion);
              newPayorName = newPatientChampion.entity.patientChampionId;
              customerId = customer?.entity?.id;
            }
            const paymentMethodType = values.paymentMethodType;

            if (!patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId) {
              const paymentProgram = mapToPatientPaymentProgram();
              const paymentProgramResponse: any = await patientService.savePatientPaymentProgram({
                paymentProgram, patientId, encounterId,
              });
              const downPaymentInfo = mapToDownPayment({ newPayorName }, userRecord);
              downPaymentInfo.patientPaymentScheduleId = _.get(paymentProgramResponse.entity, 'patientPaymentSchedule.patientPaymentScheduleId');
              downPaymentInfo.paymentGroupId = downPaymentInfo.patientPaymentScheduleId?.toString();
              const response = await patientService.createPayment(downPaymentInfo);
              if (!response.hasErrors) {
                dispatch(showStatus(successMsg));
                if (isIntegrationEnabled) {
                  await patientService.integrationUpdate({
                    patientEncounterId: selectedEncounter.patientEncounterId,
                    patientId: selectedEncounter.patientId,
                    crmTypeSlug: client?.clientCrm?.[0]?.crmType?.crmTypeSlug,
                  });
                }
                if (formik.values?.paymentDay) {
                  await dispatch(putProgram({
                    patientPaymentProgramId: patientPaymentProgram.patientPaymentProgramId,
                    paymentProgram: {
                      patientPaymentSchedule: {
                        paymentStartDay: new Date(formik.values?.paymentDay!).getDate(),
                        nextPaymentDueDt: formik.values?.paymentDay!
                      }
                    },
                    updateState: true,
                  }));
                }
                if (selectedEncounter.isConverted && response.entity.pfrCapturedBalance === 0) {
                  const statusResult = await patientService.saveStatus({
                    workFlow: {
                      workflowId: 3,
                      workflowStatus: {
                        workflowStatusId: 16,
                        workflowStatusDesc: '',
                      },
                      workflowSubStatus: {
                        workflowSubStatusId: 23,
                        workflowSubStatusDesc: '',
                      },
                    },
                    encounterId: selectedEncounter.patientEncounterId!,
                    patientId: selectedEncounter.patientId!,
                  });
                  if (statusResult.hasErrors) {
                    dispatch(showErrorStatus("Failed to update status"));
                    throw new Error('Failed to update status');
                  }
                }
                if (patientPaymentProgram.patientPaymentProgramId) {
                  if (patientPaymentProgram.isPaidInFull &&  patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F') {
                    const statusResult = await paymentService.updatePaymentProgram(patientPaymentProgram.patientPaymentProgramId, {
                      workflowStatusId: 16,
                      workflowSubStatusId: 23,
                      patientPaymentSchedule: {
                        scheduleStatus: 'Pending',
                      },
                    });
                    if (statusResult.hasErrors) {
                      dispatch(showErrorStatus("Failed to update status"));
                      throw new Error('Failed to update status');
                    }
                  }
                }
              } else {
                processError(response);
              }
              handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
            } else {
              if ((paymentMethodType !== paymentToggleTypes.CREDIT_CARD && stripeBankToken)
                || ((paymentMethodType === paymentToggleTypes.CREDIT_CARD) && stripeCardToken)
                || values.useExistingSource
              ) { // handles ACH and Credit card scenarios.
                const currentChampion = Utils.getPatientsAndChampionsList(patientDetails, selectedEncounter, false).find((champion) => champion.id === parseInt(values.payorName!))
                const paymentMethodData = {
                  customerId: customerId,
                  customerName: values.payorName === 'add-new-payor' ? values.nameOnAccount! : currentChampion?.name!,
                  accountNumber: values.accountNumber!,
                  routingNumber: values.routingNumber!
                };
                const metaData = {
                  encounterId: encounterId.toString(),
                  nameOnCard: values.nameOnCard,
                  paymentMethodType: values.paymentMethodType!,
                  payorId: values?.payorName || ''
                }
                if ((values?.recurringPayment === true) && // when we are also saving a recurring payment as well as down payment.
                  ((paymentMethodType !== paymentToggleTypes.CREDIT_CARD && stripeRecurringBankToken)
                    || ((paymentMethodType === paymentToggleTypes.CREDIT_CARD) && stripeRecurringCardToken)
                  )
                ) { // for payment types of Credit or ACH
                  if (paymentMethodType === paymentToggleTypes.ACH) {
                    if (isPaymentProgramVersionEqualTo5) {
                      const externalPaymentMethodId = await createSetupIntent(paymentMethodData, metaData);
                      const newPaymentMethod: PaymentMethod = {
                        userId: userRecord.userId,
                        externalPaymentMethodId: externalPaymentMethodId.payment_method,
                        paymentMethodTypeId: PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId,
                        last4: parseInt(paymentMethodData.accountNumber.slice(-4)),
                        expiration: new Date(Date.now() + 5 * 365 * 24 * 60 * 60 * 1000).toISOString(),
                        status: externalPaymentMethodId.status,
                        verification_id: externalPaymentMethodId.id
                      };
                      const response = await patientService.createPaymentMethod(newPaymentMethod);
                    } else {
                      await patientService.savePatientPaymentProgram({
                        paymentProgram: {
                          ...patientPaymentProgram,
                          isACH: true,
                        } as PatientPaymentProgram,
                        patientId,
                        encounterId,
                      });
                    }
                  }
                  const downPaymentInfo = mapToDownPayment({ newPayorName }, userRecord);
                  const recurringPaymentInfo = mapToRecurringPayment();
                  const response = await patientService.createPayment(downPaymentInfo);
                  if (!response.hasErrors) {
                    if (isIntegrationEnabled) {
                      await patientService.integrationUpdate({
                        patientEncounterId: selectedEncounter.patientEncounterId,
                        patientId: selectedEncounter.patientId,
                        crmTypeSlug: client?.clientCrm?.[0]?.crmType?.crmTypeSlug,
                      });
                    }
                    if (formik.values?.paymentDay) {
                      await dispatch(putProgram({
                        patientPaymentProgramId: selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
                        paymentProgram: {
                        patientPaymentProgramId: selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
                        patientId: selectedEncounter.patientId,
                        patientEncounterId: selectedEncounter.patientEncounterId,
                        patientPaymentSchedule: {
                          paymentStartDay: new Date(formik.values?.paymentDay!).getDate(),
                          nextPaymentDueDt: recurringPaymentInfo.paymentMethods[0].scheduleStartDt,
                        }
                      },
                        updateState: true,
                      }));
                    }
                    await dispatch(
                      addPatientPaymentMethod({
                        patientId: patientId,
                        encounterId: encounterId,
                        paymentDetail: recurringPaymentInfo,
                        isAccountHolder: false,
                      })
                    );
                    dispatch(showStatus(successMsg));
                    if (selectedEncounter.isConverted && response.entity.pfrCapturedBalance === 0) {
                      const statusResult = await patientService.saveStatus({
                        workFlow: {
                          workflowId: 3,
                          workflowStatus: {
                            workflowStatusId: 16,
                            workflowStatusDesc: '',
                          },
                          workflowSubStatus: {
                            workflowSubStatusId: 23,
                            workflowSubStatusDesc: '',
                          },
                        },
                        encounterId: selectedEncounter.patientEncounterId!,
                        patientId: selectedEncounter.patientId!,
                      });
                      if (statusResult.hasErrors) {
                        dispatch(showErrorStatus("Failed to update status"));
                        throw new Error('Failed to update status');
                      }
                    }
                    if (patientPaymentProgram.patientPaymentProgramId) {
                      if (patientPaymentProgram.isPaidInFull &&  patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F') {
                        const statusResult = await paymentService.updatePaymentProgram(patientPaymentProgram.patientPaymentProgramId, {
                          workflowStatusId: 16,
                          workflowSubStatusId: 23,
                          patientPaymentSchedule: {
                            scheduleStatus: 'Pending',
                          },
                        });
                        if (statusResult.hasErrors) {
                          dispatch(showErrorStatus("Failed to update status"));
                          throw new Error('Failed to update status');
                        }
                      }
                    }
                  } else {
                    processError(response);
                  }
                  handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
                } else {
                  if (paymentMethodType === paymentToggleTypes.ACH) {
                    if (isPaymentProgramVersionEqualTo5) {
                      const externalPaymentMethodId = await createSetupIntent(paymentMethodData, metaData);
                      const newPaymentMethod: PaymentMethod = {
                        userId: userRecord.userId,
                        externalPaymentMethodId: externalPaymentMethodId.payment_method,
                        paymentMethodTypeId: PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId,
                        last4: parseInt(paymentMethodData.accountNumber.slice(-4)),
                        expiration: new Date(Date.now() + 5 * 365 * 24 * 60 * 60 * 1000).toISOString(),
                        status: externalPaymentMethodId.status,
                        verification_id: externalPaymentMethodId.id
                      };
                      const response = await patientService.createPaymentMethod(newPaymentMethod);
                    } else {
                      await patientService.savePatientPaymentProgram({
                        paymentProgram: {
                          ...patientPaymentProgram,
                          isACH: true,
                        } as PatientPaymentProgram,
                        patientId,
                        encounterId,
                      });
                    }
                  }
                  const downPaymentInfo = mapToDownPayment({ newPayorName }, userRecord);
                  const response = await patientService.createPayment(downPaymentInfo);
                  if (!response.hasErrors) {
                    // await handleCreateSPARequest(downPaymentInfo);
                    dispatch(showStatus(successMsg));
                    if (isIntegrationEnabled) {
                      await patientService.integrationUpdate({
                        patientEncounterId: selectedEncounter.patientEncounterId,
                        patientId: selectedEncounter.patientId,
                        crmTypeSlug: client?.clientCrm?.[0]?.crmType?.crmTypeSlug,
                      });
                    }
                    if (formik.values?.paymentDay) {
                      await dispatch(putProgram({
                        patientPaymentProgramId: patientPaymentProgram.patientPaymentProgramId,
                        paymentProgram: {
                        patientPaymentSchedule: {
                          paymentStartDay: new Date(formik.values?.paymentDay!).getDate(),
                          nextPaymentDueDt: formik.values?.paymentDay!
                        }
                      },
                        updateState: true,
                      }));
                    }
                    if (selectedEncounter.isConverted && response.entity.pfrCapturedBalance === 0) {
                      const statusResult = await patientService.saveStatus({
                        workFlow: {
                          workflowId: 3,
                          workflowStatus: {
                            workflowStatusId: 16,
                            workflowStatusDesc: '',
                          },
                          workflowSubStatus: {
                            workflowSubStatusId: 23,
                            workflowSubStatusDesc: '',
                          },
                        },
                        encounterId: selectedEncounter.patientEncounterId!,
                        patientId: selectedEncounter.patientId!,
                      });
                      if (statusResult.hasErrors) {
                        dispatch(showErrorStatus("Failed to update status"));
                        throw new Error('Failed to update status');
                      }
                    }
                    if (patientPaymentProgram.patientPaymentProgramId) {
                      if (patientPaymentProgram.isPaidInFull &&  patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F') {
                        const statusResult = await paymentService.updatePaymentProgram(patientPaymentProgram.patientPaymentProgramId, {
                          workflowStatusId: 16,
                          workflowSubStatusId: 23,
                          patientPaymentSchedule: {
                            scheduleStatus: 'Pending',
                          },
                        });
                        if (statusResult.hasErrors) {
                          dispatch(showErrorStatus("Failed to update status"));
                          throw new Error('Failed to update status');
                        }
                      }
                    }
                  } else {
                    processError(response);
                  }
                  handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
                }
              } else if (paymentMethodType === paymentToggleTypes.PAID_AT_FACILITY
                || paymentMethodType === paymentToggleTypes.CHECK) { // handles the PAID AT FACILITY payment type.
                  const downPaymentInfo = mapToDownPayment({ newPayorName }, userRecord);
                const response = await patientService.createPayment(downPaymentInfo);
                if (!response.hasErrors) {
                  if (isIntegrationEnabled) {
                    await patientService.integrationUpdate({
                      patientEncounterId: selectedEncounter.patientEncounterId,
                      patientId: selectedEncounter.patientId,
                      crmTypeSlug: client?.clientCrm?.[0]?.crmType?.crmTypeSlug,
                    });
                  }
                  if (formik.values?.paymentDay) {
                    dispatch(showStatus(successMsg));
                    await dispatch(putProgram({
                      patientPaymentProgramId: patientPaymentProgram.patientPaymentProgramId,
                    paymentProgram: {
                      patientPaymentSchedule: {
                        paymentStartDay: new Date(formik.values?.paymentDay!).getDate(),
                        nextPaymentDueDt: formik.values?.paymentDay!
                      }
                    },
                      updateState: true,
                    }));
                  }
                  if (selectedEncounter.isConverted  && response.entity.pfrCapturedBalance === 0) {
                    const statusResult = await patientService.saveStatus({
                      workFlow: {
                        workflowId: 3,
                        workflowStatus: {
                          workflowStatusId: 16,
                          workflowStatusDesc: '',
                        },
                        workflowSubStatus: {
                          workflowSubStatusId: 23,
                          workflowSubStatusDesc: '',
                        },
                      },
                      encounterId: selectedEncounter.patientEncounterId!,
                      patientId: selectedEncounter.patientId!,
                    });
                    if (statusResult.hasErrors) {
                      dispatch(showErrorStatus("Failed to update status"));
                      throw new Error('Failed to update status');
                    }
                  }
                  if (patientPaymentProgram.patientPaymentProgramId) {
                    if (patientPaymentProgram.isPaidInFull &&  patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F') {
                      const statusResult = await paymentService.updatePaymentProgram(patientPaymentProgram.patientPaymentProgramId, {
                        workflowStatusId: 16,
                        workflowSubStatusId: 23,
                        patientPaymentSchedule: {
                          scheduleStatus: 'Pending',
                        },
                      });
                      if (statusResult.hasErrors) {
                        dispatch(showErrorStatus("Failed to update status"));
                        throw new Error('Failed to update status');
                      }
                    }
                  }
                } else {
                  processError(response);
                }
                handleSubmitCallBack && handleSubmitCallBack(downPaymentInfo, response);
              } else if(paymentMethodType === paymentToggleTypes.ONLINE_PAYMENT) {
                if (onlinePaymentRef.current) {
                  const message = await onlinePaymentRef.current.confirmPayment();
                  console.log(message || "No message received");
                }
              }
            }
            handleModalCancel();
          } catch (error: any) {
            console.log('error:', error.message);
            await dispatch(showErrorStatus(error.message
              || 'Something Went Wrong'));
          }
          //to do: consolidate with the one in down-payment-modal.tsx
          setIsPayProcessing(false);
        }
      });

  const getFullName = (currentVals: any) => {
    const { paymentMethodType, nameOnCard, firstName, lastName, nameOnAccount } = currentVals;
    return paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? nameOnCard
        : paymentMethodType === paymentToggleTypes.ACH
          ? nameOnAccount 
          : `${firstName} ${lastName}`.replace(/\s+/g, ' ').trim();
  }

  const checkAndCreateUser = async (userRecord: any) => {

    let userEmail = '';
    let userName = '';

    const currentVals = formik?.values;
    userEmail = currentVals.receiptEmail || '';
    if (currentVals?.payorName === 'add-new-payor') {
      userName = getFullName(currentVals);
    } else {
      userName = Utils.getPayorName(
        {
          payorId: parseInt(currentVals?.payorName || ''),
          patientId,
          selectedPatient: patientDetails,
          selectedEncounter: selectedEncounter,
        }
      );
    }

    userName = userName.replace(/\s{2,}/g, ' ');

    const userClientScopeToSave = new UserInfoClient();
    userClientScopeToSave.clientId = selectedEncounter?.clientId;
    userClientScopeToSave.isFacilityLevel = true;
    userClientScopeToSave.allowedFacilities = [];
    if (!userRecord) {
      // Create new user if none exists      
      const userToCreate = {
        userName: userEmail,
        firstName: userName.split(' ')[0],
        lastName: userName.split(' ')[1],
        userScope: "FINPAY",
        clientId: selectedEncounter?.clientId,
        entityId: 0,
        isActive: true,
        isPatient: true,
        convRunNum: 1,
        userRole: {
          userRoleId: 4,
          roleName: "Account Holder",
          roleDesc: "User Role for Patients or Patient Guarators",
          roleType: "E",
          isActive: true
        },
        allowedClients: [userClientScopeToSave],
        patientEncounterId: selectedEncounter?.patientEncounterId
      };
      const newUser = (await userService.createOrUpdateUser(userToCreate)).entity;
      return newUser;
    } else {
      const allowedClients = userRecord.clients?.find((allowedClient: any) => 
        allowedClient.clientId === selectedEncounter?.clientId
      );
      if (!allowedClients) {
        // Update isFacilityLevel for existing clients if null
        const updatedClients = (userRecord.clients || []).map((client: any) => {
          // Skip updating if isFacilityLevel is already set (non-null)
          if (client.isFacilityLevel === null) {
            return {
              ...client,
              isFacilityLevel: true, // Update to true if null
            };
          }
          return client; // Keep unchanged if isFacilityLevel is already set
        });
        // // Case: User is a patient but has different clientId - update needed
        await axiosSaveHelper({
          dataToSave: {
            allowedClients: [
              ...updatedClients,
              userClientScopeToSave
            ]
          },
          dataId: userRecord.userId,
          url: "user/v2/user",
        });
      }
      // Case: User is a patient and has matching clientId - no action needed
      return userRecord;
    }
  }

  const mapToPatientPaymentProgram = () => {
    const pfrAmt = selectedEncounter?.pfrAmt;
    const currentDate = new Date();
    const terms = 0;
    const recurringPaymentAmt = 0;
    const patientPaymentSchedule = {
      pfrAmt: pfrAmt,
      pfrBalance: pfrAmt,
      paymentFreq: 'M',
      downPmtAmt: formik.values.amount,
      terms: terms,
      remainingTerms: terms,
      paymentDueAmt: recurringPaymentAmt,
      scheduleStatus: 'Pending',
      scheduleStartDt: currentDate.toISOString(),
    } as PatientPaymentSchedule

    const paymentProgram = {
      patientPaymentProgramId: 0,
      isHighRisk: false,
      isPaidInFull: formik.values.amount === selectedEncounter?.pfrAmt,
      downPmtAmt: 0, //setting to 0 as we are not saving the down payment amount from the take payment modal for the program
      isACH: formik.values.paymentMethodType === paymentToggleTypes.ACH,
      areDocsSigned: false,
      patientPaymentSchedule: patientPaymentSchedule,
    } as PatientPaymentProgram
    return paymentProgram;
  }

  const getPaymentInfo = () => {
    let paymentTypeObj;
    if (paymentType) {
      paymentTypeObj = paymentType;
    } else {
      paymentTypeObj = !!selectedEncounter?.isConverted
        ? PAYMENT_TYPES.SPECIALIST
        : PAYMENT_TYPES.DOWN_PAYMENT;
    }
    switch (formik.values.paymentMethodType) {
      case paymentToggleTypes.CREDIT_CARD:
        return {
          paymentMethod: PAYMENT_METHOD_TYPES.CARD,
          paymentTypeObj,
        };
      case paymentToggleTypes.ACH:
        return {
          paymentMethod: PAYMENT_METHOD_TYPES.ACH,
          paymentTypeObj,
        };
      case paymentToggleTypes.PAID_AT_FACILITY:
      case paymentToggleTypes.CHECK:
        return {
          paymentMethod: undefined, // no payment method needed for 'paid at facility' type
          paymentTypeObj: PAYMENT_TYPES.AT_FACILITY
        }
      default:
        return {
          paymentMethod: undefined,
          paymentTypeObj,
        };
    };
  }

  const getPaymentMethod = () => {
    const { paymentMethod } = getPaymentInfo();
    const currentFormValues = formik?.values;
    if (currentFormValues?.useExistingSource) {
      return {
        externalPaymentId: JSON.parse(currentFormValues?.paymentSources || '')?.id,
        externalPaymentMethodId: JSON.parse(currentFormValues.paymentSources || '{}').object === "us_bank_account" ? PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId : PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId,
        paymentMethodTypeId: JSON.parse(currentFormValues.paymentSources || '{}').object === "us_bank_account" ? PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId : PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId,
      };
    }
    return {
      externalPaymentId: (paymentMethod?.externalPaymentMethodId === PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId)
        ? stripeCardToken
        : (paymentMethod?.externalPaymentMethodId === PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId
          ? stripeBankToken
          : null
        )
    }
  }

  const mapToDownPayment = ({ newPayorName = '' }, userRecord: any) => {
    const currentVals = formik?.values;
    const currentDateTime = new Date();

    const { paymentMethod, paymentTypeObj } = getPaymentInfo();
    let receiptEmail = currentVals.receiptEmail;
    if (!receiptEmail) {
      const championList = Utils.getPatientsAndChampionsList(patientDetails, selectedEncounter, true);
      if (championList.length > 1) {
        receiptEmail = championList[1].email;
      } else {
        receiptEmail = championList[0].email;
      }
    }

    const existingPaymentType = currentVals.useExistingSource
      ? JSON.parse(currentVals.paymentSources || '{}').paymentMethodType
      : null;

    return {
      // paymentId: 0, // always 0 when we are POSTing a new payment. use paymentID for release payment
      isManual: true, // hardcoded
      isAuthExpired: false, // hardcoded

      paymentGroupId: `${patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId}`,
      patientPaymentScheduleId: patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId,
      patientId,
      patientEncounterId: encounterId,
      receiptEmail: receiptEmail || currentClient?.supportEmail || "support@finpay.net",
      paymentMethods: [{
        ...paymentMethod,
        payorId: currentVals?.payorName === 'add-new-payor'
          ? newPayorName
          : (
            (+(currentVals?.payorName || 0) === patientId)
              ? "" : currentVals?.payorName // don't send if same as Patient Id
          ),
        ...getPaymentMethod(),
        nameOnCard: currentVals.useExistingSource
          ? JSON.parse(currentVals.paymentSources || '{}').name
          : (
            currentVals.paymentMethodType === paymentToggleTypes.CREDIT_CARD
              ? currentVals?.nameOnCard
              : Utils.getPayorName({
                payorId: parseInt(currentVals?.payorName || ''),
                patientId,
                selectedPatient: patientDetails,
                selectedEncounter: selectedEncounter,
              })
          ),
        // @ts-ignore
        isDefault: currentVals?.isDefault,
      }],
      paymentChannelId,
      paymentAmt: currentVals?.amount,
      paymentType: paymentTypeObj,
      paymentInitDt: currentDateTime.toISOString(),
      isAuthOnly: isAuthOnly,
      isRecurringDownPayment: currentVals.recurringPayment,
      ...(currentVals.paymentReason ? { paymentReason: currentVals.paymentReason } : {}),
      isCaptured: !isAuthOnly,
      ownerId: userRecord?.userId,
    } as Payment;
  }

  const mapToRecurringPayment = () => {
    const currentVals = formik.values;
    const paymentDay = new Date(currentVals?.paymentDay || '').setUTCHours(23, 59, 59, 999);
    const paymentDayIso = new Date(paymentDay || '').toISOString();

    const paymentMethods = [{
      paymentMethodId: 0,
      payorId: +(currentVals?.payorName || 0) === patientId || currentVals?.payorName === "-1"
        ? null : currentVals?.payorName,
      paymentAmt: patientPaymentProgram?.patientPaymentSchedule?.paymentDueAmt,
      scheduleStartDt: paymentDayIso,
      externalPaymentMethodId: currentVals?.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId
        : PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId,
      paymentMethodTypeId: currentVals?.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId
        : PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId,
      externalPaymentId: currentVals?.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? stripeRecurringCardToken
        : stripeRecurringBankToken,
      receiptEmail: currentVals.receiptEmail ? currentVals.receiptEmail : "support@finpay.net",
      nameOnCard: currentVals.paymentMethodType === paymentToggleTypes.CREDIT_CARD
        ? currentVals?.nameOnCard : Utils.getPayorName(
          {
            payorId: parseInt(currentVals?.payorName || ''),
            patientId,
            selectedPatient: patientDetails,
            selectedEncounter: selectedEncounter,
          }
        )
    }] as PaymentMethod[]
    const paymentType = PAYMENT_TYPES.RECURRING

    return {
      paymentMethods: paymentMethods,
      paymentChannelId,
      paymentType: paymentType
    } as PaymentDetail
  }

  const isPatient = selectedEncounter?.patientChampion?.filter((x: any) => x.isGuarantor).length <= 0;
  const signersId = selectedEncounter?.patientChampion?.filter((x: any) => x.isGuarantor).length <= 0
    ? patientId : selectedEncounter?.patientChampion?.filter((x: any) => x.isGuarantor)[0]?.patientChampionId || patientId;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // async function handleCreateSPARequest(downPaymentInfo: Payment) {
  //   if (patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F' && patientIOCData?.pfrAmt === downPaymentInfo?.paymentAmt) {
  //     await dispatch(createSPAHelloSignRequest({
  //       pfrAmount: patientIOCData?.pfrAmt,
  //       paymentTotal: downPaymentInfo?.paymentAmt,
  //       clientId: patientIOCData?.clientId,
  //       facilityId: patientIOCData?.facilityId,
  //       encounterId,
  //       signersId,
  //       isPatient,
  //       isFullPay: patientPaymentProgram?.isPaidInFull,
  //     }));
  //   }
  // }

  const handleStripeStatus = (stripeStatus: boolean) => {
    // setStripePaymentReadyStatus(stripeStatus);
  };

  function handleSaveCallback(saveSuccessful: boolean) { }

  function handleCancelCallback() {
    handleModalCancel();
  }

  const isValidStripeToken = () => {
    if (formik.values.useExistingSource) {
      return !!formik.values.paymentSources;
    } else {
      switch (formik.values.paymentMethodType) {
        case paymentToggleTypes.CREDIT_CARD:
          return !!stripeCardToken;
        case paymentToggleTypes.ACH:
          return !!stripeBankToken;
        default:
          return true;
      }
    }
  }

  return (
    <Dialog
      className="modal user-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {(isIOCLoading || isPatientLoading || isPaymentMethodLoading)
          ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <LoadingOverlay />
            </Grid>
          ) : (vobPatientState
            ? <TakePaymentView
              formik={formik}
              handleStripeStatus={handleStripeStatus}
            />
            :
            <>
              {isPayProcessing &&
                <Grid container direction="row" justifyContent="center" alignItems="center" className="overlay-load">
                  <MobilePaymentsLoader showLoader={isPayProcessing} />
                </Grid>
              }
              <TakePaymentModalContent
                  formik={formik}
                  disallowedPaymentTypes={isDisabledACH
                    ? disallowedPaymentTypes.concat('ACH')
                    : disallowedPaymentTypes
                  }
                  isOnlyAccountHolder={isOnlyAccountHolder}
                  disallowedPatientAPI={disallowedPatientAPI}
                  patientIOCData={selectedEncounter}
                  patientDetails={patientDetails}
                  hasPaymentReason={hasPaymentReason !== false}
                  onlinePaymentRef={onlinePaymentRef}
                  isPaymentProgramV5={isPaymentProgramV5}
                  showUseForRecurringButton={showUseForRecurringButton}
              />
            </>
          )
        }
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          test-id="take-payment-modal-pay-button"
          isEnabled={
            formik.dirty && 
            formik.isValid && 
            !isIOCLoading && 
            !isPatientLoading && 
            !isPayProcessing && 
            isValidStripeToken() || 
            !onlinePaymentRef.current?.isPaymentReady || 
            Number(formik.values.amount!) < Number(maxPaymentAmt!) 
          }
          // isEnabled={onlinePaymentRef.current?.isPaymentReady!}
          savebuttonText="Pay"
          saveStatus={saveStatus}
          executeSave={formik.handleSubmit}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
          spinnerLeftPosition={5}
        />
      </DialogActions>
    </Dialog>
  );
}
