import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  showErrorStatus,
  showStatus,
  updateApplicationStatus,
} from '../../security/state/user-slice';
import {RootState} from '../../shared/state/root-reducer';
import {PatientNotes} from '../components/models/patient-note';
import {Utils} from '../../shared/utils';
import {
  PatientChampion,
  PhiHelloSignRequest,
} from '../components/models/patient-champion';
import {
  emptyPatientEncounter,
  PatientEncounter, patientEncounterContact,
} from '../components/models/patient-encounter';
import {PatientInsurance} from '../components/models/patient-insurance';
import {
  PatientPaymentProgram,
} from '../components/models/patient-payment-program';
import {
  BaseAPIRequiredPatient,
  emptyPatientViewModel,
  PatientViewModel,
} from '../components/models/patient-view-model';
import {Payment} from '../components/models/payment';
import {
  deletePatientPaymentProgramModel,
} from '../components/patient-drilldown/payment-programs/payment-programs-modal';
import {patientHelper, patientService} from '../services/patient-service';
import {
  addScriptWindowObjectReference,
  resetDataAccountHolder,
  resetScriptWindowObjectReference,
  setRedirectToId,
  setSelectedPatientV2,
  setSelectedVOB,
} from './patient-slice';
import {commentsService} from '../../admin/services/comments-service';
import {PFRAdjustment} from '../components/models/pfr-adjustment';
import {PaymentDetail} from '../components/models/payment-detail';
import {StripePaymentMethod} from '../components/models/stripe-payment-method';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {
  PatientEncounterCard,
} from '../components/models/patient-encounter-card';
import {ApiBatchLimits, RecentlyViewedTypes} from '../../shared/enums';
import {cookiesService} from '../../admin/services/cookie-service';
import {
  paynowService as paymentService,
  paynowService,
} from '../../guest/services/paynow-service';
import {recentlyViewedPatientsObj} from '../../shared/model/recentlyViewed';
import {
  ACHVerification,
} from '../../account-holder/models/verify-ach-payment-method';
import FileSaver from 'file-saver';
import _ from 'lodash';
import {
  FormattedDocument,
  PFRHelloSignRequest,
  SPAHelloSignRequest,
  UnFormattedDocument,
} from '../components/models/patient-document';
import {PAYMENT_METHOD_TYPES} from '../components/models/payment-method';
import {
  admissionsAdvisorService,
} from '../../admissions-advisor/services/admissions-advisor-services';
import {APIConfig} from '../components/patient-overview/open-search-patient';
import {ClientCrm} from 'src/shared/model/client-status-card';

import {emptyVob, VobPostBody} from '../../admissions-advisor/models/vob';
import {
  EstimateRequestConfig,
  EstLevelOfCare,
  SaveEstimatorPostBody,
} from '../../admissions-advisor/models/estimator';
import {
  admissionsAdvisorUtils,
} from '../../admissions-advisor/utils/admission-advisor-utils';
import {Workflow} from '../../shared/model/client';
import {callNewEstimateGet} from 'src/admissions-advisor/state/estimator-thunk';
import { Id } from '@finpay/estimation-types';

// patient thunks
export const getPreconvertedPatients = createAsyncThunk(
    'patientContext/getPreconvertedPatients',
    async (range: string, thunkAPI) => {
        let response: AxiosResultStatus;
        let concatenatedResults: PatientEncounterCard[] = [];
        let offset = 0;
        const limit = ApiBatchLimits.patientRecordsNode;

        do {
            response = await patientService.getIOCCards({
                offset,
                limit,
                dateRange: range,
                isConverted: false,
            });

            if (response.hasErrors) {
                thunkAPI.dispatch(showErrorStatus(response.errorMessage));
                throw new Error(response.errorMessage);
            }

            concatenatedResults = [...concatenatedResults, ...response.entity];
            offset = offset + limit;
        } while (response.entity.length === limit);

        return concatenatedResults;
    }
);

export const getConvertedPatients = createAsyncThunk(
    'patientContext/getConvertedPatients',
    async (range: string, thunkAPI) => {
        let response: AxiosResultStatus;
        let concatenatedResults: PatientEncounterCard[] = [];
        let offset = 0;
        const limit = ApiBatchLimits.patientRecordsNode;

        do {
              response = await patientService.getIOCCards({
                  offset,
                  limit,
                  dateRange: range,
                  isConverted: true,
              });

            if (response.hasErrors) {
                thunkAPI.dispatch(showErrorStatus(response.errorMessage));
                throw new Error(response.errorMessage);
            }

            concatenatedResults = [...concatenatedResults, ...response.entity];
            offset = offset + limit;
        } while (response.entity.length === limit);

        return concatenatedResults;
    }
);

export const savePatient = createAsyncThunk(
    'patientContext/savePatient',
    async (
        data: {patient: PatientViewModel | BaseAPIRequiredPatient},
        thunkAPI
    ) => {
        const {patient} = data;
        const isAdd = patient.patientId === 0;

        const [patientResponse, patientEncountersResponse] = await Promise.all([
            patientService.savePatient(patient),
            !isAdd
                ? patientService.getPatientInstancesOfCare({
                      patientId: patient.patientId,
                      limit: ApiBatchLimits.patientEncounters,
                      offset: 0,
                  })
                : Promise.resolve(null),
        ]);

        let appendMessage = '';
        // this handles when there is a powerlytics error, but the patient was saved anyway.
        // in this case, we don't want to actually throw an error, just display the message, as it is more of a 'warning'.
        if (
            patientResponse?.entity?.errors &&
            patientResponse?.entity?.errors?.length > 0
        ) {
            appendMessage = `, ${patientResponse?.entity?.errors[0]?.message}`;
        }

        if (patientResponse.hasErrors) {
            patientHelper.robustErrorHandler(patientResponse, thunkAPI);
        } else if (patientEncountersResponse?.hasErrors) {
            patientHelper.robustErrorHandler(
                patientEncountersResponse,
                thunkAPI
            );
        } else {
            if (isAdd) {
                //Java returns a list of patient encounters (always null), and the UI needs the value otherwise it will break.
                patientResponse.entity.patientEncounters = null;
            } else {
                //For updates, we will call the patient encounter endpoint to fetch all encounters associated with the patientId
                patientResponse.entity.patientEncounters =
                    _.isNil(patientEncountersResponse?.entity) ||
                    _.isEmpty(patientEncountersResponse?.entity)
                        ? null
                        : patientEncountersResponse?.entity;
            }

            thunkAPI.dispatch(
                setRedirectToId(patientResponse.entity.patientId)
            );
            if (isAdd) {
                thunkAPI.dispatch(
                    showStatus('New Patient Created' + appendMessage)
                );
                await thunkAPI.dispatch(
                    setRecentlyViewedPatient({
                        patientId: patientResponse.entity.patientId,
                        encounterId: patientResponse.entity.patientEncounters,
                        type: RecentlyViewedTypes.preConvertedPatients,
                    })
                );
            } else {
                await thunkAPI.dispatch(
                    showStatus('Patient Updated' + appendMessage)
                );
            }
        }
        const patientDetails = patientResponse?.entity;
        const isMissingAddress =
            !patientDetails.contact?.primaryAddress?.addressId;
        patientResponse.entity = {
            isMissingAddress,
            patientDetails,
        };
        return patientResponse.entity;
    }
);

export const getEvaluateRule = createAsyncThunk(
    'patientContext/getEvaluateRule',
    async (instanceOfCare: PatientEncounter, thunkAPI) => {
        const response = await patientService.getEvaluateRule(instanceOfCare);

        if (response.hasErrors) {
            thunkAPI.dispatch(showErrorStatus(response.errorMessage));
            throw new Error(response.errorMessage);
        }
        return response.entity;
    }
);

export const saveInstanceOfCare = createAsyncThunk(
    'patientContext/saveInstanceOfCare',
    async (
        data: {
            encounter: PatientEncounter;
            showSnackbar?: boolean;
            isPreConverted?: boolean;
            isNewEncounter?: boolean;
        },
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as RootState;

        const currentPFRAmount =
            state?.patientContext?.selectedEncounter?.pfrAmt;

        const doesPaymentProgramExist =
            data?.encounter?.patientPaymentProgram?.length > 0 &&
            data?.encounter?.patientPaymentProgram[
                data?.encounter?.patientPaymentProgram.length - 1
            ]?.downPmtAmt >= 0;

        const currentFinClearance = data?.encounter?.finClearanceStatus ?? "New";

        data.encounter.finClearanceStatus = currentFinClearance;

        let onSuccessSetPaymentProgramUpdateError = false;

        if (
            !data.isNewEncounter &&
            currentPFRAmount !== data.encounter?.pfrAmt &&
            doesPaymentProgramExist
        ) {
            /**
             * This means that the IOC pfr amount was updated, but we also have an existing payment program
             * which has the old PFR. So we need to update the payment program.
             */
            onSuccessSetPaymentProgramUpdateError = true;
        }

        const response = await patientService.saveInstanceOfCare(
            data.encounter
        );

        if (response.hasErrors) {
            if (response.statusCode === 400) {
                thunkAPI.dispatch(showErrorStatus(response.entity));
                throw new Error(response.entity);
            } else {
                thunkAPI.dispatch(showErrorStatus(response.errorMessage));
                throw new Error(response.errorMessage);
            }
        }

        // Still need to fetch full object for put request
        // because node put response does not include updated PES Script Info

        if (data.encounter?.patientEncounterId > 0)
            thunkAPI.dispatch(
                getInstanceOfCare({
                    patientId: response.entity.patientId,
                    encounterId: response.entity.patientEncounterId,
                })
            );

        if (data.showSnackbar) {
            if (data.encounter.patientEncounterId === 0) {
                thunkAPI.dispatch(showStatus('Instance of Care Created'));
                if (data.isPreConverted === true) {
                    await thunkAPI.dispatch(
                        setRecentlyViewedPatient({
                            patientId: response.entity?.patientId,
                            encounterId: response.entity?.patientEncounterId,
                            type: RecentlyViewedTypes.preConvertedPatients,
                            isAdd: true,
                        })
                    );
                } else if (data.isPreConverted === false) {
                    await thunkAPI.dispatch(
                        setRecentlyViewedPatient({
                            patientId: response.entity?.patientId,
                            encounterId: response.entity?.patientEncounterId,
                            type: RecentlyViewedTypes.convertedPatients,
                        })
                    );
                }
            } else {
                thunkAPI.dispatch(showStatus('Instance of Care Updated'));
            }
        }
        let patientEncounters =
            Utils.deepClone(
                state?.patientContext?.selectedPatient?.patientEncounters
            ) || [];

        const indexToEdit = patientEncounters.findIndex(
            (encounter: PatientEncounter) => {
                return (
                    encounter.patientEncounterId ===
                    response.entity.patientEncounterId
                );
            }
        );

        let encounterToEdit = {} as PatientEncounter;

        if (indexToEdit > -1) {
            encounterToEdit = {
                ...patientEncounters[indexToEdit],
                ...response.entity,
            };

            patientEncounters.splice(indexToEdit, 1, encounterToEdit);
        } else {
            patientEncounters.push(response.entity);
            encounterToEdit = response.entity;
        }
        patientEncounters.sort(
            (a: PatientEncounter, b: PatientEncounter) =>
                a.patientEncounterId - b.patientEncounterId
        );

        return {
            allPatientEncounters: patientEncounters,
            selectedPatientEncounter: encounterToEdit,
            setPaymentProgramError: onSuccessSetPaymentProgramUpdateError,
        };
    }
);

export const lockIOC = createAsyncThunk(
    'patientContext/lockIOC',
    async (
        data: {lock: boolean; username?: string; lockRequestedByUser?: string},
        thunkAPI
    ) => {
        const {lock, username} = data; // if lock = true; lock IOC. if false, ioc will be unlocked.

        const state = thunkAPI.getState() as RootState;
        let selectedEncounter = Utils.deepClone(
            state?.patientContext?.selectedEncounter
        );

        const response = await patientService.saveIOCWithPartialObject(
            {isLocked: lock, lockedByUserId: username || null},
            selectedEncounter.patientEncounterId
        );

        if (response.hasErrors) {
            patientHelper.robustErrorHandler(response, thunkAPI);
        }

        return {
            selectedEncounter: selectedEncounter,
        };
    }
);

export const getVOBAndEstimateForFinpass = createAsyncThunk(
    'patientContext/getVOBAndEstimate',
    async (data: {estimateId: number}, thunkAPI) => {
        //working backwards from the estimateId to fetch the correct vob

        const state = thunkAPI.getState() as RootState;

        const estimateResponse = await admissionsAdvisorService.getEstimate(
            0,
            state.patientContext.selectedEncounter.pfrEstimateId!
        );
        if (estimateResponse?.hasErrors) {
            patientHelper.robustErrorHandler(estimateResponse, thunkAPI);
        }

        const vobResponse = await admissionsAdvisorService.getVob(
            0,
            estimateResponse.entity.vobId
        );
        if (vobResponse?.hasErrors) {
            patientHelper.robustErrorHandler(vobResponse, thunkAPI);
        }

        return {
          vob: vobResponse.entity,
          estimate: estimateResponse.entity
        };
    }
);

export const getVOB = createAsyncThunk(
    'patientContext/getVOB',
    async (vobId: Id, thunkAPI) => {
        const response = await admissionsAdvisorService.getVob(0, vobId as number);
        if (response?.hasErrors) {
            patientHelper.robustErrorHandler(response, thunkAPI);
        }
        return response.entity;
    }
);

export const getVOBAndNewEstimateForFinpass = createAsyncThunk(
  'patientContext/getVOBAndNewEstimateForFinpass',
  async (data: {estimateId: number}, thunkAPI) => {
      //working backwards from the estimateId to fetch the correct vob
      const state = thunkAPI.getState() as RootState;
      const config: EstimateRequestConfig = {
        paramId: -2,
        estimationId: data.estimateId
      }
      const estimateResponse = await thunkAPI.dispatch(callNewEstimateGet(config));
      const vobResponse = await admissionsAdvisorService.getVob(
          0,
          estimateResponse.payload.vobId
      );
      if (vobResponse?.hasErrors) {
          patientHelper.robustErrorHandler(vobResponse, thunkAPI);
      }

      return {
        vob: vobResponse.entity,
        estimate: estimateResponse.payload
      };
  }
);

// put vob
export const saveVOBForFinpass = createAsyncThunk(
    'patientContext/saveVOB',
    async (params: {vob: VobPostBody}, thunkAPI) => {
        const {vob} = params;
        
        const vobId = vob.vobBody.vobId as number;

        const isUpdate = !!vob?.vobBody.vobId;
        
        const vobResponse = await admissionsAdvisorService.updateVob(vobId, vob);

        if (vobResponse?.hasErrors) {
            patientHelper.robustErrorHandler(vobResponse, thunkAPI);
        } else {
          thunkAPI.dispatch(showStatus(isUpdate ? 'VOB Updated' : 'VOB Created'));
        }

        return vobResponse.entity;
    }
);

// post vob
export const postVob = createAsyncThunk(
    'vobContext/postVob',
    async (vob: VobPostBody, thunkAPI) => {
      const response = await admissionsAdvisorService.newVob(vob)
    
      if (response.hasErrors) {
        patientHelper.robustErrorHandler(response, thunkAPI);
      }
      return response.entity;
    }
);

export const getInstanceOfCare = createAsyncThunk(
  "patientContext/getInstanceOfCare",
  async (data: { patientId: number; encounterId: number }, thunkAPI) => {
    const response = await patientService.getPatientInstanceOfCare(data);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    const state = thunkAPI.getState() as RootState;
    
    if (
      state.patientContext?.scriptWindowObjectReference?.length > 0 &&
      response.entity?.client?.pesScripts?.length > 0
    ) {
      state.patientContext.scriptWindowObjectReference[0].location.href =
        response.entity.client.pesScripts[0].scriptUrl;
    }

    let patientEncounters =
      Utils.deepClone(
        state?.patientContext?.selectedPatient?.patientEncounters || []
      ) || [];

    const indexToEdit = patientEncounters.findIndex(
      (encounter: PatientEncounter) => {
        return (
          encounter?.patientEncounterId === response.entity?.patientEncounterId
        );
      }
    );

    let encounterToEdit;

    // format and sort the documents
    const formattedAuthorizationDocuments =
      patientHelper.processAuthorizationDocuments(
        response.entity?.authorizationDocumentStatus,
        response.entity?.patientDocument
      );
    
    if (indexToEdit > -1) {
      encounterToEdit = {
        ...response.entity,
        authorizationDocumentStatus: formattedAuthorizationDocuments,
      };
      patientEncounters.splice(indexToEdit, 1, encounterToEdit);
    } else {
      patientEncounters.push(response.entity);
      encounterToEdit = response.entity;
    }

    const blankEncounterIndex = patientEncounters.findIndex(
      (encounter: PatientEncounter) => {
        // remove blank encounter
        return (
          encounter?.patientEncounterId ===
          emptyPatientEncounter?.patientEncounterId
        );
      }
    );

    if (blankEncounterIndex > -1) {
      patientEncounters.splice(blankEncounterIndex, 1);
    }

    patientEncounters.sort(
      (a: PatientEncounter, b: PatientEncounter) =>
        a?.patientEncounterId - b?.patientEncounterId
    );

    return {
      allPatientEncounters: patientEncounters,
      selectedPatientEncounter: encounterToEdit,
    };
  }
);

export const getRiskClass = createAsyncThunk(
  "patientContext/getRiskClass",
  async (
    data: {
      facilityId: number;
      paidToPatient: boolean;
      timingRiskName: string;
      payorRiskName: string;
      pfrAmt: number;
    },
    thunkAPI
  ) => {
    const response = await patientService.getPatientEncounterRiskClass(
      data.facilityId,
      data.paidToPatient,
      data.timingRiskName,
      data.payorRiskName,
      data.pfrAmt
    );
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      return;
    }
    return response.entity;
  }
);

export const getEncounterSignUrl = createAsyncThunk(
  "patientContext/getEncounterSignUrl",
  async (data: { patientId: number; encounterId: number }, thunkAPI) => {
    const response = await patientService.getPatientEncounterSignUrl(data);
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
    }
    return response.entity;
  }
);

export const getForwardedSignUrl = createAsyncThunk(
  "patientContext/getForwardedSignUrl",
  async (helloSignRequestId: string, thunkAPI) => {
    const response = await patientService.getForwardedSignUrl(
      helloSignRequestId
    );
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }

    return response.entity;
  }
);

export const saveInsurance = createAsyncThunk(
  "patientContext/saveInsurance",
  async (
    data: {
      insurance: PatientInsurance;
      patientId: number;
      encounterId: number;
    },
    thunkAPI
  ) => {
    const response = await patientService.saveInsurance(data);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    } else {
      if (data.insurance.patientInsuranceId === 0) {
        thunkAPI.dispatch(showStatus("Insurance Created"));
      } else {
        thunkAPI.dispatch(showStatus("Insurance Updated"));
      }
    }

    const isAdd = data.insurance.patientInsuranceId === 0;
    const state = thunkAPI.getState() as RootState;
    let encounters = Utils.deepClone(
      state.patientContext.selectedPatient.patientEncounters
    );
    let selectedEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    );
    const encounterIndexToUpdate =
      encounters &&
      encounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId ===
          state.patientContext.selectedEncounter.patientEncounterId
      );
    if (isAdd) {
      // add the insurance to both the selectedEncounter and the encounters.
      if (selectedEncounter.patientInsurance) {
        // if the insurance is not 'null'
        selectedEncounter.patientInsurance.unshift(response.entity);
      } else {
        selectedEncounter.patientInsurance = [response.entity]; // init insurance array
      }
      if (encounters && encounterIndexToUpdate >= 0) {
        encounters[encounterIndexToUpdate] = selectedEncounter; // use the encounter we just updated to update the encounters list
      }
    } else {
      // edit
      if (encounters && encounterIndexToUpdate >= 0) {
        const insuranceIndexToUpdate = encounters[
          encounterIndexToUpdate
        ].patientInsurance?.findIndex(
          (item: PatientInsurance) =>
            item.patientInsuranceId === response.entity.patientInsuranceId
        );

        if (
          insuranceIndexToUpdate >= 0 &&
          selectedEncounter.patientInsurance[insuranceIndexToUpdate]
        ) {
          selectedEncounter.patientInsurance[insuranceIndexToUpdate] =
            response.entity;
        }
        encounters[encounterIndexToUpdate].patientInsurance[
          insuranceIndexToUpdate
        ] = response.entity;
      }
    }

    const sortedEncounters = encounters.sort(
      (a: PatientEncounter, b: PatientEncounter) =>
        a.patientEncounterId - b.patientEncounterId
    );

    response.entity.newSelectedEncounter = Utils.deepClone(selectedEncounter);
    response.entity.newEncounters = Utils.deepClone(sortedEncounters);

    return response.entity;
  }
);
export const savePatientChampion = createAsyncThunk(
  "patientContext/savePatientChampion",
  async (
    data: { champion: PatientChampion; patientId: number; encounterId: number },
    thunkAPI
  ) => {
    const response = await patientService.savePatientChampion(data);

    let appendMessage = "";
    // this handles when there is a powerlytics error, but the champion was saved anyway.
    // in this case, we don't want to actually throw an error, just display the message, as it is more of a 'warning'.
    if (response?.entity?.errors && response?.entity?.errors?.length > 0) {
      appendMessage = `, ${response?.entity?.errors[0]?.message}`;
    }

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    } else {
      if (data.champion.patientChampionId === 0) {
        thunkAPI.dispatch(
          showStatus("Patient Champion Created" + appendMessage)
        );
      } else {
        thunkAPI.dispatch(
          showStatus("Patient Champion Updated" + appendMessage)
        );
      }
    }

    const isAdd = data.champion.patientChampionId === 0;
    const state = thunkAPI.getState() as RootState;
    let encounters = Utils.deepClone(
      state.patientContext.selectedPatient.patientEncounters
    );
    let selectedEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    );
    const encounterIndexToUpdate =
      encounters &&
      encounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId ===
          state.patientContext.selectedEncounter.patientEncounterId
      );
    if (isAdd) {
      // add the champion to both the selectedEncounter and the encounters.
      if (selectedEncounter.patientChampion) {
        // if the champion is not 'null'
        selectedEncounter.patientChampion.push(response.entity);
      } else {
        selectedEncounter.patientChampion = [response.entity]; // init champion array
      }
      if (encounters && encounterIndexToUpdate >= 0) {
        encounters[encounterIndexToUpdate] = selectedEncounter; // use the encounter we just updated to update the encounters list
      }
    } else {
      // edit
      if (encounters && encounterIndexToUpdate >= 0) {
        const championIndexToUpdate = encounters[
          encounterIndexToUpdate
        ].patientChampion?.findIndex(
          (item: PatientChampion) =>
            item.patientChampionId === response.entity.patientChampionId
        );

        if (
          championIndexToUpdate >= 0 &&
          selectedEncounter.patientChampion[championIndexToUpdate]
        ) {
          selectedEncounter.patientChampion[championIndexToUpdate] =
            response.entity;
        }
        encounters[encounterIndexToUpdate].patientChampion[
          championIndexToUpdate
        ] = response.entity;
      }
    }

    const sortedEncounters = encounters.sort(
      (a: PatientEncounter, b: PatientEncounter) =>
        a.patientEncounterId - b.patientEncounterId
    );

    response.entity.newSelectedEncounter = Utils.deepClone(selectedEncounter);
    response.entity.newEncounters = Utils.deepClone(sortedEncounters);

    return response.entity;
  }
);

export const savePfrAdjustment = createAsyncThunk(
  "patientContext/savePfrAdjustment",
  async (adjustment: PFRAdjustment, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let selectedEncounter = Utils.deepClone(
        state.patientContext.selectedEncounter
    );
    
    const patientId = adjustment?.patientId;
    const encounterId = adjustment?.patientEncounterId;
    
    if (!adjustment?.patientPFRId) {
      // assign patientPfrId value to missing payload key
      adjustment.patientPFRId = selectedEncounter.patientPfr?.patientPfrId;
    }

    // adjust PFR amount
    const response = await patientService.savePaymentAdjustment(adjustment);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    const currentPaymentProgramId = selectedEncounter.patientPaymentProgram[0]?.patientPaymentProgramId;
    const program = await paymentService.updatePaymentProgram(currentPaymentProgramId);

    if(program.entity.patientPaymentSchedule && program.entity.patientPaymentSchedule.pfrPendingBalance < 0){
      const trxResponse = await patientService.getTransactions(
        patientId,
        encounterId
      );
      if (trxResponse.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
        throw new Error(response.errorMessage);
      }
      const paymentsList = trxResponse.entity?.filter((transactionsResponse: any) =>
        transactionsResponse?.payment).sort((a:any,b:any)=> Date.parse(b.payment.paymentInitDt) - Date.parse(a.payment.paymentInitDt))
      let remainingBalance = program.entity.patientPaymentSchedule.pfrPendingBalance
      for(let i = 0; i < paymentsList.length; i++){
        let payment = paymentsList[i].payment;
        if(payment.isCaptured && !payment.ach && !payment.isFullyRefunded){
          if(payment.paymentAmt + remainingBalance < 0 ){
             await paymentService.createRefund(payment.paymentId, encounterId, payment.patientPaymentScheduleId,payment.paymentAmt);
             remainingBalance += payment.paymentAmt;
             if(remainingBalance == 0) break;
          }else{
              await paymentService.createRefund(payment.paymentId, encounterId, payment.patientPaymentScheduleId, Math.abs(remainingBalance));
              break;
          }
        }
      }
      await patientService.saveStatus({
        // @ts-ignore
        workFlow: {
          workflowId: 3,
          workflowStatus: {
            workflowStatusId: 16,
            workflowStatusDesc: '',
          },
          workflowSubStatus: {
            workflowSubStatusId: 23,
            workflowSubStatusDesc: '',
          },
        },
        encounterId,
        patientId,
      })

    }

    // TODO: Need to simplify this and not require the entire IOC to be passed. And just update the changed state values
    const freshIOC = await patientService.getPatientInstanceOfCare({
      patientId,
      encounterId,
    });
    const encounter: PatientEncounter = freshIOC.entity;

    const oldEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    );
    encounter.authorizationDocumentStatus =
      oldEncounter.authorizationDocumentStatus;
    let encounters = [
      ...state.patientContext.selectedPatient.patientEncounters,
    ];

    const encounterToReplaceIndex = encounters.findIndex((encounter) => {
      return (
        encounter.patientEncounterId === response.entity.patientEncounterId
      );
    });

    if (encounterToReplaceIndex > -1) {
      encounters.splice(encounterToReplaceIndex, 1, encounter);
    }

    response.entity = {
      allEncounters: encounters,
      encounter: encounter,
    };

    return response.entity;
  }
);

export const savePatientPaymentProgram = createAsyncThunk(
  "patientContext/savePatientPaymentProgram",
  async (
    data: {
      paymentProgram: PatientPaymentProgram;
      patientId: number;
      encounterId: number;
      showNotify?: boolean; 
    },
    thunkAPI
  ) => {
    const { showNotify = true, ...rest } = data; // Destructure showNotify with default value

    const response = await patientService.savePatientPaymentProgram(data);

    const isAdd = data.paymentProgram.patientPaymentProgramId === 0;
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    if (showNotify === true) {
      if (isAdd) {
        thunkAPI.dispatch(showStatus("Patient Payment Program Created"));
      } else {
        thunkAPI.dispatch(showStatus("Patient Payment Program Updated"));
      }
    }

    const state = thunkAPI.getState() as RootState;

    let patientEncounters: PatientEncounter[] = []

    //fps-8892 : The state sometimes returns an empty object for selectedPatient
    if(state.patientContext?.selectedPatient?.patientEncounters){
      patientEncounters= Utils.deepClone(
          state.patientContext.selectedPatient.patientEncounters
      );
    }

    const selectedEncounter: PatientEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    );
    const encounterIndexToUpdate =
      patientEncounters &&
      patientEncounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId === selectedEncounter.patientEncounterId
      );

    const updatedPaymentSchedule = {
      ...response.entity.patientPaymentSchedule,
      patientEncounterId: selectedEncounter?.patientEncounterId,
      patientId: selectedEncounter?.patientId,
    };

    const updatedPaymentProgram = {
      // handle the case where the api might be missing data
      ...response.entity,
      patientPaymentSchedule: updatedPaymentSchedule,
    };

    //fps-8892 : state.patientContext.selectedEncounter might be empty so encounterIndexToUpdate might be -1
    if(isAdd){
      selectedEncounter.patientPaymentProgram.push(response.entity)
      if(encounterIndexToUpdate) {
        _.set(patientEncounters[encounterIndexToUpdate], `patientEncounters[${encounterIndexToUpdate}].patientPaymentProgram`, [response.entity])
      }else{
        patientEncounters.push(selectedEncounter)
      }
    }else{
      selectedEncounter.patientPaymentProgram.push(updatedPaymentProgram);
      if(encounterIndexToUpdate){
        _.set(patientEncounters[encounterIndexToUpdate], `patientEncounters[${encounterIndexToUpdate}].patientPaymentProgram`, [updatedPaymentProgram])
      }else{
        patientEncounters.push(selectedEncounter)
      }
    }

    patientEncounters.sort(
      (a, b) => a.patientEncounterId - b.patientEncounterId
    );

    return {
      allPatientEncounters: patientEncounters,
      selectedPatientEncounter: selectedEncounter,
    };
  }
);

export const putProgram = createAsyncThunk(
  "patientContext/putProgram",
  async (
    data: {
      patientPaymentProgramId: number,
      paymentProgram: any;
      updateState?: boolean;
    },
    thunkAPI
  ) => {
    const { updateState = false, ...rest } = data; // Destructure showNotify with default value

    const response = await paynowService.updatePaymentProgram(data.patientPaymentProgramId, data.paymentProgram ?? {});


    if (!updateState) return false;

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    const state = thunkAPI.getState() as RootState;

    const patientEncounters: PatientEncounter[] = Utils.deepClone(
      state.patientContext.selectedPatient.patientEncounters
    );

    const selectedEncounter: PatientEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    );
    const encounterIndexToUpdate =
      patientEncounters &&
      patientEncounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId === selectedEncounter.patientEncounterId
      );

    const updatedPaymentSchedule = {
      ...response.entity.patientPaymentSchedule,
      patientEncounterId: selectedEncounter?.patientEncounterId,
      patientId: selectedEncounter?.patientId,
    };

    const updatedPaymentProgram = {
      // handle the case where the api might be missing data
      ...response.entity,
      patientPaymentSchedule: updatedPaymentSchedule,
    };
    patientEncounters[encounterIndexToUpdate].patientPaymentProgram = [
      response.entity,
    ];
    selectedEncounter.patientPaymentProgram = [response.entity];

    patientEncounters[encounterIndexToUpdate].patientPaymentProgram = [
      updatedPaymentProgram,
    ];
    selectedEncounter.patientPaymentProgram = [updatedPaymentProgram];

    patientEncounters.sort(
      (a, b) => a.patientEncounterId - b.patientEncounterId
    );

    return {
      allPatientEncounters: patientEncounters,
      selectedPatientEncounter: selectedEncounter,
    };
  }
);

export const updateContactInfo = createAsyncThunk(
  "patientContext/saveContactInfo",
  async (
    data: {
      patientContact: patientEncounterContact;
      patientId: number;
      encounterId: number;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const response = await patientService.updateContactInfo(
      data.patientId,
      data.encounterId,
      data.patientContact
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    const patientEncounters: PatientEncounter[] = Utils.deepClone(
      state.patientContext.selectedConvertedEncounters
    );
    const encounterIndexToUpdate =
      patientEncounters &&
      patientEncounters.findIndex(
        (item: PatientEncounter) => item.patientEncounterId === data.encounterId
      );
    patientEncounters[encounterIndexToUpdate].patientContactInfo =
      response.entity;
    return {
      selectedConvertedEncounters: patientEncounters,
    };
  }
);

export const deletePatientPaymentProgram = createAsyncThunk(
  "patientContext/deletePatientPaymentProgram",
  async (data: deletePatientPaymentProgramModel, thunkAPI) => {
    const response = await patientService.deletePatientPaymentProgram(data);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    thunkAPI.dispatch(showStatus("Patient Payment Deleted"));

    const state = thunkAPI.getState() as RootState;

    const patientEncounters: PatientEncounter[] = Utils.deepClone(
      state.patientContext.selectedPatient.patientEncounters
    );
    const selectedEncounter: PatientEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    );

    const encounterIndexToUpdate =
      patientEncounters &&
      patientEncounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId === selectedEncounter.patientEncounterId
      );

    if (encounterIndexToUpdate > -1) {
      patientEncounters[encounterIndexToUpdate].patientPaymentProgram = [
        response.entity,
      ];
    }

    selectedEncounter.patientPaymentProgram = [response.entity];

    return {
      allPatientEncounters: patientEncounters,
      selectedPatientEncounter: selectedEncounter,
    };
  }
);

export const deletePatientPaymentMethod = createAsyncThunk(
  "patientContext/deletePatientPaymentMethod",
  async (
    data: {
      patientId: number;
      encounterId: number;
      stripeId: string;
      paymentTypeId: number;
      isAccountHolder?: boolean;
    },
    thunkAPI
  ) => {
    const { patientId, encounterId, stripeId, paymentTypeId, isAccountHolder } =
      data;
    const paymentTypeString = paymentTypeId === 1 ? "DOWN" : "RECURRING";
    const response = await patientService.deletePatientPaymentMethod(
      patientId,
      encounterId,
      stripeId,
      paymentTypeId
    );

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    thunkAPI.dispatch(showStatus("Payment Method Deleted"));

    const state = thunkAPI.getState() as RootState;

    if (isAccountHolder) {
      const newSelectedEncounter = Utils.deepClone(
        state.patientContext.currentConvertedEncounter
      );
      let encounters = Utils.deepClone(
        state.patientContext.selectedConvertedEncounters
      ); // deep copy
      let paymentMethods = Utils.deepClone(
        newSelectedEncounter?.patientPaymentMethods
      );

      const paymentMethodIndexToDelete =
        paymentMethods?.length > 0 &&
        paymentMethods.findIndex(
          (item: StripePaymentMethod) =>
            item?.id &&
            item?.id === stripeId &&
            item?.metadata?.metaData_paymentMethodType?.includes(
              paymentTypeString
            )
        );

      if (paymentMethods.length > 1) {
        paymentMethods.splice(paymentMethodIndexToDelete, 1);
      } else if (paymentMethods.length === 1) {
        paymentMethods = paymentMethods.pop();
      }
      newSelectedEncounter.patientPaymentMethods = paymentMethods;

      const encounterIndexToUpdate =
        encounters &&
        encounters.findIndex(
          (item: PatientEncounter) =>
            item.patientEncounterId ===
            state.patientContext.currentConvertedEncounter.patientEncounterId
        );
      if (encounters && encounterIndexToUpdate >= 0) {
        encounters[encounterIndexToUpdate] = newSelectedEncounter; // use the encounter we just updated to update the encounters list
      }
      response.entity = {
        newSelectedEncounter: Utils.deepClone(newSelectedEncounter),
        newEncounters: Utils.deepClone(encounters),
        isAccountHolder: isAccountHolder,
      };
    } else {
      let encounters = Utils.deepClone(
        state.patientContext.selectedPatient.patientEncounters
      ); // deep copy
      let selectedEncounter = Utils.deepClone(
        state.patientContext.selectedEncounter
      ); // deep copy
      let paymentMethods = Utils.deepClone(
        state.patientContext.selectedEncounter.patientPaymentMethods
      );

      const encounterIndexToUpdate =
        encounters &&
        encounters.findIndex(
          (item: PatientEncounter) =>
            item.patientEncounterId ===
            state.patientContext.selectedEncounter.patientEncounterId
        );
      const paymentMethodIndexToDelete =
        paymentMethods?.length > 0 &&
        paymentMethods.findIndex(
          (item: StripePaymentMethod) =>
            item?.id &&
            item?.id === stripeId &&
            item?.metadata?.metaData_paymentMethodType?.includes(
              paymentTypeString
            )
        );

      if (paymentMethods.length > 1) {
        paymentMethods.splice(paymentMethodIndexToDelete, 1);
      } else if (paymentMethods.length === 1) {
        paymentMethods = paymentMethods.pop();
      }

      selectedEncounter.patientPaymentMethods = paymentMethods;
      encounters[encounterIndexToUpdate].patientPaymentMethods = paymentMethods;

      const sortedEncounters = encounters.sort(
        (a: PatientEncounter, b: PatientEncounter) =>
          a.patientEncounterId - b.patientEncounterId
      );

      response.entity = {
        newSelectedEncounter: Utils.deepClone(selectedEncounter),
        newEncounters: Utils.deepClone(sortedEncounters),
      };
    }

    return response.entity;
  }
);

export const getPatientDemographics = createAsyncThunk(
  "patientContext/getPatientDemographics",
  async (patientId: number, thunkAPI) => {
    const response = await Promise.all([
      patientService.getPatient(patientId),
      patientService.getPatientInstancesOfCare({
        patientId,
        limit: ApiBatchLimits.patientEncounters,
        offset: 0,
      }),
    ]);

    response.forEach((resolvedPromise: Awaited<AxiosResultStatus>, index) => {
      if (resolvedPromise.hasErrors) {
        if (resolvedPromise.statusCode === 404 && index === 0) {
          //In this context, we are assuming a 404 is due to the failed data boundary check
          resolvedPromise.entity = emptyPatientViewModel;
        } else {
          thunkAPI.dispatch(showErrorStatus(resolvedPromise.entity));
          throw new Error(resolvedPromise.errorMessage);
        }
      }
    });

    const [patientResponse, patientEncounterResponse] = response;

    const patientDetails: PatientViewModel = {
      ...patientResponse.entity,
      patientEncounters:
        patientEncounterResponse.entity.length > 0
          ? [...patientEncounterResponse.entity]
          : null,
    };

    const isMissingAddress =
      !patientDetails?.contact?.primaryAddress?.addressId;

    return {
      isMissingAddress,
      patientDetails,
    };
  }
);

export const getDocuments = createAsyncThunk(
  "patientContext/getDocuments",
  async (data: { patientId: number; encounterId: number }, thunkAPI) => {
    const response = await patientService.getDocuments(
      data.patientId,
      data.encounterId
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    const state = thunkAPI.getState() as RootState;
    const selectedEncounter = { ...state.patientContext.selectedEncounter };
    const patientEncounters = [
      ...state.patientContext.selectedPatient.patientEncounters,
    ];

    selectedEncounter.patientDocument = response.entity;

    const patientEncounterIndexToReplace = patientEncounters.findIndex(
      (encounter) => {
        return (
          encounter.patientEncounterId === selectedEncounter.patientEncounterId
        );
      }
    );

    if (patientEncounterIndexToReplace > -1) {
      patientEncounters.splice(
        patientEncounterIndexToReplace,
        1,
        selectedEncounter
      );
    }

    response.entity = {
      selectedEncounter,
      patientEncounters,
    };
    return response.entity;
  }
);

export const getPaymentMethods = createAsyncThunk(
  "patientContext/getPaymentMethods",
  async (
    data: {
      patientId: number;
      encounterId: number;
      isAccountHolder?: boolean;
      supressErrors?: boolean;
    },
    thunkAPI
  ) => {
    const response = await patientService.getPaymentMethods(
      data.patientId,
      data.encounterId
    );
    const { isAccountHolder, supressErrors } = data;
    if (response.hasErrors) {
      if (!supressErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      } else {
        updateApplicationStatus(response.errorMessage);
      }
      throw new Error(response.errorMessage);
    }

    const state = thunkAPI.getState() as RootState;

    if (isAccountHolder) {
      const selectedEncounter = Utils.deepClone(
        state.patientContext.currentConvertedEncounter
      );
      const paymentMethods = response.entity?.data?.map(
        (paymentMethod: StripePaymentMethod) => {
          return {
            ...paymentMethod,
            name: paymentMethod?.metadata["metaData_nameOnCard"],
          };
        }
      );

      selectedEncounter.patientPaymentMethods = paymentMethods;
      const patientEncounters = Utils.deepClone(
        state.patientContext.selectedConvertedEncounters
      );

      const encounterIndexToUpdate =
        patientEncounters &&
        patientEncounters.findIndex(
          (item: PatientEncounter) =>
            item.patientEncounterId ===
            state.patientContext.currentConvertedEncounter.patientEncounterId
        );
      if (patientEncounters && encounterIndexToUpdate >= 0) {
        patientEncounters[encounterIndexToUpdate] =
          Utils.deepClone(selectedEncounter); // use the encounter we just updated to update the encounters list
      }

      response.entity = {
        selectedEncounter,
        patientEncounters,
        isAccountHolder,
      };
    } else {
      const selectedEncounter = { ...state.patientContext.selectedEncounter };
      const patientEncounters = [
        ...state.patientContext.selectedPatient.patientEncounters,
      ];
      const filteredPaymentMethods = response.entity?.data
        ?.filter((paymentMethod: StripePaymentMethod) => {
          return !paymentMethod?.metadata?.metaData_encounterId
            ? true
            : paymentMethod?.metadata["metaData_encounterId"] ===
                data?.encounterId?.toString();
        })
        .map((paymentMethod: StripePaymentMethod) => {
          return {
            ...paymentMethod,
            name: paymentMethod?.metadata["metaData_nameOnCard"],
          };
        });

      selectedEncounter.patientPaymentMethods = filteredPaymentMethods;

      const patientEncounterIndexToReplace = patientEncounters.findIndex(
        (encounter) => {
          return (
            encounter.patientEncounterId ===
            selectedEncounter.patientEncounterId
          );
        }
      );

      if (patientEncounterIndexToReplace > -1) {
        patientEncounters.splice(
          patientEncounterIndexToReplace,
          1,
          selectedEncounter
        );
      }

      response.entity = {
        selectedEncounter,
        patientEncounters,
      };
    }

    return response.entity;
  }
);

export const setUpEncountersAccountHolder = createAsyncThunk(
  "patientContext/setUpEncountersAccountHolder",
  async (
    data: {
      isActive: boolean;
      isCompleted: boolean;
      isCancelled: boolean;
      convertedPatientEncounters: PatientEncounterCard[];
    },
    thunkAPI
  ) => {
    const { isActive, isCompleted, isCancelled, convertedPatientEncounters } =
      data;
    const state = thunkAPI.getState() as RootState;
    const encountersList: PatientEncounter[] = []; // general list of all encounters
    const activeEncounters: PatientEncounter[] = []; // active encounters
    const completedEncounters: PatientEncounter[] = []; // completed encounters
    const cancelledEncounters: PatientEncounter[] = []; // cancelled encounters
    let currentSelectedEncounters: PatientEncounter[] = []; // encounters from the current tab
    const selectedPatient = Utils.deepClone(
      state.patientContext.selectedPatient
    );
    // loop through all the encounters and fetch each IOC. Once we have the IOC, fetch the payment method.
    // Then, take the Patient contact card and id, map them to the encounter, map the payment method to the encounter,
    // and then sort the whole list into 'active / completed / cancelled' statuses.
    for (let i = 0; i < convertedPatientEncounters.length; i++) {
      const patientEncounter = convertedPatientEncounters[i];
      // GET IOCS
      const IOC = await patientService.getPatientInstanceOfCare({
        patientId: patientEncounter.patientId,
        encounterId: patientEncounter.patientEncounterId,
      });

      if (IOC.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(IOC.errorMessage));
        throw new Error(IOC.errorMessage);
      }
      const IOCResult: PatientEncounter = IOC.entity;

      // format and sort the documents
      IOCResult.authorizationDocumentStatus = patientHelper.processAuthorizationDocuments(
          IOCResult?.authorizationDocumentStatus as unknown as UnFormattedDocument[],
          IOCResult?.patientDocument
      )

      // GET Transactions for the IOC
      let transactions = await patientService.getTransactions(
        IOCResult?.patientId,
        IOCResult?.patientEncounterId
      );

      if (transactions.hasErrors) {
        patientHelper.robustErrorHandler(transactions, thunkAPI);
      }
      IOCResult.patientTransactions = transactions.entity;
      const responseAsPayments = transactions.entity
        ?.filter(
          (transactionsResponse: any) =>
            transactionsResponse?.payment ||
            transactionsResponse?.paymentReversal ||
            transactionsResponse?.paymentDispute
        )
        ?.map((transaction: any) => ({
          payment: transaction?.payment,
          paymentReversal: transaction?.paymentReversal,
          paymentDispute: transaction?.paymentDispute,
        }));
      const pfrAdjustments = transactions.entity
        ?.filter(
          (transactionsResponse: any) =>
            transactionsResponse?.patientPFRAdjustment
        )
        ?.map((transaction: any) => transaction?.patientPFRAdjustment || {});
      const paymentDisputes = transactions.entity
        ?.filter(
          (transactionsResponse: any) => transactionsResponse?.paymentDispute
        )
        ?.map((transaction: any) => transaction?.paymentDispute || {});
      const adjustedPFR = patientHelper.calculateReadjustedPfr(
        IOCResult?.pfrAmt,
        pfrAdjustments || []
      );
      const groupedTransactions = responseAsPayments
        ? patientHelper.consolidateTransactions(responseAsPayments, true)
        : [];
      const PFR =
        adjustedPFR || adjustedPFR === 0 ? adjustedPFR : IOCResult?.pfrAmt;
      const calculatedRemainingBalance =
        patientHelper.calculateRemainingBalance(groupedTransactions, PFR);
      const doPFRAdjustmentsExist = !!(
        pfrAdjustments.length > 0 && pfrAdjustments[0]?.adjustmentAmt
      );
      const doDisputesExist = !!(
        paymentDisputes?.length > 0 && paymentDisputes[0]?.paymentDisputeId
      );

      IOCResult.patientPfrAdjustments = pfrAdjustments || [];
      IOCResult.paymentDisputes = paymentDisputes || [];
      IOCResult.adjustedPFR = adjustedPFR;
      IOCResult.doPFRAdjustmentsExist = doPFRAdjustmentsExist;
      IOCResult.doDisputesExist = doDisputesExist;
      IOCResult.calculatedRemainingBalance = calculatedRemainingBalance;

      // GET Payment Methods for the IOC
      let paymentMethods = await patientService.getPaymentMethods(
        IOCResult?.patientId,
        IOCResult?.patientEncounterId
      );
      if (paymentMethods.hasErrors) {
        patientHelper.robustErrorHandler(paymentMethods, thunkAPI);
      }
      // put payment methods and patient/contact info onto the IOC
      IOCResult.patientPaymentMethods = paymentMethods.entity?.data.map(
        (paymentMethod: StripePaymentMethod) => {
          return {
            ...paymentMethod,
            name: paymentMethod.metadata["metaData_nameOnCard"],
          };
        }
      );

      let documents = await patientService.getDocuments(
        IOCResult?.patientId,
        IOCResult?.patientEncounterId
      );
      if (documents.hasErrors) {
        patientHelper.robustErrorHandler(documents, thunkAPI);
      }
      IOCResult.patientDocument = documents.entity;

      let contactInfo = await patientService.getContactInfo(
        IOCResult?.patientId,
        IOCResult?.patientEncounterId
      );
      if (contactInfo.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(contactInfo.errorMessage));
        throw new Error(contactInfo.errorMessage);
      }
      IOCResult.patientContactInfo = contactInfo.entity;

      const IOCWorkflowId =
        IOCResult?.workflow?.workflowStatus?.workflowStatusId;

      if (IOCWorkflowId === 16) {
        completedEncounters.push(IOCResult);
      } else if (IOCWorkflowId === 7) {
        cancelledEncounters.push(IOCResult);
      } else if (![16, 7].includes(IOCWorkflowId)) {
        activeEncounters.push(IOCResult);
      }
      encountersList.push(IOCResult);
    }
    if (convertedPatientEncounters && convertedPatientEncounters?.length > 0) {
      selectedPatient.contact = convertedPatientEncounters[0]?.contactCard;
      selectedPatient.patientId = convertedPatientEncounters[0]?.patientId;
    }

    if (isActive) {
      currentSelectedEncounters = Utils.deepClone(
        activeEncounters?.sort(
          (a: PatientEncounter, b: PatientEncounter) =>
            a.patientEncounterId - b.patientEncounterId
        )
      );
    } else if (isCompleted) {
      currentSelectedEncounters = Utils.deepClone(
        completedEncounters?.sort(
          (a: PatientEncounter, b: PatientEncounter) =>
            a.patientEncounterId - b.patientEncounterId
        )
      );
    } else if (isCancelled) {
      currentSelectedEncounters = Utils.deepClone(
        cancelledEncounters?.sort(
          (a: PatientEncounter, b: PatientEncounter) =>
            a.patientEncounterId - b.patientEncounterId
        )
      );
    }

    return {
      currentSelectedEncounters,
      activeEncounters,
      completedEncounters,
      cancelledEncounters,
      selectedPatient,
      encountersList,
    };
  }
);

// getTransactions call 1

export const getTransactions = createAsyncThunk(
  "patientContext/getTransactions",
  async (
    data: {
      patientId: number;
      encounterId: number;
      isAccountHolder?: boolean;
      supressErrors?: boolean;
    },
    thunkAPI
  ) => {
    const { isAccountHolder, supressErrors } = data;

    const response = await patientService.getTransactions(
      data.patientId,
      data.encounterId
    );

    if (response.hasErrors) {
      if (!supressErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      } else {
        updateApplicationStatus(response.errorMessage);
      }
      throw new Error(response.errorMessage);
    }

    const responseAsPayments = response.entity
      ?.filter(
        (transactionsResponse: any) =>
          transactionsResponse?.payment ||
          transactionsResponse?.paymentReversal ||
          transactionsResponse?.paymentDispute
      )
      ?.map((transaction: any) => ({
        payment: transaction?.payment,
        paymentReversal: transaction?.paymentReversal,
        paymentDispute: transaction?.paymentDispute,
      }));
    const pfrAdjustments = response.entity
      ?.filter(
        (transactionsResponse: any) =>
          transactionsResponse?.patientPFRAdjustment
      )
      ?.map((transaction: any) => transaction?.patientPFRAdjustment || {});
    const paymentDisputes = response.entity
      ?.filter(
        (transactionsResponse: any) => transactionsResponse?.paymentDispute
      )
      ?.map((transaction: any) => transaction?.paymentDispute || {});
    const doDisputesExist = !!(
      paymentDisputes?.length > 0 && paymentDisputes[0]?.paymentDisputeId
    );
    const doPFRAdjustmentsExist = !!(
      pfrAdjustments.length > 0 && pfrAdjustments[0]?.adjustmentAmt
    );

    const state = thunkAPI.getState() as RootState;
    if (isAccountHolder) {
      const selectedEncounter = Utils.deepClone(
        state.patientContext.currentConvertedEncounter
      );
      const adjustedPFR = patientHelper.calculateReadjustedPfr(
        selectedEncounter?.pfrAmt,
        pfrAdjustments || []
      );
      const groupedTransactions = responseAsPayments
        ? patientHelper.consolidateTransactions(
            responseAsPayments,
            isAccountHolder
          )
        : [];
      const PFR =
        adjustedPFR || adjustedPFR === 0
          ? adjustedPFR
          : selectedEncounter?.pfrAmt;
      const calculatedRemainingBalance =
        patientHelper.calculateRemainingBalance(groupedTransactions, PFR);

      selectedEncounter.patientTransactions = response.entity;
      selectedEncounter.patientPfrAdjustments = pfrAdjustments || [];
      selectedEncounter.paymentDisputes = paymentDisputes || [];
      selectedEncounter.adjustedPFR = adjustedPFR;
      selectedEncounter.doPFRAdjustmentsExist = doPFRAdjustmentsExist;
      selectedEncounter.doDisputesExist = doDisputesExist;
      selectedEncounter.calculatedRemainingBalance = calculatedRemainingBalance;

      const patientEncounters = Utils.deepClone(
        state.patientContext.selectedConvertedEncounters
      );

      const patientEncounterIndexToReplace = patientEncounters.findIndex(
        (encounter: PatientEncounter) => {
          return (
            encounter.patientEncounterId ===
            selectedEncounter.patientEncounterId
          );
        }
      );

      if (patientEncounterIndexToReplace > -1) {
        patientEncounters[patientEncounterIndexToReplace] = selectedEncounter;
      }

      response.entity = {
        selectedEncounter,
        patientEncounters,
        isAccountHolder,
      };
    } else {
      const selectedEncounter = Utils.deepClone(
        state.patientContext.selectedEncounter
      );
      const patientEncounters = Utils.deepClone(
        state.patientContext.selectedPatient.patientEncounters
      );
      const adjustedPFR = patientHelper.calculateReadjustedPfr(
        selectedEncounter?.pfrAmt,
        pfrAdjustments || []
      );
      const groupedTransactions = responseAsPayments
        ? patientHelper.consolidateTransactions(responseAsPayments, false)
        : [];
      const PFR =
        adjustedPFR || adjustedPFR === 0
          ? adjustedPFR
          : selectedEncounter?.pfrAmt;
      const calculatedRemainingBalance =
        patientHelper.calculateRemainingBalance(groupedTransactions, PFR);

      selectedEncounter.patientTransactions = responseAsPayments;
      selectedEncounter.patientPfrAdjustments = pfrAdjustments || [];
      selectedEncounter.paymentDisputes = paymentDisputes || [];
      selectedEncounter.adjustedPFR = adjustedPFR;
      selectedEncounter.doPFRAdjustmentsExist = doPFRAdjustmentsExist;
      selectedEncounter.doDisputesExist = doDisputesExist;
      selectedEncounter.calculatedRemainingBalance = calculatedRemainingBalance;

      const patientEncounterIndexToReplace = patientEncounters.findIndex(
        (encounter: PatientEncounter) => {
          return (
            encounter.patientEncounterId ===
            selectedEncounter.patientEncounterId
          );
        }
      );

      if (patientEncounterIndexToReplace > -1) {
        patientEncounters.splice(
          patientEncounterIndexToReplace,
          1,
          selectedEncounter
        );
      }

      response.entity = {
        selectedEncounter,
        patientEncounters,
      };
    }

    return response.entity;
  }
);

export const getLedger = createAsyncThunk(
  "patientContext/getLedger",
  async (
    data: {
      patientId?: number;
      encounterId?: number;
      isAccountHolder?: boolean;
      supressErrors?: boolean;
      offset: number;
      limit: number;
    },
    thunkAPI
  ) => {
    const { supressErrors } = data;
    const response = await patientService.getLedger(
      data.limit,
      data.offset,
      data.patientId,
      data.encounterId,
    );

    if (response.hasErrors) {
      if (!supressErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      } else {
        updateApplicationStatus(response.errorMessage);
      }
      throw new Error(response.errorMessage);
    }
    return response.entity;
  }
);

// stripe thunks

export const makePayment = createAsyncThunk(
  "patientContext/makePayment",
  async (
    data: { payment: Payment; isAccountHolder?: boolean; paymentType?: string },
    thunkAPI
  ) => {
    const { payment, isAccountHolder } = data;
    const response = await patientService.makePayment(payment);

    if (response.hasErrors) {
      const errorObj = Utils.deepClone(response);
      if (!response?.entity?.message && response?.entity?.externalResponse) {
        errorObj.entity.message = response.entity.externalResponse;
      }
      patientHelper.robustErrorHandler(errorObj, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus("Payment Source Successful"));
    }
    thunkAPI.dispatch(updateEncountersAfterMakePayment({ payment, response, isAccountHolder }));
    return response;
  }
);

export const updateEncountersAfterMakePayment = createAsyncThunk(
  "patientContext/updateEncountersAfterMakePayment",
  async (
    data: { payment: Payment; isAccountHolder?: boolean; response: any; },
    thunkAPI,
  ) => {
    const { payment, response, isAccountHolder } = data;
    const state = thunkAPI.getState() as RootState;

    if (isAccountHolder) {
      const newSelectedEncounter = Utils.deepClone(
        state.patientContext.currentConvertedEncounter
      );
      let encounters = Utils.deepClone(
        state.patientContext.selectedConvertedEncounters
      );
      if (newSelectedEncounter?.patientTransactions?.length > 0) {
        newSelectedEncounter?.patientTransactions.push({
          payment: response.entity,
          paymentReversal: undefined,
        });
      } else {
        newSelectedEncounter.patientTransactions = [
          { payment: response.entity, paymentReversal: undefined },
        ];
      }
      const encounterIndexToUpdate =
        encounters &&
        encounters.findIndex(
          (item: PatientEncounter) =>
            item?.patientEncounterId ===
            state.patientContext.currentConvertedEncounter?.patientEncounterId
        );
      if (
        !(
          payment?.paymentMethods &&
          payment?.paymentMethods?.length > 0 &&
          payment?.paymentMethods[0]?.paymentMethodTypeId ===
            PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId
        )
      ) {
        const newPfrBalance =
          newSelectedEncounter.patientPaymentProgram[0]?.patientPaymentSchedule
            ?.pfrBalance - payment?.paymentAmt;
        newSelectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.pfrBalance =
          newPfrBalance;
      }

      if (encounterIndexToUpdate > -1) {
        encounters[encounterIndexToUpdate] = newSelectedEncounter;
      }

      response.entity = {
        newSelectedEncounter,
        newEncounters: encounters,
        isAccountHolder,
      };
    } else {
      let encounters = Utils.deepClone(
        state.patientContext.selectedPatient.patientEncounters
      ); // deep copy
      let selectedEncounter = Utils.deepClone(
        state.patientContext.selectedEncounter
      ); // deep copy
      const encounterIndexToUpdate =
        encounters &&
        encounters.findIndex(
          (item: PatientEncounter) =>
            item?.patientEncounterId ===
            state.patientContext.selectedEncounter?.patientEncounterId
        );
      // add the payment to both the selectedEncounter and the encounters.
      if (selectedEncounter.patientTransactions) {
        // if the payment is not 'null'
        selectedEncounter.patientTransactions.push({
          payment: response.entity,
          paymentReversal: undefined,
        });
      } else {
        selectedEncounter.patientTransactions = [
          { payment: response.entity, paymentReversal: undefined },
        ]; // init payment array
      }

      if (
        !(
          payment?.paymentMethods &&
          payment?.paymentMethods?.length > 0 &&
          payment?.paymentMethods[0]?.paymentMethodTypeId ===
            PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId
        )
      ) {
        const newPfrBalance =
          selectedEncounter.patientPaymentProgram[0]?.patientPaymentSchedule
            ?.pfrBalance - payment?.paymentAmt;
        selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.pfrBalance =
          newPfrBalance;
      }
      if (encounters && encounterIndexToUpdate >= 0) {
        encounters[encounterIndexToUpdate] = selectedEncounter; // use the encounter we just updated to update the encounters list
      }

      const sortedEncounters = encounters.sort(
        (a: PatientEncounter, b: PatientEncounter) =>
          a.patientEncounterId - b.patientEncounterId
      );
      response.entity = {
        newSelectedEncounter: Utils.deepClone(selectedEncounter),
        newEncounters: Utils.deepClone(sortedEncounters),
      };
    }

    return response.entity;
  }
);

export const addPatientPaymentMethod = createAsyncThunk(
  "patientContext/addPatientPaymentMethod",
  async (
    data: {
      patientId: number;
      encounterId: number;
      isAccountHolder?: boolean;
      paymentDetail: PaymentDetail;
    },
    thunkAPI
  ) => {
    const { patientId, encounterId, isAccountHolder, paymentDetail } = data;
    const response = await patientService.addPatientPaymentMethod(
      patientId,
      encounterId,
      paymentDetail
    );
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      if (paymentDetail.paymentMethods[0].paymentMethodId === 0) {
        thunkAPI.dispatch(showStatus("Payment Method Added"));
      }
    }
    const state = thunkAPI.getState() as RootState;

    response.entity = {
      ...response.entity,
      name: response.entity?.metadata["metaData_nameOnCard"],
    };

    if (isAccountHolder) {
      let encounters = Utils.deepClone(
        state.patientContext.selectedConvertedEncounters
      ); // deep copy
      const selectedEncounter = Utils.deepClone(
        state.patientContext.currentConvertedEncounter
      );

      if (selectedEncounter?.patientPaymentMethods?.length > 0) {
        selectedEncounter.patientPaymentMethods.push(response.entity);
      } else {
        selectedEncounter.patientPaymentMethods = [response.entity];
      }

      const encounterIndexToUpdate =
        encounters &&
        encounters.findIndex(
          (encounter: PatientEncounter) =>
            encounter.patientEncounterId ===
            state.patientContext.currentConvertedEncounter.patientEncounterId
        );
      if (encounters && encounterIndexToUpdate >= 0) {
        encounters[encounterIndexToUpdate] = selectedEncounter; // use the encounter we just updated to update the encounters list
      }

      response.entity.newSelectedEncounter = Utils.deepClone(selectedEncounter);
      response.entity.newEncounters = Utils.deepClone(encounters);
      response.entity.isAccountHolder = isAccountHolder;
    } else {
      let encounters = Utils.deepClone(
        state.patientContext.selectedPatient.patientEncounters
      ); // deep copy
      let selectedEncounter = Utils.deepClone(
        state.patientContext.selectedEncounter
      ); // deep copy
      const encounterIndexToUpdate =
        encounters &&
        encounters.findIndex(
          (encounter: PatientEncounter) =>
            encounter.patientEncounterId ===
            state.patientContext.selectedEncounter.patientEncounterId
        );

      if (selectedEncounter?.patientPaymentMethods?.length > 0) {
        selectedEncounter.patientPaymentMethods.push(response.entity);
      } else {
        selectedEncounter.patientPaymentMethods = [response.entity];
      }
      if (encounters && encounterIndexToUpdate >= 0) {
        encounters[encounterIndexToUpdate] = selectedEncounter; // use the encounter we just updated to update the encounters list
      }

      const sortedEncounters = encounters.sort(
        (a: PatientEncounter, b: PatientEncounter) =>
          a.patientEncounterId - b.patientEncounterId
      );
      response.entity.newSelectedEncounter = Utils.deepClone(selectedEncounter);
      response.entity.newEncounters = Utils.deepClone(sortedEncounters);
    }

    return response.entity;
  }
);

export const verifyBankAccount = createAsyncThunk(
  "patientContext/verifyBankAccount",
  async (
    data: {
      patientId: number;
      encounterId: number;
      microdeposits: ACHVerification;
      paymentProgramId: number;
    },
    thunkAPI
  ) => {
    const { patientId, encounterId, microdeposits, paymentProgramId } = data;
    const response = await patientService.verifyBankAccount(
      patientId,
      encounterId,
      microdeposits
    );

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      // put payment program to recalculate the balances in programs table
      await paymentService.updatePaymentProgram(paymentProgramId);
      thunkAPI.dispatch(showStatus("Bank Account Verified"));
      thunkAPI.dispatch(resetDataAccountHolder()); // refresh the data
    }
  }
);

export const createPatientNote = createAsyncThunk(
  "patientContext/createPatientNote",
  async (data: { message: string; comment: PatientNotes, showNotify?: boolean }, thunkAPI) => {
    const { showNotify = true, message, comment, ...rest } = data;
    const response = await commentsService.createPatientNote(
      _.omit(comment, ['patientNotesId', 'clientId', 'completionDueTime']),
    );

    if (response.hasErrors) {
      if (showNotify === true) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      }
      throw new Error(response.errorMessage);
    } else {
      if (showNotify === true) {
        thunkAPI.dispatch(showStatus(message));
      }
    }

    return response.entity;
  }
);

export const getPatientNotes = createAsyncThunk(
  "patientContext/getPatientNotes",
  async (data: { patientId: number; encounterId: number }, thunkAPI) => {
    if (data.patientId && data.encounterId) {
      const response = await commentsService.getPatientNotes(
        data.patientId,
        data.encounterId
      );

      const filteredResult = filterComments(response.entity);
      if (response.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
        throw new Error(response.errorMessage);
      }
      return filteredResult;
    } else {
      return filterComments([]);
    }
  }
);

export const deletePatientNote = createAsyncThunk(
  "patientContext/deletePatientNote",
  async (
    data: { commentId: number },
    thunkAPI
  ) => {

    const state = thunkAPI.getState() as RootState

    const response = await commentsService.deletePatientNote({
          isArchived: true,
          archivedByUserId: state.userContext.userProfile.userName
        },
        data.commentId
    );
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    return response.entity;
  }
);

export const dismissReminder = createAsyncThunk(
  "patientContext/dismissReminder",
  async (
    data: { patientId: number; encounterId: number; commentId: number },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const reminders = state.patientContext.reminders;
    const reminderToRemove = reminders.find(
      (reminder) => reminder.patientNotesId === data.commentId
    );

    if (reminderToRemove) {
      const response = await commentsService.dismissReminder({
            isCompleted: true,
            completionAtDt: new Date().toISOString(),
            completedByUserId: state.userContext.userProfile.userName
          },
        data.commentId
      );
      if (reminderToRemove.isVobReminder) {
        const icoResponse = await patientService.updateInstanceOfCare(
          data.encounterId
        );

        if (icoResponse.hasErrors) {
          thunkAPI.dispatch(showErrorStatus(icoResponse.errorMessage));
          throw new Error(icoResponse.errorMessage);
        }
      }
      if (response.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
        throw new Error(response.errorMessage);
      }
      return response.entity;
    }
  }
);

export const releaseCharge = createAsyncThunk(
  // refund/release endpoint
  "patientContext/releaseCharge",
  async (
    data: { patientId: number; encounterId: number; payment: Payment },
    thunkAPI
  ) => {
    const { payment } = data;
    const response = await patientService.releaseCharge(
      data.payment
    );

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus("Charge Released"));
    }

    const state = thunkAPI.getState() as RootState;
    let encounters = Utils.deepClone(
      state.patientContext.selectedPatient.patientEncounters
    ); // deep copy
    let selectedEncounter = Utils.deepClone(
      state.patientContext.selectedEncounter
    ); // deep copy
    const encounterIndexToUpdate =
      encounters &&
      encounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId ===
          state.patientContext.selectedEncounter.patientEncounterId
      );

    const newPfrBalance =
      selectedEncounter.patientPaymentProgram[0]?.patientPaymentSchedule
        ?.pfrBalance + payment?.paymentAmt;
    selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.pfrBalance =
      newPfrBalance;

    selectedEncounter.patientTransactions.push({
      payment: undefined,
      paymentReversal: response.entity,
    });

    if (encounters && encounterIndexToUpdate >= 0) {
      encounters[encounterIndexToUpdate] = selectedEncounter;
    }

    const sortedEncounters = encounters.sort(
      (a: PatientEncounter, b: PatientEncounter) =>
        a.patientEncounterId - b.patientEncounterId
    );
    response.entity.newSelectedEncounter = Utils.deepClone(selectedEncounter);
    response.entity.newEncounters = Utils.deepClone(sortedEncounters);

    return response.entity;
  }
);

export const convertPatientEncounter = createAsyncThunk(
  // convert encounter, replace the encounter in the store with the one returned by the API
  "patientContext/convertPatientEncounter",
  async (data: { patientId: number; encounterId: number, clientCrm?: ClientCrm[]}, thunkAPI) => {
    const response = await patientService.convertPatientEncounter(
      data.patientId,
      data.encounterId
    );
    
    const isIntegrationEnabled = admissionsAdvisorUtils.checkIntegrationFeatureFlag(data.clientCrm!);
    if (isIntegrationEnabled) {
        patientService.integrationUpdate({
            patientEncounterId: data.encounterId,
            patientId: data.patientId,
            crmTypeSlug: data.clientCrm?.[0].crmType.crmTypeSlug!,
        });
    }

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus(`Encounter ${data.encounterId} Converted`));
    }

    const state = thunkAPI.getState() as RootState;
    let encounters = Utils.deepClone(
      state.patientContext.selectedPatient.patientEncounters
    );

    // Need to refetch encounter because Java endpoint does not include all the necessary properties
    const peResponse = await patientService.getPatientInstanceOfCare({patientId: data.patientId, encounterId: data.encounterId});

    if (peResponse?.hasErrors) {
      patientHelper.robustErrorHandler(peResponse, thunkAPI);
    }

    // format and sort the documents
    const formattedAuthorizationDocuments =
        patientHelper.processAuthorizationDocuments(
            peResponse.entity?.authorizationDocumentStatus,
            peResponse.entity?.patientDocument
        );

    let selectedEncounter = {
      ...peResponse?.entity,
      authorizationDocumentStatus: formattedAuthorizationDocuments,
    } as PatientEncounter;

    const encounterIndexToUpdate =
      encounters &&
      encounters.findIndex(
        (item: PatientEncounter) =>
          item.patientEncounterId ===
          state.patientContext.selectedEncounter.patientEncounterId
      );
    if (encounters && encounterIndexToUpdate >= 0) {
      encounters.splice(encounterIndexToUpdate, 1, selectedEncounter);
    }

    const sortedEncounters = encounters.sort(
      (a: PatientEncounter, b: PatientEncounter) =>
        a.patientEncounterId - b.patientEncounterId
    );
    response.entity.newSelectedEncounter = Utils.deepClone(selectedEncounter);
    response.entity.newEncounters = Utils.deepClone(sortedEncounters);

    return response.entity;
  }
);
export const savePatientStatus = createAsyncThunk(
  "patientContext/savePatientStatus",
  async (
    data: {
      workFlow: Workflow;
      encounterId: number;
      patientId: number;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;
    const selectedEncounter = state.patientContext.selectedEncounter;
    const clientCrm: ClientCrm[] = state.implementationContext?.implementationSpecialistClient?.allClientsWithFacilitiesMap!.get(selectedEncounter.clientId)!.clientCrm!;
    const crmTypeSlug = clientCrm.length > 0 ? clientCrm[0].crmType.crmTypeSlug : undefined;
    const isIntegrationEnabled =
        admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
    const response = await patientService.saveStatus(data);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    if (isIntegrationEnabled) {
      patientService.integrationUpdate({
        patientEncounterId: data.encounterId,
        patientId: data.patientId,
        crmTypeSlug: crmTypeSlug
      })
    }

    return response.entity;
  }
);

export const setRecentlyViewedPatient = createAsyncThunk(
  "patientContext/setRecentlyViewedPatient",
  async (
    data: {
      patientId: number | undefined;
      encounterId: number | undefined;
      type: string;
      isAdd?: boolean;
    },
    thunkAPI
  ) => {
    const { patientId, encounterId, type, isAdd } = data;
    const state = (await thunkAPI.getState()) as RootState;
    let recentlyViewedPatients: Array<recentlyViewedPatientsObj> = [];
    const userName = state.userContext.userProfile.userName;

    if (type === RecentlyViewedTypes.preConvertedPatients) {
      recentlyViewedPatients = await Utils.deepClone(
        state.patientContext.recentlyViewedPreConvertedPatients
      );
    } else {
      recentlyViewedPatients = await Utils.deepClone(
        state.patientContext.recentlyViewedConvertedPatients
      );
    }

    if (isAdd) {
      const index = recentlyViewedPatients.findIndex(
        (recentlyViewedPatient) =>
          recentlyViewedPatient.patientId === patientId &&
          recentlyViewedPatient.encounterId === null
      );
      if (index && index >= 0) {
        recentlyViewedPatients.splice(index, 1);
      }
    }

    if (!patientId) return { recentlyViewedPatients, type };

    const recentlyViewedPatient = {
      patientId,
      encounterId,
    } as recentlyViewedPatientsObj;

    recentlyViewedPatients = Utils.recentlyViewedAddVerificationPatients(
      recentlyViewedPatients,
      recentlyViewedPatient
    );
    const updatedRecentlyViewed = await cookiesService.setRecentlyViewed(
      recentlyViewedPatients,
      userName,
      type
    );

    return { recentlyViewedPatients: updatedRecentlyViewed[type], type };
  }
);

export const downloadHelloSignDocument = createAsyncThunk(
  "patientContext",
  async (
    data: {
      patientId: number;
      encounterId: number;
      externalDocumentId: string;
    },
    thunkAPI
  ) => {
    const { patientId, encounterId, externalDocumentId } = data;
    const response = await patientService.downloadHelloSignDocument(
      patientId,
      encounterId,
      externalDocumentId
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }
  }
);

export const helloSignReminder = createAsyncThunk(
  "patientContext",
  async (patientEncounter: PatientEncounter, thunkAPI) => {
    const response = await patientService.helloSignReminder(patientEncounter);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }
    thunkAPI.dispatch(showStatus("Remind email sent successfully"));
  }
);

export const helloSignForward = createAsyncThunk(
  "patientContext",
  async (
    data: { patientEncounter: PatientEncounter; email: string },
    thunkAPI
  ) => {
    const response = await patientService.helloSignForward(
      data.patientEncounter,
      data.email
    );

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    thunkAPI.dispatch(showStatus("Document forwarded!"));
  }
);

const filterComments = (comments: PatientNotes[]) => {
  const reminders = comments.filter((comment) => {
    return comment.isReminder === true && !comment.isArchived;
  });
  const commentsFilter = comments.filter((comment) => {
    return comment.isReminder !== true && !comment.isArchived;
  });

  return { reminders, comments: commentsFilter };
};

export const createS3File = createAsyncThunk(
  "patientContext/createS3File",
  async (data: any, thunkAPI) => {
    const response = await patientService.uploadS3File(
      data.patientID,
      data.encounterId,
      data.file
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    } else {
      thunkAPI.dispatch(
        getS3Document({
          patientId: data.patientID,
          encounterId: data.encounterId,
        })
      );
      thunkAPI.dispatch(showStatus("Document Upload"));
    }

    return response.entity;
  }
);

export const deleteS3Document = createAsyncThunk(
  "patientContext/deleteS3Document",
  async (data: any, thunkAPI) => {
    const response = await patientService.deleteS3Document(
      data.patientId,
      data.encounterId,
      data.s3DocumentName
    );

    thunkAPI.dispatch(
      getS3Document({
        patientId: data.patientId,
        encounterId: data.encounterId,
      })
    );

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
    } else {
      thunkAPI.dispatch(showStatus("Document Deleted " + data.s3DocumentName));
    }

    return response.entity;
  }
);

export const getS3Document = createAsyncThunk(
  "patientContext/getS3Document",
  async (data: { patientId: number; encounterId: number }) => {
    const response = await patientService.getS3Documents(
      data.patientId,
      data.encounterId
    );

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }

    return response.entity;
  }
);

export const downloadS3Document = createAsyncThunk(
  "patientContext",
  async (data: any, thunkAPI) => {
    const response = await patientService.downloads3Document(
      data.patientId,
      data.encounterId,
      data.s3DocumentName
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }
    if (response.entity) {
      let blob = new Blob([response.entity], { type: "application/pdf" });

      // PDF will save to browser file manager to open manually if needed
      FileSaver.saveAs(blob, data.s3DocumentName);

      // Also open PDF automatically in a window
      window.open(URL.createObjectURL(blob));
    }
  }
);

export const getScriptDocument = createAsyncThunk(
  "patientContext/Scripts",
  async (patientEncounter: PatientEncounter, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    //closing any open script windows
    if (state.patientContext.scriptWindowObjectReference.length > 0) {
      state.patientContext.scriptWindowObjectReference[0].close();
      thunkAPI.dispatch(resetScriptWindowObjectReference());
    }
    if (!_.isEmpty(patientEncounter.client)) {
      if (patientEncounter.client.pesScripts.length === 0) {
        thunkAPI.dispatch(showErrorStatus("No Script Document to Show"));
      } else {
        const scriptWindow = window.open(
          patientEncounter.client.pesScripts[0].scriptUrl,
          "",
          "width=800, height=800, menubar=0, toolbar=0"
        );

        thunkAPI.dispatch(addScriptWindowObjectReference(scriptWindow));
      }
    }
  }
);

export const createPhiHelloSignRequest = createAsyncThunk(
  "patientContext/phiRequest",
  async (data: PhiHelloSignRequest, thunkAPI) => {
    const response = await patientService.createPhiHelloSignRequest(data);

    const pendingPHIDocument = {
      displayedDocumentStatus: "Pending Signature",
      documentStatus: "sent",
    } as FormattedDocument;

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }
    thunkAPI.dispatch(showStatus("Phi Document sent successfully"));
    return pendingPHIDocument;
  }
);

export const createSPAHelloSignRequest = createAsyncThunk(
  "patientContext/spaRequest",
  async (data: SPAHelloSignRequest, thunkAPI) => {
    const response = await patientService.createSPAHelloSignRequest(data);
    const pendingSPADocument = {
      displayedDocumentStatus: "Pending Signature",
      documentStatus: "sent",
    } as FormattedDocument;
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }
    thunkAPI.dispatch(showStatus("SPA Document sent successfully"));
    return pendingSPADocument;
  }
);

export const createPFRHelloSignRequest = createAsyncThunk(
  "patientContext/pfrRequest",
  async (data: PFRHelloSignRequest, thunkAPI) => {
    const response = await patientService.createPFRHelloSignRequest(data);
    const pendingPFRDocument = {
      displayedDocumentStatus: "Pending Signature",
      documentStatus: "sent",
    } as FormattedDocument;
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }
    thunkAPI.dispatch(showStatus("PFR Document sent successfully"));
    return pendingPFRDocument;
  }
);

export const updatePFRDocument = createAsyncThunk(
  "patientContext/updatePFRDocument",
  async (
    data: { wasPfrAdjustmentSent: boolean; documentId: number },
    thunkAPI
  ) => {
    const { wasPfrAdjustmentSent, documentId } = data;

    const response = await patientService.updatePFRDocument(
      wasPfrAdjustmentSent,
      documentId
    );
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }
    // make sure the
    const store = thunkAPI.getState() as RootState;
    const oldPfrDocument =
      store?.patientContext?.selectedEncounter?.authorizationDocumentStatus
        ?.pfrAdjustmentDocuments?.length > 0
        ? store?.patientContext?.selectedEncounter?.authorizationDocumentStatus
            ?.pfrAdjustmentDocuments[0]
        : ({} as FormattedDocument);

    const updatedPfrDocument = {
      ...oldPfrDocument,
      wasPfrAdjustmentSent,
    };

    return updatedPfrDocument;
  }
);

export const checkClientPatientIOCId = createAsyncThunk(
  "patientContext/checkClientPatientIOCId",
  async (data: any, thunkAPI) => {
    const response = await patientService.getEncounterByClientsPatientId({
      clientPatientIOCId: data.clientPatientIOCId,
      clientId: data.clientId,
    });

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }

    return response.entity;
  }
);

export const checkClientPatientAccountId = createAsyncThunk(
    'patientContext/checkClientPatientAccountId',
    async (data: any, thunkAPI) => {
        const response =
            await patientService.getEncounterByClientsPatienAccountId({
                clientsPatientAccountId: data.clientsPatientAccountId,
                clientId: data.clientId,
            });

        if (response.hasErrors) {
            updateApplicationStatus(response.errorMessage);
            throw new Error(response.errorMessage);
        }

        return response.entity;
    }
);

export const getPtRecord = createAsyncThunk(
    'patientContext/getPtRecord',
    async (
        data: {leadId: string; clientId: number; crm: ClientCrm},
        thunkAPI
    ) => {
        const {leadId, clientId, crm} = data;
        const response = await patientService.getPtRecord(
            leadId,
            clientId,
            crm?.crmType.crmTypeSlug!
        );
        if (response.hasErrors) {
            updateApplicationStatus(response.errorMessage);
            throw new Error(response.errorMessage);
        }
        return response.entity;
    }
);

export const updateWarmTransfer = createAsyncThunk(
  "patientContext/updateWarmTransfer",
  async (data: { isWarmTransferCompleted: boolean | null }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let selectedEncounter = Utils.deepClone(
      state?.patientContext?.selectedEncounter
    );

    const response = await patientService.saveIOCWithPartialObject(
      { isWarmTransferCompleted: data.isWarmTransferCompleted },
      selectedEncounter.patientEncounterId
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    return {
      selectedEncounter: {
        ...selectedEncounter,
        isWarmTransferCompleted: response.entity.isWarmTransferCompleted,
      },
    };
  }
);

export const updateIOC = createAsyncThunk(
  "patientContext/updateIOC",
  async (data: any, thunkAPI) => {
    const {
      patientContext: { selectedEncounter },
    } = thunkAPI.getState() as RootState;

    const response = await patientService.saveIOCWithPartialObject(
      data,
      selectedEncounter.patientEncounterId
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    return {
      selectedEncounter: {
        ...selectedEncounter,
        ...response.entity,
      },
    };
  }
);

export const getPatientMasterLedger = createAsyncThunk(
  "getPatientMasterLedger",
  async (data: { patientId: number; clientId: number }, thunkAPI) => {
    const { clientId, patientId } = data;
    const response = await paymentService.getPatientMasterLedger(
      clientId,
      patientId
    );
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }
    return response;
  }
);

export const getAllPatientOpenSearchDocuments = createAsyncThunk(
  "getAllPatientOpenSearchDocuments",
  async (config: APIConfig, thunkAPI) => {
    const { paramId, searchTerms, page, limit, isClicked } = config;

    const response = await admissionsAdvisorService.getPatientSearch(
      paramId,
      searchTerms,
      page,
      limit
    );
    if (response.hasErrors) {
      // user may not have finished typing double quotes around their query
      // "tom (no closing quotes) doesn't need to show an error message
      // if they are still completeing their query
      if (response.entity?.error?.caused_by?.type === "token_mgr_error") {
        if (isClicked) {
          thunkAPI.dispatch(
            showErrorStatus("Query parsing error: Please update your query")
          );
        }
        throw new Error(response.errorMessage);
      } else {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage));
        throw new Error(response.errorMessage);
      }
    }
    return response;
  }
);

export const getClientLevelsOfCare = createAsyncThunk(
    "patientContext/getClientLevelsOfCare",
    async (params: any, thunkAPI) => {
      const response = await admissionsAdvisorService.getClientLevelsOfCare(params.paramId, params.clientId);
      if (response.hasErrors) {
        patientHelper.robustErrorHandler(response, thunkAPI);
      } else {
        let clientLevelsOfCare: EstLevelOfCare[] = [];
        // filter data by payerPlanId
        if (params.filterByPayerPlanId) {
          clientLevelsOfCare = admissionsAdvisorUtils.filterLevelsOfCareByPayerPlanId(response?.entity, params.payerPlanId)
        } else {
          if (params.payerPlanId) {
            clientLevelsOfCare = admissionsAdvisorUtils.filterPayorLocRateLosByPayerPlanId(response?.entity, params.payerPlanId)
          } else {
            clientLevelsOfCare = response?.entity;
          }
        }

        if(clientLevelsOfCare.length ===0){
          const errorMessage = "There are no client levels of care to show. Please contact a system administrator."
          thunkAPI.dispatch(showErrorStatus(errorMessage));
          throw new Error(errorMessage);
        }

        let responseBody = {
          facilityLevelsOfCare: clientLevelsOfCare,
          masterListLevelsOfCare: params.masterListLevelsOfCare
        };

        return admissionsAdvisorUtils.fillClientFacilityDataGaps(responseBody) as EstLevelOfCare[];
      }
    }
);

export const resendPaymentReceipt = createAsyncThunk(
      // resend payment receipt endpoint
      "patientContext/resendPaymentReceipt",
      async (
        data: { transactionId:number, receiptEmail?: string | undefined},
        thunkAPI
      ) => {
        const response = await patientService.PaymentReceipt(
          data.transactionId,
          data.receiptEmail
        );
    
        if (response.hasErrors) {
          patientHelper.robustErrorHandler(response, thunkAPI);
        } else {
          thunkAPI.dispatch(showStatus("Resent Receipt"));
        }
        return response;
      }
    );

export const resendRefundReceipt = createAsyncThunk(
  "patientContext/resendRefundReceipt",
  async (
    data:{transactionId:number, receiptEmail:string},
    thunkAPI
  ) => {
    const response = await patientService.resendRefundReceipt(
      data.receiptEmail, data.transactionId
    );
   
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Resent Receipt'))
    }
    return response
  }
);
