import {
  PatientEncounter,
} from '../../../patient/components/models/patient-encounter';
import {GuestToken} from '../../../security/services/SSO';
import {
  ApplicationStatusState,
  GetError,
} from '../../../security/model/application-status';
import React, {useState} from 'react';
import HttpInterceptor from '../../../shared/Http/http-interceptor';
import {patientService} from '../../../patient/services/patient-service';
import {
  specialistClientService,
} from '../../../implementation-specialist/services/implementation-client-service';
import {Checkbox, FormControlLabel, Grid, Typography} from '@mui/material';
import {formatNumberToUSD, getOrdinalSuffix} from '../../../shared/calculators';
import {Button} from '@finpay-development/shared-components';
import {AuthorizationDocumentStatus} from './payment-program-enrollment-page';
import {Workflow} from '../../../shared/model/client';
import {EnrollmentThankYouView} from './enrollment-thank-you-view';
import {
  AuthDocModal, installment, singlePaymentAgreement,
} from '../../../shared/components/auth-doc-modal/auth-doc-modal';

interface EnrollmentApprovalViewProps {
  patientEncounter: PatientEncounter;
  selectedAuthDocStatus: AuthorizationDocumentStatus | null;
  guestToken: GuestToken | null;
  setMessage: (msg: ApplicationStatusState) => void;
}

export const EnrollmentApprovalView = (props: EnrollmentApprovalViewProps) => {

  const {
    patientEncounter,
    guestToken,
    selectedAuthDocStatus,
    setMessage,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [approvalReq, setApprovalReq] = useState({
    isCheckboxChecked: false,
    isAuthDocViewed: false,
  });
  const [isEnrollmentThankYou, setIsEnrollmentThankYou] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    surveyUrl: '',
    clientName: '',
    email: '',
    phoneNumber: '',
  });
  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

  const handleApproveBtnOnClick = async () => {

    HttpInterceptor(false, guestToken!, true);

    const peStatus = getPEStatusForAuthDocSigned({
        isACH: patientEncounter?.patientPaymentProgram?.[0].isACH,
        isPaidInFull: patientEncounter?.patientPaymentProgram?.[0].isPaidInFull,
        isACHVerified: patientEncounter?.patientPaymentProgram?.[0].isACHVerified!,
        pfrCapturedBalance: patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule.pfrCapturedBalance!
    });

    const resolvedPromises = await Promise.all([
      processAuthDocApproval({
        authorizationDocumentStatusId: selectedAuthDocStatus?.authorizationDocumentStatusId!,
        peStatus,
        patientEncounterId: patientEncounter.patientEncounterId,
        patientId: patientEncounter.patientId
      }),
      //getting survey url path
      specialistClientService.getClient(patientEncounter.clientId),
    ]);

    for (let i = 0; i < resolvedPromises.length; i++) {
      if (resolvedPromises?.[i]?.hasErrors) {
        setMessage(GetError(
            'A problem occurred while approving your payment program. Please reach out to a FinPay administrator.'));
        return;
      }
    }

    const fetchedClientDetail = resolvedPromises[1]?.entity?.fullClient;

    if(
        fetchedClientDetail.clientCrm.length > 0 &&
        fetchedClientDetail.clientCrm?.[0]?.crmType?.crmTypeSlug &&
        patientEncounter.clientsPatientIOCId
    ){

      const integrationUpdateResponse = await patientService.integrationUpdate({
        patientEncounterId: patientEncounter.patientEncounterId,
        patientId: patientEncounter.patientId,
        crmTypeSlug: fetchedClientDetail.clientCrm?.[0].crmType.crmTypeSlug
      })

      if(integrationUpdateResponse.hasErrors){
        console.error("Error occurred during real time update")
      }

    }

    setIsEnrollmentThankYou(true);
    setClientDetails({
      ...clientDetails,
      surveyUrl: fetchedClientDetail?.surveyUrl,
      clientName: fetchedClientDetail?.clientName,
      email: fetchedClientDetail?.primaryContact?.email,
      phoneNumber: fetchedClientDetail?.primaryContact?.primPhoneNum,
    });

  };

  const TEXT_BLOCK_MARGIN_BOTTOM = '2rem';

  const dynamicContent = getDynamicContent({
    typeOfDoc: selectedAuthDocStatus?.authorizationDocuments?.typeOfDoc!,
    patientEncounter,
    modalState: {
      state: modalOpen,
      setState: setModalOpen
    }
  })

  return (
      <Grid
          item
          sx={{
            padding: '1rem 2rem',
            maxWidth: '535px',
            minWidth: '300px',
          }}
      >
        <Grid>
          <Typography
              variant="h1"
              className="pt-2"
              test-id="enrollment-page-title"
          >
            {`${patientEncounter?.client?.clientName} at ${patientEncounter?.facilityName} Payment Agreement`}
          </Typography>
        </Grid>
        {
          isEnrollmentThankYou ? (
              <EnrollmentThankYouView clientDetails={clientDetails}/>
          ) : (
              <>
                <Typography sx={{mt: 2, mb: TEXT_BLOCK_MARGIN_BOTTOM}}>
                  We are happy you found an agreeable payment program that meets your budget. Please read the attached forms, terms, and conditions before agreeing to them.
                </Typography>

                <Grid
                    container
                    sx={{
                      margin: `0 0 ${TEXT_BLOCK_MARGIN_BOTTOM}`,
                      "& .MuiTypography-root": { color: "primary.main"},
                      "& > *": {marginTop: "0.3rem"}
                }}
                >
                  {dynamicContent?.programDetails}
                </Grid>

                <Grid item xs={12} sx={{marginBottom: TEXT_BLOCK_MARGIN_BOTTOM}}>
                  <Typography sx={{ fontStyle: "italic" }}>
                    Any pending payments paid will be credited to your estimated remaining balance.
                  </Typography>
                </Grid>

                <Grid item xs={12} sx={{marginBottom: '1rem'}}>
                  <Typography
                      test-id="enrollment-page-auth-doc"
                  >
                    Please click {' '}
                    <Typography
                        test-id="enrollment-page-auth-doc-link"
                        component="span"
                        variant="body1"
                        color="error"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => {

                          setModalOpen(true)

                          if (!approvalReq.isAuthDocViewed) {
                            setApprovalReq({
                              ...approvalReq,
                              isAuthDocViewed: true,
                            });
                          }
                        }
                        }
                    >
                      HERE
                    </Typography>
                    {" "} to review the forms and terms and conditions of this Payment Program Agreement, which includes the following:
                  </Typography>
                  <Typography
                      sx={{ml: 2}}
                      test-id="enrollment-agreement-details"
                  >
                    {dynamicContent?.programAgreementDetails}
                  </Typography>
                </Grid>
                <Grid sx={{mb: TEXT_BLOCK_MARGIN_BOTTOM}}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            test-id="enrollment-page-approve-checkbox"
                            onChange={(e) => {
                              setApprovalReq({
                                ...approvalReq,
                                isCheckboxChecked: e.target.checked,
                              });
                            }}
                            checked={approvalReq.isCheckboxChecked}
                            sx={{ color: "primary.main", "&.Mui-checked": { color: "primary.main" } }}
                        />}
                      sx={{ "& .MuiFormControlLabel-label": {color: "primary.main", fontWeight: 'bold'}}}
                      label="I accept the terms of this Payment Program"
                  />
                </Grid>
                <Grid container justifyContent="center" sx={{mb: TEXT_BLOCK_MARGIN_BOTTOM}}>
                  <Button
                      test-id="enrollment-page-approve-btn"
                      spinnerLeftPosition={6}
                      loading={isFormProcessing}
                      disabled={isFormProcessing}
                      onClick={async () => {

                        if (!approvalReq.isAuthDocViewed) {
                          setMessage(GetError(
                              'Please click on the Payment Authorization link and review the terms before clicking approve.'));
                          return;
                        }
                        if (!approvalReq.isCheckboxChecked) {
                          setMessage(GetError(
                              'Please click the checkbox to accept the terms before clicking approve.'));
                          return;
                        }
                        setIsFormProcessing(true);
                        await handleApproveBtnOnClick();
                        setIsFormProcessing(false);

                      }}
                  >
                    Approve
                  </Button>
                </Grid>
                <Typography sx={{mb: TEXT_BLOCK_MARGIN_BOTTOM}}>
                  Your actual PFR may change depending on any insurance coverages & the services you receive.
                </Typography>
                {dynamicContent.docModal}
              </>
          )
        }

      </Grid>
  );

};

type PEStatus = {
  finClearanceStatus: string
  workflow: Omit<Workflow, 'workflowName'>
} | null

const getPEStatusForAuthDocSigned = ({
                       isACH,
                       isPaidInFull,
                       isACHVerified,
                       pfrCapturedBalance
}:
                         {
                             isACH: boolean,
                             isPaidInFull: boolean,
                             isACHVerified: boolean
                             pfrCapturedBalance: number
                         }): PEStatus => {

  switch (true) {

    case !isACH && isPaidInFull && pfrCapturedBalance === 0:
    case isACH && isPaidInFull && isACHVerified && pfrCapturedBalance === 0:
      return {
        finClearanceStatus: 'Paid',
        workflow: {
          'workflowId': 3,
          'workflowStatus': {
            'workflowStatusId': 16,
            'workflowStatusDesc': 'Paid',
          },
          'workflowSubStatus': {
            'workflowSubStatusId': 23,
            'workflowSubStatusDesc': 'None',
          },
        },
      };
    case !isACH && !isPaidInFull:
    case !isACH && isPaidInFull && (pfrCapturedBalance > 0 || pfrCapturedBalance === null):
    case isACH && isACHVerified && !isPaidInFull:
    case isACH && isACHVerified && isPaidInFull && (pfrCapturedBalance > 0 || pfrCapturedBalance === null):
      return {
        finClearanceStatus: 'Converted',
        workflow: {
          'workflowId': 3,
          'workflowStatus': {
            'workflowStatusId': 12,
            'workflowStatusDesc': 'Converted',
          },
          'workflowSubStatus': {
            'workflowSubStatusId': 23,
            'workflowSubStatusDesc': 'None',
          },
        },
      };
    case isACH && !isACHVerified:
      return {
        finClearanceStatus: 'Converted, Pending - Awaiting ACH Verification',
        workflow: {
          'workflowId': 3,
          'workflowStatus': {
            'workflowStatusId': 17,
            'workflowStatusDesc': 'Converted, Pending',
          },
          'workflowSubStatus': {
            'workflowSubStatusId': 34,
            'workflowSubStatusDesc': 'Awaiting ACH Verification',
          },
        },
      };
    default:
      return null;
  }

};

const processAuthDocApproval = async (params : {
  authorizationDocumentStatusId: number
  peStatus: PEStatus
  patientEncounterId: number
  patientId: number
}) => {

  const {authorizationDocumentStatusId, peStatus, patientEncounterId, patientId} = params;

  const authDocResponse = await patientService.updateAuthorizationDocumentStatus(
      {
        dataToSave: {
          documentStatus: 'signed',
        },
        authorizationDocumentStatusId: authorizationDocumentStatusId,
      });

  if (authDocResponse?.hasErrors) {
    return authDocResponse;
  }

  const docCreationResponse =  await patientService.createNoSigHelloSignDoc({
    authorizationDocumentStatusId,
  });

  if (docCreationResponse?.hasErrors || !peStatus) {

    if(docCreationResponse?.hasErrors) {
      await patientService.updateAuthorizationDocumentStatus(
          {
            dataToSave: {
              documentStatus: 'sent',   //reverting status back to sent to avoid conflicts
            },
            authorizationDocumentStatusId: authorizationDocumentStatusId,
          });
    }

    return docCreationResponse;
  }

 const resolvedPromises = await Promise.all([
    patientService.saveStatus({
      workFlow: peStatus.workflow,
      encounterId: patientEncounterId,
      patientId,
    }),
    patientService.saveIOCWithPartialObject({
      finClearanceStatus: peStatus.finClearanceStatus,
      finClearanceStatusDt: new Date().toISOString(),
    }, patientEncounterId)
  ])

  for (let i = 0; i < resolvedPromises.length; i++) {
    if (resolvedPromises[i].hasErrors) {
      return resolvedPromises[i];
    }
  }

};

export const getDynamicContent = ({typeOfDoc, patientEncounter, modalState}: {
  typeOfDoc: "inst" | "spa"
  patientEncounter: PatientEncounter
  modalState: {
    state: boolean,
    setState: React.Dispatch<React.SetStateAction<any>>,
  }
}) =>{

  const estimatedPFR = (
      <>
        <Grid item xs={9} >
          <Typography variant="subtitle2">
            Estimated Patient <br/>
            Financial Responsibility (PFR):
          </Typography>
        </Grid>
        <Grid item xs={3}  container justifyContent="flex-start">
          <Typography
              variant="subtitle2"
              align="right"
              test-id="enrollment-page-pfr-amt"
          >
            {formatNumberToUSD(
                patientEncounter.adjustedPfrAmt)}
          </Typography>
        </Grid>
      </>
  )

  const paidToDate = (
      <>
        <Grid item xs={9} >
          <Typography variant="subtitle2">
            Paid to Date:
          </Typography>
        </Grid>
        <Grid item xs={3}  container justifyContent="flex-start">
          <Typography
              variant="subtitle2"
              align="right"
              test-id="enrollment-paid-to-date"
          >
            {formatNumberToUSD(
                patientEncounter.adjustedPfrAmt! - patientEncounter.patientPaymentProgram?.[0].patientPaymentSchedule?.pfrCapturedBalance!)}
          </Typography>
        </Grid>
      </>
  )

  const estRemainingBalance = (
      <>
        <Grid item xs={9} >
          <Typography variant="subtitle2">
            Estimated Remaining Balance:
          </Typography>
        </Grid>
        <Grid item xs={3}  container justifyContent="flex-start">
          <Typography
              variant="subtitle2"
              align="right"
              test-id="enrollment-est-remaining-balance"
          >
            {formatNumberToUSD(patientEncounter.patientPaymentProgram?.[0].patientPaymentSchedule?.pfrCapturedBalance)}
          </Typography>
        </Grid>
      </>
  )

  const pendingPayments = (
      <>
        <Grid item xs={9} >
          <Typography variant="subtitle2">
            Pending Payments:
          </Typography>
        </Grid>
        <Grid item xs={3}  container justifyContent="flex-start">
          <Typography
              variant="subtitle2"
              align="right"
              test-id="enrollment-pending-payments"
          >
            {formatNumberToUSD(patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.pfrCapturedBalance! - patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.pfrPendingBalance!)}
          </Typography>
        </Grid>
      </>
  )

  switch(typeOfDoc){
    case 'inst' :
      return {
        programDetails: (
            <>

              {estimatedPFR}
              {paidToDate}
              {estRemainingBalance}

              <Grid item xs={9} >
                <Typography
                    variant="subtitle2"
                >
                  Interest Rate:
                </Typography>
              </Grid>
              <Grid item xs={3}  container justifyContent="flex-start">
                <Typography
                    variant="subtitle2"
                    align="right"
                    test-id="enrollment-interest-rate"
                >
                  0%
                </Typography>
              </Grid>

              <Grid item xs={9} >
                <Typography variant="subtitle2">
                  Plan Type:
                </Typography>
              </Grid>
              <Grid item xs={3}  container justifyContent="flex-start">
                <Typography
                    variant="subtitle2"
                    align="right"
                    test-id="enrollment-plan-type"
                >
                  {patientEncounter.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentFreq.toUpperCase() === 'M' ? 'Monthly' : 'Weekly'}
                </Typography>
              </Grid>

              <Grid item xs={9} >
                <Typography variant="subtitle2">
                  Term Length:
                </Typography>
              </Grid>
              <Grid item xs={3}  container justifyContent="flex-start">
                <Typography
                    variant="subtitle2"
                    align="right"
                    test-id="enrollment-term-length"
                >
                  {patientEncounter.patientPaymentProgram?.[0]?.patientPaymentSchedule?.terms}
                </Typography>
              </Grid>

              <Grid item xs={9} >
                <Typography variant="subtitle2">
                  Recurring Payment Amount:
                </Typography>
              </Grid>
              <Grid item xs={3}  container justifyContent="flex-start">
                <Typography
                    variant="subtitle2"
                    align="right"
                    test-id="enrollment-recurring-payment-amt"
                >
                  {formatNumberToUSD(patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentDueAmt)}
                </Typography>
              </Grid>

              <Grid item xs={9} >
                <Typography variant="subtitle2">
                  Recurring Payment Due Date:
                </Typography>
              </Grid>
              <Grid item xs={3}  container justifyContent="flex-start">
                <Typography
                    variant="subtitle2"
                    align="right"
                    test-id="enrollment-recurring-payment-due-date"
                >
                  {(()=>{
                    const paymentSchedule = patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule;
                    const paymentStartDay = +paymentSchedule?.paymentStartDay
                    const scheduleStartDate = paymentSchedule?.scheduleStartDt
                    if( paymentSchedule?.paymentFreq === 'M' && paymentStartDay > 0){
                      return (
                          `${paymentStartDay}${getOrdinalSuffix(paymentStartDay)}`
                      )
                    }else if ( paymentSchedule?.paymentFreq === 'W' && scheduleStartDate){
                      const date = new Date(scheduleStartDate).getUTCDay();
                      return ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"][date];
                    }
                  })()}
                </Typography>
              </Grid>

              {pendingPayments}

            </>
        ),
        programAgreementDetails: (
            <>
              <li>{paymentAgreementItems[0]}</li>
              <li>{paymentAgreementItems[1]}</li>
              <li>{paymentAgreementItems[2]}</li>
              <li>{paymentAgreementItems[3]}</li>
            </>
        ),
        docModal: (
            <AuthDocModal
                isOpen={modalState.state}
                handleModalCancel={() => {
                  modalState.setState(false);
                }}
                authDocItems={installment}
            />
        )
      }
    case 'spa' :
      return {
        programDetails: (
          <>
            {estimatedPFR}
            {paidToDate}
            {estRemainingBalance}
            {pendingPayments}
          </>
        ),
        programAgreementDetails: (
          <>
            <li>{paymentAgreementItems[0]}</li>
            <li>{paymentAgreementItems[1]}</li>
          </>
        ),
        docModal: (
            <AuthDocModal
                isOpen={modalState.state}
                handleModalCancel={() => {
                  modalState.setState(false);
                }}
                authDocItems={singlePaymentAgreement}
            />
        )
      }
  }

}

export const paymentAgreementItems = [
  'Financial Responsibility and Assignment of Benefits',
  'Payment Authorization Terms and Conditions',
  'Installment Agreement',
  'Federal Truth-In-Lending Disclosure Statement'
]