import '../../../scss/components/_icons.scss';
import '../../../scss/components/_list-header.scss';

import { Button } from '@finpay-development/shared-components';
import { Box, Divider, Grid, Typography } from '@mui/material';

import { PatientEncounter } from '../../../patient/components/models/patient-encounter';
import { PatientPaymentProgram } from '../../../patient/components/models/patient-payment-program';
import { formatNumberToUSD } from '../../../shared/calculators';
import { Utils } from '../../../shared/utils';

interface paymentProgressProps {
  setPaymentProgressModalOpen: () => void,
  patientEncounter: PatientEncounter,
  isCompleteOrCancelled?: boolean,
};

const PaymentProgressAccordionContents = (props: paymentProgressProps) => {
  const { setPaymentProgressModalOpen, patientEncounter, isCompleteOrCancelled } = props;

  const paymentProgram = (patientEncounter?.patientPaymentProgram?.length > 0 && patientEncounter?.patientPaymentProgram[patientEncounter?.patientPaymentProgram?.length - 1]) || {} as PatientPaymentProgram;

  const sectionHeader = (startString: string, endString: string) => (
    <>
      <Grid item xs={6}>
        <Typography variant="h3">
          {startString}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" justifyContent="flex-end">
          <Typography variant="h3">
            {endString}
          </Typography>
        </Box>
      </Grid>
    </>
  )

  const missedPaymentsExist = !!(paymentProgram?.patientPaymentSchedule?.missedPmts > 0)
  const paymentDueAmt = paymentProgram?.patientPaymentSchedule?.paymentDueAmt;
  const missedPmts = paymentProgram?.patientPaymentSchedule?.missedPmts;
  const lastPaymentFailureDt = paymentProgram?.patientPaymentSchedule?.lastPaymentFailureDt;
  const nextPaymentDueDt = paymentProgram?.patientPaymentSchedule?.nextPaymentDueDt;

  const missedHeader = (
    <>
      <Grid item className="mb-2">
        <Typography variant="h3">
          {`${paymentProgram?.patientPaymentSchedule?.missedPmts || 0} Missed Payment(s)`}
        </Typography>
      </Grid>
    </>
  )

  const contents = (
    <>
      {sectionHeader('Opening Balance', `${formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.pfrAmt)}`)}
      <Grid item xs={12} className="mt-2 mb-4">
        <Divider />
      </Grid>
      {sectionHeader('Remaining Balance', `${formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.pfrBalance)}`)}
      {(paymentProgram?.patientPaymentSchedule?.missedPmts > 0) && (
        missedHeader
      )}
    </>
  )

  const listItem = (
    <>
      <Grid item xs={8}>
        <Typography variant="h4">
          {missedPaymentsExist
          ? `${lastPaymentFailureDt ? Utils.convertDate(new Date(lastPaymentFailureDt)) : 'Not Found'}`
          : `${nextPaymentDueDt ? Utils.convertDate(new Date(nextPaymentDueDt)) : 'Not Found'}` }
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="h4">
          {missedPaymentsExist ?
            `${(paymentDueAmt && missedPmts) ? (formatNumberToUSD(paymentDueAmt * missedPmts)) : 'Not Found'}`
            : `${(paymentDueAmt ?  formatNumberToUSD(paymentDueAmt) : 'Not Found')}`
          }
        </Typography>
      </Grid>
    </>
  );

  return (
    <div className="px-2 list-header">
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        className="pb-2"
      >
        {contents}
        {(paymentProgram?.patientPaymentSchedule?.missedPmts > 0) && (
          <>
            <Grid container direction="row" className="pl-2 py-2 header">
              <Grid item xs={8}>
                <Typography variant="h4">
                  Date
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h4">
                  Amount
                </Typography>
              </Grid>
            </Grid>
            {listItem}
          </>
        )}
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end"  className="mt-4 mb-1">
      {(!isCompleteOrCancelled) && (
        <Button type="secondary"
          onClick={() => setPaymentProgressModalOpen()}
          disabled={paymentProgram?.patientPaymentSchedule?.pfrBalance <= 0}
          test-id="payment-progress-one-time-payment-btn"
        >
          One Time Payment
        </Button>
      )}
      </Grid>
    </div>
  );
}

export default PaymentProgressAccordionContents;
