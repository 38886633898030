import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../shared/state/root-reducer';
import {AppDispatch} from '../../../shared/state/store';
import {
    FacilityPayers,
    Vob,
    vobClassificationsItem,
    VobPostBody,
} from '../../models/vob';
import {saveVob} from '../../state/vob-thunk';
import {VOBModalContents} from '@finpay-development/shared-components'
import { ClientCrm } from '../../../shared/model/client-status-card';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import { patientService } from '../../../patient/services/patient-service';

import {
    CrossoverSelectionEnum,
    FacilityLevelOfCare,
    Estimate,
    SummarySelections,
} from '@finpay/estimation-types';
import { callNewEstimatePut } from 'src/admissions-advisor/state/estimator-thunk';
import { showErrorStatus } from 'src/security/state/user-slice';

interface VOBModalProps {
    open: boolean;
    isEdit: boolean;
    classifications: vobClassificationsItem[] | null;
    facilityPayersState?: FacilityPayers[];
    handleVOBModalCancel: () => void;
    handleVOBModalSubmit: (isEdit: boolean) => void;
}

export const createCheckIfCanRemoveClassification = (
    estimate: Estimate | null | undefined,
    dispatch: AppDispatch
) => {
    return (classificationId: number): boolean => {
        if (!estimate?.estimateId || estimate?.facilityLevelOfCare?.length === 0) {
            return true;
        }

        const locList = estimate.facilityLevelOfCare?.reduce((acc: string[], loc: FacilityLevelOfCare) => {
            if(loc.vobClassificationId === classificationId) {
                acc.push(loc?.facilityLevelOfCareName ?? "");
            }

            return acc;
        }, []);

        if (locList?.length === 0) {
            return true;
        }

        dispatch(showErrorStatus(`Please remove LOC: ${locList?.join(', ')} from the estimate before removing this classification.`));

        return false;
    };
};

export function VOBModal(props: VOBModalProps) {
    const {
        open,
        isEdit,
        classifications,
        facilityPayersState,
        handleVOBModalCancel,
        handleVOBModalSubmit,
    } = props;

    const aaVOB = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.vobContext.vob;
    });

    const vobPatientState = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.vobPatientContext;
    });

    const allClients = useSelector((state: RootState) => {
        return state.implementationContext?.implementationSpecialistClient.allClientsWithFacillities;
    });

    const estimate = useSelector((state: RootState) => {
        return state?.admissionsAdvisorContext.estimatorContext.estimate
    });

    const vob = aaVOB;

    const dispatch = useDispatch<AppDispatch>();

    const copyVob = (formik: any): Vob => {
        const {values, isValid} = formik;
        const currentDateTime = new Date();
        return {
            isValid: isValid,
            selfPay: vob.selfPay,
            digitalVerificationMethod: vob.digitalVerificationMethod,
            client: {...vob.client, clientList: undefined},
            facility: {...vob.facility, facilityList: undefined},
            payer: {...vob.payer, payersList: undefined},
            plan: {...vob.plan, payersPlanList: undefined},
            groupNum: vob.groupNum,
            policyNum: vob.policyNum,
            liveVOB: values.liveVOB,
            activePolicy: values.activePolicy,
            isCarryover: values.isCarryover,
            isCopayReset: vob.isCopayReset ?? false,
            policyBeginDate: values.policyBeginDate,
            policyEndDate: values.policyEndDate,
            inNetwDeductible: values.inNetwDeductible!,
            inNetwDeductibleRemaining: values.inNetwDeductibleRemaining!,
            inNetwFamilyDeductible: values.inNetwFamilyDeductible!,
            inNetwFamilyDeductibleRemaining:
                values.inNetwFamilyDeductibleRemaining!,
            inNetwOopIncluded: values.inNetwOopIncluded,
            inNetwOopMax: values.inNetwOopMax!,
            inNetwOopMaxRemaining: values.inNetwOopMaxRemaining!,
            inNetwFamilyOopMax: values.inNetwFamilyOopMax!,
            inNetwFamilyOopMaxRemaining: values.inNetwFamilyOopMaxRemaining!,
            inNetwVobClassifications: values.inNetwVobClassifications,
            ooNetwDeductible: values.ooNetwDeductible!,
            ooNetwDeductibleRemaining: values.ooNetwDeductibleRemaining!,
            ooNetwFamilyDeductible: values.ooNetwFamilyDeductible!,
            ooNetwFamilyDeductibleRemaining:
                values.ooNetwFamilyDeductibleRemaining!,
            ooNetwOopIncluded: values.ooNetwOopIncluded,
            ooNetwOopMax: values.ooNetwOopMax!,
            ooNetwOopMaxRemaining: values.ooNetwOopMaxRemaining!,
            ooNetwFamilyOopMax: values.ooNetwFamilyOopMax!,
            ooNetwFamilyOopMaxRemaining: values.ooNetwFamilyOopMaxRemaining!,
            ooNetwVobClassifications: values.ooNetwVobClassifications,
            vobId: vob.vobId,
            patientNotes: values.noteText
                ? [
                      {
                          noteText: values.noteText,
                          noteDt: currentDateTime.toISOString(),
                      },
                  ]
                : [],
            employer: {
                employerId: vob.employer?.employerId!,
                employerName: vob.employer?.employerName!
            }
        };
    };

    async function handleSave(formik: any) {
        const vobPostCopy = copyVob(formik);

        const vobPostBody: VobPostBody = {
            advisorPatientId: vobPatientState.patient.advisorPatientId,
            fpClientId: vob.client?.clientId,
            fpClientFacilityId: vob.facility?.facilityId,
            vobBody: {
                ...vobPostCopy,
            },
        };

        const newVob = await dispatch(saveVob(vobPostBody));

        handleSaveCallback(newVob?.payload.vobBody);
    }

    async function updateCRMVOB(newVobData: Vob, clientCrm?: ClientCrm[]) {
        if (clientCrm?.[0]?.isVobUpdateEnabled || clientCrm?.[0]?.isBenefitsUpdateEnabled) {
            const instanceOfCare = await patientService.getPatientInstanceOfCare({
                patientId: 0,
                encounterId: estimate?.patientEncounterId!,
            });
    
            if (instanceOfCare.entity) {
                const formedClient = { ...newVobData.client };
                const formedFacility = { ...newVobData.facility };
                delete formedClient.clientList;
                delete formedFacility.facilityList;
    
                const getVOBPayload = admissionsAdvisorUtils.crmVOBUpdate(
                    newVobData,
                    instanceOfCare.entity.clientsPatientAccountId,
                    instanceOfCare.entity.clientsPatientIOCId,
                    formedClient,
                    formedFacility,
                    vobPatientState.patient
                );
    
                await patientService.integrationVOBUpdate({
                    vob: getVOBPayload,
                    crmTypeSlug: clientCrm[0].crmType.crmTypeSlug
                });
            }
        }
    }

    async function handleSaveCallback(newVobData: Vob) {
        handleVOBModalSubmit(isEdit);
        if (
            newVobData?.client?.clientId &&
            estimate?.patientEncounterId
        ) {
            const clientCrm = admissionsAdvisorUtils.getClientCrmDetails(
                newVobData?.client?.clientId,
                allClients!,
            );
            const isIntegrationEnabled = admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
            if (isIntegrationEnabled) {
                await updateCRMVOB(newVobData, clientCrm);
            }
        }

        if (estimate?.estimateId && estimate?.vobId === newVobData.vobId) {
            const putEstimateBody: Estimate = {
                vobId: newVobData.vobId,
                advisorPatientId: estimate.advisorPatientId,
                clientId: estimate.clientId,
                facilityId: estimate.facilityId,
                patientEncounterId: estimate.patientEncounterId,
                description: estimate.description,
                quoteMethod: estimate.quoteMethod,
                anticipatedAdmitDate: estimate?.anticipatedAdmitDate,
                facilityLevelOfCare: estimate?.facilityLevelOfCare || [],
                isPlanYearCrossover: estimate?.isPlanYearCrossover ||  false,
                isActive: true,
                crossoverSelection: estimate.crossoverSelection ?? CrossoverSelectionEnum.BEFORE,
                financialAssistance: estimate?.financialAssistance,
                priorCare: estimate?.priorCare,
                summarySelection: estimate.summarySelection ?? SummarySelections.NONADJUSTED
            };

            dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: estimate?.estimateId}));
        }
    }

    const checkIfCanRemoveClassification = createCheckIfCanRemoveClassification(estimate, dispatch);

    return (
        <VOBModalContents
            open={open}
            isEdit={isEdit}
            classifications={classifications}
            vob={vob}
            facilityPayersState={facilityPayersState}
            handleSave={handleSave}
            handleVOBModalCancel={handleVOBModalCancel}
            handleVOBModalSubmit={handleVOBModalSubmit}
            checkIfCanRemoveClassification={checkIfCanRemoveClassification}
        />
    );
}

