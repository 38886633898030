import { createAsyncThunk } from "@reduxjs/toolkit";
import { PatientViewModel } from "../../patient/components/models/patient-view-model";
import {
  patientHelper,
  patientService,
} from "../../patient/services/patient-service";
import { vobPatientPostBody } from "../models/patient";
import { admissionsAdvisorService } from "../services/admissions-advisor-services";
import { RootState } from "../../shared/state/root-reducer";
import {
  payorRiskNameLookup,
  timingRiskNameLookup,
} from "../../shared/model/timing-and-payor-risk";
import { paynowService } from "../../guest/services/paynow-service";
import { Payment } from "../../patient/components/models/payment";
import { Utils } from "../../shared/utils";
import { showStatus } from "../../security/state/user-slice";
import { stripePaymentErrors } from "../../shared/enums";
import { Workflow } from "../../shared/model/client";
import { savePatientStatus } from "src/patient/state/patient-thunk";
import { PowerlyticsGeographyData } from "../models/powerlytics";

export const mapPowerlyticsData = (data: PowerlyticsGeographyData) => {
  return {
    verificationScore: data?.hh_iv_score || 0.00,
    capacityToPay: data?.capacity_to_pay,
    disposableIncome: (parseFloat(data?.avg_disposable_income) || 0.00) * 1000,
    retirementIncome: (parseFloat(data?.avg_retirement_income) || 0.00) * 1000,
    businessIncome: (parseFloat(data?.avg_business_income) || 0.00) * 1000,
    wealthIncome: (parseFloat(data?.avg_wealth_income) || 0.00) * 1000,
    wagesAndEarnings: (parseFloat(data?.median_w2_income) || 0.00) * 1000,
  }
}

// handles add and edit cases.
export const saveVobPatient = createAsyncThunk(
  "vobPatientContext/saveVobPatient",
  async (newPatient: vobPatientPostBody, thunkAPI) => {
    let response;

    if (newPatient?.advisorPatientId) {
      // edit
      response = await admissionsAdvisorService.updateVobPatient(newPatient);
    } else {
      // add
      response = await admissionsAdvisorService.saveVobPatient(newPatient);
    }
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
);

export const getVobPatient = createAsyncThunk(
  "vobPatientContext/getVobPatient",
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getVobPatient(
      config.paramId,
      config.patientId
    );
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const saveFinPassPatient = createAsyncThunk(
  "vobPatientContext/saveFinPassPatient",
  async (
    data: {
      patient: PatientViewModel;
      advisorPatientId: number;
      isAddNew: boolean;
      patientId: number | undefined;
    },
    thunkAPI
  ) => {
    let response;
    if (data.isAddNew) {
      // add New
      response = await admissionsAdvisorService.saveFinPassPatient(
        data.patient,
        data.advisorPatientId
      );
    } else {
      // update
      response = await admissionsAdvisorService.updateFinPassPatient(
        data.patient,
        data.advisorPatientId,
        data.patientId
      );
    }
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
);

export const searchAAPatient = createAsyncThunk(
  "vobPatientContext/searchAAPatient",
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getAAPatientSearch(
      config.paramId,
      config.searchTerms,
      config.page,
      config.limit
    );
    if (response.hasErrors) {
      // user may not have finished typing double quotes around their query
      // "tom (no closing quotes) doesn't need to show an error message
      // if they are still completeing their query
      if (!(response.entity?.error?.caused_by?.type === "token_mgr_error")) {
        patientHelper.robustErrorHandler(response, thunkAPI);
      }
    } else {
      return response.entity;
    }
  }
);

export const createPatientIoc = createAsyncThunk(
  "vobPatientContext/createPatientIoc",
  async (config: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // const patientEncounter = state.admissionsAdvisorContext.vobPatientContext
    //   .patient.selectedIOC as PatientEncounter;
    const patientEncounterId =
    state.patientContext.selectedEncounter?.patientEncounterId;

    const riskClassCalcResponse =
      await patientService.getPatientEncounterRiskClass(
        config.facilityId,
        false,
        timingRiskNameLookup(config.timingRisk?.timingRiskId)!,
        payorRiskNameLookup(config.payorRisk?.payorRiskId)!,
        config.pfrAmt
      );
    if (riskClassCalcResponse.hasErrors) {
      patientHelper.robustErrorHandler(riskClassCalcResponse, thunkAPI);
    }
    const riskClass = riskClassCalcResponse.entity;

    if (typeof patientEncounterId === "number" && patientEncounterId > 0) {
      // set workflow status is available
      const currentWorkFlowStatus: Workflow = config.workflow ? Utils.deepClone(config.workflow) : null;

      const promiseResponse = await patientService.saveIOCWithPartialObject(
              {
                clientId: config.clientId,
                facilityId: config.facilityId,
                pfrAmt: config.pfrAmt,
                pfrEstimateId: config.pfrEstimateId,
                ...(riskClass?.riskClassId && { riskClassId: riskClass.riskClassId }),
                ...(config.payorRisk?.payorRiskId && { payorRiskId: config.payorRisk.payorRiskId }),
                ...(config.timingRisk?.timingRiskId && { timingRiskId: config.timingRisk.timingRiskId }),
                levelOfCare: config.levelOfCare,
                typeOfCareId: config.typeOfCare?.typeOfCareId,
                totalSurchargeRate: config.totalSurchargeRate,
                finClearanceStatus: config.finClearanceStatus ?? "New",
                admissionDt: config.admissionDt,
                dischargeDt: config.dischargeDt
              },
              patientEncounterId
          )

      if (promiseResponse.hasErrors){
        patientHelper.robustErrorHandler(promiseResponse, thunkAPI);
      }

      // on success encounter save we update the status
      if(currentWorkFlowStatus?.workflowId) {
        const statusChangePayload = {
          workFlow: currentWorkFlowStatus,
          encounterId: patientEncounterId,
          patientId: config.patientId
        }
        await thunkAPI.dispatch(savePatientStatus(statusChangePayload));
      }

      //refetching IOC to get the full object for Redux
      const response = await patientService.getPatientInstanceOfCare({
        patientId: config.patientId,
        encounterId: patientEncounterId,
      });
      
      if (response.hasErrors) {
        patientHelper.robustErrorHandler(response, thunkAPI);
      }
      return response.entity;
    } else {
      
      const response = await admissionsAdvisorService.createAAPatientIoc(
        {
          patientId: config.patientId,
          clientId: config.clientId,
          facilityId: config.facilityId,
          pfrAmt: config.pfrAmt,
          pfrEstimateId: config.pfrEstimateId,
          patientInsurance: config.patientInsurance,
          payorRisk: config.payorRisk,
          timingRisk: config.timingRisk,
          riskClass: riskClass,
          clientsPatientAccountId: config.clientsPatientAccountId ? config.clientsPatientAccountId : '',
          clientsPatientIOCId: config.clientsPatientIOCId ? config.clientsPatientIOCId : '',
          levelOfCare: config.levelOfCare,
          typeOfCare: config.typeOfCare,
          totalSurchargeRate: config.totalSurchargeRate,
          admissionDt: config.admissionDt,
          dischargeDt: config.dischargeDt,
          finClearanceStatus: "New",
          ...(config.workflow?.workflowId &&
            config.workflow?.workflowStatus?.workflowStatusId &&
            config.workflow?.workflowSubStatus?.workflowSubStatusId && {
              workflow: config.workflow,
            }),
        },
        config.paramId
      );
      if (response.hasErrors) {
        patientHelper.robustErrorHandler(response, thunkAPI);
      }
      return response.entity;
    }
  }
);

export const getCapacityToPay = createAsyncThunk(
  "vobPatientContext/getCapacityToPay",
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getCapacityToPay(
      config.paramId,
      config.income,
      config.zipCode,
      config.zipCode4
    );
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      if (response.entity?.report_results?.response?.response_records) {
        return response.entity.report_results.response.response_records[0]
          .geography[0];
      } else {
        return response.entity;
      }
    }
  }
);

export const getNationalPovertyThreshold = createAsyncThunk(
  "vobPatientContext/getNationalPovertyThreshold",
  async (
    data: {
      paramId: number;
      houseHoldSize: number;
      state: string;
      year: number;
    },
    thunkAPI
  ) => {
    const response = await admissionsAdvisorService.getNationalPovertyThreshold(
      data.paramId,
      data.houseHoldSize,
      data.state,
      data.year
    );
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const makePayment = createAsyncThunk(
  "vobPatientContext/makePayment",
  async (data: { payment: Payment, showNotify?: boolean }, thunkAPI) => {
    const { showNotify = true, payment, ...rest } = data;
    const paramId = -1;
    const response = await paynowService.makePayment(paramId, payment);
    const clonedResponse = Utils.deepClone(response);

    if (response.hasErrors) { 
      if (!response?.entity?.message && response?.entity?.externalResponse) {
        clonedResponse.entity.message = response.entity.externalResponse;
      }
      if(response.entity.message === stripePaymentErrors.generic_decline){
        clonedResponse.entity.message = `${response.entity.message} - ${response.entity.code}:${response.entity.declineCode}`;
      }
      patientHelper.robustErrorHandler(clonedResponse, thunkAPI);
    } else {
      if (showNotify === true) {
        thunkAPI.dispatch(showStatus("Payment Successful"));
        return response.entity.paymentDetails;
      }
    }
  }
);

