import '../../../../scss/components/_list-header.scss';

import { Button, DeleteModal, LoadingOverlay, Status, Toggle } from '@finpay-development/shared-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getClient } from '../../../../implementation-specialist/state/clients/implementation-clients-thunk';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import { StatusColors } from '../../../../shared/enums';
import { ClientBusinessRules } from '../../../../shared/model/client';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { patientHelper, patientService } from '../../../services/patient-service';
import { setCalculatedRemainingBalance, setTransactionsCSVData } from '../../../state/patient-slice';
import { releaseCharge, resendPaymentReceipt, resendRefundReceipt, getLedger } from '../../../state/patient-thunk';
import { PatientEncounter } from '../../models/patient-encounter';
import { Payment, PaymentStatusType, V2DisputeDetail, V2PaymentDetail, V2PFRAdjustmentDetail, V2ReversalDetail } from '../../models/payment';
import { PAYMENT_METHOD_TYPES } from '../../models/payment-method';
import { PaymentStatus } from '../../models/payment-status';
import { PAYMENT_TYPES } from '../../models/payment-type';
import { Transaction } from '../../models/transaction';
import { useNavigate } from 'react-router';
import ResendReceiptModal from './resend-receipt-modal';

type menuActions = "refund" | "resend receipt";

interface LedgerAccordionContentsProps {
  selectedEncounter: PatientEncounter;
  isAccountHolder?: boolean,
  ledger?: Ledger[],
  setLedger: (ledger: Ledger[]) => void,
  limit: number
}

export interface Ledger {
  createDt: string;
  ledgerAmt: number;
  ledgerDt: string;
  ledgerTypeId: number;
  ownerUserId: number | null;
  patientEncounterId: number;
  patientLedgerId: number;
}

interface DetailedLedger {
  payment?: V2PaymentDetail;
  dispute?: V2DisputeDetail;
  reversal?: V2ReversalDetail;
  pfrAdjustment?: V2PFRAdjustmentDetail;
  createDt: string;
  ledgerAmt: number;
  ledgerDt: string;
  ledgerTypeId: number;
  ownerUserId: number | null;
  patientEncounterId: number;
  patientLedgerId: number;
}


export const LedgerAccordionContents = (props: LedgerAccordionContentsProps) => {
  const { selectedEncounter, isAccountHolder = false, ledger, setLedger, limit } = props;
  const transactions = selectedEncounter?.patientTransactions;
  const [ledgerOffset, setLedgerOffset] = useState(limit)
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [detailedLedger, setDetailedLedger] = useState<DetailedLedger[] | []>([]);

  useEffect(() => {
    const getNewPaymentDetails = async () => {
      if (!ledger?.length) return;
      
      const paymentRecords = ledger.filter((record) => 
        [1, 4, 5, 6].includes(record.ledgerTypeId)
      );

      const newPaymentRecords = paymentRecords.filter(record => 
        !detailedLedger.some(transaction => transaction.patientLedgerId === record.patientLedgerId)
      );

      if (newPaymentRecords.length > 0) {
        try {
          const newPaymentDetails = await Promise.all(
            newPaymentRecords.map(async (record) => {
              const details = await patientService.getPaymentDetails(record.patientLedgerId) as unknown as V2PaymentDetail;
              return {
                ...record,
                payment: details
              };
            })
          );

          setDetailedLedger(prevPayments => {
            const uniquePayments = [...prevPayments];
            newPaymentDetails.forEach(newPayment => {
              const existingIndex = uniquePayments.findIndex(p => p.patientLedgerId === newPayment.patientLedgerId);
              if (existingIndex >= 0) {
                uniquePayments[existingIndex] = newPayment;
              } else {
                uniquePayments.push(newPayment);
              }
            });
            return uniquePayments;
          });
        } catch (error) {
          console.error('Error fetching payment details:', error);
        }
      }

      const disputeRecords = ledger.filter((record) => 
        [3].includes(record.ledgerTypeId)
      );
      const newDisputeRecords = disputeRecords.filter(record => 
        !detailedLedger.some(transaction => transaction.patientLedgerId === record.patientLedgerId)
      );
      if (newDisputeRecords.length > 0) {
        try {
          const newDisputeDetails = await Promise.all(
            newDisputeRecords.map(async (record) => {
              const details = await patientService.getDisputeDetails(record.patientLedgerId) as unknown as V2DisputeDetail; // update to new dispute type
              return {
                ...record,
                dispute: details
              };
            })
          );

          setDetailedLedger(prevDisputes => {
            const uniqueDisputes = [...prevDisputes];
            newDisputeDetails.forEach(newDispute => {
              const existingIndex = uniqueDisputes.findIndex(d => d.patientLedgerId === newDispute.patientLedgerId);
              if (existingIndex >= 0) {
                uniqueDisputes[existingIndex] = newDispute;
              } else {
                uniqueDisputes.push(newDispute);
              }
            });
            return uniqueDisputes;
          });
        } catch (error) {
          console.error('Error fetching dispute details:', error);
        }
      }

      const reversalRecords = ledger.filter((record) => 
        [2].includes(record.ledgerTypeId)
      );
      const newReversalRecords = reversalRecords.filter(record => 
        !detailedLedger.some(transaction => transaction.patientLedgerId === record.patientLedgerId)
      );
      if (newReversalRecords.length > 0) {
        try {
          const newReversalDetails = await Promise.all(
            newReversalRecords.map(async (record) => {
              const details = await patientService.getReversalDetails(record.patientLedgerId) as unknown as V2ReversalDetail; // need updated type for reversals details
              return {
                ...record,
                reversal: details
              };
            })
          );

          setDetailedLedger(prevReversals => {
            const uniqueReversals = [...prevReversals];
            newReversalDetails.forEach(newReversal => {
              const existingIndex = uniqueReversals.findIndex(p => p.patientLedgerId === newReversal.patientLedgerId);
              if (existingIndex >= 0) {
                uniqueReversals[existingIndex] = newReversal;
              } else {
                uniqueReversals.push(newReversal);
              }
            });
            return uniqueReversals;
          });
        } catch (error) {
          console.error('Error fetching reversal details:', error);
        }
      }
      const pfrAdjustments = ledger.filter((record) => 
        [8].includes(record.ledgerTypeId)
      );
      const newPfrAdjustments = pfrAdjustments.filter(record => 
        !detailedLedger.some(transaction => transaction.patientLedgerId === record.patientLedgerId)
      );

      if (newPfrAdjustments.length > 0) {
        try {
          const newPfrDetails = await Promise.all(
            newPfrAdjustments.map(async (record) => {
              const details = await patientService.getPfrDetails(record.patientLedgerId) as unknown as V2PFRAdjustmentDetail; // need updated type for reversals details
              return {
                ...record,
                pfrAdjustment: details
              };
            })
          );

          setDetailedLedger(prevPfrAdjustments => {
            const uniquePfrAdjustments = [...prevPfrAdjustments];
            newPfrDetails.forEach(newPfr => {
              const existingIndex = uniquePfrAdjustments.findIndex(p => p.patientLedgerId === newPfr.patientLedgerId);
              if (existingIndex >= 0) {
                uniquePfrAdjustments[existingIndex] = newPfr;
              } else {
                uniquePfrAdjustments.push(newPfr);
              }
            });
            return uniquePfrAdjustments;
          });
        } catch (error) {
          console.error('Error fetching PFR Adjustment details:', error);
        }
      }
    };

    getNewPaymentDetails();
  }, [ledger])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const groupedTransactions = transactions ? patientHelper.consolidateTransactions(transactions, isAccountHolder) : [] // this might need to be updated based off new structure

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [transactionId, setTransactionId] = useState(0);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReceiptModalOpen, setIsReceiptModalOpen] = useState(false)
  const [showTransactionDetails, setShowTransactionsDetails] = useState(false)

  const navigate = useNavigate();
  const [optionTexts, setOptionTexts] = useState(
    {
      menuText: "Refund",
      deleteModalTitle: "Refund",
    }
  )
  const resendReceiptButton = {
    menuText: "Resend Receipt",
    refundMenuText: "Resend Refund Receipt",
    modalTitle: "Receipt"
  }
  const formRef: any = useRef();

  const stateFields = {
    isLoading: useSelector((state: RootState) => {
      return state.patientContext.isLoading.patientTransactions;
    }),
    isTransactionsFetchError: useSelector((state: RootState) => {
      return state.patientContext.isError.patientTransactions
    }),
    transactionsCSVData: useSelector((state: RootState) => {
      return state.patientContext.transactionsCSVData
    }),
    currentClientBusinessRule: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientBusinessRules as ClientBusinessRules
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    isReleaseChargeError: useSelector((state: RootState) => {
      return state.patientContext.isError.releaseCharge
    }),
    releasePaymentStatusMsg: useSelector((state: RootState) => {
      return state.patientContext.releasePaymentStatusMsg
    }),
    selectedPatient: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient
    }),
    allClients: useSelector((state: RootState) => {
      return state.implementationContext?.implementationSpecialistClient
        ?.allClients
    }),
    selectedPatientEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
  }
  const {isLoading, isTransactionsFetchError, transactionsCSVData, currentClientBusinessRule, missingAddressError, releasePaymentStatusMsg,selectedPatient, selectedPatientEncounter} = stateFields;

  const { adjustedPFR } = selectedEncounter;
  const patientPaymentProgram = selectedEncounter?.patientPaymentProgram?.[selectedEncounter.patientPaymentProgram.length - 1];
  const dispatch = useDispatch<AppDispatch>();

  const getSelectedPayment = () => {
    return selectedEncounter?.patientTransactions?.find((transaction: Transaction) => (
      transaction?.payment?.paymentId === transactionId))?.payment as Payment;
  }

  useEffect(() => {
    if (!isLoading && groupedTransactions?.length > 0) {
      const PFR = (adjustedPFR === 0 || adjustedPFR) ? adjustedPFR : selectedEncounter?.pfrAmt
      const recalculatedBalance = patientHelper.calculateRemainingBalance(groupedTransactions, PFR);
      if (selectedEncounter?.calculatedRemainingBalance !== recalculatedBalance) {
        dispatch(setCalculatedRemainingBalance({calculatedRemainingBalance: recalculatedBalance, isAccountHolder}));
      }
    }
  }, [adjustedPFR, dispatch, groupedTransactions, isAccountHolder, isLoading, selectedEncounter?.calculatedRemainingBalance, selectedEncounter?.pfrAmt])

  useEffect(() => {
    if (currentClientBusinessRule?.clientId !== selectedEncounter?.clientId && !isAccountHolder) {
      dispatch(getClient(selectedEncounter.clientId));
    }
  }, [dispatch, selectedEncounter?.clientId, currentClientBusinessRule?.clientId, isAccountHolder])

  const handleRefreshTransactions = async () => {
    if (selectedEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      const response = await dispatch(getLedger({
        offset: 0,
        limit: 10,
        encounterId: selectedPatientEncounter?.patientEncounterId,
        supressErrors: true,
      }));
      setLedger(response.payload.ledger)
      setLedgerOffset(limit);
    }
  }

  // don't allow refunds to be issued by PES if the client is configured to be the one who issues refunds.
  const disallowRefund = currentClientBusinessRule?.refundsIssuedBy?.toLowerCase() === 'client' && selectedEncounter?.workflow?.workflowId === 3
  function handleReleaseFunds() {
    dispatch(releaseCharge({patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId, payment: getSelectedPayment()}))
    setIsRefundModalOpen(false);
  }

  const handleRefundMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    transactionId: number,
    isPaymentTypeCard: boolean,
    paymentStatus: string,
    paymentTypeId: number,
    isPaymentCaptured?: boolean,
  ) => {
    const isPaymentPending = paymentStatus === "Pending"

    if (paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId) {
      setOptionTexts({menuText: "Remove", deleteModalTitle: "Remove Funds"}) // successful Paid at Facility
    } else if (!isPaymentCaptured || isPaymentPending) {
      if (!isPaymentTypeCard) {
        selectedEncounter?.workflow?.workflowId === 3
          ? setOptionTexts({menuText: "Cancel", deleteModalTitle: "Cancel Transfer"}) // converted cancelling ACH
          : setOptionTexts({menuText: "Remove", deleteModalTitle: "Remove Funds"}) // preconverted cancelling ACH
      } else {
        setOptionTexts({menuText: "Release", deleteModalTitle: "Release Funds"}) // release uncaptured credit card (hold on card)
      }
    } else {
      setOptionTexts({menuText: "Refund", deleteModalTitle: "Issue Refund"}) // successful Card or ACH
    }

    setAnchorEl(event.currentTarget);
    setTransactionId(transactionId); // paymentId
  };

  const handleMenuClose = (menuActionType?: menuActions) => {
    setAnchorEl(null);
    if (menuActionType === 'refund') {
      setIsRefundModalOpen(true);
    } else if (menuActionType === 'resend receipt'){
      setIsReceiptModalOpen(true)
    } 
  };

  const handlePatientLedgerClick = (clientId: number, patientId: number) => {
    window.open(`/specialist/dashboard/client/${clientId}/patient/${patientId}/ledger`, '_blank');
  };

  const handleFailedResponse = (externalResponse: string) => {
      let toolTipTitle: string;
      if (externalResponse.startsWith('{')) {
          const parsedExternalResponse = JSON.parse(externalResponse);
          toolTipTitle = `${parsedExternalResponse.message} - ${parsedExternalResponse.code}:${parsedExternalResponse.declineCode}`;
      } else {
        toolTipTitle = externalResponse;
      }
      return toolTipTitle;
  };

  const getStatusTextReversalItem = (paymentTypeId: number, paymentMethodTypeId: number, isCaptured: boolean, paymentStatus: string) => {
    const paymentSuccessful = paymentStatus === 'Success'
    let text = 'Refunded';
    if ((paymentMethodTypeId!==2) && 
      (!
        isCaptured)) 
      { // uncaptured credit card payment
        text = 'Released'
      } else if ((paymentMethodTypeId===2 && (!isCaptured)) || (paymentTypeId === PAYMENT_TYPES.AT_FACILITY.paymentTypeId))
      {
        text = 'Removed'
      }
    if (selectedEncounter?.workflow?.workflowId === 3 && paymentMethodTypeId===2 && isCaptured && !paymentSuccessful) {
      text = "Cancelled"
    }
    return text;
  }



  const showStatus = (payment: V2PaymentDetail) => !(payment?.paymentMethodTypeId === 2 && !payment?.isCaptured && !(selectedEncounter?.workflow?.workflowId === 3));


  const getStatusText = (transaction: DetailedLedger, showDetailedTransactions?: boolean) => {
    if (transaction?.payment?.paymentMethodTypeId === 1) {
      if (transaction?.payment?.paymentStatus.toLowerCase() === "pending") {
        return transaction?.payment?.isCaptured ? "Pending" : "Uncaptured"
      } else if (transaction?.payment?.paymentStatus === "Failed") {
        return "Failed"
      } else if (transaction?.payment?.paymentStatus === "Success") {
        return transaction?.payment?.isCaptured ? "Success" : "Uncaptured"
      }
    } else if ((transaction?.payment?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (!(transaction?.payment?.paymentMethodTypeId === 1) && !(transaction?.payment?.paymentMethodTypeId === 2))) {
      // special condition for if a recurring payment was removed
      return "Failed"
    } else {
      return transaction?.payment?.paymentStatus
    }
    return '';
  }

  const transactionSuccess = Array.isArray(transactions)? transactions.map((transaction)=> {
    const paymentStatus = transaction.payment?.paymentStatus !== PaymentStatus.failed;
    const paymentReversalIdExists = transaction.paymentReversal?.paymentReversalId !==null;
    return paymentStatus || paymentReversalIdExists;
  }):[]

  const reversalIdArray = Array.isArray(detailedLedger)
  ? detailedLedger.map((transaction) =>transaction?.reversal?.paymentReversalId).filter(Boolean)
  :[]

  const paymentIdArray = Array.isArray(detailedLedger) ?
  detailedLedger.map((transaction)=> transaction?.payment?.paymentId).filter(Boolean)
  :[]

  const reversalPaymentOriginArray = Array.isArray(detailedLedger)?
  detailedLedger.map((transaction)=> transaction?.reversal?.paymentId): []

  const disputeIdArray = Array.isArray(detailedLedger) ?
  detailedLedger.map((transaction)=> transaction?.dispute?.paymentDisputeId): []

  const handleResendReceipt = (receiptEmail: string) => {

    // Logic to resend the appropriate receipt
    if (paymentIdArray.includes(transactionId)) {
        
        // Dispatch resend payment receipt action
        dispatch(resendPaymentReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else if (reversalIdArray.includes(transactionId)) {
       
        // Dispatch resend refund receipt action
        dispatch(resendRefundReceipt({ 
            transactionId, 
            receiptEmail 
        }));
    } 
    else {
        console.error('Transaction ID not found in either array, or payment status is invalid.');
    }
    setIsReceiptModalOpen(false);
  }

  const transactionsHeader = (
    <Grid container spacing={2} className="pl-4 pb-1">
      <Grid container spacing={2} className="header px-1 mb-1">
        <Grid item xs={2}>
          <Typography variant="h4" >
            Initiated Date
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h4">
            Type
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" className="pl-3">
            Status / Source
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            Amount / ID
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h4" >
            Transaction
          </Typography>
          <Typography variant="h4">
            Type
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const pfrAdjustmentsHeader = (
    <Grid container className="header mb-5 pb-2" spacing={2}>
      <Grid item xs={5}/>
      <Grid item xs={3}>
        <Typography variant="h4">
          PFR Adjustments
        </Typography>
      </Grid>
      <Grid item xs={4}/>
    </Grid>
  )

  const pfrAdjustmentListItem = (pfrAdjustment: V2PFRAdjustmentDetail, index: number) => { 
    let arrayDate = Utils.convertISODate(new Date(pfrAdjustment.adjustmentDt), showTransactionDetails).split(" ")
    const shortDate = arrayDate[0];
    const fullDate = `${arrayDate[1]} ${arrayDate[2]}`;
    return (
    <Grid container spacing={2} key={index} data-testid="ledger-pfr-children">
      <Grid item xs={2}>
        <Typography variant="body2">
          {shortDate}
        </Typography>
        <Typography variant="body2" className="pfr-full-date">
          {showTransactionDetails && fullDate}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body2">
          PFR
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2" className="pl-3">
          ---
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {formatNumberToUSD(pfrAdjustment?.adjustmentAmt)}
        </Typography>
        <Typography variant="body2">
          {showTransactionDetails && pfrAdjustment?.patientPFRAdjustmentId}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body2">
          Adjustment
        </Typography>
      </Grid>
    </Grid>
    )
  };

  const balanceInfo = (
    <Grid
      container
      spacing={2}
      className="px-2"
    >
      <Grid item xs={12} className="mt-6">
        <Divider />
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2">
          Estimated PFR
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(selectedEncounter?.pfrAmt)}
          </Typography>
        </Box>
      </Grid>
      {(detailedLedger && detailedLedger.some(item => item?.pfrAdjustment?.patientPFRAdjustmentId)) &&
        <>
          <Grid item xs={8} className="">
            <Typography variant="subtitle2">
              Adjusted PFR
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" justifyContent="flex-end" className="">
              <Typography variant="subtitle2">
                {formatNumberToUSD(adjustedPFR)}
              </Typography>
            </Box>
          </Grid>
        </>
      }
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="pending-remaining-balance">
          Pending Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrPendingBalance)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={8} className="">
        <Typography variant="subtitle2" test-id="remaining-balance">
          Remaining Balance
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" className="">
          <Typography variant="subtitle2">
            {formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule.pfrCapturedBalance)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )

  // this use effect is using hard coded payment data from old endpoint
  useEffect(() => {
    if (!isLoading && detailedLedger && detailedLedger?.length > 0) {
      let fullTable = [];
      let rowValues: string[] = []
      rowValues.push('Date/Time', 'Type', 'Status/Source', 'Amount/Id', "Transaction Type")
      fullTable.push(rowValues)
      detailedLedger.forEach((transaction: DetailedLedger) => {
        let shortDate = "", fullDate = "", shortDateReversal = "", fullDateReversal = "", fullDateDispute = "", shortDateDispute = ""
        if (transaction?.payment?.paymentInitDt) {
          let arrayDate = Utils.convertISODate(new Date(transaction.payment.paymentInitDt), showTransactionDetails).split(" ")
          shortDate = arrayDate[0]
          if (showTransactionDetails) {
            fullDate = `${arrayDate[1]} ${arrayDate[2]} `
          }
        }
        if (transaction?.reversal?.reversalDate) {
          let arrayDateReversal = Utils.convertISODate(new Date(transaction.reversal.reversalDate), showTransactionDetails).split(" ")
          shortDateReversal = arrayDateReversal[0]
          if (showTransactionDetails) {
            fullDateReversal = `${arrayDateReversal[1]} ${arrayDateReversal[2]} `
          }
        }
        if (transaction?.dispute?.disputeCloseDt) {
          let arrayDateDispute = Utils.convertISODate(new Date(transaction.dispute.disputeCloseDt), showTransactionDetails).split(" ")
          shortDateReversal = arrayDateDispute[0]
          if (showTransactionDetails) {
            fullDateDispute = `${arrayDateDispute[1]} ${arrayDateDispute[2]} `
          }
        }
        rowValues = [];
        const payment = transaction?.payment;
        const paymentReversal = transaction?.reversal;
        const paymentDispute = transaction?.dispute;
        const status = showStatus(payment as V2PaymentDetail) ? (payment?.isCaptured || payment?.paymentMethodTypeId === PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId) ? payment?.paymentStatus || '' : "Uncaptured" : ""
        const cardInfo = Utils.getLedgerDetails(payment?.paymentMethodTypeId!, payment?.paymentTypeId!, payment?.paymentAmt!, payment?.last4);
        const date = !showTransactionDetails ? `${shortDate}`:`${shortDate} \n ${fullDate}`
        const reversalDate = !showTransactionDetails ? `${shortDateReversal}`:`${shortDateReversal} \n ${fullDateReversal}`
        const disputeDate = !showTransactionDetails ? `${shortDateDispute}`:`${shortDateDispute} \n ${fullDateDispute}`
        const amountInfo = !showTransactionDetails
          ? `${formatNumberToUSD(payment?.paymentAmt)}` : `${formatNumberToUSD(payment?.paymentAmt)} \n ${Utils.getExternalPaymentId(payment)}`
        if(transaction?.payment?.paymentId) {
          const paymentInfo = !showTransactionDetails ? `${status}` : `${status} \n ${cardInfo}`
          rowValues.push(
            `${date}`,
            `${payment ? Utils.getLedgerPaymentTypeName(payment?.paymentTypeId!, payment?.paymentMethodTypeId!, payment?.paymentAmt!, false): ''}`,
            `${(paymentInfo || '')}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(payment?.paymentTypeId!, payment?.paymentAmt!) : '')}`
          )
          fullTable.push(rowValues);
        }

        // also a row if the transaction has a reversal/refund
        if (paymentReversal?.paymentReversalId) {
        const paymentInfo = !showTransactionDetails ? `Refund` : `Refund \n ${cardInfo}`
          rowValues = [];
          rowValues.push(
            `${reversalDate}`,
            `${payment ? Utils.getLedgerPaymentTypeName(paymentReversal?.paymentTypeId, paymentReversal?.paymentMethodTypeId, paymentReversal?.reversalAmt): ''}`,
            `${(paymentInfo  || '' )}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(paymentReversal?.paymentTypeId, paymentReversal?.reversalAmt) : '')}`
          )
          fullTable.push(rowValues);
        }

        if (paymentDispute?.paymentDisputeId) {
        const disputeInfo = !showTransactionDetails ? `` : `Card Info: \n ${cardInfo}`
          rowValues = [];
          rowValues.push(
            `${disputeDate}`,
            `${payment ? Utils.getLedgerPaymentTypeName(paymentDispute?.paymentTypeId, paymentDispute?.paymentMethodTypeId, paymentDispute?.disputeAmt): ''}`, // update for dispute
            `${(disputeInfo  || '' )}`,
            `${(amountInfo || '')}`,
            `${(payment ? Utils.getTransactionType(paymentDispute?.paymentTypeId, paymentDispute?.disputeAmt) : '')}`
          )
          fullTable.push(rowValues);
        }
      })

      dispatch(setTransactionsCSVData(fullTable));
    }
    // eslint-disable-next-line
  }, [transactions, isLoading, showTransactionDetails])


  const initialValues = {
    showDetailedTransactions: false,
  }

  const validationSchema = Yup.object({
    showDetailedTransactions: Yup.boolean(),
  })

  function checkIfIsValid(value: { showDetailedTransactions: boolean }) {
    setShowTransactionsDetails(value.showDetailedTransactions);
    validationSchema
      .validate(value)
  }

  const disabledDownloadButton = (<Button
      type="text"
      icon={<GetAppIcon />}
      paddingLeft={0}
      disabled
    >
      Download File
    </Button>)
  
  const disabledPatientLedgerButton = (
    <IconButton
      color="primary"
      style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
      disabled
    >
      <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
    </IconButton>
  )

  const handleLoadMore = async () => {
    if (selectedPatientEncounter?.patientEncounterId && ledger && ledger.length % limit === 0) {
      setIsLoadingMore(true);
      try {
        const response = await dispatch(getLedger({
          encounterId: selectedPatientEncounter?.patientEncounterId,
          supressErrors: true,
          offset: ledgerOffset,
          limit: limit
        }));

        // Check if we got any new records back
        if (response.payload?.ledger?.length > 0) {
          setLedger([...ledger, ...response.payload.ledger]);
          setLedgerOffset(prev => prev + limit);
        }
      } catch (error) {
        console.error('Error loading more transactions:', error);
      } finally {
      }
      setIsLoadingMore(false);
    }
  }

  const getMenuButtons = () => {
    const currentTransaction = detailedLedger && detailedLedger.find(
      (transaction) =>
        transaction.payment?.paymentId === transactionId ||
        transaction.reversal?.paymentReversalId === transactionId
    );

    const isTransactionSuccess = currentTransaction?.payment?.paymentStatus === PaymentStatus.success && 
    (currentTransaction?.payment?.isCaptured || currentTransaction?.reversal?.paymentReversalId);

    // Check if this is specifically a refund transaction (not the original transaction)
    const isRefund = reversalIdArray.includes(transactionId);

    //if transaction is disputed, show dispute response option
    if (disputeIdArray.includes(transactionId)) {
      return (
        <MenuItem onClick={() => navigate(`/specialist/dashboard/dispute/${transactionId}`)} className="danger">
          Dispute Response
        </MenuItem>
      );
    } else if (!reversalIdArray.includes(transactionId) && !reversalPaymentOriginArray.includes(transactionId)) {
      // else if transaction is not disputed, show refund and receipt options
      return (
        <>
          {!disallowRefund && (
            <MenuItem onClick={() => handleMenuClose('refund')} className="danger">
              {optionTexts.menuText}
            </MenuItem>
          )}
          {isTransactionSuccess && (
            <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
              {resendReceiptButton.menuText}
            </MenuItem>
          )}
        </>
      );
    } else {
      // else if transaction is refunded, show only receipt option
      return (
        <MenuItem onClick={() => handleMenuClose('resend receipt')} className="danger">
          {isRefund ? resendReceiptButton.refundMenuText : resendReceiptButton.menuText}
        </MenuItem>
      );
    }
  };

  const paymentListItem = (detailedTransaction: DetailedLedger) => {
    const { payment } = detailedTransaction
    const isTransactionFailed = detailedTransaction?.payment?.paymentStatus === PaymentStatus.failed;
    let shortDate = "", fullDate = ""
    if(detailedTransaction.ledgerDt) {
      let arrayDate = Utils.convertISODate(new Date(detailedTransaction.ledgerDt), showTransactionDetails).split(" ")
      shortDate = arrayDate[0]
      if(showTransactionDetails)
        fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }
    const wasPaymentMethodDeleted = (detailedTransaction?.payment?.paymentTypeId === PAYMENT_TYPES.RECURRING.paymentTypeId) && (detailedTransaction?.payment?.paymentMethodTypeId!==1 && detailedTransaction?.payment?.paymentMethodTypeId!==2)

    return (
      <Grid container spacing={2} data-testid="ledger-payment-children">
        <Grid item xs={2}>
          <Typography variant="body2">{shortDate}</Typography>
          <Typography variant="body2" data-testid="payment-full-date">
            {showTransactionDetails && fullDate}
          </Typography>
        </Grid>
        <Grid item xs={1}>
                {!wasPaymentMethodDeleted && (
                    <Typography
                        variant="body2"
                        color='textSecondary'
                    >
                        {Utils.getLedgerPaymentTypeName(payment?.paymentTypeId!, payment?.paymentMethodTypeId!, payment?.paymentAmt!, false)}
                    </Typography>
                )}
            </Grid>
        <Grid item xs={2}>
          <>
          {showStatus(detailedTransaction?.payment as V2PaymentDetail) && (
            <Typography variant="body2" className="pl-3" data-testid="payment-status-text">
              {(detailedTransaction?.payment?.paymentStatus === 'Failed' && detailedTransaction?.payment?.externalResponse) ? (
                <Tooltip
                  title={handleFailedResponse(detailedTransaction?.payment?.externalResponse)}
                >
                  <div>
                    <Status
                      text={
                        getStatusText(
                          detailedTransaction
                        ) || ''
                      }
                      statusColor={
                        (detailedTransaction?.payment?.paymentStatus === "Success" as PaymentStatusType) &&
                        detailedTransaction?.payment?.isCaptured &&
                        !releasePaymentStatusMsg.errorMsg
                            ? StatusColors.success
                            : (detailedTransaction?.payment?.paymentStatus === "Pending" as PaymentStatusType ||
                                  !detailedTransaction?.payment?.isCaptured) &&
                              !(detailedTransaction?.payment?.paymentStatus === "Failed" as PaymentStatusType)
                            ? StatusColors.warning
                            : StatusColors.error
                    }
                    />
                  </div>
                </Tooltip>
                ) : (
                  <Status
                    text={
                      getStatusText(
                        detailedTransaction
                      ) || ''
                    }
                    statusColor={
                      (detailedTransaction?.payment?.paymentStatus === "Success") &&
                        detailedTransaction?.payment?.isCaptured &&
                        !releasePaymentStatusMsg.errorMsg
                            ? StatusColors.success
                            : (detailedTransaction?.payment?.paymentStatus === "Pending" ||
                                  !detailedTransaction?.payment?.isCaptured) &&
                              !(detailedTransaction?.payment?.paymentStatus === "Failed")
                            ? StatusColors.warning
                            : StatusColors.error
                    }
                  />
              )}
            </Typography>
          )}
        {showTransactionDetails && wasPaymentMethodDeleted && (
            <Typography variant="body2" color="error">
                No Recurring Payment Method in Stripe
            </Typography>
        )}
            </>
            <Typography
                variant="body2"
                className="pl-2"
                color='textSecondary'
                data-testid="payment-card-info"
            >
        {showTransactionDetails && !wasPaymentMethodDeleted
            ? Utils.getLedgerDetails(detailedTransaction?.payment?.paymentMethodTypeId!, detailedTransaction?.payment?.paymentTypeId!, detailedTransaction?.payment?.paymentAmt!, detailedTransaction?.payment?.last4)
            : ''}
    </Typography>
          
        </Grid>
        <Grid item xs={4}>
                <Typography variant="body2">
                    {formatNumberToUSD(detailedTransaction?.ledgerAmt)}
                </Typography>
                <Typography variant="body2" className="break-word">
                    {showTransactionDetails &&
                        Utils.getExternalPaymentId(detailedTransaction?.payment)}
                </Typography>
            </Grid>
            <Grid item xs={isAccountHolder ? 3 : 2}>
                <Typography variant="body2">
                    {Utils.getTransactionType(detailedTransaction?.payment?.paymentTypeId!, detailedTransaction?.payment?.paymentAmt!)}
                </Typography>
            </Grid>
        {ledger && 
        !isTransactionFailed &&
        !isAccountHolder &&
        transactionSuccess &&
        !disallowRefund ? (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
            <IconButton
            aria-haspopup='true'
            onClick={e => {
              const isPaymentTypeCard =
                detailedTransaction?.payment?.paymentMethodTypeId ===
                PAYMENT_METHOD_TYPES.CARD
                  .paymentMethodTypeId;
              const isPaymentCaptured = detailedTransaction?.payment?.isCaptured;
              handleRefundMenuClick(
                e,
                detailedTransaction?.payment?.paymentId as number,
                isPaymentTypeCard,
                detailedTransaction?.payment?.paymentStatus as string,
                detailedTransaction?.payment?.paymentTypeId as number,
                isPaymentCaptured,
              );
            }}
            size='large'
            >
              <MoreHorizIcon/>
            </IconButton>
          </Grid>
          ) :
          !detailedTransaction?.reversal?.paymentId &&
          !isAccountHolder &&
          !isTransactionFailed &&
          detailedTransaction?.payment?.paymentStatus !== PaymentStatus.failed &&
          !disallowRefund &&(
            <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
                   <IconButton
                        aria-haspopup="true"
                        onClick={e => {
                            const isPaymentTypeCard =
                                detailedTransaction?.payment?.paymentMethodTypeId ===
                                PAYMENT_METHOD_TYPES.CARD
                                    .paymentMethodTypeId;
                            const isPaymentCaptured =
                                detailedTransaction?.payment?.isCaptured;
                            handleRefundMenuClick(
                                e,
                                detailedTransaction?.payment?.paymentId as number,
                                isPaymentTypeCard,
                                detailedTransaction?.payment?.paymentStatus as string,
                                detailedTransaction?.payment?.paymentTypeId as number,
                                isPaymentCaptured
                            ); 
                        }}
                        size="large"
                    >
                        <MoreHorizIcon/>
                    </IconButton>
            </Grid>
        )}
    </Grid>
    );
  }

  const paymentDisputeListItem = (ledgerItem: DetailedLedger) => {
    let shortDate = "", fullDate = "";
    if (ledgerItem?.dispute?.disputeCloseDt || ledgerItem?.createDt) {
      let arrayDate = Utils.convertISODate(new Date(ledgerItem?.dispute?.disputeCloseDt || ledgerItem?.createDt), showTransactionDetails).split(" ");
      shortDate = arrayDate[0];
      if (showTransactionDetails) {
        fullDate = `${arrayDate[1]} ${arrayDate[2]} `;
      }
    }
  
    return (
      <Grid container spacing={2} data-testid="ledger-dispute-children">
        <Grid item xs={2}>
          <Typography variant="body2">{shortDate}</Typography>
          <Typography variant="body2" data-testid="dispute-full-date">{showTransactionDetails && fullDate}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2">Dispute</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className="pl-3">
            <Status
              text={isAccountHolder ? 'Dispute Won' : 'Dispute Lost'}
              statusColor={isAccountHolder ? StatusColors.success : StatusColors.error}
            />
          </Typography>
          {showTransactionDetails && (
            <Typography variant="body2">{'Reason: ' + ledgerItem?.dispute?.disputeReason}</Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="error">
            {formatNumberToUSD(ledgerItem?.dispute?.disputeAmt)}
          </Typography>
          <Typography variant="body2" className="break-word">
            {showTransactionDetails && ledgerItem?.dispute?.externalDisputeId}
          </Typography>
        </Grid>
        <Grid item xs={isAccountHolder ? 3 : 2}>
          <Typography variant="body2">{Utils.getTransactionType(ledgerItem?.dispute?.paymentTypeId!, ledgerItem?.dispute?.disputeAmt!)}</Typography>
        </Grid>
      </Grid>
    );
  };

  const reversalListItem = (ledgerItem: DetailedLedger) => { // update the types here for reversals
    const { reversal } = ledgerItem
    const {paymentTypeId, paymentMethodTypeId, isCaptured, paymentStatus, reversalAmt, reversalDate, paymentReversalId, paymentId} = reversal as V2ReversalDetail
    let shortDate = "", fullDate = ""
    if(reversalDate) {
      let arrayDate = Utils.convertISODate(new Date(reversalDate), showTransactionDetails).split(" ")
      shortDate = arrayDate[0]
      if(showTransactionDetails)
      fullDate = `${arrayDate[1]} ${arrayDate[2]} `
    }
    // "To do: Remove `reservalAmount` and use `reservalAmt` after rebuilding the transaction Java API in Node.js."
    return (
      <Grid container spacing={2} data-testid="ledger-reversal-children">
        <Grid item xs={2}>
          <Typography variant="body2">
            {shortDate}
          </Typography>
          <Typography variant="body2" data-testid="reversal-full-date">
            {showTransactionDetails && fullDate }
          </Typography>
        </Grid>
        <Grid item xs={1} >
            <Typography variant="body2" color="textSecondary">
              {Utils.getLedgerPaymentTypeName(paymentTypeId, paymentMethodTypeId, reversalAmt)}
            </Typography>
          </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className="pl-2">
              <Status
                text={getStatusTextReversalItem(paymentTypeId, paymentMethodTypeId, isCaptured, paymentStatus)}
                statusColor={
                  paymentStatus === PaymentStatus.success ? StatusColors.success :
                  (paymentStatus === PaymentStatus.pending ? StatusColors.warning
                  : StatusColors.error)
                }
              />
          </Typography>
        <Typography variant="body2" className="pl-2">
            {showTransactionDetails ?
              Utils.getLedgerDetails(paymentMethodTypeId, paymentTypeId, reversalAmt, reversal?.last4) : ""
            }
          </Typography>
        </Grid>
        <Grid item xs={4} style={{maxHeight: 10}}>
          <Typography variant="body2" color="error">
            {formatNumberToUSD(reversalAmt && -Math.abs(reversalAmt))}
          </Typography>
          <Typography variant="body2" className="break-word">
            {showTransactionDetails && (
              reversal?.externalReversalId
            )}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2">
            {Utils.getTransactionType(paymentTypeId, reversalAmt)}
          </Typography>
        </Grid>
        {((reversal?.paymentId) && (!isAccountHolder)) && (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
            <>
              <IconButton
                aria-haspopup="true"
                onClick={(e) => {
                  const isPaymentTypeCard = (paymentMethodTypeId === PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId)
                  const isPaymentCaptured = isCaptured
                  handleRefundMenuClick(e, paymentId, isPaymentTypeCard, paymentStatus, paymentTypeId, isPaymentCaptured)
                  setTransactionId(paymentReversalId)
                }}
                size="large">
                <MoreHorizIcon />
              </IconButton>
            </>
          </Grid>
        )}
      </Grid>
    );
  }

  return <>
    <div className="pb-7 px-1">
      <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Send Ledger" isAccountHolderPortal={true}
        renderNoAccess={() => (disabledDownloadButton)}
      >
        {!isLoading && !isTransactionsFetchError && ledger!==undefined && ledger?.length > 0 ? (
          <CSVLink data={transactionsCSVData} filename={'Transactions_Data.csv'}>
          <Button
            type="text"
            icon={<GetAppIcon />}
            paddingLeft={0}
          >
            Download File
          </Button>
        </ CSVLink>
        ) : (disabledDownloadButton)
      }
      </AccessControl>
      <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Patient Ledger" isAccountHolderPortal={true}
        renderNoAccess={() => (disabledPatientLedgerButton)}
      >
        <IconButton
            color="primary"
            style={{ fontSize: "1.5rem", float: 'right', paddingRight: '6px', paddingTop: '7px' }}
            onClick={() =>
              handlePatientLedgerClick(selectedEncounter?.clientId, selectedEncounter?.patientId)
            }
          >
            <OpenInNewIcon fontSize="small" style={{paddingRight: '6px'}} /> View Master Patient Ledger
          </IconButton>
      </AccessControl>
      {!isAccountHolder && (
        <Grid container className="my-3 ml-2">
          <Grid xs={12} item>
            <Typography variant="h3" className="mb-2">
              Details
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              validate={checkIfIsValid}
              onSubmit={() => {}}
            >
              {(formik) => (
                <Form>
                  <div className="mb-6" data-testid="show-detailed-transactions">
                    <Toggle
                      name="showDetailedTransactions"
                      formik={formik}
                      value={formik.values.showDetailedTransactions}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </div>
    <div className="list-header documents-accordion px-4 pb-4">
      {isTransactionsFetchError ? (
        <Grid container direction="row">
          <Grid item xs={10}>
            <Typography variant="h4">
              Transactions Pending. Use Refresh button to update.
            </ Typography>
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
              <IconButton onClick={() => handleRefreshTransactions()} size="large">
                <RefreshIcon className="icon" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {isLoading ? (
            <LoadingOverlay whiteBackground />
            ) : (
              <div className="px-1">
                {transactionsHeader}
                <div className="striped-row-container" data-testid="ledger-list">
                  {detailedLedger && detailedLedger.length > 0 ? (
                    detailedLedger.map((ledgerItem: DetailedLedger) => (

                        <React.Fragment key={ledgerItem.patientLedgerId}>
                          {ledgerItem?.payment?.paymentId &&
                            paymentListItem(ledgerItem)
                          }
                          {ledgerItem?.reversal?.paymentReversalId && 
                            reversalListItem(ledgerItem)
                          }
                          {ledgerItem?.dispute?.paymentDisputeId &&
                            paymentDisputeListItem(ledgerItem)
                          }
                        </React.Fragment>

                    ))
                  ) : (
                    <Typography className="mt-4" variant="h4">
                      There are no transactions available
                    </Typography>
                  )}
                </div>
                  {ledger && ledger?.length % limit === 0 && ledger.length > 0 && (
                    <Box 
                    display="flex" 
                    justifyContent="center" 
                    width="100%" 
                    >
                      {isLoadingMore ? (
                        <LoadingOverlay whiteBackground />
                      ) : (
                        <IconButton
                          color="primary"
                          title="Open Details"
                          style={{
                            fontSize: '1.5rem',
                          }}
                          onClick={handleLoadMore}
                        >
                          <AddCircleOutlineIcon fontSize="medium" style={{marginRight: '0.5rem'}}/>
                          Load More Transactions
                        </IconButton>
                      )}
                    </Box>
                  
                  )}
                {detailedLedger && detailedLedger.some(item => item?.pfrAdjustment?.patientPFRAdjustmentId) && (
                    <React.Fragment>
                      {pfrAdjustmentsHeader}
                      <div className="striped-row-container">
                      {detailedLedger.filter(item => item.pfrAdjustment).map((item, index) => pfrAdjustmentListItem(item.pfrAdjustment as V2PFRAdjustmentDetail, index))}
                      </div>
                    </React.Fragment>
                  )}
              </div>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleMenuClose()}
            >
              <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
                renderNoAccess={() => <MenuItem disabled>danger</MenuItem>}>
                {getMenuButtons()}
                <></>
              </AccessControl>
            </Menu>
        </>
      )}
    </div>
    {balanceInfo}
    {isReceiptModalOpen && (
      <ResendReceiptModal
        open={isReceiptModalOpen}
        receiptEmail={selectedPatient?.contact.email}
        titleText={reversalIdArray.includes(transactionId) ? resendReceiptButton.refundMenuText + '?' : resendReceiptButton.menuText + '?'}
        onSend={handleResendReceipt}
        onClose={()=> setIsReceiptModalOpen(false)}
      />
    )}
    {isRefundModalOpen && (
      <DeleteModal
        open={isRefundModalOpen}
        title={optionTexts.deleteModalTitle}
        subTitle="This action cannot be undone"
        okButtonText= {`${optionTexts.deleteModalTitle}`}
        handleDeleteCancel={() => setIsRefundModalOpen(false)}
        handleDeleteOk={handleReleaseFunds}
      />
    )}
  </>;
}