import '../../../../scss/components/_list-header.scss';

import { Button, LoadingOverlay, StartAndEndText, Status } from '@finpay-development/shared-components';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import { StatusColors } from '../../../../shared/enums';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { getInstanceOfCare, getPaymentMethods, getTransactions, updateEncountersAfterMakePayment } from '../../../state/patient-thunk';
import { PatientDocument } from '../../models/patient-document';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { Payment } from '../../models/payment';
import { Transaction } from '../../models/transaction';
import { TakePaymentModal } from 'src/shared/components/take-payment-modal';
import { PAYMENT_TYPES } from '../../models/payment-type';
import {paynowService as paymentService } from '../../../../guest/services/paynow-service';
import { showErrorStatus, showStatus } from "../../../../security/state/user-slice";
import { patientService, patientHelper } from 'src/patient/services/patient-service';
import { setSelectedEncounter } from '../../../state/patient-slice';

interface paymentStatusAccordionProps {
  setBalanceAdjustmentModalOpen: () => void;
  documents: PatientDocument[];
}

export const PaymentStatusAccordionContents = (props: paymentStatusAccordionProps) => {
  const { setBalanceAdjustmentModalOpen } = props;
  const [takePaymentModalOpen, setTakePaymentModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const stateFields = {
    selectedEncounter: useSelector(
      (state: RootState) => state.patientContext.selectedEncounter
    ),
    patientPaymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    payments: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter.patientTransactions?.length > 0 ? state.patientContext.selectedEncounter.patientTransactions : []
    }),
    missedPayments: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter?.patientTransactions?.length > 0 ?
        state.patientContext.selectedEncounter.patientTransactions?.filter((transaction: Transaction) => (
          transaction?.payment?.paymentStatusFailed
        )) : []
    }),
    userRoleId: useSelector((state: RootState) => state.userContext.userProfile.userRole.userRoleId),
    userProfile: useSelector((state: RootState) => {
      return state.userContext.userProfile;
    }),
    isTransactionsLoading: useSelector((state: RootState) => {
      return state.patientContext.isLoading?.patientTransactions
    }),
    isTransactionsFetchError: useSelector((state: RootState) => {
      return state.patientContext.isError?.patientTransactions
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
    selectedPatient: useSelector((state: RootState) => state.patientContext.selectedPatient),
  }
  const {
    selectedEncounter,
    patientPaymentProgram,
    missedPayments,
    isTransactionsLoading,
    isTransactionsFetchError,
    missingAddressError,
    userRoleId,
    selectedPatient,
    userProfile,
  } = stateFields;
  const doPFRAdjustmentsExist = selectedEncounter?.doPFRAdjustmentsExist;

  const paymentChannelId = 5

  const isDefault = selectedEncounter?.workflow?.workflowSubStatus?.workflowSubStatusId === 37;
  const paymentProgramId = selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0;

  const handleRefreshTransactions = async () => {
    if (selectedEncounter?.patientEncounterId !== 0 && !missingAddressError) {
      await dispatch(getTransactions({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId
      }));
    }
  }

  const handleRefreshPaymentMethods = async () => {
    if (!missingAddressError) {
      await dispatch(getPaymentMethods({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId
      }));
    }
  }

  const handleTakePaymentCallBack = async (payment: any, response: any) => {
    await dispatch(updateEncountersAfterMakePayment({payment, response}));
    await dispatch(getInstanceOfCare({
      patientId: selectedEncounter?.patientId,
      encounterId: selectedEncounter?.patientEncounterId,
    }));
    await handleRefreshPaymentMethods();
    await handleRefreshTransactions();
  }

  const shouldDisableTakePayment = () => {
    let enabled = true;

    enabled = !isDefault ? (patientPaymentProgram?.patientPaymentSchedule?.pfrBalance <= 0) : true
    // FPS-1133 - temporarily enable take a payment button when docs aren't signed

    // if (patientPaymentProgram?.isPaidInFull) {
    //   enabled = !isDefault ? (patientPaymentProgram?.patientPaymentSchedule?.pfrBalance <= 0) : true
    // } else {
    //   enabled = (isDocumentSigned && !isDefault) ? (patientPaymentProgram?.patientPaymentSchedule?.pfrBalance <= 0) : true
    // }
    return enabled;
  }

  const fetchIoCAndSetEncounter = async () => {
    const iocResponse = await dispatch(getInstanceOfCare({
      patientId: selectedEncounter.patientId,
      encounterId: selectedEncounter.patientEncounterId,
    }));

    if (iocResponse?.type !== "patientContext/getInstanceOfCare/rejected") {
      if (iocResponse.payload && (iocResponse.payload as { selectedPatientEncounter: any }).selectedPatientEncounter) {
        const selectedPatientEncounter = (iocResponse.payload as { selectedPatientEncounter: any }).selectedPatientEncounter;
        dispatch(setSelectedEncounter(selectedPatientEncounter));
      }
    }
  };
  const handleCaptureBtnClick = async () => {
    const success: boolean = await handleCapture();
    if (success) {
      console.log("Payments captured successfully!");
      dispatch(showStatus("Payments captured successfully!"));
      await handleRefreshTransactions();
      await fetchIoCAndSetEncounter();
    } else {
      console.warn("Failed to capture some or all payments.");
      dispatch(showErrorStatus("An error occurred while capturing payments. Please try again."));
    }
  };

  const handleCapture = async (): Promise<boolean> => {
  
    try {
      if (!selectedEncounter?.patientTransactions?.length) {
        console.warn("No patient transactions found for the selected encounter.");
        dispatch(showErrorStatus("No patient transactions found for the selected encounter."));
        return false;
      }

      const cancelledTransactions = selectedEncounter.patientTransactions
        .filter((transaction) => transaction.paymentReversal?.paymentReversalId && transaction.paymentReversal?.paymentId)
        .map(transaction => transaction.paymentReversal?.paymentId);

    const pendingTransactions = selectedEncounter.patientTransactions.filter(
        (transaction) => {
          const isPending = transaction.payment?.externalResponse?.toLowerCase() === 'pending';
          const isNotCancelled = !cancelledTransactions.includes(transaction.payment?.paymentId);
          return isPending && isNotCancelled;
        }
      );
  
      if (!pendingTransactions.length) {
        console.log("No pending transactions to capture.");
        dispatch(showStatus("No pending transactions to capture."));
        return true;
      }
      await paymentService.updatePaymentProgram(paymentProgramId, {
        isAchVerified: true,
      });
      const captureResults = await Promise.allSettled(
        pendingTransactions.map(async (transaction) => {
          const { paymentId } = transaction.payment;
  
          const result = await paymentService.updatePaymentDetails(-2, {
            isCaptured: true,
            paymentId,
          });
  
          if (result.hasErrors) {
            await paymentService.updatePaymentProgram(paymentProgramId, {
              isAchVerified: false,
            });
            throw new Error(`Failed to capture payment: ${result.entity}`);
          }
        })
      );
  
      const failedCaptures = captureResults.filter(({ status }) => status === 'rejected');
      if (failedCaptures.length > 0) {
        console.error("Some payments failed to capture:", failedCaptures);
        dispatch(showErrorStatus(`Some payments failed to capture: ${failedCaptures}`));
        return false;
      }
      const statusResult = await patientService.saveStatus({
        workFlow: {
          workflowId: 3,
          workflowStatus: {
            workflowStatusId: 12,
            workflowStatusDesc: 'Converted',
          },
          workflowSubStatus: {
            workflowSubStatusId: 23,
            workflowSubStatusDesc: 'None',
          },
        },
        encounterId: selectedEncounter.patientEncounterId!,
        patientId: selectedEncounter.patientId!,
      });

      if (statusResult.hasErrors) {
        dispatch(showErrorStatus("Failed to update status"));
        throw new Error('Failed to update status');
      }
      const iocResult = await patientService.saveIOCWithPartialObject(
        {
          finClearanceStatus: 'Converted',
          finClearanceStatusDt: new Date(),
          convertDt: new Date(),
          convertUserId: userProfile.userName,
        },
        selectedEncounter.patientEncounterId!
      );

      if (iocResult.hasErrors) {
        dispatch(showErrorStatus(`Failed to update financial clearance status: ${iocResult.entity}`));
        throw new Error(`Failed to update financial clearance status: ${iocResult.entity}`);
      }

      await paymentService.updatePaymentProgram(paymentProgramId, {
        workflowStatusId: 12,
        workflowSubStatusId: 23,
        patientPaymentSchedule: {
          scheduleStatus: 'Active',
        },
      });
      console.log("All payments captured successfully!");
      await fetchIoCAndSetEncounter();
      return true;
    } catch (error: any) {
      dispatch(showErrorStatus(`Error during payment capture:: ${error.message || error}`));

      return false;
    }
    
  }

  const shouldDisableBalanceAdjustment = (isTransactionsLoading || isTransactionsFetchError)

  const transactionsRefreshView = (
    <>
      <Grid item xs={8}>
        <Typography variant="h4">
          Missed Payments Pending. Use Refresh button to update.
        </ Typography>
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
          <IconButton onClick={() => handleRefreshTransactions()} size="large">
            <RefreshIcon className="icon" />
          </IconButton>
        </Box>
      </Grid>
    </>
  );

  const isWorkFlowStatusGoodStandingOrConvert = (
    (selectedEncounter?.workflow?.workflowStatus.workflowStatusId === 8) || (selectedEncounter?.workflow?.workflowStatus.workflowStatusId === 12)
  ) // 'good standing' and 'converted' ids
  const doMissedPaymentsExist = (patientPaymentProgram?.patientPaymentSchedule?.missedPmts > 0 && !isWorkFlowStatusGoodStandingOrConvert)

  const transactionsListItem = (missedPayment: Payment, isShaded: boolean) => (
    <div className="mx-2">
      <Grid container spacing={2} className={isShaded ? "list-item-shade py-2" : "py-2 mb-2"}>
        <Grid item xs={8}>
          <Typography variant="body1">
            {Utils.convertISODate(new Date(missedPayment?.paymentInitDt))}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body1">
            {formatNumberToUSD(missedPayment?.paymentAmt)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )

  const missedPaymentsHeader = (
    <div className="mb-1 mx-2">
      <Grid container spacing={2} className="header">
        <Grid item xs={8}>
          <Typography variant="h4">
            Date
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            Amount
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  const shouldShowCaptureButton = () => {
    const hasPendingACH = selectedEncounter?.patientTransactions?.some(
      (transaction) => 
        transaction?.payment?.externalResponse?.toLowerCase() === 'pending' && 
        transaction?.payment?.paymentMethodUsed?.ach && transaction?.payment?.isCaptured === false
    );
    const isWorkflowStatusConverted = selectedEncounter?.workflow?.workflowId === 3;
    const isSuperUser = userRoleId === 1;

    return isSuperUser && hasPendingACH && isWorkflowStatusConverted;
  };

  return (
    <div className="list-header documents-accordion px-4 pb-4">
      <StartAndEndText startText="Current PFR" endText={formatNumberToUSD(selectedEncounter?.adjustedPfrAmt)} />
      <Box display="flex" justifyContent="flex-end" className="pb-4 pt-2">
        <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Adjust Account Balance"
          renderNoAccess={() => <Button disabled>Balance Adjustment</Button>}>
          <Button
            type="secondary"
            onClick={() => { setBalanceAdjustmentModalOpen() }}
            disabled={shouldDisableBalanceAdjustment}
          >
            Balance Adjustment
        </Button>
        </AccessControl>
      </Box>
      <Divider className="mb-5"/>
      <StartAndEndText startText="Pending Remaining Balance" endText={formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule?.pfrPendingBalance)} />

      {patientPaymentProgram?.patientPaymentSchedule?.nextPaymentDueDt &&
        <StartAndEndText
          startText="Next Payment Date"
          endText={Utils.convertDate(new Date(patientPaymentProgram?.patientPaymentSchedule?.nextPaymentDueDt))}
          />
          }
      {(!patientPaymentProgram?.patientPaymentSchedule?.nextPaymentDueDt && patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt) &&
        <StartAndEndText
          startText="Schedule Start Date"
          endText={Utils.convertDate(new Date(patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt))}
          />}

      {doMissedPaymentsExist && (
        isTransactionsLoading ? (
          <LoadingOverlay whiteBackground />
        ) : (
          isTransactionsFetchError ? (
            <Grid container>
              {transactionsRefreshView}
            </ Grid>
          ) : (
            <>
              <Typography variant="subtitle2" className="py-2 pb-2">
                {`${patientPaymentProgram?.patientPaymentSchedule?.totalMissedPmts} Missed Payment(s)`}
              </Typography>
              {missedPaymentsHeader}
              {missedPayments.map((transaction: Transaction, index: number) => (
                <div key={transaction?.payment?.paymentId}>
                  {transactionsListItem(transaction?.payment, (index % 2 !== 0))}
                </div>
              ))}
            </>
          )
        )
      )}
      {isDefault && (
        <Status text={'Default'} statusColor={StatusColors.error} />
      )}
      <Box display="flex" justifyContent="flex-end"  className="pb-4 pt-2">
        <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Take a Payment"
          renderNoAccess={() => <Button disabled>Take Payment</Button>}>
          <Button
            type="secondary"
            onClick={() => { setTakePaymentModalOpen(true) }}
            disabled={shouldDisableTakePayment()}
            test-id="payment-status-take-payment"
          >
            Take Payment
        </Button>
        </AccessControl>
      </Box>
      {takePaymentModalOpen &&
        <TakePaymentModal
          open={takePaymentModalOpen}
          handleModalCancel={() => { setTakePaymentModalOpen(false); }}
          handleSubmitCallBack={handleTakePaymentCallBack}
          paymentData={{
            paymentType: PAYMENT_TYPES.SPECIALIST,
            patientId: selectedEncounter.patientId,
            patientEncounterId: selectedEncounter.patientEncounterId,
            paymentChannelId,
            isAuthOnly: !(selectedEncounter?.workflow?.workflowId === 3),
          }}
        />
      }
      <Divider className="mb-5"/>
      {shouldShowCaptureButton() && (
        <Box display="flex" justifyContent="flex-end" className="pb-6">
          <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Capture"
            renderNoAccess={() => <Button disabled={false} onClick={handleCaptureBtnClick}>Capture ACH Payment</Button>}>
            <Button
              type="secondary"
              disabled={false}
              onClick={handleCaptureBtnClick}
            >
              Capture ACH Payment
            </Button>
          </AccessControl>
        </Box>
      )}
    </div>
  )
}
