import {useDispatch, useSelector} from 'react-redux';

import {VOBModalContents} from '@finpay-development/shared-components';

import {RootState} from 'src/shared/state/root-reducer';
import {AppDispatch} from 'src/shared/state/store';
import {
    Vob,
    vobClassificationsItem,
    VobPostBody,
    FacilityPayers,
} from 'src/admissions-advisor/models/vob';
import {postVob, saveInstanceOfCare, saveVOBForFinpass, updateIOC} from 'src/patient/state/patient-thunk';
import {PatientEncounter} from '../../models/patient-encounter';
import { admissionsAdvisorUtils } from 'src/admissions-advisor/utils/admission-advisor-utils';
import { callNewEstimatePost, callNewEstimatePut } from 'src/admissions-advisor/state/estimator-thunk';
import { CrossoverSelectionEnum, Estimate, QuoteMethod } from '@finpay/estimation-types';
import { SummarySelections } from '@finpay/estimation-types';
import { admissionsAdvisorService } from 'src/admissions-advisor/services/admissions-advisor-services';
import { setSelectedPatientV2 } from 'src/patient/state/patient-slice';
import { patientHelper } from 'src/patient/services/patient-service';
import { showErrorStatus } from 'src/security/state/user-slice';
import { createCheckIfCanRemoveClassification } from 'src/admissions-advisor/components/vob/vob-modal';

interface FinpassVOBModalProps {
    open: boolean;
    isEdit: boolean;
    classifications: vobClassificationsItem[] | null;
    facilityPayersState?: FacilityPayers[];
    handleVOBModalCancel: () => void;
    handleVOBModalSubmit: (isEdit: boolean) => void;
    isFinpass?: boolean;
}

export function FinpassVOBModal(props: FinpassVOBModalProps) {
    const {
        open,
        isEdit,
        classifications,
        facilityPayersState,
        handleVOBModalCancel,
        handleVOBModalSubmit,
    } = props;

    const finpassVOB = useSelector((state: RootState) => {
        return state.patientContext.selectedVOB;
    });

    const estimate = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.estimatorContext.estimate
    });

    const currentPatient = useSelector((state: RootState) => {
        return state.patientContext.selectedPatient
    });

    const selectedPatient = useSelector((state: RootState) => {
        return state.patientContext.selectedPatient;
    });

    const selectedEncounter = useSelector((state: RootState) => {
        return state.patientContext.selectedEncounter
    });

    const dispatch = useDispatch<AppDispatch>();

    const createVobBody = (
        formik: any,
        selectedEncounter: PatientEncounter,
        vob: Vob = {} as Vob
    ): Vob => {
        const {values, isValid} = formik;
        const currentDateTime = new Date();

        return {
            isValid,
            selfPay: vob?.selfPay || false,
            digitalVerificationMethod: vob?.digitalVerificationMethod || false,
            client: {
                clientId: selectedEncounter.clientId,
                clientName: selectedEncounter.clientName,
            },
            facility: {
                facilityId: selectedEncounter.facilityId,
                facilityName: selectedEncounter.facilityName,
            },
            payer: {...values.payer},
            plan: {...values.plan},
            groupNum: values.groupNum,
            policyNum: values.policyNum,
            liveVOB: values.liveVOB,
            activePolicy: values.activePolicy,
            isCarryover: values.isCarryover,
            policyBeginDate: values.policyBeginDate,
            policyEndDate: values.policyEndDate,
            inNetwDeductible: values.inNetwDeductible!,
            inNetwDeductibleRemaining: values.inNetwDeductibleRemaining!,
            inNetwFamilyDeductible: values.inNetwFamilyDeductible!,
            inNetwFamilyDeductibleRemaining:
                values.inNetwFamilyDeductibleRemaining!,
            inNetwOopIncluded: values.inNetwOopIncluded,
            inNetwOopMax: values.inNetwOopMax!,
            inNetwOopMaxRemaining: values.inNetwOopMaxRemaining!,
            inNetwFamilyOopMax: values.inNetwFamilyOopMax!,
            inNetwFamilyOopMaxRemaining: values.inNetwFamilyOopMaxRemaining!,
            inNetwVobClassifications: values.inNetwVobClassifications,
            ooNetwDeductible: values.ooNetwDeductible!,
            ooNetwDeductibleRemaining: values.ooNetwDeductibleRemaining!,
            ooNetwFamilyDeductible: values.ooNetwFamilyDeductible!,
            ooNetwFamilyDeductibleRemaining:
                values.ooNetwFamilyDeductibleRemaining!,
            ooNetwOopIncluded: values.ooNetwOopIncluded,
            ooNetwOopMax: values.ooNetwOopMax!,
            ooNetwOopMaxRemaining: values.ooNetwOopMaxRemaining!,
            ooNetwFamilyOopMax: values.ooNetwFamilyOopMax!,
            ooNetwFamilyOopMaxRemaining: values.ooNetwFamilyOopMaxRemaining!,
            ooNetwVobClassifications: values.ooNetwVobClassifications,
            vobId: vob?.vobId,
            patientNotes: values.noteText
                ? [
                      {
                          noteText: values.noteText,
                          noteDt: currentDateTime.toISOString(),
                      },
                  ]
                : [],
        };
    };

    async function handleSave(formik: any, didPayorPlanChange?: boolean) {
        const vobPostCopy = createVobBody(
            formik,
            selectedEncounter,
            finpassVOB
        );

        const vobPutBody: VobPostBody = {
            advisorPatientId: selectedPatient.advisorPatientId,
            fpClientId: selectedEncounter.clientId,
            fpClientFacilityId: selectedEncounter.facilityId,
            vobBody: {
                ...vobPostCopy,
            },
        };

        // we have an existing vob
        // update the vob (put)
        // update the estimate
        if(finpassVOB.vobId) {
            await dispatch(saveVOBForFinpass({vob: vobPutBody}));

            if (estimate?.estimateId && finpassVOB.vobId === estimate?.vobId) {
                const createdDate = admissionsAdvisorUtils.formatDateTime(new Date());
                const estimateDescription = `Last Modified - ${createdDate}`;
                const facilityLevelOfCare = didPayorPlanChange ? [] : estimate.facilityLevelOfCare ?? [];

                // estimate could be "empty" or filled in from finadvisor
                const putEstimateBody: Estimate = {
                    vobId: estimate.vobId,
                    advisorPatientId: estimate.advisorPatientId,
                    clientId: estimate.clientId,
                    facilityId: estimate.facilityId,
                    patientEncounterId: estimate.patientEncounterId,
                    description: estimate.description || estimateDescription,
                    quoteMethod: estimate.quoteMethod ?? QuoteMethod.AVGLOS,
                    anticipatedAdmitDate: estimate.anticipatedAdmitDate,
                    facilityLevelOfCare: facilityLevelOfCare,
                    isPlanYearCrossover: estimate.isPlanYearCrossover ?? false,
                    isActive: true,
                    crossoverSelection: estimate.crossoverSelection ?? CrossoverSelectionEnum.BEFORE,
                    financialAssistance: estimate.financialAssistance,
                    priorCare: estimate.priorCare,
                    summarySelection: estimate.summarySelection ?? SummarySelections.NONADJUSTED
                };
    
                await dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: estimate?.estimateId}));
            }
        // we don't have an existing vob
        // create a new advisor patient, vob, and estimate
        } else {

            /**
            * We need an advisorPatientId when creating a VOB.
            * If we don't have an advisor patient record associated with
            * a patient record, we need to create an advisor patient record
            * */
            let advisorPatientId = currentPatient?.advisorPatientId;

            let advisorPatientResponse;
            let vobResponse;
            let estimateResponse;

            if (!advisorPatientId) {
                advisorPatientResponse =
                    await admissionsAdvisorService.saveVobPatient({
                        firstName: currentPatient?.contact?.firstName,
                        lastName: currentPatient?.contact?.lastName,
                        birthDate: currentPatient?.contact?.birthDate,
                        fpPatientId: currentPatient?.patientId,
                        advisorPatientBody: {
                            email: currentPatient?.contact?.email,
                            primaryAddress: currentPatient?.contact?.primaryAddress,
                            phoneNumber: currentPatient?.contact?.primPhoneNum,
                        },
                        clientId: +currentPatient?.clientId!,
                    });
            
                if (advisorPatientResponse?.hasErrors) {
                    dispatch(showErrorStatus(patientHelper.extractErrorMessage(advisorPatientResponse)));
                }
                advisorPatientId = advisorPatientResponse.entity.advisorPatientId;

                dispatch(setSelectedPatientV2({
                    ...currentPatient,
                    advisorPatientId: advisorPatientResponse.entity.advisorPatientId
                }))

            }
        
            vobResponse = await dispatch(postVob({
                ...vobPutBody,
                advisorPatientId,
            }));

            /**
             * In order to connect the IOC to the VOB,
             * we need have an estimateId on the patientEncounter table.
             * So if we don't have an estimate, we need to create one
             * */
            if (!estimate?.estimateId) {
                const createdDate = admissionsAdvisorUtils.formatDateTime(new Date());
                const estimateDescription = `New Estimate - ${createdDate}`;

                const postEstimateBody: Estimate = {
                    vobId: vobResponse.payload.vobId,
                    clientId: vobResponse.payload.fpClientId!,
                    facilityId: vobResponse.payload.fpClientFacilityId!,
                    advisorPatientId: advisorPatientId!,
                    patientEncounterId: selectedEncounter?.patientEncounterId,
                    description: estimateDescription,
                    quoteMethod: QuoteMethod.AVGLOS,
                    facilityLevelOfCare: [],
                    isPlanYearCrossover: false,
                    summarySelection: SummarySelections.NONADJUSTED,
                    crossoverSelection: CrossoverSelectionEnum.BEFORE, 
                };

                estimateResponse = await dispatch(callNewEstimatePost(postEstimateBody));
                
                await dispatch(
                    saveInstanceOfCare({
                        encounter: {
                            ...selectedEncounter,
                            pfrEstimateId: estimateResponse.payload.estimateId,
                        },
                }));
            }
        }
        handleSaveCallback();
    }

    const didVOBPFRValuesChange = (
        originalVOB: Vob,
        updatedVOB: Vob,
        keysToCompare: string[],
    ) => {
        const checkKeys = (element: string, index: number, array: any[]) => {
            return originalVOB[element as keyof typeof originalVOB] !== updatedVOB[element as keyof typeof updatedVOB]
        }
        const didOOPorDeductiblesChange = keysToCompare.some(checkKeys)

        if(didOOPorDeductiblesChange) return true

        // co insurance, max days, and co pays are all nested under
        // vob classifications
        const originalOONetwVobClassifications = originalVOB.ooNetwVobClassifications
        const originalINNetwVobClassifications = originalVOB.inNetwVobClassifications
        const updatedOONetwVobClassifications = updatedVOB.ooNetwVobClassifications
        const updatedINNetwVobClassifications = updatedVOB.inNetwVobClassifications

        // if the length changes, we know we'll need to recalc
        if(originalOONetwVobClassifications!.length !== updatedOONetwVobClassifications!.length) return true
        if(originalINNetwVobClassifications!.length !== updatedINNetwVobClassifications!.length) return true

        // need to loop through the calssifications independently
        // for co-ins, max days, and co-pays
        for(let i = 0; i < originalINNetwVobClassifications!.length; i++) {
            const didCoInsuranceChange = originalINNetwVobClassifications![i].coInsurance !== updatedINNetwVobClassifications![i].coInsurance
            const didCoPayChange = originalINNetwVobClassifications![i].coPay !== updatedINNetwVobClassifications![i].coPay
            const didMaxDaysChange = originalINNetwVobClassifications![i].maxDays !== updatedINNetwVobClassifications![i].maxDays
            const didCoPaySelectionChange = originalINNetwVobClassifications![i].coPaySelection !== updatedINNetwVobClassifications![i].coPaySelection

            if(didCoInsuranceChange || didCoPayChange || didMaxDaysChange || didCoPaySelectionChange) {
                return true
            }
        }

        for(let i = 0; i < originalOONetwVobClassifications!.length; i++) {
            const didCoInsuranceChange = originalOONetwVobClassifications![i].coInsurance !== updatedOONetwVobClassifications![i].coInsurance
            const didCoPayChange = originalOONetwVobClassifications![i].coPay !== updatedOONetwVobClassifications![i].coPay
            const didMaxDaysChange = originalOONetwVobClassifications![i].maxDays !== updatedOONetwVobClassifications![i].maxDays
            const didCoPaySelectionChange = originalOONetwVobClassifications![i].coPaySelection !== updatedOONetwVobClassifications![i].coPaySelection

            if(didCoInsuranceChange || didCoPayChange || didMaxDaysChange || didCoPaySelectionChange) {
                return true
            }
        }

        // nothing changed
        return false
    }

    function handleSaveCallback() {
        handleVOBModalSubmit(isEdit);
    }

    const checkIfCanRemoveClassification = createCheckIfCanRemoveClassification(estimate, dispatch);

    return (
        <VOBModalContents
            open={open}
            isEdit={isEdit}
            classifications={classifications}
            vob={finpassVOB}
            facilityPayersState={facilityPayersState}
            handleSave={handleSave}
            handleVOBModalCancel={handleVOBModalCancel}
            handleVOBModalSubmit={handleVOBModalSubmit}
            checkIfCanRemoveClassification={checkIfCanRemoveClassification}
        />
    );
}
