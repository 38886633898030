import {axiosDeleteHelper} from '../../shared/service/axios-delete-helper';
import {AxiosDeletePayload} from '../../shared/service/axios-delete-payload';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';
import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {
  AxiosResultDeleteStatus,
} from '../../shared/service/axios-result-delete-status';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {axiosSaveHelper} from '../../shared/service/axios-save-helper';
import {AxiosSavePayload} from '../../shared/service/axios-save-payload';
import {
  getPatientStatusByStatusId,
  StatusType,
  WorkflowId,
} from '../components/misc/patient-status';
import {PFRAdjustment} from '../components/models/pfr-adjustment';
import {
  InstanceOfCareViewModel,
} from '../components/models/instance-of-care-view-model';
import {
  PatientChampion,
  PhiHelloSignRequest,
} from '../components/models/patient-champion';
import {
  PatientEncounter, patientEncounterContact,
} from '../components/models/patient-encounter';
import {
  PatientEncounterCard,
} from '../components/models/patient-encounter-card';
import {PatientInsurance} from '../components/models/patient-insurance';
import {
  PatientPaymentProgram,
} from '../components/models/patient-payment-program';
import {
  BaseAPIRequiredPatient,
  PatientViewModel,
} from '../components/models/patient-view-model';
import {Payment, PaymentReversal} from '../components/models/payment';
import {
  deletePatientPaymentProgramModel,
} from '../components/patient-drilldown/payment-programs/payment-programs-modal';
import {authDocDispatchMethod, StatusColors} from '../../shared/enums';
import {
  PAYMENT_METHOD_TYPES,
  PaymentMethod,
  PaymentIntents
} from '../components/models/payment-method';
import {PAYMENT_TYPES, PaymentType} from '../components/models/payment-type';
import {PaymentDetail} from '../components/models/payment-detail';
import {StripePaymentMethod} from '../components/models/stripe-payment-method';
import {Transaction} from '../components/models/transaction';
import {
  PatientClientFilter,
  PatientFacilityFilter,
} from '../components/models/patient-filter';
import {recentlyViewedPatientsObj} from '../../shared/model/recentlyViewed';
import {showErrorStatus} from '../../security/state/user-slice';
import {
  ACHVerification,
} from '../../account-holder/models/verify-ach-payment-method';
import {axiosDownloadHelper} from '../../shared/service/axios-download-helper';
import {PaymentStatus} from '../components/models/payment-status';
import {
  axiosDownloadFileHelper,
} from '../../shared/service/axios-download-file-helper';
import _ from 'lodash';
import {
  FormattedDocument,
  PatientDocument,
  PFRHelloSignRequest,
  SortedDocuments,
  SPAHelloSignRequest,
  UnFormattedDocument,
} from '../components/models/patient-document';
import {Utils} from '../../shared/utils';
import {SignatureStatus} from '../components/models/signature-tracking';
import {Workflow} from '../../shared/model/client';
import {CompletePatientComment} from 'src/shared/model/crm';

export const patientHelper = {
  formatPatient: (patient: PatientViewModel | BaseAPIRequiredPatient) => {
    return {
      ..._.omit(patient, ["patientId"]),
      patientStatus: "NEW",
    };
  },

  filterPreconvertedPatients: (patients: PatientEncounterCard[]) => {
    patients = patientHelper.setStatusColor(patients, WorkflowId.Preconverted);

    // workflow statuses, ids, etc: https://anexinet.atlassian.net/wiki/spaces/FNP/pages/2716730103/Master+Workflow+Database+List

    return {
      reminders: patients.filter((patient) => patient.hasReminder),

      // new
      newPatients: patients.filter(
          (patient) =>
              patient.workflow?.workflowStatus?.workflowStatusId === 13 ||
              patient.workflow?.workflowStatus?.workflowStatusId === null
      ),
      newNone: patients.filter(
          (patient) =>
              patient.workflow?.workflowStatus?.workflowStatusId === 13 && patient.workflow?.workflowSubStatus?.workflowSubStatusId === 7
      ),
      newPendingPaymentAtFacility: patients.filter(
          (patient) =>
              patient.workflow?.workflowSubStatus?.workflowSubStatusId === 66
      ),

      // follow up
      followUpPatients: patients.filter(
        (patient) => patient.workflow?.workflowStatus?.workflowStatusId === 14
      ), // used only for filter 'count'
      awaitingClient: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 17
      ),
      awaitingConsentDocs: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 11
      ),
      awaitingHREApproval: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 13
      ),
      educationalCurriculum: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 8
      ),
      secondContactAttempt: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 15
      ),
      thirdContactAttempt: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 16
      ),
      patientChampion: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 12
      ),
      paymentCurriculum: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 10
      ),
      paymentMethodNeeded: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 14
      ),
      noStatus: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 39
      ),
      hreApproved: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 40
      ),
      hreApprovedPaymentConfirmationRequired: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 46
      ),
      hreApprovedDirectBillFollowUp: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 47
      ),
      mobilePaymentFailureFollowUp: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 63
      ),
      mobilePaymentRetryLimit: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 65
      ),
      followUpNeededPendingPaymentAtFacility: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 67
      ),
      // closed
      closedPatients: patients.filter(
        (patient) => patient.workflow?.workflowStatus?.workflowStatusId === 15
      ), // used only for filter 'count'
      fileError: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 18
      ),
      hreRejectedByPatient: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 20
      ),
      hreRejectedByClient: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 21
      ),
      patientFailedToEngage: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 19
      ),
      patientNeverAdmitted: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 22
      ),
      finpayFailedToEngage: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 41
      ),
      duplicateRecord: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 42
      ),
      patientCoveredAt100Percent: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 43
      ),
      hreApprovedForPaymentAtFacilityPaymentConfirmed: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 44
      ),
      hreApprovedForFullScholarship: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 45
      ),
      mobilePaymentFailureClosed: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 62
      ),
      mobilePaymentRetryLimitClosed: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 64
      ),
    };
  },
  filterConvertedPatients: (patients: PatientEncounterCard[]) => {
    patients = patientHelper.setStatusColor(patients, WorkflowId.Converted);

    return {
      goodStanding: patients.filter(
        (patient) =>
          patient.workflow?.workflowStatus?.workflowStatusId === 8 ||
          patient.workflow?.workflowStatus?.workflowStatusId === 12
      ),
      reminders: patients.filter((patient) => patient.hasReminder),
      paid: patients.filter(
        (patient) => patient.workflow?.workflowStatus?.workflowStatusId === 16
      ),

      // priority
      awaitingAuthorizations: patientHelper.priorityFilter(
        patients,
        "Awaiting Authorizations"
      ),
      awaitingACHVerification: patientHelper.priorityFilter(
        patients,
        "Awaiting ACH Verification"
      ),

      // at risk
      atRisk: patients.filter(
        (patient) => patient.workflow?.workflowStatus?.workflowStatusId === 10
      ), // used only for filter 'count'
      missedPayment: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 25
      ),
      pastDueBalance: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 24
      ),
      onHold: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 26
      ),
      balanceAdjustmentRequired: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 36
      ),
      missingFundingSource: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 59
      ),

      // closed
      closed: patients.filter(
        (patient) => patient.workflow?.workflowStatus?.workflowStatusId === 7
      ), // used only for filter 'count'
      fileError_converted: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 27
      ),
      patientFailedToEngage_converted: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 28
      ),
      patientNeverAdmitted_converted: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 29
      ),
      default: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 37
      ),
      finpayFailedToEngage: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 48
      ),
      duplicateRecord: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 49
      ),
      insurancePaidInFull: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 50
      ),
      accountHolderPaidBalanceOwed: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 51
      ),
      accountHolderPaidBalanceOwedDueARefund: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 52
      ),
      revokedPaymentPlan: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 53
      ),
      providersRequest: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 54
      ),
      refinanced: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 55
      ),
      hreApprovedDirectBillPaymentMadeToFacility: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 56
      ),
      hreApprovedPaymentAtFacility: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 57
      ),
      hreApprovedFullScholarship: patients.filter(
        (patient) =>
          patient.workflow?.workflowSubStatus?.workflowSubStatusId === 58
      ),
    };
  },
  setStatusColor: (patients: PatientEncounterCard[], workflow: WorkflowId) => {
    return patients.map((patientCard) => {
      // Status: NEW
      if (
        patientCard?.workflow?.workflowStatus?.workflowStatusId === null ||
        patientCard?.workflow?.workflowStatus?.workflowStatusId === 13
      ) {
        return {
          ...patientCard,
          cardColor: StatusColors.blue,
        };
      } else {
        return {
          ...patientCard,
          cardColor:
            patientCard?.workflow?.workflowSubStatus?.workflowSubStatusDesc ===
            "None"
              ? getPatientStatusByStatusId(
                  patientCard?.workflow?.workflowStatus?.workflowStatusId,
                  StatusType["primary-status"],
                  workflow
                )?.color
              : getPatientStatusByStatusId(
                  patientCard?.workflow?.workflowSubStatus?.workflowSubStatusId,
                  StatusType["sub-status"],
                  workflow
                )?.color,
        };
      }
    });
  },
  priorityFilter: (patients: PatientEncounterCard[], statusDesc: string) => {
    return patients
      .filter(
        (patient) =>
          patient.workflow.workflowSubStatus?.workflowSubStatusId === 30 || patient.workflow.workflowSubStatus?.workflowSubStatusId === 34
      )
      .filter((patient) => patient?.finClearanceStatus?.includes(statusDesc));
  },

  getNonRefundedDownPayments: (transactions: Transaction[]) => {
    const refundedPaymentIds =
      transactions &&
      transactions?.length > 0 &&
      transactions
        ?.filter(
          (transaction: Transaction) => transaction?.paymentReversal?.paymentId
        )
        .map(
          (transaction: Transaction) => transaction.paymentReversal.paymentId
        );
    const paymentIds =
      transactions &&
      transactions?.length > 0 &&
      new Set(
        transactions
          ?.filter(
            (transaction: Transaction) => transaction?.payment?.paymentId
          )
          .map((transaction: Transaction) => transaction.payment.paymentId)
      );
    const refundedIds: number[] = [];
    // return an array of ids that match each other. Filter those ids out of the transactions array (those payments have been refunded and shouldn't show in this UI.)
    paymentIds &&
      refundedPaymentIds &&
      refundedPaymentIds?.length > 0 &&
      refundedPaymentIds?.forEach((refundedId: number) => {
        if (paymentIds.has(refundedId)) {
          refundedIds.push(refundedId);
        }
      });

    const filteredTransactions =
      transactions &&
      transactions?.length > 0 &&
      transactions
        ?.filter(
          (transaction: Transaction) =>
            !refundedIds?.includes(transaction?.payment?.paymentId) &&
            (transaction?.payment?.paymentType?.paymentTypeId ===
              PAYMENT_TYPES.DOWN_PAYMENT.paymentTypeId ||
              transaction?.payment?.paymentType?.paymentTypeId ===
                PAYMENT_TYPES.AT_FACILITY.paymentTypeId) &&
            transaction?.payment?.paymentStatus !== PaymentStatus.failed
        )
        .map((transaction: Transaction) => transaction.payment);

    return filteredTransactions &&
      filteredTransactions?.length > 0 &&
      filteredTransactions[0]
      ? filteredTransactions
      : [];
  },

  consolidateTransactions: (
    transactions: Transaction[],
    isAccountHolder: boolean
  ) => {
    const refundedPaymentIds =
      transactions &&
      transactions?.length > 0 &&
      new Set(
        transactions
          ?.filter(
            (transaction: Transaction) =>
              transaction?.paymentReversal?.paymentId
          )
          .map(
            (transaction: Transaction) => transaction.paymentReversal.paymentId
          )
      );
    const disputedPaymentIds =
      transactions &&
      transactions?.length > 0 &&
      new Set(
        transactions
          ?.filter(
            (transaction: Transaction) => transaction?.paymentDispute?.paymentId
          )
          .map(
            (transaction: Transaction) => transaction?.paymentDispute?.paymentId
          )
      );
    let groupedTransactions: Transaction[] = [];
    transactions &&
      transactions?.length > 0 &&
      transactions?.forEach((transaction: Transaction) => {
        // search through transactions and look for ones that have payments. Then, see if the transaction has been refunded or not and consolidate it.
        if (transaction?.payment) {
          if (
            refundedPaymentIds &&
            refundedPaymentIds.has(transaction?.payment?.paymentId)
          ) {
            // this means that we have a reversal for this transaction.
            // we need to find that payment reversal and then include it in the transaction.
            if (
              isAccountHolder &&
              transaction?.payment?.paymentMethodUsed?.ach &&
              transaction.payment?.isCaptured &&
              transaction?.payment?.paymentStatus !== "Success"
            ) {
              // do not push to the array if the transaction has a refund (it's an ACH captured transaction that is also not successful).
            } else {
              const matchingPaymentReversal = transactions?.find(
                (searchTransaction: Transaction) =>
                  searchTransaction?.paymentReversal?.paymentId ===
                  transaction?.payment?.paymentId
              );
              groupedTransactions.push({
                payment: transaction?.payment,
                paymentReversal:
                  matchingPaymentReversal?.paymentReversal ||
                  ({} as PaymentReversal),
              });
            }
          } else if (
            disputedPaymentIds &&
            disputedPaymentIds.has(transaction?.payment?.paymentId)
          ) {
            const matchingPaymentDispute = transactions?.find(
              (searchTransaction: Transaction) =>
                searchTransaction?.paymentDispute?.paymentId ===
                transaction?.payment?.paymentId
            );
            groupedTransactions.push({
              payment: transaction?.payment,
              paymentReversal: {} as PaymentReversal,
              paymentDispute: matchingPaymentDispute?.paymentDispute,
            });
          } else {
            // this means that there is no reversal for this transaction but that there is a transaction.
            groupedTransactions.push({
              payment: transaction?.payment,
              paymentReversal: {} as PaymentReversal,
            });
          }
        }
      });
    return groupedTransactions &&
      groupedTransactions?.length > 0 &&
      groupedTransactions[0]
      ? groupedTransactions
      : [];
  },

  checkNonRefundedAchTransactionsOrPaymentMethods: (
    transactions?: Transaction[] | false | undefined,
    paymentMethods?: StripePaymentMethod[] | false | undefined
  ) => {
    const refundedPaymentIds =
      transactions &&
      transactions?.length > 0 &&
      new Set(
        transactions
          ?.filter(
            (transaction: Transaction) =>
              transaction?.paymentReversal?.paymentId
          )
          .map(
            (transaction: Transaction) => transaction.paymentReversal.paymentId
          )
      );
    let doAnyAchTransactionsExist = false;
    let doAnyAchPaymentMethodsExist = false;

    transactions &&
      transactions?.length > 0 &&
      transactions?.forEach((transaction: Transaction) => {
        // when we return false, we exit the loop.
        if (
          transaction?.payment &&
          transaction?.payment?.paymentMethodUsed?.ach
        ) {
          // we found a transaction that is ACH
          if (
            refundedPaymentIds &&
            refundedPaymentIds.has(transaction?.payment?.paymentId)
          ) {
            // this transaction has a refund, so we don't care about it. (it is canceled out by the refund.)
          } else {
            // there is no refund. this transaction is ACH so we need to exit the loop.
            doAnyAchTransactionsExist = true;
          }
        }
      });

    paymentMethods &&
      paymentMethods?.length > 0 &&
      paymentMethods?.forEach((paymentMethod: StripePaymentMethod) => {
        if (
          (paymentMethod?.metadata?.metaData_paymentMethodType?.includes(
            "RECURRING"
          ) ||
            paymentMethod?.metadata?.isRecurring) &&
          paymentMethod?.object === "bank_account"
        ) {
          // this means we found an ACH payment method.
          doAnyAchPaymentMethodsExist = true;
        }
      });

    return doAnyAchPaymentMethodsExist || doAnyAchTransactionsExist; // if either is true then, an ACH transaction or method exists and we can't add another one.
  },

  processAuthorizationDocuments: (
    documents: UnFormattedDocument[],
    iocDocuments?: PatientDocument[]
  ) => {
    /**
     * determine the patient document status. if any document has a status that is not 'sent', then we are pending status.
     * We will take the statuses and boil them down to either 'pending' or 'signed'. i.e. if the status is not pending, then it is signed.
     */
    let iocDocumentStatus = "";
    let doIocDocumentsExist = false;
    if (iocDocuments && iocDocuments?.length > 0) {
      doIocDocumentsExist = true;
      iocDocuments?.forEach((document: PatientDocument) => {
        if (
          document?.signatureTracking?.signatureStatus !==
          SignatureStatus.SignatureCompleted
        ) {
          iocDocumentStatus = "pending";
        }
      });
      if (iocDocumentStatus !== "pending") {
        iocDocumentStatus = "signed";
      }
    }

    const sortedDocuments = {
      overallDocumentStatus: "signed",
      phiConsentDocuments: [],
      spaDocuments: [],
      pfrAdjustmentDocuments: [],
      installmentDocuments: []
    } as SortedDocuments;

    // sort all authorization documents by date. Then when we go into the next loop, every document will already be in the right order in its own sub-array.
    const dateSortedDocuments = _.orderBy(
      documents,
      (document: UnFormattedDocument) => {
        if (document?.authorizationDocumentStatusId) {
          return document?.authorizationDocumentStatusId;
        }
      },
      ["desc"]
    );

    // loop through all documents. sort into the 3 types: PHI, SPA and PFR
    dateSortedDocuments.forEach((document: UnFormattedDocument) => {
      if (document?.authorizationDocuments?.typeOfDoc === "phi") {
        sortedDocuments.phiConsentDocuments.push({
          ...document,
          displayedDocumentStatus: Utils.getDocumentDisplayStatus(
            document.documentStatus || undefined
          ),
        });
      } else if (document?.authorizationDocuments?.typeOfDoc === "spa") {
        sortedDocuments.spaDocuments.push(
          // full pay docs
          {
            ...document,
            displayedDocumentStatus: Utils.getDocumentDisplayStatus(
              document.documentStatus || undefined
            ),
          }
        );
      } else if (document?.authorizationDocuments?.typeOfDoc === "pfr") {
        sortedDocuments.pfrAdjustmentDocuments.push({
          ...document,
          displayedDocumentStatus: Utils.getDocumentDisplayStatus(
            document.documentStatus || undefined
          ),
        });
      } else if (document?.authorizationDocuments?.typeOfDoc === "inst") {
        sortedDocuments.installmentDocuments.push({
          ...document,
          displayedDocumentStatus: Utils.getDocumentDisplayStatus(
              document.documentStatus || undefined
          ),
        });
      }
    });

    /**
     * Determine overall document status.
     *
     * 1. IOC doc exists and Authorization Documents exist.
     *   a. If one is Pending then the status will be Pending.
     *   b. if they are both Success then the status will be Success.
     * 2. IOC doc does NOT exist and Authorization Docs exist.
     *   a. If Authorization Docs are Pending then overall status is Pending.
     *   b. If Authorization Docs status is Success then overall status is Success.
     * 3. IOC doc exists and Authorization Docs do NOT exist.
     *   a. If  IOC docs are Pending then overall status is Pending.
     *   b. If  IOC docs status is Success then overall status is Success.
     * 4. Both don't exist.
     *   a. status will be No Documents.
     */

    sortedDocuments.doPfrAdjustmentDocumentsExist = !!(
      sortedDocuments?.pfrAdjustmentDocuments &&
      sortedDocuments?.pfrAdjustmentDocuments?.length > 0
    );
    sortedDocuments.doPhiConsentDocumentsExist = !!(
      sortedDocuments?.phiConsentDocuments &&
      sortedDocuments?.phiConsentDocuments?.length > 0
    );
    sortedDocuments.doFullPayDocumentsExist = !!(
      sortedDocuments?.spaDocuments && sortedDocuments?.spaDocuments?.length > 0
    );
    sortedDocuments.doInstallmentDocumentsExist = !!(
        sortedDocuments?.installmentDocuments && sortedDocuments?.installmentDocuments?.length > 0
    );

    const recentDocumentsToCheckStatus = [];
    if (sortedDocuments.doPfrAdjustmentDocumentsExist) {
      recentDocumentsToCheckStatus.push(
        sortedDocuments.pfrAdjustmentDocuments[0]
      );
    }
    if (sortedDocuments.doFullPayDocumentsExist) {
      recentDocumentsToCheckStatus.push(sortedDocuments.spaDocuments[0]);
    }
    if (sortedDocuments.doPhiConsentDocumentsExist) {
      recentDocumentsToCheckStatus.push(sortedDocuments.phiConsentDocuments[0]);
    }
    if (sortedDocuments.doInstallmentDocumentsExist) {
      recentDocumentsToCheckStatus.push(sortedDocuments.installmentDocuments[0]);
    }
    // If *every* existing document is signed then it's signed. if any document is pending then it's pending.

    let isEveryDocumentSigned = true;
    let isAnyDocumentPending = false;
    if (recentDocumentsToCheckStatus?.length > 0) {
      recentDocumentsToCheckStatus.forEach((document: FormattedDocument) => {
        if (document?.documentStatus !== "signed") {
          isEveryDocumentSigned = false;
        }
        if (
          document?.documentStatus === "sent" ||
          document?.documentStatus === "created"
        ) {
          isAnyDocumentPending = true;
        }
      });
    } else if (!doIocDocumentsExist) {
      sortedDocuments.overallDocumentStatus = "No Documents";
    }
    if (sortedDocuments.overallDocumentStatus !== "No Documents") {
      if (isEveryDocumentSigned) {
        if (doIocDocumentsExist && iocDocumentStatus === "pending") {
          sortedDocuments.overallDocumentStatus = "Pending Signature";
        } else {
          sortedDocuments.overallDocumentStatus = "Signature Completed";
        }
      } else if (
        isAnyDocumentPending ||
        (doIocDocumentsExist && iocDocumentStatus === "pending")
      ) {
        sortedDocuments.overallDocumentStatus = "Pending Signature";
      }
    }


    return sortedDocuments;
  },

  mapResponseToPaymentDetail: (stripeResponsesToMap: StripePaymentMethod[]) =>
    stripeResponsesToMap.map((stripeResponseToMap: StripePaymentMethod) => {
      const paymentMethod = {
        paymentMethodId: stripeResponseToMap?.id,
        externalPaymentMethodId:
          stripeResponseToMap?.object === "card"
            ? PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId
            : PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId,
        paymentMethodTypeId: stripeResponseToMap?.object === "card" ? 1 : 2,
        externalPaymentId: stripeResponseToMap?.id,
        // paymentAmt?:
        nameOnCard: stripeResponseToMap?.name,
      } as PaymentMethod;

      const paymentType = {
        ...PAYMENT_TYPES.RECURRING, //todo, the stripe response needs to include payment type, an ID to use when deleting, and payment amt
      } as PaymentType;

      return {
        paymentMethods: [paymentMethod],
        paymentType: paymentType,
      } as PaymentDetail;
    }),

  calculateReadjustedPfr: (
    initialPfrAmt: number,
    pfrAdjustments: PFRAdjustment[]
  ) => {
    let newPfrAmt: any = initialPfrAmt;
    if (
      newPfrAmt &&
      typeof newPfrAmt === "number" &&
      pfrAdjustments?.length > 0 &&
      pfrAdjustments?.length > 0 &&
      initialPfrAmt === pfrAdjustments[0]?.preAdjustmentPfrAmt
    ) {
      pfrAdjustments?.forEach(
        (pfrAdjustment: PFRAdjustment) =>
          (newPfrAmt = newPfrAmt + pfrAdjustment.adjustmentAmt)
      );
    } else {
      newPfrAmt = undefined; // pfr has already been adjusted or does not exist. this is here to prevent accidentally re-calculating the pfr twice.
    }
    return newPfrAmt;
  },

  // calculate the actual remaining balance (this is different from the pending remaining balance)
  calculateRemainingBalance: (
    groupedTransactions: Transaction[],
    pfrAmt: number | undefined
  ) => {
    let remainingBalanceSum: number = pfrAmt || 0;

    if (groupedTransactions.length > 0) {
      groupedTransactions.forEach((transaction: Transaction) => {
        if (
          transaction?.payment?.isCaptured &&
          transaction?.payment?.paymentStatusSuccess
        ) {
          // remove from running balance
          remainingBalanceSum -= transaction?.payment?.paymentAmt;
        }
        if (
          transaction?.paymentReversal?.reversalAmount &&
          transaction?.payment?.isCaptured &&
          transaction?.payment?.paymentStatusSuccess
        ) {
          // add to running balance
          remainingBalanceSum += Math.abs(
            transaction?.paymentReversal?.reversalAmount
          );
        }
        if (transaction?.paymentDispute?.disputeStatus === "lost") {
          remainingBalanceSum -= transaction?.paymentDispute?.disputeAmt;
        }
      });
    }
    return remainingBalanceSum;
  },

  getClientsAndFacilities: (allPatientEncounterCards: PatientEncounterCard[]) => {
    // get all patient encounter cards with distinct clientId
    let distinctClients: PatientClientFilter[] = [];
    allPatientEncounterCards.forEach(patientEncounter => {
      const foundIndex = distinctClients.findIndex((item) => item.clientId === patientEncounter.clientId);
      if (foundIndex === -1 && patientEncounter.clientId !== null && patientEncounter.clientId !== 0) {
        distinctClients.push({
          clientId: patientEncounter.clientId,
          clientName: patientEncounter.clientName
        })
      }
    })

    // from above distinct group of patient encounter cards get all distinct facilities
    const arr = distinctClients.map((client) => {
      const patientEncountersWithSameClientId = allPatientEncounterCards.filter((patientEncounter) => {
        return patientEncounter.clientId === client.clientId;
      });

      let distinctFacilities: PatientFacilityFilter[] = [];
      patientEncountersWithSameClientId.forEach(patientEncounter => {
        const foundIndex = distinctFacilities.findIndex((item) => item.clientFacilityId === patientEncounter.clientFacilityId);
        if (foundIndex === -1 && patientEncounter.clientFacilityId !== null) {
          distinctFacilities.push({
            clientFacilityId: patientEncounter.clientFacilityId,
            clientFacilityName: patientEncounter.clientFacilityName,
          });
        }
      })

      return {
        clientId: client.clientId,
        clientName: client.clientName,
        facilities: distinctFacilities
      }
    })

    //alphabetizing clients
    arr.sort((a,b) => {
      return a.clientName.toLowerCase().localeCompare(b.clientName.toLowerCase());
    }).forEach((clientObj) => {
      //alphabetizing facilities
      clientObj.facilities.sort((a, b) => {
        return a.clientFacilityName.toLowerCase().localeCompare(b.clientFacilityName.toLowerCase())
      })
    })

    return arr
  },

  removeNotFoundPatients: (
    patientsFromCookie: recentlyViewedPatientsObj[] | undefined,
    patientstFromState: PatientEncounterCard[]
  ) => {
    return patientsFromCookie?.filter((patient) => {
      let returnPatient;
      patientstFromState.forEach((preConvertedPatient) => {
        if (
          patient.patientId === preConvertedPatient.patientId &&
          patient.encounterId === preConvertedPatient.patientEncounterId
        ) {
          returnPatient = patient;
        }
      });
      return returnPatient;
    });
  },

  robustErrorHandler: (
    error: {
      entity: { code: string; message: string };
      errorMessage: string;
      hasErrors: boolean;
    },
    thunkAPI: any
  ) => {
    const { entity, errorMessage } = error;
    if (
      (entity?.code !== "INTERNAL_SERVER_ERROR" && entity?.message) ||
      entity?.message
    ) {
      // handle external error
      thunkAPI.dispatch(showErrorStatus(entity?.message));
      throw new Error(entity?.message);
    } else {
      // handle internal error
      thunkAPI.dispatch(showErrorStatus(errorMessage));
      throw new Error(errorMessage);
    }
  },

  extractErrorMessage: (apiResponse: {
    entity: { code: string; message: string };
    errorMessage: string;
    hasErrors: boolean;
  }) => {
    return apiResponse.entity?.message || apiResponse.errorMessage;
  },
};
export class PatientService {
  async getPatients(
    offset: number,
    limit: number,
    isPreConverted: boolean,
    dateRange: string
  ): Promise<AxiosResultStatus> {
    let patientType = isPreConverted ? "preconverted" : "converted";

    let range: string;
    if (dateRange !== "3") {
      // 6, 12, or 0 (zero means all)
      range = `&range=${dateRange}`;
    } else {
      range = ""; // no range in querystring. Defaults to 3 (months)
    }

    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `patientrecord/listing/${patientType}/?offset=${offset}&limit=${limit}${range}`,
    };
    return await axiosReadHelper(readPayload);
  }

  async getIOCCards(data: {
    offset: number;
    limit: number;
    dateRange: string;
    isConverted: boolean;
  }): Promise<AxiosResultStatus> {
    const { offset, limit, dateRange, isConverted } = data;

    let range: string;
    if (dateRange !== "3") {
      // 6, 12, or 0 (zero means all)
      range = `&range=${dateRange}`;
    } else {
      range = ""; // no range in querystring. Defaults to 3 (months)
    }

    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `patient-encounter/v2/patient/encounter?fetchIOCCards=true&offset=${offset}&limit=${limit}&isConverted=${isConverted}${range}`,
    };
    return await axiosReadHelper(readPayload);
  }

  async getPatient(patientId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: patientId,
      url: "patient/v2/patient",
    };
    return await axiosReadHelper(readPayload);
  }

  async savePatient(
    patient: PatientViewModel | BaseAPIRequiredPatient
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: patientHelper.formatPatient(patient),
      dataId: patient.patientId,
      url: "patient/v2/patient",
    };
    return await axiosSaveHelper(payload);
  }

  //update method name once above one is deprecated
  async updatePatient(
    data: any,
    patientId: number,
    cancelSignal?: AbortSignal
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: data,
      dataId: patientId,
      url: "patient/v2/patient",
      signal: cancelSignal,
    };
    return await axiosSaveHelper(payload);
  }

  mapInstanceOfCareToRequiredPayload(instanceOfCare: PatientEncounter, resetLockedFields: boolean){

    const instanceOfCareCreateProperties = {
      timingRisk: instanceOfCare.timingRisk,
      payorRisk: instanceOfCare.payorRisk,
      riskClass: instanceOfCare.riskClass,
      patientId: instanceOfCare.patientId,
    };

    const instanceOfCareUpdateProperties = {
      timingRiskId: instanceOfCare.timingRisk.timingRiskId,
      payorRiskId: instanceOfCare.payorRisk.payorRiskId,
      riskClassId: instanceOfCare.riskClass.riskClassId,
    }

    return {
      clientId: instanceOfCare.clientId,
      facilityId: instanceOfCare.facilityId,
      clientsPatientAccountId: instanceOfCare.clientsPatientAccountId,
      clientsPatientIOCId: instanceOfCare.clientsPatientIOCId,
      instanceCreateDt: instanceOfCare.instanceCreateDt,
      pfrAmt: instanceOfCare.pfrAmt,
      ...instanceOfCare?.levelOfCare ? {levelOfCare: instanceOfCare.levelOfCare} : {},
      admissionDt:instanceOfCare.admissionDt,
      dischargeDt: instanceOfCare.dischargeDt,
      paidToPatient: instanceOfCare.paidToPatient,
      isRiskClassOverriden: instanceOfCare.isRiskClassOverriden,
      ...instanceOfCare.patientEncounterId === 0 ? instanceOfCareCreateProperties : instanceOfCareUpdateProperties,
      ...resetLockedFields && {
        isLocked: false,
        lockedByUserId: null,
        lockRequestedById: null, // may not need this
      },
      pfrEstimateId: instanceOfCare.pfrEstimateId
    };


  };

  async PaymentReceipt( paymentId: number, receiptEmail?: string | undefined ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: receiptEmail ? { receiptEmail } : {},
      dataId:0,
      isPatch: false,
      url: `payment/v2/payment/${paymentId}/receipt`,
    }
  
    return await axiosSaveHelper(payload);
  }

  async resendRefundReceipt(
    receiptEmail: string,
    paymentReversalId: number
): Promise<AxiosResultStatus>{
  const payload: AxiosSavePayload = {
    dataToSave: {receiptEmail},
    dataId: 0,
    isPatch: false,
    url:`payment/v2/refund/${paymentReversalId}/receipt`
};
return await axiosSaveHelper(payload)
}

  async saveInstanceOfCare(
    instanceOfCare: PatientEncounter,
    resetLockedFields = true
  ): Promise<AxiosResultStatus> {

    const payload: AxiosSavePayload = {
      dataToSave: this.mapInstanceOfCareToRequiredPayload(instanceOfCare, resetLockedFields),
      dataId: instanceOfCare.patientEncounterId,
      url:
        instanceOfCare.patientEncounterId === 0
          ? `patient-encounter/v2/patient/encounter?minor_version=1`
          : `patient-encounter/v2/patient/encounter`,
    };

    return await axiosSaveHelper(payload);
  }

  async updateInstanceOfCare(
    patientEncounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: {
        vobDate: new Date().toISOString(),
        patientEncounterId: patientEncounterId,
      },
      dataId: -2, // less than 0 is post...
      isPatch: true, // but is patch true makes it a put... yikes
      url: `patient-encounter/v2/patient/encounter/${patientEncounterId}`,
    };
    const saveResult = await axiosSaveHelper(payload);

    if (!saveResult.hasErrors) {
      saveResult.entity = this.mapToInstanceOfCareOverview(saveResult.entity);
    }
    return saveResult;
  }

  // pass any individual IOC fields under 'data'; encounterId is required.
  async saveIOCWithPartialObject(
    data: any,
    encounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: { ...data },
      dataId: -2,
      isPatch: true,
      url: `patient-encounter/v2/patient/encounter/${encounterId}`,
    };
    return await axiosSaveHelper(payload);
  }

  async getEvaluateRule(
    instanceOfCare: PatientEncounter
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: instanceOfCare,
      dataId: 0,
      url: `patientrecord/patient/${instanceOfCare.patientId}/encounter/${instanceOfCare.patientEncounterId}/evaluaterule`,
    };
    return await axiosSaveHelper(payload);
  }

  async saveInsurance(data: {
    insurance: PatientInsurance;
    patientId: number;
    encounterId: number;
  }) {
    const { insurance, patientId, encounterId } = data;
    const payload: AxiosSavePayload = {
      dataToSave: insurance,
      dataId: insurance.patientInsuranceId,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/insurance`,
    };
    return await axiosSaveHelper(payload);
  }

  async savePatientChampion(data: {
    champion: PatientChampion;
    patientId: number;
    encounterId: number;
  }): Promise<AxiosResultStatus> {
    const { champion} = data;
    const isUpdate = champion.patientChampionId > 0
    const payload: AxiosSavePayload = {
      dataToSave: _.omit(champion, ["patientChampionId"]),
      dataId: champion.patientChampionId,
      url: `patient-encounter/v2/patient/encounter/champion` + (isUpdate ? `/${champion.patientChampionId}`: ""),
      isPatch: isUpdate
    };
    return await axiosSaveHelper(payload);
  }

  async updatePatientChampion(
    champion: any,
    championId: number,
    cancelSignal?: AbortSignal
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: _.omit(champion, ["patientChampionId"]), //omitting pk just in case
      dataId: championId,
      url: `patient-encounter/v2/patient/encounter/champion/${championId}`,
      isPatch: true,
      signal: cancelSignal,
    };
    return await axiosSaveHelper(payload);
  }

  async createCustomer(
    customer: any,
    cancelSignal?: AbortSignal
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: customer, //omitting pk just in case
      url: `payment/v2/customer`,
      signal: cancelSignal,
      dataId: 0,
    };
    return await axiosSaveHelper(payload);
  }

  async createPatientChampion(
    champion: any,
    cancelSignal?: AbortSignal
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: champion, //omitting pk just in case
      url: `patient-encounter/v2/patient/encounter/champion`,
      signal: cancelSignal,
      dataId: 0,
    };
    return await axiosSaveHelper(payload);
  }

  async savePaymentAdjustment(data: PFRAdjustment): Promise<AxiosResultStatus> {
    if (!data.patientPFRId) {
      return {
        entity: "",
        hasErrors: true,
        errorMessage: "Missing patientPFRId",
      };
    }
    const adjustmentValueToSave = {
        adjustmentAmt: data.adjustmentAmt,
        adjustmentReasonId: data.adjustmentReasonId,
    };
    const payload: AxiosSavePayload = {
      dataToSave: adjustmentValueToSave,
      dataId: 0,
      url: `patient-encounter/v2/patient/encounter/${data.patientEncounterId}/pfrAdjustment`,
    };
    return await axiosSaveHelper(payload);
  }

  async savePatientPaymentProgram(data: {
    paymentProgram: PatientPaymentProgram;
    patientId: number;
    encounterId: number;
  }): Promise<AxiosResultStatus> {
    const { paymentProgram, patientId, encounterId } = data;
    paymentProgram.patientId = patientId;
    paymentProgram.patientEncounterId = encounterId;
    const payload: AxiosSavePayload = {
      dataToSave: {
        ...paymentProgram,
        patientPaymentSchedule: {
          ...paymentProgram.patientPaymentSchedule,
          pfrBalance: undefined,
          pfrAmt: paymentProgram.patientPaymentSchedule.pfrAmt || 0,
        }
      },
      dataId: paymentProgram.patientPaymentProgramId,
      url: `payment/v2/program`,
    };
    return await axiosSaveHelper(payload);
  }

  async deletePatientPaymentProgram(
    data: deletePatientPaymentProgramModel
  ): Promise<AxiosResultDeleteStatus> {
    const { paymentProgramId, patientId, encounterId } = data;
    const deletePayload: AxiosDeletePayload = {
      dataId: paymentProgramId,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/program`,
    };
    return await axiosDeleteHelper(deletePayload);
  }

  async convertPatientEncounter(
    patientId: number,
    encounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: {}, // do not need to send encounter object on Convert.
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/convert`,
    };
    return await axiosSaveHelper(payload);
  }

  async getPatientDemographics(patientId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: patientId,
      url: `patientrecord/patient`,
    };
    return await axiosReadHelper(payload);
  }

  async getDocuments(
    patientId: number,
    encounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/document/listing`,
    };
    return await axiosReadHelper(payload);
  }

  async getTransactions(
    patientId: number,
    encounterId: number
  ): Promise<AxiosResultStatus> {

    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/transactions`,
    };

    return await axiosReadHelper(payload);
  }

  async getLedger(
    limit: number,
    offset: number,
    patientId?: number,
    encounterId?: number,
  ): Promise<AxiosResultStatus> {
    // let url = `v2/payment/ledger?` // local testing purposes, set dataId to something unique and handling in readHelper
    let url = `payment/v2/payment/ledger?`

    if (encounterId) {
      url += `encounterId=${encounterId}`
    } else if (patientId) {
      url += `patientId=${patientId}`
    }

    url += `&limit=${limit}&offset=${offset}`

    const payload: AxiosReadPayload = {
      dataId: 0,
      url: url,
    };
    
    const result = await axiosReadHelper(payload);
    return result;
  }
  
  async getPaymentDetails(
    ledgerId: number
  ): Promise<AxiosResultStatus> {
    // let url = `v2/payment?ledgerId=${ledgerId}` // local testing purposes, set dataId to something unique and handling in readHelper
    let url = `payment/v2/payment?ledgerId=${ledgerId}`

    const payload: AxiosReadPayload = {
      dataId: 0,
      url: url,
    };
    
    const result = await axiosReadHelper(payload);

    return result.entity;
  }

  async getPfrDetails(
    ledgerId: number
  ): Promise<AxiosResultStatus> {
    // let url = `v2/payment?ledgerId=${ledgerId}` // local testing purposes, set dataId to something unique and handling in readHelper
    let url = `payment/v2/pfradjustment?ledgerId=${ledgerId}`

    const payload: AxiosReadPayload = {
      dataId: 0,
      url: url,
    };
    
    const result = await axiosReadHelper(payload);

    return result.entity;
  }

  async getDisputeDetails(
    ledgerId: number
  ): Promise<AxiosResultStatus> {
    // let url = `v2/dispute?ledgerId=${ledgerId}` // local testing purposes, set dataId to something unique and handling in readHelper
    let url = `payment/v2/dispute?ledgerId=${ledgerId}`

    const payload: AxiosReadPayload = {
      dataId: 0,
      url: url,
    };
    
    const result = await axiosReadHelper(payload);

    return result.entity;
  }

  async getReversalDetails(
    ledgerId: number
  ): Promise<AxiosResultStatus> {
    // let url = `v2/reversal?ledgerId=${ledgerId}` // local testing purposes, set dataId to something unique and handling in readHelper
    let url = `payment/v2/reversal?ledgerId=${ledgerId}`

    const payload: AxiosReadPayload = {
      dataId: 0,
      url: url,
    };
    
    const result = await axiosReadHelper(payload);
    return result.entity;
  }

  async getPaymentMethods(
    patientId: number,
    encounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/paymentmethod/listing`,
    };

    return await axiosReadHelper(payload);
  }

  async getContactInfo(
    patientId: number,
    encounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `patient-encounter/v2/encounter/${encounterId}/contact`,
    };

    return await axiosReadHelper(payload);
  }

  async updateContactInfo(
    patientId: number,
    encounterId: number,
    patientContactInfo: patientEncounterContact
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: patientContactInfo,
      dataId: encounterId,
      isPatch: true,
      url: `patient-encounter/v2/encounter/${encounterId}/contact`,
    };

    return await axiosSaveHelper(payload);
  }

  async addPatientPaymentMethod(
    patientId: number,
    encounterId: number,
    paymentDetail: PaymentDetail
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: paymentDetail,
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/paymentmethod`,
    };
    return await axiosSaveHelper(payload);
  }

  async deletePatientPaymentMethod(
    patientId: number,
    encounterId: number,
    stripeId: string,
    paymentTypeId: number
  ): Promise<AxiosResultDeleteStatus> {
    const deletePayload: AxiosDeletePayload = {
      dataId: paymentTypeId,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/paymentmethod/${stripeId}/paymentmethodtype`,
    };
    return await axiosDeleteHelper(deletePayload);
  }

  async verifyBankAccount(
    patientId: number,
    encounterId: number,
    microdeposits: ACHVerification
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: microdeposits,
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/ach-verification`,
    };
    return await axiosSaveHelper(payload);
  }

  async makePayment(payment: Payment): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: payment,
      dataId: 0,
      url: `patientrecord/patient/${payment.patientId}/encounter/${payment.patientEncounterId}/payment`,
    };
    return await axiosSaveHelper(payload);
  }

  async createPayment(payment: Payment): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: payment,
      dataId: 0,
      url: `payment/v2/payment`,
    };
    return await axiosSaveHelper(payload);
  }

  async listPaymentMethodsByContactId(contactId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `payment/v2/contacts/${contactId}/paymentmethods`,
    };
    return await axiosReadHelper(payload);
  }

  async releaseCharge(
    payment: Payment
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: payment,
      dataId: 0,
      url: `payment/v2/payment/${payment.paymentId}/refund`,
    };
    return await axiosSaveHelper(payload);
  }

  async getPatientInstanceOfCare(
    data: { patientId: number; encounterId: number | string },
    cancelSignal?: AbortSignal
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: data.encounterId,
      url: `patient-encounter/v2/patient/encounter`,
      signal: cancelSignal,
    };
    return await axiosReadHelper(payload);
  }

  async getPatientEncounterRiskClass(
    facilityId: number,
    paidToPatient: boolean,
    timingRiskName: string,
    payorRiskName: string,
    pfrAmt: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: -1,
      url: `patient-encounter/v2/patient/encounter/riskClass?facilityId=${facilityId}&paidToPatient=${paidToPatient}&payorRiskName=${payorRiskName}&timingRiskName=${timingRiskName}&pfrAmt=${pfrAmt}`,
    };
    return await axiosReadHelper(payload);
  }

  async getPatientEncounterSignUrl(data: {
    patientId: number;
    encounterId: number;
  }): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url: `patientrecord/patient/${data.patientId}/encounter/${data.encounterId}/signingurl`,
    };
    return await axiosReadHelper(payload);
  }

  async getForwardedSignUrl(
    helloSignRequestId: string
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url: `sign-document/${helloSignRequestId}`,
    };
    return await axiosReadHelper(payload);
  }

  async saveStatus(data: {
    workFlow: Workflow | Omit<Workflow, "workflowName">;
    encounterId: number;
    patientId: number;
  }) {
    const { workFlow, encounterId } = data;
    const payload: AxiosSavePayload = {
      dataToSave: workFlow,
      dataId: encounterId,
      isPatch: true,
      url: `patient-encounter/v2/patient/encounter/${encounterId}/status`,
    };
    return await axiosSaveHelper(payload);
  }

  async downloadHelloSignDocument(
    patientId: number,
    encounterId: number,
    externalDocumentId: any
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/signdocument/${externalDocumentId}/download`,
      customHeader: {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
      },
    };
    return await axiosDownloadHelper(readPayload);
  }

  async helloSignReminder(patientEncounter: PatientEncounter) {
    const { patientId, patientEncounterId } = patientEncounter;
    const { patientDocumentId } = patientEncounter.patientDocument?.[0];

    const payload: AxiosSavePayload = {
      dataToSave: {}, // no payload
      dataId: 0, // 0 means POST
      url: `patientrecord/patient/${patientId}/encounter/${patientEncounterId}/document/${patientDocumentId}/remind`,
    };
    return await axiosSaveHelper(payload);
  }

  async helloSignForward(patientEncounter: PatientEncounter, email: string) {
    const { patientId, patientEncounterId } = patientEncounter;
    const { patientDocumentId } = patientEncounter.patientDocument?.[0];

    const payload: AxiosSavePayload = {
      dataToSave: { email: email },
      dataId: 0, // 0 means POST
      url: `patientrecord/patient/${patientId}/encounter/${patientEncounterId}/document/${patientDocumentId}/forward`,
    };
    return await axiosSaveHelper(payload);
  }

  //need to ask what the purpose of this mapping function is
  mapToInstanceOfCareOverview(instanceOfCare: InstanceOfCareViewModel) {
    return {
      patientEncounterId: instanceOfCare.patientEncounterId,
      patientId: instanceOfCare.patientId,
      clientId: instanceOfCare.clientId,
      clientName: instanceOfCare.clientName,
      facilityId: instanceOfCare.facilityId,
      facilityName: instanceOfCare.facilityName,
      hasChampions: instanceOfCare.hasChampions,
      clientsPatientAccountId: instanceOfCare.clientsPatientAccountId,
      clientsPatientIOCId: instanceOfCare.clientsPatientIOCId,
      instanceCreateDt: instanceOfCare.instanceCreateDt,
      pfrAmt: instanceOfCare.pfrAmt,
      levelOfCare: instanceOfCare.levelOfCare,
      timingRisk: instanceOfCare.timingRisk,
      payorRisk: instanceOfCare.payorRisk,
      riskClass: instanceOfCare.riskClass,
      admissionDt: instanceOfCare.admissionDt,
      dischargeDt: instanceOfCare.dischargeDt,
      paidToPatient: instanceOfCare.paidToPatient,
      client: instanceOfCare.client,
      workflow: instanceOfCare.workflow,
      //need to include autoCalcenabled, isRiskClassOverriden, mceSent, romVersion, vobDate?
    } as InstanceOfCareViewModel;
  }

  async uploadS3File(
    patientId: string,
    encounterId: string,
    file: any
  ): Promise<AxiosResultStatus> {
    let data = new FormData();
    data.append("file", file);
    const payload: AxiosSavePayload = {
      dataToSave: data,
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/document`,
    };

    return await axiosSaveHelper(payload);
  }

  async getS3Documents(
    patientId: number,
    encounterId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `patient-encounter/v2/patient/${patientId}/encounter/${encounterId}/s3document`,
    };
    return await axiosReadHelper(payload);
  }

  async deleteS3Document(
    patientId: string,
    encounterId: string,
    s3Document: string
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `patientrecord/patient/${patientId}/encounter/${encounterId}/s3document/delete/${s3Document}`,
    };
    return await axiosReadHelper(readPayload);
  }

  async downloads3Document(
    patientId: string,
    encounterId: string,
    s3Document: string
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      url: `patient-encounter/v2/patient/${patientId}/encounter/${encounterId}/s3document/${s3Document}`,
    };

    if (s3Document.split(".").pop() === "pdf") {
      readPayload.customHeader = {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
      };
    }

    const responseData = await axiosDownloadFileHelper(readPayload);

    const url = window.URL.createObjectURL(
      new Blob([responseData.entity.data], {
        type: responseData.entity.headers["content-type"],
      })
    );

    const link = document.createElement("a");
    link.href = url;
    link.download = s3Document;
    document.body.appendChild(link);
    link.click();
    link.remove();

    responseData.entity = null;

    return responseData;
  }

  async getScriptDocument(
    patientEncounter: PatientEncounter
  ): Promise<AxiosResultStatus> {
    const { clientId, riskClass } = patientEncounter;

    const readPayload: AxiosReadPayload = {
      dataId: -1,
      url: `encounter/client-id/${clientId}/risk-class/${riskClass.riskClassId}/script`,
      customHeader: {
        Accept: "application/pdf",
        "Content-Type": "application/pdf",
      },
    };
    return await axiosReadHelper(readPayload);
  }

  async updateDocumentSignature(data: {
    data: any,
    encounterId: number
  }): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: data.data,
      dataId: -2,
      isPatch: true,
      url: `patient-encounter/v2/patient/encounter/${data.encounterId}/document`,
    };
    return await axiosSaveHelper(payload);
  }

  async createPhiHelloSignRequest(
    phiRequest: PhiHelloSignRequest
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: phiRequest,
      dataId: -1,
      isPatch: false,
      url: `patient-encounter/v2/patient/encounter/phiDocument/signature`,
    };
    const saveResult = await axiosSaveHelper(payload);

    return saveResult.entity;
  }

  async createSPAHelloSignRequest(
    spaRequest: SPAHelloSignRequest
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: spaRequest,
      dataId: -1,
      isPatch: false,
      url: `patient-encounter/v2/patient/encounter/spaDocument/signature`,
    };
    const saveResult = await axiosSaveHelper(payload);

    return saveResult.entity;
  }

  async createPFRHelloSignRequest(
    pfrRequest: PFRHelloSignRequest
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: pfrRequest,
      dataId: -1,
      isPatch: false,
      url: `patient-encounter/v2/patient/encounter/pfrDocument/signature`,
    };
    const saveResult = await axiosSaveHelper(payload);

    return saveResult.entity;
  }

  async updatePFRDocument(
    wasPfrAdjustmentSent: boolean,
    documentId: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: { wasPfrAdjustmentSent },
      dataId: documentId,
      isPatch: false,
      url: `patient-encounter/v2/patient/encounter/authorizationDocumentStatus`,
    };
    const saveResult = await axiosSaveHelper(payload);
    return saveResult.entity;
  }

  async integrationUpdate(data: {
    patientEncounterId: number;
    patientId: number;
    crmTypeSlug?: string;
  }): Promise<any> {
    const instanceOfCare = await this.getPatientInstanceOfCare({
      patientId: data.patientId,
      encounterId: data.patientEncounterId,
    });

    const payload: AxiosSavePayload = {
      dataToSave: instanceOfCare.entity,
      dataId: 0,
      url: `${data.crmTypeSlug}/event/finpay`,
    };
    if(instanceOfCare?.entity?.clientsPatientIOCId || !data.crmTypeSlug) {
      return await axiosSaveHelper(payload);
    }
  }

  async integrationAddComment(data: {
    comment: CompletePatientComment;
    crmTypeSlug?: string;
  }): Promise<any> {

    const payload: AxiosSavePayload = {
      dataToSave: data.comment,
      dataId: 0,
      url: `${data.crmTypeSlug}/event/comment`
    };

    return await axiosSaveHelper(payload);
  }

  async integrationVOBUpdate(data: {
    vob: any;
    crmTypeSlug?: string;
  }): Promise<any> {

    const payload: AxiosSavePayload = {
      dataToSave: data.vob,
      dataId: 0,
      url: `${data.crmTypeSlug}/event/vob`,
    };
    return await axiosSaveHelper(payload);
  }

  async getPatientIdsWithFilter(filter: string): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: -1,
      url: `patient/v2/patient/keys?${filter}`,
    };
    return await axiosReadHelper(payload);
  }

  async getPatientInstancesOfCare(data: {
    patientId: number;
    limit?: number;
    offset?: number;
  }): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url: `patient-encounter/v2/patient/encounter?patientId=${data.patientId}&&limit=${data.limit}&&offset=${data.offset}`,
    };
    return await axiosReadHelper(payload);
  }

  async getEncounterByClientsPatientId(data: {
    clientPatientIOCId: string;
    clientId: number
  }): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: `patient-encounter/v2/patient/encounter/keys?clientsPatientIOCId=${data.clientPatientIOCId}&clientId=${data.clientId}`,
    };
    return await axiosReadHelper(payload);
  }

  async getEncounterByClientsPatienAccountId(data: {
      clientsPatientAccountId: string;
      clientId: number;
  }): Promise<AxiosResultStatus> {
      const payload: AxiosReadPayload = {
          dataId: 0,
          url: `patient-encounter/v2/patient/encounter/keys?clientsPatientAccountId=${data.clientsPatientAccountId}&clientId=${data.clientId}`,
      };
      return await axiosReadHelper(payload);
  }

  async getPtRecord(
    leadId: string,
    clientId: number,
    crmTypeSlug: string
  ): Promise<AxiosResultStatus> {

    const patientRecordUrl = `${crmTypeSlug}/patient-record?clientId=${clientId}&leadId=${leadId}`;
    const payload: AxiosReadPayload = {
      dataId: 0,
      url: patientRecordUrl,
    };
    return await axiosReadHelper(payload);
  }

  async sendInstanceOfCareToMobileQueue(
    patientEncounterId: number,
    conditionsToCheck?: {
      checkPFRAgainstThreshold: boolean
    }
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: conditionsToCheck ? conditionsToCheck : {},
      dataId: 0,
      url: `patient-encounter/v2/patient/encounter/${patientEncounterId}/mobileQueue`,
    };
    return await axiosSaveHelper(payload);
  }

  async updateAuthorizationDocumentStatus(
      data: {
        dataToSave: {
          documentStatus?: string,
          externalSignatureRequestId?: string,
          wasPfrAdjustmentSent?: boolean,
        },
        authorizationDocumentStatusId: number
      }
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: data.dataToSave,
      dataId: data.authorizationDocumentStatusId,
      isPatch: false,
      url: `patient-encounter/v2/patient/encounter/authorizationDocumentStatus`,
    }
    return await axiosSaveHelper(payload);
  }

  async createNoSigHelloSignDoc(
      data: {
        authorizationDocumentStatusId: number
      }
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataId: 0,
      isPatch: false,
      url: `patient-encounter/v2/patient/encounter/authorizationDocumentStatus/${data.authorizationDocumentStatusId}/noSigDocument`,
    }
    return await axiosSaveHelper(payload);
  }

  getAuthDocQueryStringParam(dispatchMethod?: authDocDispatchMethod){

    let queryStringParam;

    switch(dispatchMethod){
      case authDocDispatchMethod.email:
        queryStringParam = '?sendEmail=true'
        break;
      case authDocDispatchMethod.sms:
        queryStringParam = '?sendSms=true'
        break;
      default:
        queryStringParam = ""
    }

    return queryStringParam
  }

  async spaDocument(
      encounterId: number,
      dispatchMethod?: authDocDispatchMethod): Promise<AxiosResultStatus> {

    const payload: AxiosSavePayload = {
      dataToSave: {},
      dataId: 0,
      url: `patient-encounter/v2/patient/encounter/${encounterId}/authDocs/spaDocument${this.getAuthDocQueryStringParam(dispatchMethod)}`,
    };
    return await axiosSaveHelper(payload);
  }

  async installmentDocument(
      encounterId: number,
      dispatchMethod?: authDocDispatchMethod): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: {},
      dataId: 0,
      url: `patient-encounter/v2/patient/encounter/${encounterId}/authDocs/installmentDocument${this.getAuthDocQueryStringParam(dispatchMethod)}`,
    };
    return await axiosSaveHelper(payload);
  }

  async createPaymentMethod(paymentMethod: PaymentMethod): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: paymentMethod,
      dataId: 0,
      url: `payment/v2/payment/payment-method`,
    };
    return await axiosSaveHelper(payload);
  }

  async createPaymentIntents(paymentIntents: PaymentIntents): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: paymentIntents,
      dataId: 0,
      url: `payment/v2/payment/paymentintent`,
    };
    return await axiosSaveHelper(payload);
  }

  async updatePaymentIntents(paymentIntentId: string, paymentIntents: PaymentIntents): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: paymentIntents,
      dataId: 1,
      url: `payment/v2/payment/paymentintent/${paymentIntentId}`,
    };
    return await axiosSaveHelper(payload);
  }
}

export const patientService = new PatientService();
