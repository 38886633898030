import './App.scss';

import { Amplify } from '@aws-amplify/core';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { LoadingOverlay, Snackbar, SubNavigation } from '@finpay-development/shared-components';
import { CircularProgress, Grid } from '@mui/material';
import jwt from 'jwt-decode';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import {
  setIsPublicMode,
  setPathName,
  setUrlParameters,
} from './admin/state/users/admin-slice';
import { setIsChannel, setChannelType } from './guest/state/guest-slice';
import { PublicPagesWrapper } from './guest/components/PublicPagesWrapper';
import { isCurrentUrlPublic } from './guest/misc/helpers';
import RouteConfiguration, { RouteConfigProps } from './route-configuration';
import AuthenticationMonitor from './security/components/authentication-monitor';
import ConfirmSignIn from './security/components/confirm-sign-in';
import CreatePassword from './security/components/create-password';
import ForgetPassword from './security/components/forget-password';
import GuardedComponent from './security/components/guarded-component';
import Login from './security/components/login';
import SessionTimeout from './security/components/session-timeout';
import { hideStatus } from './security/state/user-slice';
import { checkIfUserIsAuthenticated, logUserOut, refreshToken } from './security/state/user-thunk';
import FppFooter from './shared/components/footer';
import FppHeader from './shared/components/header';
import { getConfiguration } from './shared/configuration/config-settings';
import { DecodedIdToken } from './shared/model/decodedIdToken';
import { RootState } from './shared/state/root-reducer';
import { AppDispatch } from './shared/state/store';
import FinDigitalPlatformFooter from './shared/components/findigital-platform-footer';
import {getMetaData} from './meta-data/state/meta-data-thunk';

// get the configuration based on current url dns name
const config = getConfiguration();

let cookieDomain;

switch(true){
  case window.location.hostname.includes('localhost'):
    cookieDomain = window.location.hostname.includes('patient') ? 'patient.localhost' : '.localhost'
    break;
  case window.location.hostname.includes('myfinpay'):
    cookieDomain = ".myfinpay.net"
    break;
  default:
    //Need to support config, report, and admin portals.
    //Prod env doesn't have the env in the domain name
    cookieDomain = config.app_environment==='prod' ? ".finpay.net" : `.${config.app_environment}.finpay.net`
}

// Setup Amplify AWS User Pool Configuration
Amplify.configure({
  Auth: {
    region: config.aws_region,
    userPoolId: config.aws_userPoolId,
    userPoolWebClientId: config.aws_userPoolWebClientId,
  },
  cookieStorage: {
    domain:cookieDomain,
    secure: true,
    //expire in days
    expires: 1,
    sameSite: 'none'
  },
});

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const subNavItemsToIgnore = ["Forward Document", "Admissions Inbound"];
  const existingPatientLoginMessage_1 = "Great News!"
  const existingPatientLoginMessage_2 = "Based on the information you provided, we see you are already a FinPay patient with an active payment plan.";
  const existingPatientLoginMessage_3 = "Please login below to access your account in the FinPay Patient Portal";

  const isPublic = isCurrentUrlPublic(window.location.href);

  if (isPublic.isCurrentUrlPublic) {
    dispatch(setIsPublicMode(isPublic.isCurrentUrlPublic));
  }
  if (isPublic.channelStatus) {
    dispatch(setUrlParameters(isPublic.guestInfo));
    dispatch(setChannelType(isPublic.channelType));
    dispatch(setIsChannel(isPublic.channelStatus));
  }
  const [isMobile, setIsMobile] = useState(false)
  const hasCheckedUser = useRef(false);

  const handleResize = () => {
    if (window.innerWidth < 960) {
        setIsMobile(true)
    } else {
        setIsMobile(false)
    }
  }

  const stateFields = {
    userIsAuthenticated: useSelector((state: RootState) => state.userContext.userIsAuthenticated),
    userRolePages: useSelector((state: RootState) => state.userContext.userProfile.userRole.userRolePages),
    subNav: useSelector((state: RootState) => state.userContext.userProfile.SubNav),
    isAccountHolderUser: useSelector((state: RootState) => state.userContext.userProfile.userRole.roleName === "Account Holder"),
    applicationStatus: useSelector((state: RootState) => state.userContext.applicationStatus),
    isLoading: useSelector((state: RootState) => state.userContext.isLoading),
    idToken: useSelector((state: RootState) => state.userContext.userProfile.tokenState.jwtIdToken),
    isPublicMode: useSelector((state: RootState) => state.adminContext.adminUserContext.isPublicMode),
    isPublicModeLoggedIn: useSelector((state: RootState) => state.adminContext.adminUserContext.isPublicModeLoggedIn),
    guestContext: useSelector((state: RootState) => state.guestContext),
    isSSO: useSelector((state: RootState) => state.userContext.isSSO),
    isSSOLoading: useSelector((state: RootState) => state.userContext.isSSOLoading),
    channelType: useSelector((state: RootState) => state.guestContext.channelType),
    isChannel: useSelector((state: RootState) => state.guestContext?.isChannel),
    userRoleId: useSelector((state: RootState) => state.userContext.userProfile.userRole.userRoleId),
    showLogin: useSelector((state: RootState) => state.adminContext.adminUserContext.showLogin),
    pathName: useSelector((state: RootState) => state.adminContext.adminUserContext.pathName)
  }

  const {
    userIsAuthenticated,
    userRolePages,
    subNav,
    isAccountHolderUser,
    applicationStatus,
    isLoading,
    idToken,
    isPublicMode,
    isPublicModeLoggedIn,
    guestContext,
    isSSO,
    isSSOLoading,
    channelType,
    isChannel,
    userRoleId,
    showLogin,
    pathName
  } = stateFields;

  useEffect(() => {
    const path = window.location.pathname
    if(path.includes('forgot-password')){
      dispatch(logUserOut());
      dispatch(setPathName('forgot-password'))
    }else if(path.includes('webpayment')){
      dispatch(setPathName('webpayment'))
    }else if(path.includes('payment-program-enrollment')){
      dispatch(setPathName('payment-program-enrollment'))
    }
  }, [window.location.pathname]);

  const hasAccessToPage = (pageName: string) => {
    return (
      userRolePages.findIndex((page) => {
        return (
          page?.sysRolePage?.rolePageName === pageName &&
          page.isEnabled === true
        );
      }) !== -1
    );
  };

  useEffect(()=>{
    if(userIsAuthenticated && userRoleId !== 4){
      //fetching meta in case browser is refreshed
      dispatch(getMetaData());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch, userIsAuthenticated])

  // though the token is automatically refreshed when it expires and an api call happens,
  // this function may have some performance benefits due to caching.
  useEffect(() => {
    if (idToken) {
      const tokenInfo: DecodedIdToken = jwt(idToken);
      const timeoutMs = tokenInfo.exp * 1000 - new Date().getTime() + 500;
      setTimeout(() => {
        dispatch(refreshToken());
      }, timeoutMs);
    }
  }, [idToken, dispatch]);

  useEffect(() => { handleResize() }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize)
  });

  const routeComponents = RouteConfiguration.map(
    (route: RouteConfigProps) => (
        <Routes>
        {route.protectedPageName ? (
          <Route
            path={route.path}
            key={route.path}
            element={
              <GuardedComponent
                exact={true}
                key={route.path}
                path={route.path}
                component={route.component}
                isAuthenticated={userIsAuthenticated}
                hasAccess={hasAccessToPage(route.protectedPageName)}
              />
            }>
          </Route>
        ) : (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component/>}
          />
        )}
        </Routes>
    )
  );

  // use in case the promise is empty string, to prevent errors.
  const routeComponentsWithoutStripe = RouteConfiguration.map(
    (route: RouteConfigProps) => (
      <Route
        key={route.path}
        path={route.path}
        element={<route.component/>}
      />
    )
  );
  function handleCloseSnackbar() {
    dispatch(hideStatus());
  }

  const handleLogoutClick = () => {
    dispatch(logUserOut());
    window.location.href = "";
  };

  const shouldShowNav = () => {
    return (
      userIsAuthenticated &&
      !window.location.pathname.includes("sign-document") &&
      !window.location.pathname.includes("sign-doc")
    ); // client signing URL
  };

  useEffect(() => {
    if (!hasCheckedUser.current && !(userIsAuthenticated && isSSO)) {
      dispatch(checkIfUserIsAuthenticated());
    }
    if (userIsAuthenticated && isSSO) {
        hasCheckedUser.current = true;
    }
  }, [dispatch, userIsAuthenticated, isSSO]);

  function toggleLoading(comp : JSX.Element){
     if (isSSOLoading) {
      return <LoadingOverlay whiteBackground={true}/>;
    }
    else{
      return comp;
    }
  }

  const isNotAuthenticated =(
    <div style={ isMobile && isPublicMode ? { height: 590 } : {}}>
      {isPublicMode && guestContext?.bannerText && !userIsAuthenticated  && !isChannel && (guestContext?.isExistingPatient === undefined) && (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ backgroundColor: "#F5F7F9"}}>
          <Grid item style={{}} direction="column" md={12} xs={12} className={'pt-5 px-4'}>
            Already have access to the FinPay Patient Portal? Log in below.
          </Grid>
        </Grid>
      )}
      {(!isPublicMode || guestContext?.bannerText || showLogin) && !isChannel && (
           <AmplifyAuthenticator >
             <Login />
             <ForgetPassword />
             <CreatePassword />
             <ConfirmSignIn />
           </AmplifyAuthenticator>
      )}
    </div>
  );

  const isAuthenticated = (
    <>
      <AuthenticationMonitor />
      <div className="page-container">
        <FppHeader showLinks={shouldShowNav()} />
        {isLoading || isSSOLoading ? (
            <LoadingOverlay />
        ) : (
          <>
            {shouldShowNav() && <SubNavigation subNav={subNav} subNavItemsToIgnore={subNavItemsToIgnore} />}
            { /* prevents stripe initialization errors caused by empty string passed into 'stripe' prop */}
            {config.stripe_key ? (routeComponents) : (routeComponentsWithoutStripe)}
            {isAccountHolderUser && <div><FppFooter isMobile={isMobile} /></div>}
          </>
        )}
      </div>
    </>
  );

  return (
    <main>
    <div
      className={ channelType === 'mobile' ? "page-container mobile-page-container" : "page-container"}
      style={ channelType === 'mobile' || pathName==='payment-program-enrollment' ? { backgroundColor: "#FFF", height: "initial" } : { backgroundColor: "#F5F7F9" }}
    >
      <Router>
        <Grid container>
          {!userIsAuthenticated && (
            <>
            <Grid item xs={12}>
              <FppHeader showLinks={shouldShowNav()} />
            </Grid>
            </>
          )}
          {isPublicMode && !userIsAuthenticated && !isPublicModeLoggedIn && !isChannel && pathName === 'webpayment' && (
            <Grid container direction="row" style={{ backgroundColor: "#F5F7F9"}}>
              <Grid item direction="column" md={12} xs={12} style={{textAlign: "center", padding: "2em"}}>
                { (guestContext?.isExistingPatient === undefined) && (
                  <h1>
                    {!guestContext?.bannerText && (<CircularProgress />)}
                    {guestContext.bannerText}
                  </h1>
                )}
                { (guestContext?.isExistingPatient === true) && (
                  <div>
                    <h1>{existingPatientLoginMessage_1}</h1>
                    <h1>{existingPatientLoginMessage_2}</h1>
                    <h1>{existingPatientLoginMessage_3}</h1>
                  </div>
                )}
              </Grid>
            </Grid>
          )}

          {!isPublicModeLoggedIn && (guestContext?.isExistingPatient !== false) && pathName !== 'forgot-password' && pathName !== 'payment-program-enrollment' && (
          <Grid item md={isPublicMode && !userIsAuthenticated && (guestContext?.isExistingPatient === undefined) ? 5 : 12} xs={12}>
            {toggleLoading(userIsAuthenticated ? isAuthenticated : isNotAuthenticated)}
              <Snackbar
                open={applicationStatus.showMessage}
                message={applicationStatus.message}
                type={applicationStatus.messageType}
                onClose={handleCloseSnackbar}
              />
              <SessionTimeout
                isAuthenticated={userIsAuthenticated}
                logOut={handleLogoutClick}
              />
          </Grid>
          )}

          {/*  OR box  */}
          {isPublicMode && !userIsAuthenticated  && !isPublicModeLoggedIn && guestContext?.bannerText && !isChannel && (guestContext?.isExistingPatient === undefined) && pathName === 'webpayment' && (
            <Grid
              item
              md={2}
              xs={12}
              direction="column"
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgb(245,247,249)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  width: "80px",
                  height: "80px",
                  border: "3px solid #000",
                  marginTop: isMobile ? "20px" : "200px",
                  marginBottom: isMobile ? "20px" : "300px",
                }}
              >
                OR
              </div>
              <FinDigitalPlatformFooter showEmailFooter={false} channelType={channelType} />
            </Grid>
          )}

          {isPublicMode && !userIsAuthenticated  && (guestContext?.isExistingPatient !== true) && !showLogin && (
                <Grid item md={!isPublicModeLoggedIn ? 5 : 12} xs={12} style={ channelType === 'mobile' ? { backgroundColor: "#FFF" } : { backgroundColor: "#F5F7F9" }}>
                  {!isPublicModeLoggedIn  && guestContext?.bannerText && !isChannel &&(
                      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={'pt-5'}>
                        <Grid item direction="column" md={12} xs={12} style={{ paddingLeft: "3%"}}>
                          Not already setup in the FinPay Patient Portal? Continue below.
                        </Grid>
                      </Grid>
                  )}
                  <div style={ channelType === 'mobile' || pathName==='payment-program-enrollment'? { backgroundColor: "#FFF" } : { backgroundColor: "#F5F7F9" }}>
                    <PublicPagesWrapper />
                  </div>
                </Grid>
          )}

        </Grid>
      </Router>
    </div>
    </main>
  );
}
export default App;
