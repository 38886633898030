import {
  LoadingOverlay,
  TextField,
  NewLOCSummary, checkCrossoverAndUpdateLocs,
} from '@finpay-development/shared-components';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { estimatorSchema } from '../../../shared/validation/schemas';
import { clearEstimate, clearFacilityLevelsOfCareList, setEstimate } from '../../state/estimator-slice';
import { EstimatorFooter } from './estimator-footer';
import { SidebarFinancialSummary } from './sidebar-financial-summary';
import { SidebarPlanYearCrossover } from './sidebar-plan-year-crossover';
import { SidebarPriorCareAdjustment } from './sidebar-prior-care-adjustment';
import { SidebarTotalEstimatedPfr } from './sidebar-total-estimated-pfr';
import { PatientSearchArea } from '../vob/patient-search-area';
import { clearPatient, setPatient } from '../../state/vob-patient-slice';
import { clearFacilityPayers, clearPaymentURLParams, clearVOB } from '../../state/vob-slice';
import { clearRiskAssessment } from "../../state/risk-assessment-slice";
import { Estimate } from '../../models/estimator';

import { getPatientDemographics } from 'src/patient/state/patient-thunk';
import { TakePaymentModal } from 'src/shared/components/take-payment-modal';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import {
  stringToQuoteMethod, quoteMethodToString,
  quoteMethodToDisplayString,
  EstimatorLevelOfCareModal,
} from './estimator-loc-modal/estimator-loc-modal';
import AlertBoxes from '../vob/alert-box';
import { getAlerts } from 'src/meta-data/state/meta-data-thunk';
import { Alert } from 'src/meta-data/models/metaData';
import { Vob } from 'src/admissions-advisor/models/vob';
import { showErrorStatus } from '../../../security/state/user-slice';
import { admissionsAdvisorUtils } from 'src/admissions-advisor/utils/admission-advisor-utils';
import { finAdvisorErrorMessage } from '../../../shared/enums';
import { getNewFacilityLevelsOfCare } from 'src/admissions-advisor/state/estimator-thunk';
import {callNewEstimatePut} from '../../state/estimator-thunk';
import {
  Estimate as NewEstimate,
  FacilityLevelOfCareWithCrossoverDays,
  QuoteMethod
} from '@finpay/estimation-types';
import {EstimatorPriorCareSummary} from './estimator-prior-care-summary';
import { clearEncounter, setSelectedPatient, setSelectedPatientV2 } from 'src/patient/state/patient-slice';
import _ from 'lodash';
import {AdmissionDate} from './admission-date';

interface VobProps {
	handleEstimatorTabClick1: (tab: number) => void;
  }

export const locModalErrorHandling = (estimate: NewEstimate, createEstimatorFormik: any, vob: Vob, dispatch: AppDispatch) => {
	const locErrorConditions = [
        {
            state: createEstimatorFormik.values.client,
            message: finAdvisorErrorMessage.missingClient,
        },
        {
            state: createEstimatorFormik.values.facility,
            message: finAdvisorErrorMessage.missingFacility,
        },
        {
            state: vob.payer?.payorId && vob.payer?.payorId > 0,
            message: finAdvisorErrorMessage.missingPayer,
        },
        {
            state: vob.plan?.payorPlanId && vob.plan?.payorPlanId > 0,
            message: finAdvisorErrorMessage.missingPlan,
        },
		{
            state: createEstimatorFormik.values.quoteMethod,
            message: finAdvisorErrorMessage.missingQuoteMethod,
        }
    ];

    for (const {state, message} of locErrorConditions) {
        if (!state) {
            const estimateCopy = { ...estimate };
            estimateCopy.facilityLevelOfCare = [];
            dispatch(setEstimate(estimateCopy));
            dispatch(showErrorStatus(message));
            return true;
        }
    }

    return false; 
};

function Estimator(props: VobProps) {

  const state = {
    clients: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient
        .filteredByIsActive
    ),
    facilities: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationFacility.facilities
    ),
    isLoadingClients: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.isLoading
    ),
    isLoadingFacilities: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationFacility.isLoading
    ),
    vob: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
      ),
    vobPatient: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext?.vobPatientContext.patient
    ),
    estimatorLoadingState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.isLoading
    ),
    clientAlerts: useSelector(
      (state: RootState) => {
        return state.metaData.alerts
      }
    ),
    clientLevelsOfCareState: useSelector(
      (state: RootState) => {
        return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare
      }
    ),
    payers: useSelector(
      (state: RootState) =>
          state.adminContext.adminConfigurationContext?.payers
    ),
	  masterLevelsOfCareState: useSelector(
		  (state: RootState) => {
			  return state?.adminContext.adminConfigurationContext?.levelsOfCare
		  }
	  ),
	  estimate: useSelector(
		  (state: RootState) => {
			  return state?.admissionsAdvisorContext.estimatorContext.estimate
		  }
	  ),
	  facilityLevelsOfCareList: useSelector(
		  (state: RootState) => {
			  return state?.admissionsAdvisorContext.estimatorContext.facilityLevelsOfCareList
		  }
	  ),
    userProfile: useSelector(
      (state: RootState) => state.userContext.userProfile
    )
  };

  const {
    clients,
    facilities,
    isLoadingClients,
    vob,
    vobPatientState,
    vobPatient,
    clientAlerts,
    clientLevelsOfCareState,
    estimate,
    facilityLevelsOfCareList,
    userProfile
  } = state;

  const { handleEstimatorTabClick1 } = props;
  const [isLOCModalOpen, setIsLOCModalOpen] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState<any>(undefined);

  const [payerAlerts, setPayerAlerts] = useState<Alert[]>([])
  const [employerAlerts, setEmployerAlerts] = useState<Alert[]>([])
  const [groupAlerts, setGroupAlerts] = useState<Alert[]>([])
  const [prefixAlerts, setPrefixAlerts] = useState<Alert[]>([])

  const [doesLOCSummaryValNeedUpdating, setDoesLOCSummaryValNeedUpdating] = useState(false)

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    forceValidateOnload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

// if the client is configured with the feature flag, use the new estimate values, otherwise use the estimator state values, otherwise use the vob values
  const currentClientId = estimate?.clientId ? estimate.clientId : vob?.client?.clientId
  const currentFacilityId = estimate?.facilityId ? estimate.facilityId : vob?.facility?.facilityId
  const initialAdmissionDate = estimate?.anticipatedAdmitDate ? estimate.anticipatedAdmitDate :  new Date().toDateString()
  const initialQuoteMethod = estimate?.quoteMethod ? estimate.quoteMethod : QuoteMethod.AVGLOS

  const [admissionDate, setAdmissionDate] = useState(initialAdmissionDate)

  useEffect(()=>{
    setAdmissionDate(initialAdmissionDate)
  }, [initialAdmissionDate])

  useEffect(() => {
    if(vob?.plan?.facilityPlanId) {
      dispatch(getNewFacilityLevelsOfCare({facilityId: currentFacilityId!, facilityPayorPlanId: vob?.plan?.facilityPlanId!}));
    } else {
      dispatch(clearFacilityLevelsOfCareList())
    }
  }, [currentFacilityId]);

  useEffect(() => {
	  if(!vob.isValid) {
		  handleEstimatorTabClick1(0);
	  }
	  if (vob?.urlParams?.op === "pay") {
		  setPaymentData({
        patientId: vobPatient?.fpPatientId,
        patientEncounterId: estimate?.patientEncounterId,
        paymentType: PAYMENT_TYPES.DOWN_PAYMENT,
			  paymentChannelId: userProfile.userRole.roleType === 'I' ? 5 : 3 //Launch route should be 5 (FMS) if internal or 3 (CRM) if external
      });
		  setOpenPaymentModal(true);
	  }
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vob]);

  // setting up clientAlerts
  // needs client id from vob and the meta data
  useEffect(() => {
	const clientId = vob?.fpClientId

	// possible that we are still loading vob
	if(!clientId) return

	const alerts = clientAlerts[clientId]

	// if we have a client id and our client's alerts, set everything
	if(clientId && alerts) {

		const payerAlerts = clientAlerts[clientId].payer.filter((payerAlert) => {
			return payerAlert.payor_id === vob.payer.payorId
		})

		const groupAlerts = clientAlerts[clientId].group.filter((groupAlert) => {
			return groupAlert?.group_code === vob.groupNum
		})

		const employerAlerts = clientAlerts[clientId].employer.filter((employerAlert) => {
			return employerAlert.client_employer_id === vob.employer?.employerId
		})

		let newPrefixAlerts: Alert[] = []
        const prefixes = clientAlerts[clientId].prefix

        for(let i = 0; i < prefixes.length; i++) {
            if(prefixes.length > 0) {
                const prefixAlert = prefixes[i]
                const subStringEndingIndex = prefixAlert.prefix!.length;

                const policyNumberSubString = vob?.policyNum?.substring(0, subStringEndingIndex)

                const isAMatch = policyNumberSubString === prefixAlert.prefix
                // match
                if(isAMatch) {
                    // if nothing in the new alerts, add it
                    if(newPrefixAlerts.length === 0) {
                        newPrefixAlerts.push(prefixAlert)
                        continue;
                    }

                    // length is same as the first prefix
                    if(newPrefixAlerts[0].prefix!.length === prefixAlert.prefix!.length) {
                        // add it the list, don't replace
                        newPrefixAlerts.push(prefixAlert)
                        continue
                    }

                    // length is greater than the first prefix
                    if(newPrefixAlerts[0].prefix!.length < prefixAlert.prefix!.length) {
                        // replace with new alert
                        newPrefixAlerts = [prefixAlert]
                        continue
                    }
                }
            }

        }


		setPayerAlerts(payerAlerts)
		setGroupAlerts(groupAlerts)
		setPrefixAlerts(newPrefixAlerts)
		setEmployerAlerts(employerAlerts)

	} else if(clientId && !clientAlerts[clientId]) {
		// client id but no alerts, fetch the alerts
		dispatch(getAlerts(clientId))
	}
  }, [vob, clientAlerts])

  const setAdvisorPatientBody = async () => {
      if(vobPatientState.patient.fpPatientId) {

      const { payload }: { payload: any} = await dispatch(
        getPatientDemographics(vobPatientState.patient.fpPatientId!)
      );

      await dispatch(
          setPatient({
            advisorPatientBody: {
              email: payload.patientDetails.contact.email,
              primaryAddress: {
                streetAddress1: payload.patientDetails?.contact?.primaryAddress?.streetAddress1,
                streetAddress2: payload.patientDetails?.contact?.primaryAddress?.streetAddress2,
                city: payload.patientDetails?.contact?.primaryAddress?.city,
                state: {
                  stateCode: payload.patientDetails.contact.primaryAddress?.state?.stateCode,
                },
                zipCode: payload.patientDetails?.contact?.primaryAddress?.zipCode
              },
              phoneNumber: payload.patientDetails?.contact?.primPhoneNum,
            }
          })
      );
        }
  }

  useEffect(() => {
    setAdvisorPatientBody()
  }, [])

const clickEstimatorTab = (tab: number) => {
	handleEstimatorTabClick1(tab)
}

async function handleEstimatorLevelOfCareModalClose(){
	console.log('%cLOC Modal Close - Formik:', 'background-color: #0199cc; color: white; font-weight: bold', createEstimatorFormik.values);
	console.log('%cLOC Modal Close - Estimator State:', 'background-color: #0199cc; color: white; font-weight: bold', estimate);
	console.log('%cLOC Modal Open - Vob State:', 'background-color: #0199cc; color: white; font-weight: bold', vob);
	console.log('%cLOC Modal Open - Client LOCState:', 'background-color: #0199cc; color: white; font-weight: bold', clientLevelsOfCareState);
	setIsLOCModalOpen(false);
}

const estimatorValidationSchema = Yup.object(estimatorSchema);

  // formik for estimator
  const createEstimatorFormik = useFormik({
    initialValues: {
      client: currentClientId,
      facility: currentFacilityId,
	    quoteMethod: initialQuoteMethod
    },
		enableReinitialize: true,
    validationSchema: estimatorValidationSchema,
    onSubmit: () => {},
  });

  const handleNewEstimateQuoteMethodChange = (e: Event) => {
    
    try{
      const selectedQuoteMethod = (e.target as HTMLInputElement).value as unknown as QuoteMethod;

      if(estimate){  //estimate already exists

        let putEstimateBody: NewEstimate | undefined;

        switch(true){

          case selectedQuoteMethod === QuoteMethod.SCA : {

            setDoesLOCSummaryValNeedUpdating(true);

            putEstimateBody = {
              vobId: vob.vobId!,
              patientEncounterId: estimate?.patientEncounterId,
              clientId:
              estimate?.clientId,
              facilityId:
              estimate?.facilityId,
              advisorPatientId:
              estimate?.advisorPatientId,
              description:
              estimate?.description,
              quoteMethod: selectedQuoteMethod,
              anticipatedAdmitDate: new Date(admissionDate),
              facilityLevelOfCare: estimate.facilityLevelOfCare,
              priorCare: estimate.priorCare,
              isPlanYearCrossover: estimate.isPlanYearCrossover,
              crossoverSelection:
              estimate?.crossoverSelection,
              isActive: estimate.isActive,
            };

            break;
          }

          case selectedQuoteMethod === QuoteMethod.MANUAL: {

            setDoesLOCSummaryValNeedUpdating(true);

            const updatedFacilityLevelOfCare = estimate?.facilityLevelOfCare?.map((facLOC) => {

              const foundFacLOCFromList = facilityLevelsOfCareList?.find((facLOCFromList)=>{
                return facLOC.facilityLevelOfCareId == facLOCFromList.facilityLevelOfCareId
              })

              if(foundFacLOCFromList){
                return {
                  ...facLOC,
                  pdrRate: foundFacLOCFromList.pdrRate,
                }
              }else{
                throw new Error("Could not find facility level of care from newFacilityLevelsOfCareList Redux state")
              }
            })

            putEstimateBody = {
              vobId: vob.vobId!,
              patientEncounterId: estimate?.patientEncounterId,
              clientId:
              estimate?.clientId,
              facilityId:
              estimate?.facilityId,
              advisorPatientId:
              estimate?.advisorPatientId,
              description:
              estimate?.description,
              quoteMethod: selectedQuoteMethod,
              anticipatedAdmitDate: new Date(admissionDate),
              facilityLevelOfCare: updatedFacilityLevelOfCare,
              priorCare: estimate.priorCare,
              isPlanYearCrossover: estimate.isPlanYearCrossover,
              crossoverSelection:
              estimate?.crossoverSelection,
              isActive: estimate.isActive,
            };

            break;
          }

          case [QuoteMethod.AVGLOS, QuoteMethod.RLOS].includes(selectedQuoteMethod): {
            // Any quote method -> avlos/rlos

            let updatedFacilityLevelOfCare = estimate?.facilityLevelOfCare?.map((facLOC) => {

              const foundFacLOCFromList = facilityLevelsOfCareList?.find((facLOCFromList)=>{
                return facLOC.facilityLevelOfCareId == facLOCFromList.facilityLevelOfCareId
              })

              if(foundFacLOCFromList){
                return {
                  ...facLOC,
                  losDays: selectedQuoteMethod === QuoteMethod.AVGLOS ? foundFacLOCFromList.losDays : foundFacLOCFromList.recommendedLos,
                  pdrRate: foundFacLOCFromList.pdrRate,
                }
              }else{
                throw new Error("Could not find facility level of care from newFacilityLevelsOfCareList Redux state")
              }
            })

            const {isCrossover, newSelectedLocs} = checkCrossoverAndUpdateLocs({
              selectedLocs: updatedFacilityLevelOfCare as FacilityLevelOfCareWithCrossoverDays[],
              admissionDate: new Date(admissionDate),
              policyEndDate: new Date(vob.policyEndDate)
            })

            /**
             * Because the endpoint doesn't allow request bodies with missing losDaysBeforeCrossover/losDaysAfterCrossover properties if isPlanYearCrossover === true,
             * we will set isPlanYearCrossover to false and prompt the user to update from the modal
             * */

            promptUserToUpdateModalForCrossover = admissionsAdvisorUtils.isModalUpdateNeededForCrossover({
              isCrossover,
              newSelectedLocs,
              policyEndDate: new Date(vob.policyEndDate),
              anticipatedAdmissionDate: new Date(admissionDate)
            })

            putEstimateBody = {
              vobId: vob.vobId!,
              patientEncounterId: estimate?.patientEncounterId,
              clientId: estimate?.clientId,
              facilityId: estimate?.facilityId,
              advisorPatientId: estimate?.advisorPatientId,
              description: estimate?.description,
              quoteMethod: selectedQuoteMethod,
              anticipatedAdmitDate: new Date(admissionDate),
              facilityLevelOfCare: promptUserToUpdateModalForCrossover ? updatedFacilityLevelOfCare : newSelectedLocs,
              priorCare: estimate.priorCare,
              isPlanYearCrossover: promptUserToUpdateModalForCrossover ? false : isCrossover,
              crossoverSelection: estimate?.crossoverSelection,
              isActive: estimate.isActive,
              summarySelection: estimate?.summarySelection,
            };

            break;
          }

          default:

        }

        if(putEstimateBody){
          dispatch(callNewEstimatePut({
            estimateId: estimate.estimateId,
            estimate: putEstimateBody
          }))
        }
      }
    }catch(e){
      console.error(e)
      dispatch(showErrorStatus("Error updating quote method and Level of Care"));
    }

  }

  let promptUserToUpdateModalForCrossover: boolean = admissionsAdvisorUtils.isModalUpdateNeededForCrossover({
       ...checkCrossoverAndUpdateLocs({
         selectedLocs: estimate?.facilityLevelOfCare as FacilityLevelOfCareWithCrossoverDays[] || [],
         admissionDate: new Date(admissionDate),
         policyEndDate: new Date(vob.policyEndDate)
       }),
       policyEndDate: new Date(vob.policyEndDate),
       anticipatedAdmissionDate:  new Date(admissionDate)
      })

  const handleAdmissionDateOnChange = (date: Date) => {

    try{

      const selectedAdmissionDate = new Date(date);

      setAdmissionDate(selectedAdmissionDate)

      if(estimate){  //estimate already exists

        const {isCrossover, newSelectedLocs} = checkCrossoverAndUpdateLocs({
          selectedLocs: estimate.facilityLevelOfCare as FacilityLevelOfCareWithCrossoverDays[],
          admissionDate: selectedAdmissionDate,
          policyEndDate: new Date(vob.policyEndDate)
        })

        /**
         * Because the endpoint doesn't allow request bodies with missing losDaysBeforeCrossover/losDaysAfterCrossover properties if isPlanYearCrossover === true,
         * we will set isPlanYearCrossover to false and prompt the user to update from the modal
         * */

        promptUserToUpdateModalForCrossover = admissionsAdvisorUtils.isModalUpdateNeededForCrossover({
          isCrossover,
          newSelectedLocs,
          policyEndDate: new Date(vob.policyEndDate),
          anticipatedAdmissionDate: selectedAdmissionDate
        })

        let putEstimateBody = {
          ..._.omit(estimate, ['estimateId', 'createUserId', 'createDt', 'lastUpdateUserId', 'lastUpdateDt', 'summary', 'crossOverSummary', 'priorCareSummary']),
          vobId: vob.vobId!,
          anticipatedAdmitDate: selectedAdmissionDate,
          facilityLevelOfCare: promptUserToUpdateModalForCrossover ? estimate.facilityLevelOfCare : newSelectedLocs,
          isPlanYearCrossover: promptUserToUpdateModalForCrossover ? false : isCrossover,
        };

        dispatch(callNewEstimatePut({
          estimateId: estimate.estimateId,
          estimate: putEstimateBody
        }))
      }
    }catch(e){
      console.error(e)
      dispatch(showErrorStatus("Error updating Anticipated Admission Date"));
    }

  }

  const forceValidateOnload = () => {
    setTimeout(() => {
      createEstimatorFormik.validateForm() // unlock the IOC
    }, 500);
  }

  const clearAAState = () => {
	dispatch(clearPatient());
  dispatch(setSelectedPatient({}))
  dispatch(setSelectedPatientV2({}))
	dispatch(clearVOB());
	dispatch(clearFacilityPayers());
	dispatch(clearRiskAssessment());
	dispatch(clearEstimate())
	dispatch(clearEncounter());
	handleEstimatorTabClick1(0);
  }

  const handlePaymentModalCancel = () => {
	setOpenPaymentModal(false);
	dispatch(clearPaymentURLParams());
  }

  const quoteMethodValueAsString = typeof createEstimatorFormik.values.quoteMethod === 'string' ? createEstimatorFormik.values.quoteMethod : quoteMethodToString(createEstimatorFormik.values.quoteMethod as QuoteMethod);
  const quoteMethodValueAsEnum = typeof createEstimatorFormik.values.quoteMethod === 'string' ? stringToQuoteMethod(createEstimatorFormik.values.quoteMethod) : createEstimatorFormik.values.quoteMethod;
  const quoteMethodValueAsDisplayString = quoteMethodToDisplayString(initialQuoteMethod)

  const currentClient = clients.find((client) => {
    return client.clientId === currentClientId 
  })
  const currentFacility = facilities.flat().find((facility) => {
    return facility.facilityId === currentFacilityId
  })

	return (
		<>
			{isLoadingClients ? (
				<LoadingOverlay />
			) : (
				<>
					<Grid container justifyContent="space-between" direction="row" spacing={2}>
						<Grid item direction="column" xs={8}>
							{/* Estimator Details */}
							<Paper>
								<Box
									style={{
										padding: "1.0em",
										borderBottom: "1px solid #cccccc",
										marginBottom: "1.5em"
									}}
								>
									<Grid container justifyContent="space-between" direction="row">
										<Grid
											item
											justifyContent="center"
											direction="column"
											style={{
												display: "flex",
												alignItems: "center",
												flexWrap: "wrap",
												flexDirection: "row",
												paddingBottom: "12px"
											}}
										>
											<Typography
												variant="subtitle2"
												style={{
													display: "flex",
													alignItems: "center",
													paddingBottom: "0px",
													marginBottom: "0px"
												}}
											>
												<SettingsIcon sx={{ marginRight: "0.25em" }} /> Estimator Details
											</Typography>
											<PatientSearchArea
												clearAAState={clearAAState}
												vob={vob}
												updatePatientFormik={() => {}}
												updateVOBOnPatientSelection={() => {}}
												updateVobFormik={() => {}}
												handleEstimatorTabClick={clickEstimatorTab}
											/>
										</Grid>
										<Grid
											item
											justifyContent="center"
											direction="column"
											style={{
												display: "flex",
												alignItems: "center",
												flexWrap: "wrap",
											}}
										></Grid>
									</Grid>
									<form>
                    <Grid container spacing={2} direction="row">
                      <Grid item xs={3}>
                        <Typography variant='caption' style={{color: 'grey'}}>Client</Typography>
                        <Typography variant='h3'>{currentClient?.clientName}</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant='caption' style={{color: 'grey'}}>Facility</Typography>
                        <Typography variant='h3'>{currentFacility?.facilityName}</Typography>
                      </Grid>

                      <Grid item xs={4}>
                      <TextField
                          select
                          label="Quote Method"
                          name="quoteMethod"
                          value={quoteMethodValueAsEnum}
                          onChange={(e: Event) => {
                            createEstimatorFormik.handleChange(e);
                              handleNewEstimateQuoteMethodChange(e)
                          }}
                          onBlur={createEstimatorFormik.handleBlur}
                          error={Boolean(
                              createEstimatorFormik.touched.quoteMethod &&
                              createEstimatorFormik.errors.quoteMethod
                            )
                          }
                        >
                          <MenuItem value={QuoteMethod.AVGLOS}>Average Length of Stay</MenuItem>
                          <MenuItem value={QuoteMethod.RLOS}>Recommended Length of Stay</MenuItem>
                          <MenuItem value={QuoteMethod.MANUAL}>Manual</MenuItem>
                          <MenuItem value={QuoteMethod.SCA}>Single Case Agreement</MenuItem> 
                        </TextField>
                        {/* <Typography variant='caption' style={{color: 'grey'}}>Quote Method</Typography>
                        <Typography variant='h3'>{quoteMethodValueAsDisplayString}</Typography> */}
                      </Grid>
                    </Grid>
									</form>
								</Box>
							</Paper>

							 {/*Levels of Care */}

              <Paper>
                <Box
                    style={{
                      padding: "1.0em",
                      borderBottom: "1px solid #cccccc",
                      marginBottom: "1.5em",
                    }}
                >
                  <Grid container justifyContent="space-between" direction="row">
                    <Grid
                        item
                        direction="column"
                        xs={12}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          paddingBottom: "0.5em"
                        }}
                    >
                      <Typography
                          variant="subtitle2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                      >
                        <PermContactCalendarIcon sx={{ marginRight: "0.25em" }} /> Levels of Care
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                  <Grid item xs={6} direction="column">
                    <AdmissionDate
                        admissionDate={initialAdmissionDate}
                        onChangeHandler={handleAdmissionDateOnChange}
                    />
                  </Grid>
                  <>
                    {promptUserToUpdateModalForCrossover && (
                          <Grid container direction="row" sx={{backgroundColor: "#E8DB47", marginY: '0.5rem'}}>
                            <Grid item xs={12} className="p-2">
                              Please open the LOC modal and update Days Before Crossover value(s)
                            </Grid>
                          </Grid>
                    )}

                    <NewLOCSummary
                        handleLocModalOpen={() => {
                          setIsLOCModalOpen(true)
                        }}
                        enableLOSbutton={true}
                        selectedLevelsOfCare={estimate?.facilityLevelOfCare || []}
                        quoteMethod={quoteMethodValueAsEnum}
                        displayValueRed={doesLOCSummaryValNeedUpdating}
                    />
                  </>
                  </Grid>
                </Box>
              </Paper>
                
              <EstimatorPriorCareSummary />

              {/* <EstFinAssistanceSummaryNew /> */}

						</Grid>


              {/* Side section with summaries */}
						<Grid item direction="column" xs={4}>
							<Box border={1} borderColor="primary.main" p={2}
								style={{
									backgroundColor: "white",
									marginBottom: "1.5em",
								}}>
								<Typography variant="subtitle2">
									Financial Summary
								</Typography>
								<SidebarFinancialSummary />
							</Box>

							<SidebarPlanYearCrossover />

							<SidebarTotalEstimatedPfr />

							<SidebarPriorCareAdjustment />

							<AlertBoxes alerts={payerAlerts}/>
              <AlertBoxes alerts={employerAlerts}/>
              <AlertBoxes alerts={groupAlerts}/>
              <AlertBoxes alerts={prefixAlerts}/>

						</Grid>
					</Grid>

          {isLOCModalOpen && facilityLevelsOfCareList && (
            <EstimatorLevelOfCareModal
              open={isLOCModalOpen}
              clientId={createEstimatorFormik.values.client}
              facilityId={createEstimatorFormik.values.facility}
              facilityName={facilities?.flat().find((el) => el.facilityId === createEstimatorFormik.values.facility)?.facilityName}
              quoteMethod={createEstimatorFormik.values.quoteMethod}
              admissionDate={admissionDate}
              facilityLevelsOfCareList={facilityLevelsOfCareList}
              handleEstimatorLevelOfCareModalClose={handleEstimatorLevelOfCareModalClose}
              promptUserToUpdateModalForCrossover={promptUserToUpdateModalForCrossover}
            />
          )}

			    <EstimatorFooter />
					<TakePaymentModal paymentData={paymentData} open={openPaymentModal} handleModalCancel={handlePaymentModalCancel} />
				</>
			)}
		</>
	);

}

export default Estimator;
