import '../../../scss/components/mobile-payments.scss';
import {Button} from '@finpay-development/shared-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';

interface ModalProps {
  isOpen: boolean;
  handleModalCancel: () => void;
  authDocItems: JSX.Element[];
}

export function AuthDocModal(props: ModalProps) {

  const {isOpen, handleModalCancel, authDocItems} = props;

  return (
      <Dialog
          scroll="paper"
          className="modal user-modal mobile-modal"
          open={isOpen}
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          PaperProps={{
            sx: {
              maxHeight: '80vh', // Ensure the whole dialog can scroll
              overflow: 'auto',
            },
          }}
      >
        {authDocItems}
        <DialogActions style={{borderTop: '', marginTop: '0', padding: '2rem'}}>
          <Button test-id="auth-doc-modal-close-btn" onClick={handleModalCancel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  );
}

const FINANCIAL_RESPONSIBILITY_AND_ASSIGNMENT_OF_BENEFITS = (
    <>
      <DialogTitle>
        Financial Responsibility and Assignment of Benefits
      </DialogTitle>
      <DialogContent
          sx={{overflow: 'unset'}}
          test-id="fin-resp-assign-benefits"
      >
        <Grid container direction="row">
          <Grid item direction="column" md={12} xs={12} className="spa-content">

            <p>THIS FINANCIAL RESPONSIBILITY AND ASSIGNMENT OF BENEFITS FORM IS
              A LEGALLY BINDING
              DOCUMENT. PLEASE READ IT CAREFULLY BEFORE AGREEING TO IT.
            </p>
            <p>
              <strong>Patient Financial Responsibility.</strong> I (“Patient” or
              “Guarantor”) understand that, in consideration of the services
              and/or procedures provided, I am directly and primarily
              responsible to pay for all services and procedures rendered
              by Provider. Patient understands that he/she is responsible to pay
              for any applicable deductible, co-payment or other
              patient cost-share amounts under any insurance plan, program or
              policy (including but not limited to Medicare) under
              which Patient is entitled to benefits (“Insurance Plan”) and any
              other amounts due to Provider that are not paid by an
              Insurance Plan. In addition, Patient shall be responsible to pay
              or reimburse Provider for any and all expenses incurred
              by Provider in order to enforce this Agreement or collect any
              amounts due hereunder, whether incurred with or without
              the commencement of suit and, without limitation, litigation or
              arbitration expenses and reasonable attorneys’ and
              experts’ fees and costs.
            </p>
            <p>
              <strong>Assignment of Insurance Benefits.</strong> To the extent
              permitted by law or the applicable Insurance Plan, Patient hereby
              assigns all right title and interest to any and all benefits under
              his/her Insurance Plan and rights to payment in
              connection with services rendered to Patient by Provider.
              Additionally, Patient authorizes Provider to file any claim
              as appropriate for payment for such services with Patient’s
              Insurance Plan. If the Insurance Plan fails to pay for any
              reason, then Patient understands that he/she will be responsible
              for prompt payment of all amounts owed to Provider
              for such services. Patient agrees to execute any and all
              additional documents necessary to facilitate the assignment of
              insurance benefits and the payment of any and all amounts due from
              his/her Insurance Plans to Provider as set forth
              above.
            </p>
            <p>
              <strong>Responsibility to Provide Proof of
                Insurance.</strong> Patient understands that it is his/her
              responsibility to provide Provider
              with a copy of Patient’s current insurance card. Patient will
              notify Provider immediately upon any change in Patient’s
              Insurance Plan.
            </p>
            <p>
              <strong>Insurance Waiver and Non-Covered Services
                Waiver.</strong> There may be a service Patient desires, or is
              provided to
              Patient that is not covered under Patient’s Insurance Plan
              (“Non-Covered Services”). Patient understands he/she must
              pay for Patient’s “Non-Covered Services.” If requested by
              Provider, Patient agrees to execute an Advance Beneficiary
              Notice or other document(s) indicating that he/she understands
              that the service Patient intends to receive from Provider
              is for Non-Covered Services under Patient’s Insurance Plan, but
              Patient is nonetheless agreeing to pay the entire cost
              of such services.
            </p>
            <p>
              <strong>Medicare – Patient’s Certification/Authorization to
                Release Information and Payment Request.</strong> Patient
              certifies
              that the information given by Patient in connection with the
              application for payment under title XVII/XIX of the Social
              Security Act is true and correct. Patient authorizes any holder of
              medical or other information about Patient to release
              to Social Security Administration Division of Family Services or
              its intermediaries or carriers any information needed
              for this or a related Medicare claim. Patient consents to the use
              of a copy of these authorizations and assignments in
              place of the original, which is on file at the Provider’s office.
            </p>
            <p>
              By accepting the terms of this Financial Responsibility and
              Assignment of Benefits form as the Patient and in
              consideration of Provider’s agreement to render services or
              procedures to Patient, the sufficiency of which is hereby
              acknowledged, I hereby consent and agree to the financial
              arrangements, assignment of benefits and other terms and
              conditions described in this Financial Responsibility and
              Assignment of Benefits.
            </p>
            <p>
              By accepting the terms of this Financial Responsibility and
              Assignment of Benefits form as the Guardian or Parent of
              the Patient and in consideration of Provider’s agreement to render
              services or procedures to Patient the sufficiency of
              which is hereby acknowledged, I hereby consent and agree to the
              financial arrangements, assignment of benefits and
              other terms and conditions described in this Financial
              Responsibility and Assignment of Benefits form on behalf of
              Patient. I represent and warrant that I have the full power and
              authority to bind the Patient to such arrangements,
              assignment and other terms and conditions.
            </p>
            <p>
              By accepting the terms of this Financial Responsibility and
              Assignment of Benefits form as a Guarantor and in
              consideration of Provider’s agreement to render services or
              procedures to Patient, the sufficiency of which is hereby
              acknowledged, I hereby, unconditionally and irrevocably guarantee
              to Provider the prompt and full payment (and not
              merely the collectability), performance, and observance of all of
              the obligations, terms and conditions to be paid,
              performed or observed by Patient under this Financial
              Responsibility and Assignment of Benefits form. I understand
              that my liability for such obligations, terms and conditions shall
              be joint and several with the Patient and any other
              Guarantor accepting the terms of this Financial Responsibility and
              Assignment of Benefits form; provided however,
              I shall be solely and fully liable for such obligations, terms and
              conditions if neither the Patient and or any other
              Guarantor accept such terms.
            </p>
          </Grid>
        </Grid>
      </DialogContent>
    </>
);

const PAYMENT_AUTHORIZATION_TERMS_AND_CONDITIONS = (
    <>
      <DialogTitle>
        Payment Authorization Terms and Conditions
      </DialogTitle>
      <DialogContent
          sx={{overflow: 'unset'}}
          test-id="payment-auth-terms-conditions"
      >
        <Grid container direction="row">
          <Grid item direction="column" md={12} xs={12} className="spa-content">

            <p>THESE PAYMENT AUTHORIZATION TERMS AND CONDITIONS ARE LEGALLY
              BINDING. PLEASE
              READ THEM CAREFULLY BEFORE AGREEING TO THEM.
            </p>
            <p>
              By providing my credit card, debit card, or ACH information and
              checking the box below, I (“Account Holder”)
              hereby authorize FinPay, on behalf of my healthcare provider or
              the individual’s health care provider whose services
              for which I am agreeing to pay (as applicable, “Health Care
              Provider”) , to initiate preauthorized payments by
              making automatic charges to my credit or debit card, or
              withdrawals from my bank account (each such card or
              account is, as applicable, referred to herein as an “Account”)
              according to my Payment Program Page, which sets
              forth the true and correct terms of my “Payment Program,” and can
              also be found in my portal at
              https://myfinpay.net. I understand that these charges or
              withdrawals to or from my Account will occur automatically
              (“Auto Pay”) and may be drafted as early as today. Payment(s) may
              be a single payment or recurring payments at
              such times as designated by the terms of my Payment Program.
            </p>
            <p>
              I acknowledge that I have received an estimate of the Patient
              Financial Responsibility for healthcare services
              provided by the Health Care Provider and I have voluntarily
              enrolled in the Payment Program to pay for such
              services as set forth in my Payment Program. I acknowledge that
              FinPay is a third-party vendor engaged by the
              Health Care Provider to assist with the processing of medical bill
              payments, and does not determine any amounts
              owed to such provider, including but not limited to those amounts
              owed under my Payment Program, process
              insurance claims, determine insurance coverage, charge patient
              fees, or otherwise determine any amounts owed by
              me to such provider. I agree to cooperate and execute any
              additional documents necessary to facilitate withdrawals
              to/from my Account in accordance with my Payment Program.
            </p>
            <p>
              I have provided a valid email address and/or mobile phone number,
              and I am authorizing that I can be contacted at
              that email address and/or mobile phone and all communications
              regarding this Authorization notice and the terms
              hereof shall be sent to such email address or mobile phone. Such
              notice shall constitute valid notice under this
              Authorization. I will promptly update any changes to such email
              address or mobile phone number, as well as make
              requests to change the terms of my Payment Program via my patient
              portal (https://myfinpay.net); provided however
              I acknowledge that no such requested change to my Payment Program
              shall be effective unless and until such
              change is agreed to in writing by the Health Care Provider.
            </p>
            <p>
              My Authorization for Autopay shall remain in full force and effect
              until terminated or revoked by me in writing at
              any time by notice sent to support@finpay.com. Any termination
              will only apply to future payments from the time
              the termination request is received with a reasonable opportunity
              to act. FinPay reserves the right to terminate its
              services for any reason, including but not limited to:
              <ul style={{marginLeft: '3rem'}}>
                <li>Your automated payment is returned by your financial
                  institution because of “Authorization Revoked” or
                  “Account Closed” or any other reason;
                </li>
                <li>Three automated payments are returned unpaid for
                  insufficient funds within a consecutive 12-month period;
                </li>
                <li>Upon our written notice to you that you have not complied
                  with this terms and conditions Authorization or
                  the User Agreement; or
                </li>
                <li>We provide you with written notice of our intent to
                  terminate this Authorization and our services no less than
                  fifteen (15) days prior to the termination date.
                </li>
              </ul>
            </p>
            <p>
              I acknowledge and agree that my use of FinPay’s services and
              website shall be governed by this Authorization. I am
              able and consent to receive the User Agreement and any
              disclosures, notices, or other communications related to my
              use of the FinPay services electronically, including but not
              limited to chat, text, email or phone.
            </p>
            <p>
              <strong>
                <u>
                  I acknowledge and agree that my obligation to pay for health
                  care services in full is to the Health Care
                  Provider and nothing in this Authorization other than the
                  receipt of funds by FinPay in accordance with my
                  Payment Program shall reduce eliminate, or otherwise affect my
                  obligation to pay for such health care in full.
                  Further, I understand that if I choose not to enroll in my
                  Payment Program via this Authorization, I revoke
                  this Authorization or fail to make payment in accordance with
                  my Payment Program, I shall remain
                  obligated to pay the Health Care Provider in full for such
                  health care services.
                </u>
              </strong>
            </p>
            <p>
              I further acknowledge and agree that the Health Care Provider may
              have offered to extend payment terms on the
              condition that I enroll in my Payment Program and make all
              payments under such program pursuant to this
              Authorization and that such terms may not (or may no longer) be
              available to me if I do not enroll in my Payment
              Program, I revoke this Authorization and/or fail to make all
              payments required by my Payment Program.
            </p>
            <p>
              <strong>
                <u>
                  I agree to indemnify, release and hold harmless FinPay, its
                  parent, subsidiaries, successors, and assigns, and
                  their respective , directors, officers, and employees
                  (“Released Parties”), from any action, causes of action,
                  suits,
                  claims, liabilities, losses, costs and expenses, including but
                  not limited to reasonable attorneys fees, at law or
                  in equity, I, my heirs, successors, and assigns, and all
                  persons claiming through me or any of them (“Releasing
                  Parties”), individually or collectively, may have now or in
                  the future against the Released Parties, arising
                  from or in connection with any dispute related to payment made
                  or owed by me to the Health Care Provider
                  for services rendered, except for any action, causes of
                  action, suits, claims, liabilities, losses, costs and
                  expenses which is/are based or incurred solely as a result of
                  FinPay’s breach of this Authorization or the
                  terms of my Payment Program; provided however, in no event
                  shall any Released Party be liable to me for
                  any lost profits or consequential, indirect, incidental,
                  punitive or exemplary damages. For avoidance of doubt, to the
                  extent my Account is charged in error, I agree that my sole
                  remedy against any of the Released
                  Parties shall be solely for the amount of money charged in
                  error and the Release Parties shall not be liable for
                  any lost profits or consequential, indirect, incidental,
                  including but not limited to any overdraft or similar
                  fees, punitive or exemplary damages.
                </u>
              </strong>
            </p>
            <p>
              By accepting the terms of these Payment Authorization Terms and
              Conditions as the Account Holder I hereby consent
              and agree to the withdrawals, payments, financial arrangements and
              other terms and conditions described in this
              Payment Authorization form. I represent and warrant that I have
              the full power and authority to authorize such
              withdrawals and payments and to agree to the financial
              arrangements and other terms and conditions.
            </p>
          </Grid>
        </Grid>
      </DialogContent>
    </>
);

const INSTALLMENT_AGREEMENT = (
    <>
      <DialogTitle>
        Installment Agreement
      </DialogTitle>
      <DialogContent
          sx={{overflow: 'unset'}}
          test-id="inst-agreement"
      >
        <Grid container direction="row">
          <Grid item direction="column" md={12} xs={12} className="spa-content">

            <p>THIS INSTALLMENT AGREEMENT IS LEGALLY BINDING. PLEASE READ IT
              CAREFULLY BEFORE
              AGREEING TO IT.
            </p>
            <p>
              I, (“Patient”) or (“Guarantor”) promise, to pay Provider as
              identified in my Payment Program Page, hereinafter known
              individually and collectively with its affiliates, employees and
              agents, as the “Provider”, all amounts as set forth in
              this Installment Agreement (“Agreement”).
            </p>
            <p>
              <strong>DEFINITIONS.</strong>
              <ul style={{listStyleType: 'lower-alpha', marginLeft: '3rem'}}>
                <li>
                  “Patient” shall mean the individual accepting this Agreement
                  as the Patient that will receive or has received
                  healthcare services from the Provider.
                </li>
                <li>
                  “Provider” or “Health Care Provider” shall mean the entity and
                  its affiliates, employees and agents who are
                  providing, have provided or will provide medical and/or
                  healthcare services to the Patient.
                </li>
                <li>
                  “Effective Date” shall mean the date set forth as the
                  Effective Date on my Payment Program Page.
                </li>
                <li>
                  “Guarantor” shall mean the individual(s) accepting this
                  Agreement as the Guarantor and accepting financial
                  responsibility for the charges and costs associated with
                  services provided to the Patient by the Provider and
                  guaranteeing payment, not collection, of monies owed to
                  Provider for medical and healthcare services provided
                  to the Patient.
                </li>
                <li>
                  “Down Payment” shall mean any payment towards the PFR, in
                  whole or in part, made on or about the Effective
                  Date and set forth on my Payment Program Page.
                </li>
                <li>
                  “Due Date” or “Payment Due” shall mean the due date of
                  recurring date chosen by the Patient or Guarantor for
                  payments to be processed on a monthly or weekly basis in
                  accordance with the Patient’s and/or Guarantor’s chosen
                  Payment Program. It is found on the Payment Program Page, and
                  is also accessible to the Patient or Guarantor
                  online at https://myfinpay.net.
                </li>
                <li>
                  “Installment Amount” or “Payment Amount” shall mean the
                  Installment amount set forth on my Payment Program
                  Page.
                </li>
                <li>
                  “Payment Program” means the Payment Program set forth on my
                  Payment Program Page.
                </li>
                <li>
                  “PFR” or “PFR Amount” shall mean the Patient Financial
                  Responsibility (PFR) Amount expressed in US Dollars
                  as set forth on my Payment Program Page.
                </li>
                <li>
                  “FinPay” shall mean FinPay, LLC, a third-party vendor engaged
                  by my health care provider to assist with the
                  processing of medical bill payments, and any successor to
                  FinPay.
                </li>
                <li>
                  “Payment Program Page” shall mean the screen displayed to the
                  Patient or Guarantor indicating their specific
                  payment program details, and where they authorize and accept
                  their Payment Program Agreement.
                </li>
              </ul>
            </p>

            <p>
              <strong>PAYMENT.</strong> In consideration of services rendered, I
              agree to pay Provider the Down Payment on or before the
              Effective Date and all Installment Amounts on their respective Due
              Dates until the PFR (as may be adjusted hereunder)
              is paid in full.
            </p>

            <p>
              <strong>LATE CHARGE.</strong> If any payments are not timely made,
              at the Provider’s discretion, Patient/Guarantor may also be
              subject to a late charge equal to five percent (5%) per annum on
              each payment which is past due for ten (10) or more
              days. This late charge shall not apply to payments first due at
              maturity or by acceleration hereof, unless such late
              payment is in an amount not greater than the Installment Amount.
              Acceptance by Provider of any late payment without
              an accompanying late charge shall not be deemed a waiver of
              Provider’s right to collect such late charge or to collect
              a late charge for any subsequent late payment received.
            </p>

            <p>
              <strong>APPLICATION OF PAYMENTS.</strong> Monies received by
              Provider from any source for application toward payment
              of the PFR shall be applied to the PFR in any manner or order
              deemed appropriate by Provider in, to the maximum
              extent permitted by law, its sole and absolute discretion,
              including; (i)self or patient-pay portions (e.g., deductible(s),
              copay(s), coinsurance) of amounts due for services covered by a
              health plan, health insurance or other type of
              insurance that covers the costs of medical or health care
              services, (ii)costs associated with services that are not covered
              by a health plan, health insurance or other type of insurance,
              premiums or travel related charges, and (iii) fees or non-
              refundable charges for missed session(s), appointment(s), late
              cancellations, admission assessments, or late payments.
            </p>

            <p>
              <strong>PFR ADJUSTMENTS:</strong> Patient and Guarantor
              acknowledge that the PFR Amount is based on an estimate of
              Patient’s
              PFR for healthcare services with Provider and subject to
              adjustment. Patient and Guarantor, as applicable,
              acknowledge that the PFR Amount may increase if there is a change
              to the level(s) of care or duration of services
              provided to Patient, the purchase of additional services, or the
              denial of payment for services by Patient’s insurer.
              Patient/Guarantor hereby authorizes Provider to auto adjust
              (credit or debit) the PFR Amount once all Provider charges
              are determined, or if applicable, upon the final adjudication of
              any health insurance claim(s) with Provider. In the
              event there is an increase of the PFR Amount under this Agreement,
              Patient and Guarantor, as applicable, authorize
              Provider to extend the term of the Payment Program for any number
              of months necessary to keep the Patient Payment
              Program’s recurring payments unchanged.
            </p>

            <p>
              Provider may give Patient and Guarantor, as applicable, advance
              notice of such change(s). Unless Patient/Guarantor
              contacts Provider or FinPay to make alternative payment
              arrangements, the extension of the Payment Program term
              will go into effect. The failure to contact Provider to make
              alternative payment arrangements will result in the
              immediate implementation of the new payments terms and
              corresponding adjustment to the PFR.
            </p>

            <p>
              Patient and Guarantor, as applicable, have given Provider a valid
              email address where Provider shall send notice of
              any adjustments to the PFR Amount (credit or debit) and Provider
              shall not be responsible if the email account
              provided to Provider is changed or canceled without prior written
              notification to Provider. Patient and Guarantor, as
              applicable, have access to their Payment Program and authorization
              on the FinPass Platform where Patient and
              Guarantor, as applicable, can view and manage the Payment Program,
              make changes to linked funding sources, and
              make requests to change the Payment Program terms; provided
              however, no changes to the Payment Program terms
              requested the Patient or Guarantor, shall be effective unless
              agreed to in writing by Provider.
            </p>

            <p>
              <strong>ATTORNEYS’ FEES AND OTHER COLLECTION
                COSTS.</strong> Patient and Guarantor, as applicable, shall be
              responsible to pay or reimburse Provider for all expenses incurred
              by Provider in enforcing or collecting any of the
              PFR Amount including, without limitation, litigation expenses and
              reasonable attorneys’ and experts’ fees and costs,
              whether incurred without the commencement of a suit, in any trial,
              arbitration, or administrative proceeding or in any
              appellate, collection or bankruptcy proceeding.
            </p>

            <p>
              <strong>DEFAULT.</strong> If any of the following occurs with
              respect to either the Patient or Guarantor, a default (“Default”)
              under
              this Agreement shall exist, whatever the reason therefor:
              <ul style={{listStyle: 'none', paddingLeft: '3rem'}}>
                <li>
                  <strong>Nonpayment; Nonperformance.</strong> The failure to
                  timely pay or perform any of the PFR as and when due or
                  required under this Agreement.
                </li>
                <li>
                  <strong>False Warranty.</strong> A warranty or representation
                  made to Provider in connection with this Agreement and/or
                  the ability of the patient of Guarantor hereunder is false.
                </li>
                <li>
                  <strong>Failure to Maintain.</strong> The failure to pay and
                  maintain the Payment Program and authorization as set forth
                  herein.
                </li>
                <li>
                  <strong>REMEDIES UPON DEFAULT.</strong> If a Default occurs
                  under this Agreement, Provider will have and be
                  entitled to exercise all rights and remedies under this
                  Agreement and/or applicable law, including but not
                  limited to, accelerating the maturity of all amounts due and
                  owing to Provider hereunder such that such
                  amounts shall become due and owing immediately.
                </li>
              </ul>
            </p>

            <p>
              <strong>WAIVERS AND AMENDMENTS.</strong>No waivers, amendments, or
              modifications of this Agreement shall be valid
              unless in writing and signed by an officer of Provider. No waiver
              by Provider of any Default shall operate as a waiver
              of any other Default or the same Default on a future occasion.
              Neither the failure nor any delay on the part of Provider
              in exercising any right, power or remedy under this Agreement
              shall operate as a waiver thereof, nor shall a single or
              partial exercise thereof preclude any other or further exercise
              thereof or the exercise of any other right, power or
              remedy.
            </p>

            <p>
              Except to the extent otherwise prohibited by law, Patient and
              Guarantor, as applicable, and each other person liable
              under this Agreement waives presentment, protest, notice of
              dishonor, demand for payment, notice of intention to
              accelerate maturity, notice of acceleration of maturity, notice of
              sale and all other notices of any kind.
            </p>

            <p>
              <strong>NOTICES.</strong>Any notices to Patient and Guarantor
              shall be sufficiently given, if by electronic communication such
              as
              email or text, in writing, and either hand delivered, sent by
              certified mail or by overnight delivery to the Patient and
              Guarantor’s address, as applicable, shown on the signature page
              hereto or such other address as provided hereunder,
              and to Provider, if in writing and mailed by certified mail,
              return receipt requested or reputable overnight courier, or
              hand delivered to the address of Provider. Notices to Provider
              must include the mail code. In the event that Patient or
              Guarantor, as applicable, changes their address, email address, or
              phone number at any time prior to the date the PFR
              is paid in full, Patient and Guarantor, as applicable, agree to
              promptly give written notice of said change of address
              by registered or certified mail, return receipt requested, all
              charges prepaid.
            </p>

            <p><strong>MISCELLANEOUS PROVISIONS</strong></p>

            <p>
              <strong>Assignment.</strong>This Agreement shall inure to the
              benefit of and be binding upon the parties hereto and their
              respective
              heirs, legal representatives, successors, and assigns. Provider’s
              interests in and rights under this Agreement and are
              freely assignable, in whole or in part, by Provider.
              Patient/Guarantor shall not assign his/her respective rights,
              duties
              or interest hereunder without the prior written consent of
              Provider, and any attempt by Patient/Guarantor to assign this
              Agreement without Provider’s prior written consent shall be null
              and void ab initio. Any assignment shall not release
              Patient/Guarantor from the PFR.
            </p>

            <p>
              <strong>Applicable Law; Conflict Between Documents.</strong> This
              Agreement shall be governed by and interpreted in accordance
              with federal law and, except as preempted by federal law, the laws
              of Delaware without regard to that state’s conflict
              of laws principles.
            </p>

            <p>
              <strong>Jurisdiction.</strong> Conflict Between Documents. This
              Agreement shall be governed by and interpreted in accordance
              with federal law and, except as preempted by federal law, the laws
              of Delaware without regard to that state’s conflict
              of laws principles.
            </p>

            <p>
              <strong>Severability.</strong> If any provision of this Agreement
              shall be prohibited or invalid under applicable law, such
              provision
              shall be ineffective but only to the extent of such prohibition or
              invalidity, without invalidating the remainder of such
              provision or the remaining provisions of this Agreement.
            </p>

            <p>
              <strong>Payments.</strong> The Patient and/or Guarantor, as
              applicable, agree to register and maintain their Payment Program
              and
              authorization for managing and making payments covered under this
              Agreement as a condition of Provider entering
              into this Agreement. Automated and self-service payments shall be
              made by the Patient or Guarantor at
              https://myfinpay.net. Patient and Guarantor agree to execute all
              additional documents as reasonably requested by
              Provider or FinPay.
            </p>

            <p>
              <strong>Plural; Captions.</strong> All references in this
              Agreement to Guarantor, Patient/Guarantor, person, document, or
              other
              nouns of reference mean both the singular and plural form, as the
              case may be, and the term “person” shall mean any
              individual, person or entity. The captions contained in this
              Agreement are inserted for convenience only and shall not
              affect the meaning or interpretation of the Agreement.
            </p>

            <p>
              <strong>Posting of Payments.</strong> All payments received during
              normal banking hours after 2:00 p.m. local time at the address
              for payments set forth above shall be deemed received at the
              opening of the next banking day.
            </p>

            <p>
              <strong>Joint and Several Liability for PFR/Guaranty of
                Payment.</strong> Subject to the paragraph immediately below,
              the
              Patient and Guarantor are jointly and severally obligated for the
              PFR. The Guarantor is guaranteeing payment, not
              collection, of all amounts de hereunder, and Provider shall not be
              required to provide notice to Patient or pursue the
              Patient for any amounts due hereunder before pursuing payment by
              or collection of any amounts due hereunder from,
              the Guarantor.
            </p>

            <p>
              <strong>No Effect on Obligations.</strong> Each person signing
              this Agreement as a Patient or Guarantor agrees that they shall be
              fully and individually (or if more than one person other than
              Provider signs this Agreement, jointly with such other
              person) liable for all obligations hereunder whether or not any
              other person signs this Agreement, and the fact that no
              other person signs Agreement as a Patient or Guarantor shall not
              in any way reduce obligation of a person signing this
              Agreement as Parent or Guardian hereunder.
            </p>

            <p>
              <strong>Final Agreement.</strong> This Agreement represents the
              final agreement between the parties and may not be contradicted
              by evidence of prior or contemporaneous agreements of the parties.
            </p>

            <p>
              By accepting the terms of this Installment Agreement as the
              Patient and in consideration of Provider’s agreement to
              render services or procedures to me, the sufficiency of which is
              hereby acknowledged, I hereby consent and agree to
              the payments, financial arrangements and other terms and
              conditions described in this Installment Agreement.
            </p>

            <p>
              By accepting the terms of this Installment Agreement as the
              Guardian or Parent of the Patient and in consideration of
              Provider’s agreement to render services or procedures to Patient,
              the sufficiency of which is hereby acknowledged, I
              hereby consent and agree to the payments, financial arrangements
              and other terms and conditions described in this
              Installment Agreement on behalf of Patient. I represent and
              warrant that I have the full power and authority to bind
              the Patient to such payments arrangements and other terms and
              conditions.
            </p>

            <p>
              By accepting the terms of this Installment Agreement as a
              Guarantor and in consideration of Provider’s agreement to
              render services or procedures to Patient, the sufficiency of which
              is hereby acknowledged, I hereby, unconditionally
              and irrevocably guarantee to Provider the prompt and full payment
              (and not merely the collectability), performance,
              and observance of all of the payments, obligations, terms and
              conditions to be paid, performed or observed by Patient
              under this Financial Installment Agreement. I understand that my
              liability for such payments, obligations, terms and
              conditions shall be joint and several with the Patient and any
              other Guarantor accepting the terms of this Installment
              Agreement; provided however, I shall be solely and fully liable
              for such payments obligations, terms and conditions
              if neither the Patient and or any other Guarantor accept such
              terms.
            </p>

          </Grid>
        </Grid>
      </DialogContent>
    </>
);

const FEDERAL_TRUTH_IN_LENDING_DISCLOSURE_STATEMENT = (
    <>
      <DialogTitle>
        Federal Truth-In-Lending Disclosure Statement
      </DialogTitle>
      <DialogContent
          sx={{overflow: 'unset'}}
          test-id="federal-truth-in-lending"
      >
        <Grid container direction="row">
          <Grid item direction="column" md={12} xs={12} className="spa-content">

            <Grid
                style={{
                  width: '100%',
                  border: '1px solid',
                  padding: '1rem 0 0 1rem',
                  marginBottom: '2rem',
                }}>
              <p>
                Provider: Provider as defined in my Payment Program Page
              </p>
              <p>
                Date: The date the “I Accept” checkbox is executed in the
                Payment Program Page
              </p>
              <p>
                Guarantor(s): As defined above
              </p>
              <p>
                Final disclosure based on contract
              </p>
            </Grid>

            <Grid
                container
                item
                style={{
                  width: '100%',
                  border: '1px solid',
                }}
                sx={{mb:1}}
            >
              <Grid item xs={3} sx={{p: 2}}>
                <p>
                  <strong>ANNUAL PERCENTAGE RATE</strong>
                  <br/>
                  The cost of your credit as a yearly rate.
                  <br/>
                  <strong>0.00%</strong>
                </p>
              </Grid>
              <Grid item xs={3} sx={{p: 2}}>
                <p>
                  <strong>FINANCE CHARGE</strong>
                  <br/>
                  The dollar amount the credit
                  will cost you assuming the
                  annual percentage rate does not
                  change.
                  <br/>
                  <strong>$0.00</strong>
                </p>
              </Grid>
              <Grid item xs={3} sx={{p: 2}}>
                <p>
                  <strong>AMOUNT FINANCED</strong>
                  <br/>
                  The amount of credit provided to
                  you based on your behalf as of the
                  credit closing. Details of which
                  are defined in the Payment
                  Program Page as Remaining
                  Balance.
                </p>
              </Grid>
              <Grid item xs={3} sx={{p: 2}}>
                <p>
                  <strong>TOTAL OF PAYMENTS</strong>
                  <br/>
                  The amount you will have paid after you
                  have made all payments as scheduled
                  assuming the annual percentage rate
                  does not change. Details of which are
                  defined in the Payment Program
                  Page.
                </p>
              </Grid>
            </Grid>

            <p>
              Annual percentage rate does not take into account any deposit you
              may have been required to make
              by your Provider. Your payment schedule is defined in the Payment
              Program Page and the
              Installment Agreement.
            </p>

            <p>
              <strong>Late Charge: </strong>If a payment is late, you will be charged 0.00% of the payment.
              <br/>
              <strong>Prepayment: </strong>If you pay off early, you will not pay a penalty.
              <br/>
              <strong>Default: </strong>If in default, your account may be placed to an attorney or a 3rd party collection company, Provider may
              also report your account to consumer credit bureau(s).
            </p>

            <p>
              <i>See your Installment Agreement for additional information about prepayments, default, any
                required repayment in full before scheduled date.</i>
            </p>

            <p>
              You are not required to complete the Installment Agreement merely because you received this disclosure.
            </p>

            <p>
              <i>I hereby acknowledge receiving and reading this disclosure along with copies of the documents provided. The
                delivery and acknowledgement of this disclosure does not constitute an obligation on part of the Provider to
                make, or the Patient/Guarantor(s) to accept the credit as identified. All Dates and numerical & currency
                disclosures, except late payment disclosures, are estimates.</i>
            </p>

            <Grid
              container
              item
              style={{
                width: '100%'
              }}
            >
              <p style={{  border: '1px dotted', width: '100%', padding: '0.5rem 0.5rem'}}>
                <strong>Itemization of this Agreement is defined in the Payment Program Page.</strong>
              </p>
               <p style={{  border: '1px dotted', width: '100%', padding: '0.5rem 0.5rem'}}>
                Itemization of the amount owed is defined in the Payment Program Page.
              </p>
               <p style={{  border: '1px dotted', width: '100%', padding: '0.5rem 0.5rem'}}>
                $ Amount owed is defined as the Estimated Patient Financial Responsibility
                in the Payment Program Page.
              </p>
               <p style={{  border: '1px dotted', width: '100%', padding: '0.5rem 0.5rem'}}>
                $ Down Payment is defined in the Payment Program Page.
              </p>
               <p style={{  border: '1px dotted', width: '100%', padding: '0.5rem 0.5rem'}}>
                $ Amount Financed is defined as the Remaining Balance in the Payment Program
                Page.
              </p>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </>
);

export const installment = [
  FINANCIAL_RESPONSIBILITY_AND_ASSIGNMENT_OF_BENEFITS,
  PAYMENT_AUTHORIZATION_TERMS_AND_CONDITIONS,
  INSTALLMENT_AGREEMENT,
  FEDERAL_TRUTH_IN_LENDING_DISCLOSURE_STATEMENT,
];

export const singlePaymentAgreement = [
  FINANCIAL_RESPONSIBILITY_AND_ASSIGNMENT_OF_BENEFITS,
  PAYMENT_AUTHORIZATION_TERMS_AND_CONDITIONS,
];

