import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StatusCard from "../../../shared/components/cards/status-card";
import { RootState } from "../../../shared/state/root-reducer";
import { convertedFilterTypes, preconvertedFilterTypes, } from "../models/patient-filter";
import { clearComments, clearSelectedEncounter, setRecentlyViewedPatients, } from "../../state/patient-slice";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { RecentlyViewedUser, UsersCookiesList } from "../../../shared/model/recentlyViewed";
import { PatientEncounterCard } from "../models/patient-encounter-card";
import { getInstanceOfCare, setRecentlyViewedPatient } from "../../state/patient-thunk";
import { RecentlyViewedTypes } from "../../../shared/enums";
import { TakePaymentModal } from '../../../shared/components/take-payment-modal';
import { patientHelper } from "../../services/patient-service";
import { workFlowStatus } from "../models/workflow-status";
import { AppDispatch } from "../../../shared/state/store";
import { PAYMENT_TYPES } from "../models/payment-type";
import { checkPermissions } from "../../../security/components/access-control";
import { RolePageNames } from "../../../security/model/role-page-names";
import { Utils } from 'src/shared/utils';

interface PatientFilterResultsProps {
  preconvertedPatients: { [filterByType: string]: PatientEncounterCard[] };
  convertedPatients: { [filterByType: string]: PatientEncounterCard[] };
}

function PatientFilterResults(props: PatientFilterResultsProps) {
  const { preconvertedPatients, convertedPatients } = props;

  const [paymentData, setPaymentData] = useState<any>(undefined);
  const [isTakePaymentModalOpen, setIsTakePaymentModalOpen] = useState(false);
  const navigate = useNavigate();

  const preConvertedFilterFormValues = useSelector((state: RootState) => {
    return state.patientContext.preConvertedFilterFormValues;
  });

  const stateFields = {
    convertedFilterFormValues: useSelector((state: RootState) => {
      // todo: use this state field for converted filters
      return state.patientContext.convertedFilterFormValues;
    }),
    isPreConverted: useSelector((state: RootState) => {
      return state.patientContext.isPreConverted;
    }),
    userName: useSelector((state: RootState) => {
      return state.userContext.userProfile.userName
    }),
    preconvertedPatientsState: useSelector((state: RootState) => {
      return state.patientContext.preconvertedPatients;
    }),
    convertedPatientsState: useSelector((state: RootState) => {
      return state.patientContext.convertedPatients;
    }),
    selectedPatient: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient
    }),
    userProfile: useSelector((state: RootState) => {
      return state.userContext.userProfile;
    }),
  }
  const {
    convertedFilterFormValues,
    isPreConverted,
    userName,
    preconvertedPatientsState,
    convertedPatientsState,
    selectedPatient,
    userProfile
  } = stateFields;

  const dispatch = useDispatch<AppDispatch>();

  const handleOnClick = async (patientId: number, encounterId: number, isPreConverted: boolean) => {
    dispatch(clearSelectedEncounter());
    if (patientId && encounterId && (encounterId !== 0) && (selectedPatient?.patientEncounters?.length > 0)) {
      dispatch(getInstanceOfCare({ patientId: patientId, encounterId: encounterId }))
    }

    if (isPreConverted) {
      const type = RecentlyViewedTypes.preConvertedPatients
      dispatch(setRecentlyViewedPatient({ patientId, encounterId, type }))
    } else {
      const type = RecentlyViewedTypes.convertedPatients
      dispatch(setRecentlyViewedPatient({ patientId, encounterId, type }))
    }

    navigate(`/specialist/dashboard/${patientId}`);
  };

  const getStatusText = (patient: PatientEncounterCard) => {
    if (patient?.workflow?.workflowStatus?.workflowStatusId === 14 || patient?.workflow?.workflowStatus?.workflowStatusId === 15) {
      return workFlowStatus.preconvert.filter((status) => status?.workflowSubStatus.workflowSubStatusId === patient?.workflow?.workflowSubStatus?.workflowSubStatusId)[0]?.workflowSubStatus?.workflowSubStatusName;
    } else if (patient?.workflow?.workflowStatus?.workflowStatusId === 8 || patient?.workflow?.workflowStatus?.workflowStatusId === 12) {
      return "Good Standing"
    } else if (patient?.workflow?.workflowStatus?.workflowStatusId === 13 || !patient?.workflow?.workflowSubStatus?.workflowSubStatusDesc) {
      return "New"; // consider null as "new" for now
    } else if (patient?.workflow?.workflowStatus?.workflowStatusId === 16) {
      return "Paid";
    } else {
      return (patient?.workflow?.workflowSubStatus?.workflowSubStatusDesc || "")
    }
  };

  // This is used to add the info from the cookie to redux
  useEffect(() => {
    const recentlyViewedListFromCookie: UsersCookiesList = Cookies.getJSON('recentlyViewed')
    if (recentlyViewedListFromCookie) {
      const { usersCookiesList } = recentlyViewedListFromCookie
      const index = usersCookiesList?.findIndex((recentlyViewed: RecentlyViewedUser) => (
        recentlyViewed.userName === userName
      ))
      if (index >= 0) {
        const preConvertedCookiPatients = patientHelper.removeNotFoundPatients(usersCookiesList[index].preConvertedPatients, preconvertedPatientsState)
        const convertedCookiePatients = patientHelper.removeNotFoundPatients(usersCookiesList[index].convertedPatients, convertedPatientsState)
        dispatch(setRecentlyViewedPatients({
          recentlyViewed: preConvertedCookiPatients,
          type: RecentlyViewedTypes.preConvertedPatients
        }))
        dispatch(setRecentlyViewedPatients({
          recentlyViewed: convertedCookiePatients,
          type: RecentlyViewedTypes.convertedPatients
        }))
      }
    }
    dispatch(clearSelectedEncounter());
    dispatch(clearComments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const hasMiddleName = (patient: PatientEncounterCard) =>
    !!patient?.contactCard?.middleName;

  const handleTakePayment = (patientEncounter: any) => {
    const paymentChannelId = Utils.getChannelId(userProfile);

    setPaymentData({
      patientId: patientEncounter?.patientId,
      patientEncounterId: patientEncounter.patientEncounterId,
      paymentChannelId
    });
    setIsTakePaymentModalOpen(true);
  }

  const handleTakePaymentModalClose = () => {
    setIsTakePaymentModalOpen(false);
    setPaymentData(undefined);
  }

  const getFilterResults = (filterBy: string) => {
    const patientsToMap: { [filterByType: string]: PatientEncounterCard[] } =
      isPreConverted ? preconvertedPatients : convertedPatients;
    return (
      <Grid container spacing={2} className="mb-8">
        {patientsToMap?.[filterBy]?.length > 0 &&
          patientsToMap[filterBy].map((patient: PatientEncounterCard) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={`${patient?.patientId}-${patient?.patientEncounterId}`}
            >
              <StatusCard
                title={`${patient?.contactCard?.firstName} ${hasMiddleName(patient)
                    ? patient.contactCard.middleName + " . "
                    : ""
                  } ${patient?.contactCard?.lastName}`}
                statusText={`${patient.patientEncounterId
                    ? patient.patientEncounterId +
                    " - " +
                    patient.clientName +
                    " - " +
                    patient.clientFacilityName
                    : ""
                  } `}
                reminderDate={patient?.completionDueDt}
                createdDate={patient?.createDt}
                isPreConverted={isPreConverted}
                subtitle={getStatusText(patient)}
                admissionDate={patient?.admissionDt}
                pfrTimingRiskPayorRisk={
                  (patient?.pfrAmt || patient?.timingRisk?.timingRiskName || patient?.payorRisk?.payorRiskName) ? `${(patient?.pfrAmt ? `Pfr: $${patient?.pfrAmt.toLocaleString()}` : "No PFR") +
                    " - " +
                    (patient?.timingRisk?.timingRiskName ? patient?.timingRisk?.timingRiskName : "No Timing Risk") +
                    " - " +
                    (patient?.payorRisk?.payorRiskName ? patient?.payorRisk?.payorRiskName : "No Payor Risk")
                    }` : ""}
                riskClassName={patient?.riskClass?.riskClassName}
                typeOfCareName={patient?.typeOfCare?.typeOfCare}
                statusColor={patient.cardColor}
                onClick={() => handleOnClick(patient.patientId, patient.patientEncounterId, isPreConverted)}
                handleTakePayment={() => {
                  handleTakePayment(patient);
                }}
              />
            </Grid>
          ))}
      </Grid>
    );
  };

  const preconvertedFilters = (
    <div className="client-filter-results">
      {preConvertedFilterFormValues.recentlyViewed && (
        <>
          <Typography variant="h1" className="mb-4">
            Recently Viewed
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.recentlyViewed)}
        </>
      )}
      {preConvertedFilterFormValues.reminders && (
        <>
          <Typography variant="h1" className="mb-4">
            Reminders
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.reminders)}
        </>
      )}
      {preConvertedFilterFormValues.newNone && (
        <>
          <Typography variant="h1" className="mb-4">
            None
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.newNone)}
        </>
      )}
      {preConvertedFilterFormValues.newPendingPaymentAtFacility && (
        <>
          <Typography variant="h1" className="mb-4">
            Pending Payment at Facility
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.newPendingPaymentAtFacility)}
        </>
      )}
      {preConvertedFilterFormValues.educationalCurriculum && (
        <>
          <Typography variant="h1" className="mb-4">
            Educational Curriculum
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.educationalCurriculum)}
        </>
      )}
      {preConvertedFilterFormValues.paymentCurriculum && (
        <>
          <Typography variant="h1" className="mb-4">
            Payment Curriculum
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.paymentCurriculum)}
        </>
      )}
      {preConvertedFilterFormValues.awaitingConsentDocs && (
        <>
          <Typography variant="h1" className="mb-4">
            Awaiting Consent Docs
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.awaitingConsentDocs)}
        </>
      )}
      {preConvertedFilterFormValues.awaitingHREApproval && (
        <>
          <Typography variant="h1" className="mb-4">
            Awaiting HRE Approval
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.awaitingHREApproval)}
        </>
      )}
      {preConvertedFilterFormValues.secondContactAttempt && (
        <>
          <Typography variant="h1" className="mb-4">
            Second Contact Attempt
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.secondContactAttempt)}
        </>
      )}
      {preConvertedFilterFormValues.thirdContactAttempt && (
        <>
          <Typography variant="h1" className="mb-4">
            Third Contact Attempt
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.thirdContactAttempt)}
        </>
      )}
      {preConvertedFilterFormValues.awaitingClient && (
        <>
          <Typography variant="h1" className="mb-4">
            Awaiting Client
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.awaitingClient)}
        </>
      )}
      {preConvertedFilterFormValues.hreApprovedPaymentConfirmationRequired && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved By Client For Payment At Facility, Payment Confirmation Required
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreApprovedPaymentConfirmationRequired)}
        </>
      )}
      {preConvertedFilterFormValues.hreApprovedDirectBillFollowUp && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved By Client For Direct Bill Follow Up
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreApprovedDirectBillFollowUp)}
        </>
      )}

      {preConvertedFilterFormValues.mobilePaymentFailureFollowUp && (
        <>
          <Typography variant="h1" className="mb-4">
            Mobile Payment Unsuccessful
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.mobilePaymentFailureFollowUp)}
        </>
      )}

      {preConvertedFilterFormValues.mobilePaymentRetryLimit && (
        <>
          <Typography variant="h1" className="mb-4">
            Mobile Payment Retry Limit
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.mobilePaymentRetryLimit)}
        </>
      )}

      {preConvertedFilterFormValues.patientChampion && (
        <>
          <Typography variant="h1" className="mb-4">
            Payment Champion
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.patientChampion)}
        </>
      )}
      {preConvertedFilterFormValues.paymentMethodNeeded && (
        <>
          <Typography variant="h1" className="mb-4">
            Payment Methods Needed
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.paymentMethodNeeded)}
        </>
      )}
      {preConvertedFilterFormValues.noStatus && (
        <>
          <Typography variant="h1" className="mb-4">
            No Status
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.noStatus)}
        </>
      )}
      {preConvertedFilterFormValues.hreApproved && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreApproved)}
        </>
      )}
      {preConvertedFilterFormValues.patientFailedToEngage && (
        <>
          <Typography variant="h1" className="mb-4">
            Patient Failed To Engage
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.patientFailedToEngage)}
        </>
      )}
      {preConvertedFilterFormValues.fileError && (
        <>
          <Typography variant="h1" className="mb-4">
            File Error
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.fileError)}
        </>
      )}
      {preConvertedFilterFormValues.hreRejectedByPatient && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Rejected By Patient
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreRejectedByPatient)}
        </>
      )}
      {preConvertedFilterFormValues.hreRejectedByClient && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Rejected By Client
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreRejectedByClient)}
        </>
      )}
      {preConvertedFilterFormValues.patientNeverAdmitted && (
        <>
          <Typography variant="h1" className="mb-4">
            Patient Never Admitted
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.patientNeverAdmitted)}
        </>
      )}

      {preConvertedFilterFormValues.finpayFailedToEngage && (
        <>
          <Typography variant="h1" className="mb-4">
            FinPay Failed to Engage
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.finpayFailedToEngage)}
        </>
      )}

      {preConvertedFilterFormValues.duplicateRecord && (
        <>
          <Typography variant="h1" className="mb-4">
            Duplicate Record
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.duplicateRecord)}
        </>
      )}
      {preConvertedFilterFormValues.patientCoveredAt100Percent && (
        <>
          <Typography variant="h1" className="mb-4">
            Patient Covered at 100%
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.patientCoveredAt100Percent)}
        </>
      )}
      {preConvertedFilterFormValues.hreApprovedForPaymentAtFacilityPaymentConfirmed && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved by Client for Payment at Facility, Payment Confirmed
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreApprovedForPaymentAtFacilityPaymentConfirmed)}
        </>
      )}
      {preConvertedFilterFormValues.hreApprovedForFullScholarship && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved by Client for Full Scholarship
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.hreApprovedForFullScholarship)}
        </>
      )}
      {preConvertedFilterFormValues.mobilePaymentFailureClosed && (
        <>
          <Typography variant="h1" className="mb-4">
            Mobile Payment Failure
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.mobilePaymentFailureClosed)}
        </>
      )}
      {preConvertedFilterFormValues.mobilePaymentRetryLimitClosed && (
        <>
          <Typography variant="h1" className="mb-4">
            Mobile Payment Retry Limit
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.mobilePaymentRetryLimitClosed)}
        </>
      )}
      {preConvertedFilterFormValues.followUpNeededPendingPaymentAtFacility && (
        <>
          <Typography variant="h1" className="mb-4">
            Pending Payment at Facility
          </Typography>
          {isPreConverted &&
            getFilterResults(preconvertedFilterTypes.followUpNeededPendingPaymentAtFacility)}
        </>
      )}

    </div>
  );

  const convertedFilters = (
    <div className="client-filter-results">
      {convertedFilterFormValues.recentlyViewedPatientsConverted && (
        <>
          <Typography variant="h1" className="mb-4">
            Recently Viewed
          </Typography>
          {!isPreConverted &&
            getFilterResults(
              convertedFilterTypes.recentlyViewedPatientsConverted
            )}
        </>
      )}
      {convertedFilterFormValues.reminders && (
        <>
          <Typography variant="h1" className="mb-4">
            Reminders
          </Typography>
          {!isPreConverted && getFilterResults(convertedFilterTypes.reminders)}
        </>
      )}
      {convertedFilterFormValues.goodStanding && (
        <>
          <Typography variant="h1" className="mb-4">
            Good Standing
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.goodStanding)}
        </>
      )}
      {convertedFilterFormValues.paid && (
        <>
          <Typography variant="h1" className="mb-4">
            Paid
          </Typography>
          {!isPreConverted && getFilterResults(convertedFilterTypes.paid)}
        </>
      )}
      {convertedFilterFormValues.awaitingAuthorizations && (
        <>
          <Typography variant="h1" className="mb-4">
            Awaiting Authorizations
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.awaitingAuthorizations)}
        </>
      )}
      {convertedFilterFormValues.awaitingACHVerification && (
        <>
          <Typography variant="h1" className="mb-4">
            Awaiting ACH Verification
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.awaitingACHVerification)}
        </>
      )}
      {convertedFilterFormValues.missedPayment && (
        <>
          <Typography variant="h1" className="mb-4">
            Missed Payment
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.missedPayment)}
        </>
      )}
      {convertedFilterFormValues.missingFundingSource && (
        <>
          <Typography variant="h1" className="mb-4">
            Missing Funding Source
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.missingFundingSource)}
        </>
      )}
      {convertedFilterFormValues.pastDueBalance && (
        <>
          <Typography variant="h1" className="mb-4">
            Past Due Balance
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.pastDueBalance)}
        </>
      )}
      {convertedFilterFormValues.balanceAdjustmentRequired && (
        <>
          <Typography variant="h1" className="mb-4">
            Balance Adjustment Required
          </Typography>
          {!isPreConverted && getFilterResults(convertedFilterTypes.balanceAdjustmentRequired)}
        </>
      )}
      {convertedFilterFormValues.onHold && (
        <>
          <Typography variant="h1" className="mb-4">
            On Hold
          </Typography>
          {!isPreConverted && getFilterResults(convertedFilterTypes.onHold)}
        </>
      )}
      {convertedFilterFormValues.fileError_converted && (
        <>
          <Typography variant="h1" className="mb-4">
            File Error
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.fileError_converted)}
        </>
      )}
      {convertedFilterFormValues.patientFailedToEngage_converted && (
        <>
          <Typography variant="h1" className="mb-4">
            Patient Failed to Engage
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.patientFailedToEngage)}
        </>
      )}
      {convertedFilterFormValues.patientNeverAdmitted_converted && (
        <>
          <Typography variant="h1" className="mb-4">
            Patient Never Admitted
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.patientNeverAdmitted)}
        </>
      )}
      {convertedFilterFormValues.default && (
        <>
          <Typography variant="h1" className="mb-4">
            Default
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.default)}
        </>
      )}


      {convertedFilterFormValues.finpayFailedToEngage && (
        <>
          <Typography variant="h1" className="mb-4">
            FinPay Failed to Engage
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.finpayFailedToEngage)}
        </>
      )}
      {convertedFilterFormValues.duplicateRecord && (
        <>
          <Typography variant="h1" className="mb-4">
            Duplicate Record
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.duplicateRecord)}
        </>
      )}
      {convertedFilterFormValues.insurancePaidInFull && (
        <>
          <Typography variant="h1" className="mb-4">
            Insurance Paid in Full
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.insurancePaidInFull)}
        </>
      )}
      {convertedFilterFormValues.accountHolderPaidBalanceOwed && (
        <>
          <Typography variant="h1" className="mb-4">
            Account Holder Paid Balance Owed
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.accountHolderPaidBalanceOwed)}
        </>
      )}
      {convertedFilterFormValues.accountHolderPaidBalanceOwedDueARefund && (
        <>
          <Typography variant="h1" className="mb-4">
            Account Holder Paid Balance Owed and is Due a Refund
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.accountHolderPaidBalanceOwedDueARefund)}
        </>
      )}
      {convertedFilterFormValues.revokedPaymentPlan && (
        <>
          <Typography variant="h1" className="mb-4">
            Revoked Payment Plan
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.revokedPaymentPlan)}
        </>
      )}
      {convertedFilterFormValues.providersRequest && (
        <>
          <Typography variant="h1" className="mb-4">
            Provider's Request
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.providersRequest)}
        </>
      )}
      {convertedFilterFormValues.refinanced && (
        <>
          <Typography variant="h1" className="mb-4">
            Refinanced
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.refinanced)}
        </>
      )}
      {convertedFilterFormValues.hreApprovedDirectBillPaymentMadeToFacility && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved by Client for Direct Bill, Payment Made to Facility
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.hreApprovedDirectBillPaymentMadeToFacility)}
        </>
      )}
      {convertedFilterFormValues.hreApprovedPaymentAtFacility && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved by Client for Payment at Facility
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.hreApprovedPaymentAtFacility)}
        </>
      )}
      {convertedFilterFormValues.hreApprovedFullScholarship && (
        <>
          <Typography variant="h1" className="mb-4">
            HRE Approved by Client for Full Scholarship
          </Typography>
          {!isPreConverted &&
            getFilterResults(convertedFilterTypes.hreApprovedFullScholarship)}
        </>
      )}
    </div>
  );

  return (
    <>
      {isPreConverted ? preconvertedFilters : convertedFilters}
      {isTakePaymentModalOpen &&
        <TakePaymentModal
          open={isTakePaymentModalOpen}
          handleModalCancel={handleTakePaymentModalClose}
          paymentData={paymentData}
        />}
    </>
  );
}

export default PatientFilterResults;
