import { Button, Snackbar, TextField, Checkbox } from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Grid, Paper, Typography } from '@mui/material';
import {FormikValues, useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { hideStatus } from '../../security/state/user-slice';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import MobilePaymentsLoader from '../../shared/components/mobile-payments-loader';
import StripeCardField from '../../stripe/components/StripeCardField';
import { createPayNowMakePaymentRequest } from '../misc/make-payment-helper';
import { PaymentInfo } from '../models/anonymous-guest';
import { PayNowMakePaymentRequest } from '../models/pay-now-make-payment';
import {
  setGuestTakePaymentToken,
  setPatientInfo,
  setPaymentInfo,
  setPaymentIsProcessing,
  setPaymentRequest,
} from '../state/guest-slice';
import { getMakePaymentAuthToken, submitPayment, eventPayment } from '../state/guest-thunk';
import { guestPaymentInfoSchema } from '../validation/schema';
import HttpInterceptor from '../../shared/Http/http-interceptor';
import {PatientInfo} from './mobile-payment-view';
import {Payment} from '../../patient/components/models/payment';
import moment from 'moment';
import {
  AuthDocModal,
  installment, singlePaymentAgreement,
} from '../../shared/components/auth-doc-modal/auth-doc-modal';
import { ClientCrm, ClientStatusCardViewModel } from 'src/shared/model/client-status-card';
import { patientService } from 'src/patient/services/patient-service';
import { showErrorStatus } from 'src/security/state/user-slice';
import { processGuestUserRecord, processUserRecord } from 'src/shared/components/take-payment-modal/user-record-utils';

interface PaymentInfoViewProps{
  parentFormValues?: FormikValues
}

interface PaymentEventPayload {
  clientId: string | number | undefined,
  facilityId: string | number | undefined,
  crmObjectId: string,
  paymentAmt: number | null,
  paymentCaptureDate: string | null | undefined,
  cardHolderName: string | null | undefined,
  facilityName: string | null | undefined,
  extPaymentID: string | null | undefined
}

export function PaymentInfoView(props: PaymentInfoViewProps) {
  const [isSPAModalOpen, setiIsSPAModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isMobilePaymentLoader, setMobilePaymentLoader] = useState(false);
  const [paymentAuth, setPaymentAuth] = useState('');
  const { parentFormValues } = props;

  const queryParams = new URLSearchParams(window.location.search);
  const programId = queryParams.get('program');
  const [ownerId, setOwnerId] = useState<string | null>(null);

  const stateFields = {
    selectedGuest: useSelector((state: RootState) => {
      return state.guestContext.selectedGuest;
    }),
    paymentInfo: useSelector((state: RootState) => {
      return state.guestContext.paymentInfo;
    }),
    matchingRule: useSelector((state: RootState) => (state?.patientContext?.matchingRule)),
    patientEncounter: useSelector((state: RootState) => (state?.patientContext?.selectedEncounter)),
    isLoading: useSelector((state: RootState) => state.guestContext.isLoading),
    isPublicModeLoggedIn: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.isPublicModeLoggedIn
    ),
    isPublicMode: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isPublicMode
    ),
    userIsAuthenicated: useSelector(
      (state: RootState) => state.userContext.userIsAuthenticated
    ),
    configId: useSelector((state: RootState) => {
      return state.guestContext.configId;
    }),
    configIdSource: useSelector((state: RootState) => {
      return state.guestContext.configIdSource;
    }),
    guestContext: useSelector((state: RootState) => {
      return state.guestContext;
    }),
    paymentInfoContext: useSelector((state: RootState) => {
      return state.guestContext.paymentInfo;
    }),
    stripeToken: useSelector(
      (state: RootState) => state.guestContext?.guestTakePaymentTokens?.token
    ),
    stripeCardToken: useSelector(
      (state: RootState) =>
        state.guestContext?.guestTakePaymentTokens?.cardToken
    ),
    paymentAuthToken: useSelector((state: RootState) =>  state.guestContext?.paymentAuthToken),
    paymentAuthTokens: useSelector((state: RootState) =>  state.guestContext?.paymentAuthTokens),
    paymentRequest: useSelector((state: RootState) =>  state.guestContext?.paymentRequest),
    isPaymentProcessing: useSelector((state: RootState) =>  state.guestContext?.isLoading.paymentProcessing),
    isPaymentSuccessful: useSelector((state: RootState) =>  state.guestContext?.isPaymentSuccessful),
    submittedPaymentResponse: useSelector((state: RootState) =>  state.guestContext?.submittedPaymentResponse),
    applicationStatus: useSelector((state: RootState) => state.userContext.applicationStatus),
    isChannel: useSelector(
      (state: RootState) => state.guestContext.isChannel
    ),
    channelType: useSelector(
      (state: RootState) => state.guestContext.channelType
    ),
    paymentChannels: useSelector(
      (state: RootState) =>  state.guestContext.paymentChannels
    ),
    mobilePayment: useSelector(
        (state: RootState) =>  state.guestContext.mobilePayment
    ),
    clientFacilityDetails: useSelector(
        (state: RootState) =>  state.guestContext.clientFacilityDetails
    ),
    urlParameters: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.urlParameters
    ),
    allClients: useSelector(
      (state: RootState) =>
      state.implementationContext?.implementationSpecialistClient.allClientsWithFacillities
    ),
    isPatientPortal: useSelector((state: RootState) => state.userContext.userProfile?.isPatient),
    userProfile: useSelector((state: RootState) => state.userContext.userProfile),
    authToken: useSelector((state: RootState) => state.guestContext.authToken),
  };

  const {
    selectedGuest,
    stripeToken,
    stripeCardToken,
    isPublicModeLoggedIn,
    guestContext,
    paymentRequest,
    isPaymentSuccessful,
    submittedPaymentResponse,
    applicationStatus,
    configId,
    paymentAuthToken,
    paymentAuthTokens,
    isPaymentProcessing,
    isChannel,
    channelType,
    paymentChannels,
    mobilePayment,
    clientFacilityDetails,
    urlParameters,
    allClients,
    matchingRule,
    patientEncounter,
    isPatientPortal,
    userProfile,
    authToken
  } = stateFields;

  const client: ClientStatusCardViewModel | undefined = allClients && allClients.find(
    (c: ClientStatusCardViewModel) => c.clientId === patientEncounter?.clientId
  );
  const isIntegrationEnabled = client?.clientCrm?.some((crm: ClientCrm) => crm.isIntEnabled);
  
  const payorString = guestContext.bannerText?.substring(
    guestContext.bannerText?.indexOf(",") + 1
  );

  const stripeDisabledCallback = (isDisabledFromStripe: boolean) => {
    dispatch(setGuestTakePaymentToken({ token: "", cardToken: "" }));
  };

  const stripeTokenCallback = (data: {
    token?: string;
    cardToken?: string;
    bankToken?: string;
  }) => {
    const { token, cardToken, bankToken } = data;
    if (token && cardToken) {
      dispatch(
        setGuestTakePaymentToken({ token: token, cardToken: cardToken })
      );
    } else if (bankToken) {
      dispatch(setGuestTakePaymentToken({ bankToken: bankToken }));
    }
  };

  function getEmail(){
    let payerEmail;
    if(channelType === 'mobile' && props.parentFormValues){
      payerEmail = props.parentFormValues.email
    }else if(selectedGuest?.isPatientAddrSameAsBilling){
      payerEmail = selectedGuest?.email
    }else{
      payerEmail = ""
    }
    return payerEmail
  }

  const initialPaymentInfo = {
    payerEmail: getEmail(),
    //do we need to prefill name for card?
    nameOnCard: selectedGuest?.isPatientAddrSameAsBilling
      ? selectedGuest?.firstName + " " + selectedGuest?.lastName
      : "",
    paymentAmount: channelType === 'mobile'
      ? (parentFormValues?.paymentAmount || mobilePayment.patientInfo?.pfrAmt)
      : "",
    stripeCardToken: "",
    stripeToken: "",
    acknowledgeSPA: false
  } as PaymentInfo;

  const initialFieldsTouched = {
    payerEmail: true,
    nameOnCard: true,
    paymentAmount: true,
  };

  let validationPaymentSchema = Yup.object(guestPaymentInfoSchema);

  const paymentFormik = useFormik({
    initialValues: initialPaymentInfo,
    enableReinitialize: true,
    onSubmit: (values) => {
      attemptMakePayment(values);
    },
    validationSchema: validationPaymentSchema,
    initialTouched: initialFieldsTouched,
  });

  async function createMobilePaymentRequest(patientInfo: PatientInfo, paymentInfo: PaymentInfo): Promise<Payment>{
    return {
      isAuthExpired: false,
      isAuthOnly: false,
      isManual: true,
      patientEncounterId: patientInfo.patientEncounterId,
      patientId: patientInfo.patientId,
      paymentAmt: paymentInfo.paymentAmount as number,
      paymentId: 0,
      paymentInitDt: new Date().toISOString(),
      paymentMethods: [
        {
          externalPaymentId: paymentInfo.stripeToken,
          externalPaymentMethodId: "card",
          nameOnCard: paymentInfo.nameOnCard,
          paymentMethodTypeId: 1,
          payorId: ""
        }
      ],
      paymentType: {
        paymentTypeId: 1,
        paymentTypeName: "Down Payment"
      },
      receiptEmail: paymentInfo.payerEmail,
      paymentChannelId: 4, //always FinMobile
      ownerId: Number(ownerId!)
    }
  }

  async function attemptMakePayment(formValues: PaymentInfo) {
    try {
      // Set loading state and prepare payment info
      dispatch(setPaymentIsProcessing(true));
      const savePaymentInfo = mapToSaveModel();
      dispatch(setPaymentInfo(savePaymentInfo));
      
      // Update patient info with email
      dispatch(setPatientInfo({
        ...mobilePayment.patientInfo,
        email: formValues.payerEmail
      }));

      // Create the payment request
      let paymentRequest;
      if (channelType === "mobile" && props.parentFormValues) {
        console.log("%cFinPay Mobile Payment", "background: blue; color: white; font-weight: bold; padding: 4px; border-radius: 4px;");
        // For mobile payments, fetch user record first
        const fullName = formValues.nameOnCard || "";
        const firstName = fullName.trim().split(" ")[0];
        const lastName = fullName.trim().replace(/^\S+\s*/, "");

        // Fetch user record only for mobile payments
        const userRecord = await processGuestUserRecord(
          formValues.payerEmail,
          authToken,
          false,
          () => {}, 
          {
            userEmail: formValues.payerEmail,
            firstName,
            lastName,
            clientId: Number(clientFacilityDetails.clientId),
            patientEncounterId: patientEncounter.patientEncounterId,
            dispatch,
          },
          (userId) => setOwnerId(userId) // Update state for reference
        );

        // Create the mobile payment request
        paymentRequest = await createMobilePaymentRequest(
          mobilePayment.patientInfo,
          savePaymentInfo
        );

        // Add the owner ID to the payment request if available
        if (userRecord && userRecord.userId) {
          paymentRequest = {
            ...paymentRequest,
            ownerId: userRecord.userId
          };
        }

      } else {
        // For non-mobile payments, create payment request without user record
        console.log("%cFinPay Guest Payment", "background: red; color: white; font-weight: bold; padding: 4px; border-radius: 4px;");
        paymentRequest = await createPayNowMakePaymentRequest(
          configId, 
          selectedGuest, 
          savePaymentInfo
        );
      }

      // Dispatch the payment request and get auth token
      dispatch(setPaymentRequest(paymentRequest));
      dispatch(getMakePaymentAuthToken());
    } catch (error) {
      console.error("Error in payment preparation:", error);
      dispatch(showErrorStatus("Failed to prepare payment. Please try again."));
      dispatch(setPaymentIsProcessing(false));
    }
  }

  useEffect(() => {
    if (!configId) {
      window.location.href = window.location.href.substring(0, window.location.href.indexOf('/webpayment'));
    }
  }, [
    configId
  ]);

  const processError = (response: any) => {
    if (response.entity?.code) dispatch(showErrorStatus(response.entity?.message || response.entity));
    else dispatch(showErrorStatus(response.entity || response.errorMessage));
  }

  useEffect(() => {
    const makePayment = async () => {
      if(channelType === 'mobile'){
        setMobilePaymentLoader(true);
        //temp solution before Intraprise team refactor
        HttpInterceptor(false, paymentAuthTokens, true);
      }

      const pfrAmt = patientEncounter.pfrAmt;
      const optDownPayment = matchingRule?.optimalDownPmtType === '%'
        ? matchingRule?.optimalDownPmt * pfrAmt / 100
        : matchingRule?.optimalDownPmt;
      const recurringAmt = (pfrAmt - optDownPayment) / +matchingRule?.optimalTerm;
      const response = await dispatch(submitPayment({
        "payment": paymentRequest,
        "authToken": paymentAuthToken,
        "isOptimal": programId === 'optimal',
        extraData: {
          paymentDueAmt: recurringAmt,
          paymentFreq: 'M',
          scheduleStartDt: moment().add(1, 'months').toISOString(),
          remainingTerms: +matchingRule?.optimalTerm,
          terms: +matchingRule?.optimalTerm,
        },
      }))
      if (response?.type !== "patientContext/getInstanceOfCare/rejected") {
        if (isIntegrationEnabled) {
          await patientService.integrationUpdate({
            patientEncounterId: patientEncounter.patientEncounterId,
            patientId: patientEncounter.patientId,
            crmTypeSlug: client?.clientCrm?.[0]?.crmType?.crmTypeSlug,
          });
        }
      } else {
        processError(response);
      }
    }
    if (paymentRequest && paymentAuthToken) {
      setPaymentAuth(paymentAuthToken)
      makePayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentRequest, paymentAuthToken
  ]);

  useEffect(() => {
    if (isPaymentSuccessful) {
      setMobilePaymentLoader(false);
      if (urlParameters.crmObjectId) {
        const eventPaymentPayload: PaymentEventPayload = {
          clientId: clientFacilityDetails.clientId,
          facilityId: paymentRequest?.clientFacilityId || clientFacilityDetails.clientFacilityId,
          crmObjectId: urlParameters.crmObjectId,
          paymentAmt: Number(paymentFormik?.values.paymentAmount), 
          paymentCaptureDate: submittedPaymentResponse?.paymentCaptureDate,
          cardHolderName: paymentFormik?.values.nameOnCard,
          facilityName: clientFacilityDetails.facilityName,
          extPaymentID: submittedPaymentResponse.extPaymentID
        };
        dispatch(eventPayment({"payment": eventPaymentPayload, "authToken": paymentAuth}))
      }
      navigate("/webpayment/payment-submitted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPaymentSuccessful
  ]);

  useEffect(() => {
    if (applicationStatus.hasErrorOccurred) {
      setMobilePaymentLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applicationStatus
  ]);

  const mapToSaveModel = () => {
    const formValues = paymentFormik?.values;
    return {
      nameOnCard: formValues.nameOnCard,
      payerEmail: formValues.payerEmail,
      paymentAmount: formValues.paymentAmount,
      stripeToken: stripeToken,
      stripeCardToken: stripeCardToken,
    } as PaymentInfo;
  };

  function backToPatientInfo() {
    navigate(-1); // go back 1 page
  }

  function handleCloseSnackbar() {
    dispatch(hideStatus());
  }

  function handleCloseSPAModal() {
    setiIsSPAModalOpen(false)
  }

  return (
    <>
      <MobilePaymentsLoader showLoader={isMobilePaymentLoader} />
      {isPublicModeLoggedIn && (
        <div>
          <Grid
            container
            direction="row"
            style={{ backgroundColor: "#F5F7F9" }}
          >

            {channelType !== 'mobile' && (
                <>
                <Grid
                    item
                    direction="column"
                    md={12}
                    xs={12}
                    style={{ alignItems: "center", paddingTop: "1em", display: "flex" }}
                >
                  <h1>
                    Make a Payment
                    {isChannel && paymentChannels.length > 0 && paymentChannels.map((channel: any) => (
                        channel.shortName === channelType ? ' - ' + channel.paymentChannelName : ''
                    ))}
                  </h1>
                </Grid>
              <Grid
                item
                direction="column"
                md={12}
                xs={12}
                style={channelType === 'mobile' ? { paddingLeft: "25%", paddingTop: "1.1em", display: "flex" } : { alignItems: "center", paddingTop: "1.1em", display: "flex" }}
              >
                  {`Powered by FinPay, ${payorString}`}
                </Grid>
              <Grid
              item
              direction="column"
              md={12}
              xs={12}
              style={{
              paddingTop: "1.1em",
              paddingBottom: "1.1em",
              display: "flex",
              alignItems: "center",
            }}
              >
              <Button type="primary" onClick={backToPatientInfo}>
              Back To Patient Information
              </Button>
              </Grid>
                </>
            )}

          </Grid>
          <Paper
            style={
            channelType !== 'mobile' ?
            {
              maxWidth: "600px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10px",
              marginTop: "0.5em",
              paddingLeft: "35px",
              paddingRight: "35px",
              paddingBottom: "20px",
            } : {
              paddingTop: "20px",
              boxShadow: "initial"
            }}
          >
            {channelType !== 'mobile' && 
              <Typography variant="h3" className="pt-2">
                Billing Information
              </Typography>
            }
            <form onSubmit={paymentFormik.handleSubmit} className="form-updates">
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  md={12}
                  style={channelType !== 'mobile' ? {paddingTop: "1em"} : {}}
                  className={channelType === 'mobile' ? 'input-item' : ''}
                >
                  <TextField
                    label="Payer Email (for Receipt)"
                    name="payerEmail"
                    required={true}
                    value={paymentFormik.values.payerEmail}
                    onChange={paymentFormik.handleChange}
                    onBlur={paymentFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={
                      paymentFormik.touched.payerEmail &&
                      paymentFormik.errors.payerEmail
                    }
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  md={12}
                  style={{
                    paddingTop: "1em",
                  }}
                  className={channelType === 'mobile' ? 'input-item' : ''}
                >
                  <TextField
                    label="Name On Card"
                    name="nameOnCard"
                    required={true}
                    value={paymentFormik.values.nameOnCard}
                    onChange={paymentFormik.handleChange}
                    onBlur={paymentFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={
                      paymentFormik.touched.nameOnCard &&
                      paymentFormik.errors.nameOnCard
                    }
                  />
                </Grid>
              </Grid>

              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  md={12}
                  style={{
                    paddingTop: "1em",
                  }}
                  className={channelType === 'mobile' ? 'input-item' : ''}
                >
                  <StripeCardField
                    disabledCallback={stripeDisabledCallback}
                    tokenCallback={stripeTokenCallback}
                    data={{
                      name: paymentFormik.values.nameOnCard,
                      address_country: "US"
                    }}
                    recurringPayment={false}
                    // clearStripeField={clearStripeField}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  md={12}
                  style={{
                    paddingTop: "1em",
                  }}
                >
                  <TextField
                    label="Pay Amount"
                    name="paymentAmount"
                    required={true}
                    value={paymentFormik.values.paymentAmount}
                    onChange={paymentFormik.handleChange}
                    onBlur={paymentFormik.handleBlur}
                    startAdornment={<AttachMoneyIcon />}
                    placeholder="0.00"
                    disabled={channelType === 'mobile'}
                    helperText=""
                    className={channelType === 'mobile' ? 'input-item button-holder' : ''}
                    error={
                      paymentFormik.touched.paymentAmount &&
                      paymentFormik.errors.paymentAmount
                    }
                  />
                </Grid>
              </Grid>
              {channelType === 'mobile' &&
                <Grid item direction="column" xs={12} md={12}
                    style={{
                      // paddingTop: "1em",
                    }}
                  >
                    <Checkbox
                      checked={paymentFormik.values.acknowledgeSPA}
                      id="acknowledgeSPA"
                      label={`I agree to the ${programId ? 'Installment Document' : 'Single Payment Agreement'}`}
                      name="acknowledgeSPA"
                      onChange={paymentFormik.handleChange}
                      marginBottom={1}
                    />
                    <div
                      style={{
                        fontSize: "15px",
                        paddingBottom: "10px"
                      }}
                    >Check here to indicate you have read and agree to the
                      <span
                        style={{
                          color: "#297ec1",
                          paddingLeft: "5px",
                          cursor: "pointer"
                        }}
                        onClick={() => setiIsSPAModalOpen(true)}>
                          { programId ? 'Installment Document' : 'Single Payment Agreement' }
                      </span>
                     </div>
                </Grid>
              }
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12}
                  md={12}
                  style={{
                    paddingTop: "1em",
                    textAlign: "right",
                  }}
                  className={channelType === 'mobile' ? 'input-item button-holder' : ''}
                >
                  <Button
                    type="primary"
                    isSubmitButton
                    disabled={
                      !(
                        paymentFormik.isValid &&
                        paymentFormik.dirty &&
                        stripeToken &&
                        !isPaymentProcessing &&
                          (channelType === 'mobile' ? paymentFormik.values.acknowledgeSPA === true : true)
                      )
                    }
                  >
                    Make Payment
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <Snackbar
              open={applicationStatus.showMessage}
              message={applicationStatus.message}
              type={applicationStatus.messageType}
              onClose={handleCloseSnackbar}
            />
          <AuthDocModal
              isOpen={isSPAModalOpen && programId !== 'optimal'}
              handleModalCancel={handleCloseSPAModal}
              authDocItems={singlePaymentAgreement}
          />
          <AuthDocModal
              isOpen={isSPAModalOpen && programId === 'optimal'}
              handleModalCancel={handleCloseSPAModal}
              authDocItems={installment}
          />
        </div>

      )}
    </>
  );
}

export default PaymentInfoView;
