import "../../../scss/components/_forms.scss";
import _ from "lodash/fp";


import SpecialistHeader from "./specialist-header";
import PatientTiles from "./patient-tiles";

const SpecialistDashboard = () => {

  return (
    <main>
      <div className="content fullwidth px-4 py-4">
        <SpecialistHeader canUseOpenSearch={true} />
        <PatientTiles />
      </div>
    </main>
  );
};

export default SpecialistDashboard;
